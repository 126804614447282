import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CuiMealCardLoading } from 'front-lib'
import moment from 'moment'

import { usePauseData } from 'src/api/PauseData/usePauseData'
import Modal from 'src/components/NewModal'
import Calendar from './Calendar/Calendar'
import tracking from 'src/shared/tracking'

import './FeedbackAfterPausingModal.scss'

interface FeedbackAfterPausingModalProps {
  onClose?: () => void
}

export function FeedbackAfterPausingModal({
  onClose = () => null
}: FeedbackAfterPausingModalProps) {
  const { t } = useTranslation()
  const { data, loading } = usePauseData()

  useEffect(() => {
    if (!data) {
      return
    }

    tracking.track('Overlay Viewed', {
      overlay_name: 'Your plan has been paused',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      overlay_trigger: 'automatic',
      page_name: 'My Plan'
    })
  }, [data])

  const handleClose = () => {
    tracking.track('Overlay Closed', {
      overlay_name: 'Your plan has been paused',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      overlay_trigger: 'automatic',
      page_name: 'My Plan'
    })

    onClose()
  }

  if (!data) return null

  const firstConfirmedOrder = data.days?.find(day => day.type === 'confirmed')

  const startDate = moment(data.startDate)
  const endDate = moment(data.endDate)

  // Calculate the difference in weeks
  const daysDifference = endDate.diff(startDate, 'days')
  const weeksDifference = Math.ceil(daysDifference / 7)

  return (
    <Modal
      isOpen
      mediumSize
      onRequestClose={handleClose}
      className="feedback-after-pausing-modal">
      <div className="modal-content">
        <div className="modal-title">{t('feedbackAfterPause.title')}</div>
        <div className="modal-description">
          <Trans
            i18nKey="feedbackAfterPause.description"
            values={{
              weeks: weeksDifference,
              startDate: moment(data.startDate).format('MMMM Do'),
              endDate: moment(data.endDate).format('MMMM Do')
            }}
            components={{
              strong: <strong />
            }}
          />
          {firstConfirmedOrder && (
            <strong>
              <br />
              {t('feedbackAfterPause.orderDate', {
                orderDate: moment(firstConfirmedOrder?.date).format('MMMM Do')
              })}
            </strong>
          )}
        </div>
        {loading && <CuiMealCardLoading />}
        {!loading && <Calendar days={data.days} />}
      </div>
    </Modal>
  )
}
