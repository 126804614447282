import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getReactivating } from '../../shared/reducers/reactivation'
import { reactivateSubscriptionInit } from '../../shared/actions/reactivation'

import {
  getResurrectionCampaign,
  getFeaturedMeals
} from 'src/redux-api-bridge/resurrection'
import {
  isResurrectionCampaignLoading,
  getResurrectionCampaignSelector,
  getFeaturedMealsSelector
} from 'src/redux-api-bridge/resurrection/selectors'

import ResurrectionPage from './ResurrectionPage'

const mapStateToProps = state => ({
  subscriptionInfo: state.api.subscription.info,
  isReactivating: getReactivating(state),
  isResurrectionCampaignLoading: isResurrectionCampaignLoading(state),
  resurrectionCampaign: getResurrectionCampaignSelector(state),
  featuredMeals: getFeaturedMealsSelector(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { reactivateSubscriptionInit, getResurrectionCampaign, getFeaturedMeals },
    dispatch
  )
})

export const Resurrection = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResurrectionPage)
