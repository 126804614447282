import { useEffect } from 'react'
import { CuiButton } from 'front-lib'

import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import {
  Step,
  MembershipData,
  MembershipOverlayName,
  MembershipOverlayActions
} from 'src/types/membership'

import './MembershipIntroduction.scss'

interface Props {
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipIntroduction({ onContinue, membershipData }: Props) {
  const { trackModalViewed, trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.introduction
  })

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.discover })
    onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
  }
  return (
    <>
      <div className="unity-pass-banner" />
      <div className="introduction-container">
        <div className="title-container">
          <span className="title">Introducing Unity Pass:</span>
          <span className="subtitle">Save more, savor more!</span>
        </div>
        <div className="description-container">
          <span className="description">
            Start enjoying free delivery, exclusive discounts, and three premium
            meals per order at no extra cost for only $
            {membershipData?.membership?.cost}/month.
          </span>
        </div>
        <div className="introduction-buttons">
          <CuiButton onClick={handleContinue}>
            Discover Unity Pass benefits
          </CuiButton>
        </div>
      </div>
    </>
  )
}
