import { combineReducers } from 'redux'

import app from '../shared/app/reducer'
import api from '../redux-api-bridge/reducer'
import inbox from '../redux-api-bridge/inbox/reducer'
import mealsMenuReducer from '../shared/reducers/mealsMenu'
import reactivationReducer from '../shared/reducers/reactivation'
import mealDetail from '../redux-api-bridge/mealDetail/reducer'
import orders from '../redux-api-bridge/order/reducer'
import payments from '../redux-api-bridge/payments/reducer'
import filters from '../redux-api-bridge/filters/reducer'
import changeAddress from '../redux-api-bridge/CheckChangeAddress/reducer'
import account from '../redux-api-bridge/account/reducer'
import menu from '../redux-api-bridge/menu/reducer'
import merchandiseSets from '../redux-api-bridge/merchandiseMeals/reducer'
import chefs from '../redux-api-bridge/chefs/reducer'
import share from '../redux-api-bridge/share/reducer'
import referralReducer from '../redux-api-bridge/referral/reducer'
import happyPathReducer from '../redux-api-bridge/happy-path/reducer'
import scheduleReducer from '../redux-api-bridge/schedule/reducer'
import ordersSwimlanes from '../redux-api-bridge/orders-swimlanes/reducer'
import ratings from 'src/redux-api-bridge/ratings/reducer'
import recipes from '../redux-api-bridge/recipes/reducer'
import fastOrdering from '../redux-api-bridge/fast-ordering/reducer'
import resurrectionReducer from '../redux-api-bridge/resurrection/reducer'
import preferences from '../redux-api-bridge/preferences/reducer'
import cart from '../redux-api-bridge/cart/reducer'
import mealsList from '../redux-api-bridge/mealsList/reducer'

import localstorage from './localstorage.js'
import { selectedDeliveryReducer } from 'src/redux/slices/selectedDelivery/slice'
import { trackDeliveryPanelReducer } from 'src/redux/slices/trackDeliveryPanel/slice'
import { challengeReducer } from 'src/redux-api-bridge/challenge/reducer'
import calendar from 'src/redux-api-bridge/calendar/reducer'

const rootReducer = combineReducers({
  app,
  challenge: challengeReducer,
  localstorage,
  api,
  inbox,
  mealDetail,
  orders,
  mealsMenu: mealsMenuReducer,
  reactivation: reactivationReducer,
  payments,
  filters,
  changeAddress,
  account,
  menu,
  merchandiseSets,
  chefs,
  share,
  happyPath: happyPathReducer,
  schedule: scheduleReducer,
  ordersSwimlanes,
  ratings,
  referralConfig: referralReducer,
  selectedDelivery: selectedDeliveryReducer,
  trackDeliveryPanel: trackDeliveryPanelReducer,
  recipes,
  fastOrdering,
  resurrection: resurrectionReducer,
  preferences,
  cart,
  mealsList,
  calendar
})

export default rootReducer
