window.dataLayer = window.dataLayer || []

function gtag(...args: any[]) {
  window.dataLayer.push(arguments)
}

export const gtm = {
  init() {
    gtag('js', new Date())
    gtag('config', process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID)
  },
  trackEvent({ name, data = {} }: { name: string; data?: any }) {
    gtag('event', name, {
      send_to: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}/${process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONVERSION_LABEL}`,
      transaction_id: '',
      ...data
    })
  }
}
