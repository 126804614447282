import { useState } from 'react'
import SlidingPanel from '../SlidingPanel'
import ChallengePanelContent from './Content/ChallengePanelContent'
import ChallengePanelHeader from './Header/ChallengePanelHeader'
import { ChallengeStepWithCopy } from 'src/types/challenge'

import './ChallengePanel.scss'

interface Props {
  onClose: () => void
  onClick: (ctaText: string) => void
  stepsData: ChallengeStepWithCopy[]
  isOpen: boolean
}

export const ChallengePanel: React.FC<Props> = ({
  stepsData,
  onClose,
  onClick,
  isOpen
}) => {
  const [openStep, setOpenStep] = useState(0)

  const handleSelectStep = step => {
    setOpenStep(step)
  }

  const handleClose = () => {
    setOpenStep(0)
    onClose()
  }

  const handleClick = ctaText => {
    onClick(ctaText)
    handleClose()
  }

  return (
    <SlidingPanel
      title="Challenge Panel"
      onClose={handleClose}
      header={<ChallengePanelHeader />}
      isOpen={isOpen}
      className={'challenge-panel'}
      content={
        <ChallengePanelContent
          stepsData={stepsData}
          openStep={openStep}
          handleSelectStep={handleSelectStep}
          onClick={handleClick}
        />
      }
    />
  )
}
