import './ReturnToWeeklyPlan.scss'

import { CuiButton } from 'front-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { SettingSection } from '../../../../components/SettingsCard'
import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'
import { formatNumber } from '../../../../utils/utils'
import { ReactComponent as InfoEmptyCircledIcon } from 'src/assets/svg/info-empty-circled.svg'
import { InformationModal } from './InformationModal'
import { ConfirmationModal } from './ConfirmationModal'
import { toaster } from 'src/utils/toaster'
import { useReturnToWeeklyPlanMutation } from '../../../../api/plan/returnToWeeklyPlanMutation'
import { initApiStore } from 'src/redux-api-bridge'

export function ReturnToWeeklyPlan() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [showInformationModal, setShowInformationModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)
  const deliveriesPerWeek = subscriptionPlan?.deliveries ?? 1
  const mealsPerDelivery = subscriptionPlan?.mealsPerDelivery ?? 1
  const price = subscriptionPlan?.price ?? ''
  const mealsPerWeek = mealsPerDelivery * deliveriesPerWeek
  const pricePerMeal = formatNumber(price / mealsPerWeek)
  const [reloadUserData, setReloadUserData] = useState(false)
  const {
    returnToWeeklyPlan,
    rest: { loading }
  } = useReturnToWeeklyPlanMutation()

  function handleOpenInformationModal() {
    setShowInformationModal(true)
  }

  function handleCloseInformationModal() {
    setShowInformationModal(false)
  }

  function handleOpenConfirmationModal() {
    setShowConfirmationModal(true)
  }

  function handleCloseConfirmationModal() {
    setShowConfirmationModal(false)
  }

  useEffect(() => {
    if (reloadUserData) {
      dispatch(initApiStore())
      setReloadUserData(false)
    }
  }, [reloadUserData, dispatch])

  async function handleUpgradePlan() {
    try {
      await returnToWeeklyPlan()
      toaster.success(t('pages.my_plan.return_to_weekly.action_success'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true
      })
      setShowConfirmationModal(false)
      setReloadUserData(true)
    } catch (error) {
      const message = `${t(
        'pages.my_plan.return_to_weekly.action_error'
      )} ${error}`
      toaster.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true
      })
    }
  }

  return (
    <>
      {showInformationModal && (
        <InformationModal
          onCloseHandler={handleCloseInformationModal}
          isOpen={showInformationModal}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          onCloseHandler={handleCloseConfirmationModal}
          onConfirmHandler={handleUpgradePlan}
          isOpen={showConfirmationModal}
          inRequest={loading}
        />
      )}

      <SettingSection
        className="cookunity__my_plan_section upgrade_to_standard_section"
        title={
          <div className="upgrade_to_standard_title">
            <CuiButton type="button" onClick={handleOpenInformationModal}>
              {t(
                'pages.my_plan.return_to_weekly.information_modal.trigger_label'
              )}
            </CuiButton>
            <InfoEmptyCircledIcon className="info-icon" />
          </div>
        }
        withActionButton={true}
        buttonLabel={t('pages.my_plan.return_to_weekly.cta')}
        onActionClick={handleOpenConfirmationModal}
        subtitle={
          <div className="upgrade_to_standard_subtitle">
            <p className="advice">
              <span className="">
                {t('pages.my_plan.return_to_weekly.advice')}
              </span>
            </p>
            <p className="note">
              <span>
                {t('pages.my_plan.return_to_weekly.note', {
                  price: pricePerMeal
                })}
              </span>
            </p>
          </div>
        }></SettingSection>
    </>
  )
}
