import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { CuiTag } from 'front-lib'
import { ComponentProps } from 'src/types/component'
import MealImage from 'src/components/MealImage'
import {
  Icon,
  IconName,
  Text,
  TextColor,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import { useMealFavorite } from 'src/hooks/useMealFavorite'
import { useMeals } from 'src/pages/orders/components/Main/Delivery/useMeals'
import { useShareProduct } from 'src/hooks/useShareProduct'
import { getStarIcon, RATINGS } from 'src/utils/rating'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import {
  getCurrentProductId,
  getProducts,
  getRatingProductForm
} from 'src/redux-api-bridge/ratings/selectors'
import { RatingProductFormAttributes } from 'src/redux-api-bridge/ratings/reducer'
import { getMealWishes } from 'src/redux-api-bridge/selectors'
import './MealRatingCardDetail.scss'

interface MealRatingCardProps extends ComponentProps {
  id: string
  title: string
  image: {
    name: string
  }
  onRate?: (rating: number) => void
}

export const MealRatingCardDetail: React.FC<MealRatingCardProps> = ({
  id,
  title,
  image,
  onRate,
  children
}) => {
  const isMobile = useMobileDevice()
  const ratingProductForm = useSelector(getRatingProductForm)
  const products = useSelector(getProducts)
  const currentProductId = useSelector(getCurrentProductId)
  const mealWishes = useSelector(getMealWishes)
  const isFavorite: boolean = mealWishes.includes(Number(id))

  const [currentHoveredStar, setCurrentHoveredStar] = useState(null)
  const onBlurRating = star => {
    setCurrentHoveredStar(star)
  }

  const { onLikeMeal, onUnLikeMeal } = useMeals()
  const { isLiked, toggleFavorite } = useMealFavorite({
    mealId: Number(id),
    isLikeMarked: isFavorite,
    onLike: onLikeMeal,
    onUnlike: onUnLikeMeal
  })

  const { onShare } = useShareProduct()
  const onShareFn = useCallback(() => onShare(id, title), [onShare, id, title])

  const isRatedProduct = !!products[id]?.rating

  return (
    <div className="meal-rating-card-detail">
      <MealImage
        mealImage={image.name || '/no-image'}
        classNameNoImgContainer="meal-rating-card-detail__no-image-container"
        classNameImage="meal-rating-card-detail__image"
        withoutText
      />
      <div className="meal-rating-card-detail__details">
        <div className="meal-rating-card-detail__details-top">
          <div className="meal-rating-card-detail__details-title-and-ratings">
            <Text
              value={title}
              style={isMobile ? TextStyle.SMALL_BOLD : TextStyle.MEDIUM_BOLD}
              color={TextColor.PRIMARY}
              className="meal-rating-card-detail__details-title"
            />
            <div
              className={classnames('meal-rating-card-detail__ratings', {
                clickable: !!onRate && !isRatedProduct
              })}>
              {RATINGS.map(star => (
                <Icon
                  key={star}
                  name={getStarIcon(
                    star,
                    currentHoveredStar,
                    ratingProductForm[RatingProductFormAttributes.RATING],
                    products[id]?.rating,
                    currentProductId === id
                  )}
                  onClick={() => !!onRate && !isRatedProduct && onRate(star)}
                  onMouseEnter={() =>
                    !isMobile &&
                    !!onRate &&
                    !isRatedProduct &&
                    onBlurRating(star)
                  }
                  onMouseLeave={() =>
                    !isMobile &&
                    !!onRate &&
                    !isRatedProduct &&
                    onBlurRating(null)
                  }
                />
              ))}
            </div>
          </div>
          {(!isMobile || !!onRate) && (
            <div className="meal-rating-card-detail__icons">
              <Icon
                name={
                  isLiked ? IconName.FULL_FAVORITE : IconName.EMPTY_FAVORITE
                }
                onClick={toggleFavorite}
              />
              <Icon name={IconName.SHARING} onClick={onShareFn} />
            </div>
          )}
        </div>
        <div className="meal-rating-card-detail__details-bottom">
          <div className="meal-rating-card-detail__chef">{children}</div>
          <div className="meal-rating-card-detail__rated-meal">
            {isRatedProduct && (
              <CuiTag
                color="green"
                fill="solid"
                size="small"
                className="meal-rating-card-detail__rated-meal-tag">
                <Icon name={IconName.CHECKMARK} />
                <Text
                  value="Rated"
                  style={TextStyle.XXS_BOLD}
                  color={TextColor.SECONDARY}
                />
              </CuiTag>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
