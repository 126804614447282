import './MyFlexModePlan.scss'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { CuiButton } from 'front-lib'
import { useSetAtom } from 'jotai'

import { ComparePlansModal } from './components/comparePlans/ComparePlansModal'
import {
  ComparePlansTourModal,
  useShouldShowComparePlansHighligthModal
} from './components/comparePlansTourModal/ComparePlansTourModal'
import { TOUR_HIGHLIGHT_TARGET_SELECTOR_COMPARE_PLANS } from './components/comparePlansTourModal/constants'
import { SwitchConfirmChangePlanModalDesktop } from './components/switchConfirmChangePlan/SwitchConfirmChangePlanModalDesktop'
import { SwitchConfirmChangePlanModalMobile } from './components/switchConfirmChangePlan/SwitchConfirmChangePlanModalMobile'
import { TOUR_HIGHLIGHT_TARGET_SELECTOR_SWITCH_PLAN } from './components/switchPlanTourModal/constants'
import { SwitchPlanTourModal } from './components/switchPlanTourModal/SwitchPlanTourModal'
import { getUserDeliveryInfoSelector } from '../../selectors'
import { useChangeDefaultPlan } from 'src/api/plan/useChangeDefaultPlan'
import { confirmationPlanChangeModalAtom } from 'src/atoms/modals/confirmationPlanChangeModal.atom'
import Header from 'src/components/Header/Header'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { COMPARE_PLANS_LOCALSTORAGE_KEY } from 'src/modules/Modals/constants'
import { getSubscriptionData } from 'src/redux-api-bridge'
import tracking from 'src/shared/tracking'
import { getPlatformText } from 'src/utils/utils'

export function MyFlexModePlan() {
  const dispatch = useDispatch()
  const [showComparePlansModal, setShowComparePlansModal] = useState(false)
  const [
    showComparePlansTourStepModal,
    setShowComparePlansTourStepModal
  ] = useState(useShouldShowComparePlansHighligthModal())
  const [
    showSwitchPlanTourStepModal,
    setShowSwitchPlanTourStepModal
  ] = useState(false)
  const [
    showSwitchConfirmChangePlanModal,
    setShowSwitchConfirmChangePlanModal
  ] = useState(false)
  const userDeliveryInfo: any = useSelector(getUserDeliveryInfoSelector)
  const { t } = useTranslation()
  const { changeDefaultPlan } = useChangeDefaultPlan({
    onSuccess
  })
  const isMobile = useMobileDevice()
  const platform = getPlatformText(isMobile)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAsViewed] = useLocalStorage(
    COMPARE_PLANS_LOCALSTORAGE_KEY,
    false
  )
  const setConfirmationPlanChangeModal = useSetAtom(
    confirmationPlanChangeModalAtom
  )

  const SwitchConfirmChangePlanModal = isMobile
    ? SwitchConfirmChangePlanModalMobile
    : SwitchConfirmChangePlanModalDesktop

  function onSwitchBackPlan() {
    const selectedDeliveryDays = userDeliveryInfo?.selectedDeliveryDays ?? []
    const selectedTimeSlot = userDeliveryInfo?.selectedTimeslot ?? {}

    const defaultPlanIdToChangeFromFlex = 39

    changeDefaultPlan({
      planChange: {
        plan: defaultPlanIdToChangeFromFlex,
        deliveryDays: {
          days: selectedDeliveryDays.map(d => d.day),
          start: selectedTimeSlot.start || '',
          end: selectedTimeSlot.end || ''
        }
      }
    })

    tracking.track('Meal Plan Updated', {
      page_name: 'My Plan',
      old_plan_id: 49,
      old_plan_name: 'FlexMode - 4 Meals 1 Delivery',
      new_plan_id: defaultPlanIdToChangeFromFlex,
      new_plan_name: 'ExperimentSeptember2022 - 4 Meals 1 Delivery',
      context: 'subscription'
    })

    setConfirmationPlanChangeModal({ isOpen: true })
  }

  function handleCloseComparePlansTourStepModal() {
    setShowComparePlansTourStepModal(false)
    setShowSwitchPlanTourStepModal(true)
  }

  function handleCloseSwitchPlanTourModal() {
    setAsViewed(true)
    setShowSwitchPlanTourStepModal(false)
  }

  function onSuccess() {
    dispatch(getSubscriptionData)
  }

  return (
    <>
      {showComparePlansModal && (
        <ComparePlansModal
          showComparePlansModal={showComparePlansModal}
          setShowComparePlansModal={setShowComparePlansModal}
          onSwitchBackPlan={onSwitchBackPlan}
        />
      )}
      {showSwitchConfirmChangePlanModal && (
        <SwitchConfirmChangePlanModal
          showSwitchConfirmChangePlanModal={showSwitchConfirmChangePlanModal}
          setShowSwitchConfirmChangePlanModal={
            setShowSwitchConfirmChangePlanModal
          }
          onSwitchBackPlan={onSwitchBackPlan}
        />
      )}
      {showComparePlansTourStepModal && (
        <ComparePlansTourModal
          handleCloseComparePlansTourStepModal={
            handleCloseComparePlansTourStepModal
          }
        />
      )}
      {showSwitchPlanTourStepModal && (
        <SwitchPlanTourModal
          handleCloseSwitchPlanTourModal={handleCloseSwitchPlanTourModal}
        />
      )}
      <Header />
      <div className="myPlan__flexMode__container">
        <h1 className="myPlan__flexMode__title">
          {t('pages.my_plan.flex_mode.you_are_currently_ordering_flex_mode')}
        </h1>
        <div className="myPlan__flexMode__bodyContainer">
          <div className="myPlan__flexMode__contentContainer">
            <h2 className="myPlan__flexMode__subtitle">
              {t('pages.my_plan.flex_mode.want_to_save_on_meals_and_time')}
            </h2>
            <p className="myPlan__flexMode__description">
              {t(
                `pages.my_plan.flex_mode.switch_back_to_the_weekly_plan.${platform}`
              )}
            </p>
          </div>
          <div className="myPlan__flexMode__buttonsContainer">
            <CuiButton
              className={`compare-plans ${TOUR_HIGHLIGHT_TARGET_SELECTOR_COMPARE_PLANS}`}
              onClick={() => setShowComparePlansModal(showModal => !showModal)}>
              {t('pages.my_plan.flex_mode.compare_with_weekly_plan')}
            </CuiButton>
            <div className="divider" />
            <CuiButton
              className={`compare-plans ${TOUR_HIGHLIGHT_TARGET_SELECTOR_SWITCH_PLAN}`}
              fill="outline"
              onClick={() =>
                setShowSwitchConfirmChangePlanModal(showModal => !showModal)
              }>
              {t('pages.my_plan.flex_mode.switch_to_weekly_plan')}
            </CuiButton>
          </div>
        </div>
      </div>
    </>
  )
}
