import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { getPreferencesOptionsSelector } from 'src/redux-api-bridge/preferences/selectors'
import './AllergiesSelector.scss'
import { FormItemHeader } from '../FormItemHeader/FormItemHeader'
import { SECTIONS } from '../ProgressBar/constants'

const AllergiesSelector = ({
  profileAllergies,
  toggleAllergy,
  noAllergySelected,
  onNoPreferenceSelection
}) => {
  const preferencesOptions = useSelector(getPreferencesOptionsSelector)
  return (
    <div className="preferences-allergies">
      <FormItemHeader
        title={preferencesOptions?.allergies?.title}
        subtitle={preferencesOptions?.allergies?.subtitle}
        info={preferencesOptions?.allergies?.info}
      />
      <div className="preferences-allergies__list">
        {preferencesOptions?.allergies?.items.map(allergy => (
          <div
            key={allergy.id}
            role="button"
            onClick={() => toggleAllergy(allergy)}
            className={classnames('preferences-allergies__item', {
              selected: profileAllergies?.find(a => a.id === allergy.id)
            })}>
            {allergy.label}
          </div>
        ))}
        <div
          role="button"
          onClick={() =>
            onNoPreferenceSelection(SECTIONS.ALLERGENS, !noAllergySelected)
          }
          className={classnames('preferences-allergies__item', {
            selected: noAllergySelected
          })}>
          No allergies
        </div>
      </div>
    </div>
  )
}

export { AllergiesSelector }
