import { CircularProgress } from 'src/componentsV2/CircularProgress/CircularProgress'
import './CompletenessCircle.scss'

const CompletenessCircle = ({ percentage }) => {
  if (percentage === 0)
    return <div className="completeness-circle__empty">😰</div>
  return (
    <div className="completeness-circle__progress">
      <CircularProgress
        percentage={percentage}
        colour="blue"
        diameterInPx={56}
        borderWidthInPx={6}
      />
    </div>
  )
}

export { CompletenessCircle }
