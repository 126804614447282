import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getAllCartsSelector = state => pathOr([], ['cart', 'carts'], state)

export const cartByDateSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts ? carts[date] : null
  })

export const shouldLoadGiftsSelector = date =>
  createSelector(
    isCartLoadingSelector,
    getCartErroredSelector,
    getAllGiftsSelector,
    (isLoading, isError, gifts) => {
      if (isLoading) return false
      if (isError) return false

      return !!gifts && !!date && !gifts[date]
    }
  )

export const cartQuantityByDateSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts && carts[date]
      ? carts[date].reduce((total, item) => {
          return total + item.quantity
        }, 0)
      : null
  })

export const getAllGiftsSelector = state => pathOr({}, ['cart', 'gifts'], state)

export const getGiftsByDateSelector = date =>
  createSelector(getAllGiftsSelector, gifts => (gifts ? gifts[date] : null))

export const productsWithStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock > 0) ?? []
  })

export const productsWithoutStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock <= 0) ?? []
  })

export const isCartLoadingSelector = state =>
  pathOr(false, ['cart', 'isLoading'], state)

export const isCartOpenSelector = state =>
  pathOr(false, ['cart', 'isOpen'], state)

export const getCartErroredSelector = state =>
  pathOr('', ['cart', 'isError'], state)

export const getUnifiedCarts = state =>
  pathOr(null, ['cart', 'unifiedCarts'], state)

export const isUnifiedCartOperationInFlight = state =>
  pathOr(null, ['cart', 'isCrudOperationInFlight'], state)

export const getUseUnifiedCart = state =>
  pathOr(null, ['cart', 'useUnifiedCart'], state)

export const getUnifiedCartsIsLoading = state =>
  pathOr(null, ['cart', 'isLoading'], state)
