import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'

import { Card, Logo, LoginBtn } from './components'

const LoginCard = styled(Card)`
  padding-top: 10%;
  max-width: 400px;
  padding: 0px 10px;
`

const LoginView = () => {
  const [ready, setReady] = useState(false)
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    const timeout = setTimeout(() => setReady(true), 1000)
    return () => clearTimeout(timeout)
  }, [])

  if (!ready) {
    return null
  }

  return (
    <LoginCard>
      <Logo />

      <LoginBtn
        onClick={() =>
          loginWithRedirect({
            action: 'login'
          })
        }>
        Log In
      </LoginBtn>
    </LoginCard>
  )
}
export default LoginView
