import { CuiButton, CuiModal } from 'front-lib'
import './SaveChangesModal.scss'
import { useEffect } from 'react'
import { track } from 'src/shared/tracking'

const SaveChangesModal = ({ isOpen, onClose, onSave, onProceed }) => {
  useEffect(() => {
    if (isOpen) {
      trackOverlayViewed()
    }
  }, [isOpen])

  const trackOverlayViewed = () => {
    track('Overlay Viewed', {
      overlay_name: 'Preferences - Save changes',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'Custom',
      page_name: 'Preferences',
      overlay_trigger: 'automatic'
    })
  }

  const onSaveHandler = () => {
    trackOverlayClicked('Save changes')
    onSave()
  }

  const onLeaveHandler = () => {
    trackOverlayClicked('Leave without saving')
    onProceed()
  }

  const onCloseHandler = () => {
    trackOverlayClicked('Close')
    onClose()
  }

  const trackOverlayClicked = action => {
    track('Overlay Clicked', {
      overlay_name: 'Preferences - Save changes',
      overlay_action: action,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'Custom',
      page_name: 'Preferences',
      overlay_trigger: 'automatic'
    })
  }

  return (
    <CuiModal
      smallSize
      isOpen={isOpen}
      onRequestClose={onCloseHandler}
      className="preferences-save-modal">
      <div className="preferences-save-modal__content">
        <h1 className="title">Save changes?</h1>
        <p className="description">
          Would you like to save changes to your profile before leaving?
        </p>
        <div className="action_container">
          <CuiButton fill="solid" color="dark" onClick={onSaveHandler}>
            Save changes
          </CuiButton>
          <CuiButton fill="outline" onClick={onLeaveHandler}>
            Leave without saving
          </CuiButton>
        </div>
      </div>
    </CuiModal>
  )
}

export { SaveChangesModal }
