import createReducer from '../../utils/createReducer'

import * as actions from './actions'

const initialState = {
  currentTabIndex: 0
}

export default createReducer(initialState, {
  [actions.setCurrentTabIndex]: (state, action) => ({
    ...state,
    currentTabIndex: action.payload
  })
})
