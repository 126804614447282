import React from 'react'
import Loader from 'src/common/Loader'
import { ComponentType } from 'src/types/component'
import './LoadingIndicator.scss'

export type TLoadingIndicator = {
  type: ComponentType.LOADING_INDICATOR
  props: {}
  children: []
}

export const loadingIndicator: TLoadingIndicator = {
  type: ComponentType.LOADING_INDICATOR,
  props: {},
  children: []
}

export const LoadingIndicator: React.FC = () => {
  return (
    <div className="loading-indicator">
      <Loader />
    </div>
  )
}
