import { gql, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { getDefaultAddressZipcode } from 'src/redux-api-bridge/selectors'

export const GET_AVAILABLE_PLANS_QUERY = gql`
  fragment Plan on Plan {
    id
    sidedishes
    price
    deliveryFee
    discountPrice
    mealsPerDelivery
    deliveries
    productDiscountRate
    planGiftId
    available
    featured
    type
  }

  query getAvailablePlans($zipcode: String) {
    allPlans: availablePlans(zipcode: $zipcode) {
      ...Plan
    }
    featuredPlans: availablePlans(zipcode: $zipcode, featured: true) {
      ...Plan
    }
  }
`

export function useGetAvailablePlans() {
  const zipcode = useSelector(getDefaultAddressZipcode)
  const { data, ...rest } = useQuery(GET_AVAILABLE_PLANS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    variables: { zipcode }
  })

  return {
    availablePlans: data?.featuredPlans || [],
    allPlans: data?.allPlans || [],
    ...rest
  }
}
