import { gql, useMutation } from '@apollo/client'

export const RETURN_TO_WEEKLY_PLAN_MUTATION = gql`
  mutation returnToWeeklyPlan {
    returnToWeeklyPlan {
      success
    }
  }
`

export function useReturnToWeeklyPlanMutation() {
  const [returnToWeeklyPlan, rest] = useMutation(RETURN_TO_WEEKLY_PLAN_MUTATION)

  return {
    returnToWeeklyPlan,
    rest
  }
}
