import { useDeflectionWithPromoStrategy } from './useDeflectionWithPromoStrategy'
import { useCancelSubscription } from './useCancelSubscription'

export function useCancelDeflection({ pageName }) {
  const { enabled, triggerStrategy } = useDeflectionWithPromoStrategy()
  const { cancelSubscription } = useCancelSubscription()

  function handleCancelSubscription() {
    if (enabled) {
      triggerStrategy({
        type: 'cancel',
        payload: { pageName },
        onContinue: () => cancelSubscription({ pageName })
      })
    } else {
      cancelSubscription({ pageName })
    }
  }

  return {
    handleCancelSubscription
  }
}
