import { useIsMobile } from 'src/hooks/useIsMobile'
import { CuiBottomSheet, CuiModal } from 'front-lib'
import {
  Text,
  TextColor,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title?: string
  className?: string
}

/**
 * Useful when you need the pattern: Modal for desktop and BottomSheet for mobile.
 */
export function ResponsiveModalOrBottomSheet({
  isOpen,
  onClose,
  children,
  title,
  className = ''
}: Props) {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <CuiBottomSheet isOpen={isOpen} className={className}>
        <CuiBottomSheet.Header onRequestClose={onClose}>
          {title && (
            <Text
              value={title}
              style={TextStyle.MEDIUM_BOLD}
              color={TextColor.PRIMARY}
            />
          )}
        </CuiBottomSheet.Header>
        <CuiBottomSheet.Body>{children}</CuiBottomSheet.Body>
      </CuiBottomSheet>
    )
  }
  return (
    <CuiModal isOpen={isOpen} onRequestClose={onClose} className={className}>
      {children}
    </CuiModal>
  )
}
