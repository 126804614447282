import moment, { Moment } from 'moment'
import {
  ChallengeState,
  ChallengeStep,
  ChallengeStepCopy
} from 'src/types/challenge'
import ButtonLink from 'src/components/ChallengePanel/Components/ButtonLink'
import HowToFavoriteModal from 'src/components/ChallengePanel/Components/HowToFavoriteModal'

export const getChallengeStepCopies = (
  step: ChallengeStep,
  values: { firstDelivery: any; date: string; nextOrderDate: string }
): ChallengeStepCopy | {} => {
  switch (step.type) {
    case 'has_first_order_trial':
      return {
        DESCRIPTION: values.firstDelivery
          ? 'challenge.steps.has_first_order_trial.description'
          : null,
        ICON: null,
        CTA: '',
        REDIRECT: '',
        TITLE: 'challenge.steps.has_first_order_trial.title',
        Component: null
      }
    case 'has_eater_profile_setted':
      return {
        DESCRIPTION: 'challenge.steps.has_eater_profile_setted.description',
        ICON: null,
        CTA:
          step.status === ChallengeState.Completed
            ? 'challenge.steps.has_eater_profile_setted.cta.completed'
            : 'challenge.steps.has_eater_profile_setted.cta.pending',
        REDIRECT: '/personalize',
        TITLE: 'challenge.steps.has_eater_profile_setted.title',
        Component: ButtonLink
      }
    case 'has_favorite_meal':
      return {
        DESCRIPTION: 'challenge.steps.has_favorite_meal.description',
        ICON: null,
        CTA: 'challenge.steps.has_favorite_meal.cta',
        REDIRECT: `/meals/?date=${values.nextOrderDate}`,
        TITLE: 'challenge.steps.has_favorite_meal.title',
        Component: HowToFavoriteModal
      }
    case 'has_treats_order':
      return {
        DESCRIPTION: 'challenge.steps.has_treats_order.description',
        ICON: null,
        CTA: 'challenge.steps.has_treats_order.cta',
        REDIRECT: `/meals/?categoryId=34&categoryLabel=Treats&date=${values.date}`,
        TITLE: 'challenge.steps.has_treats_order.title',
        Component: ButtonLink
      }
    case 'has_referral':
      return {
        DESCRIPTION: 'challenge.steps.has_referral.description',
        ICON: 'gift',
        CTA: 'challenge.steps.has_referral.cta',
        REDIRECT: `/referral`,
        TITLE: 'challenge.steps.has_referral.title',
        Component: ButtonLink
      }
    default:
      return {}
  }
}

export function getDayDiff(date: Moment): number {
  return date.diff(moment(), 'days')
}
