import { MEMBERSHIP_OVERLAY_OPTIONS } from 'src/modules/Modals/constants'
import { MembershipOverlayName, Step } from 'src/types/membership'
import { track } from 'src/shared/tracking'

interface MembershipTrackingProps {
  overlayName: MembershipOverlayName | Step
  origin?: string
}

export function useTrackMembershipModal({
  overlayName,
  origin
}: MembershipTrackingProps) {
  const SHARED_TRACKING_PROPS = {
    overlay_class: 'modal',
    overlay_source: 'custom',
    overlay_type: 'custom',
    page_name: 'Orders',
    overlay_trigger: 'manual'
  }

  function trackModalViewed() {
    track('Overlay Viewed', {
      ...SHARED_TRACKING_PROPS,
      overlay_name: overlayName,
      overlay_action: 'open',
      origin
    })
  }

  function trackModalClosed() {
    track('Overlay Closed', {
      overlay_name: MembershipOverlayName[overlayName],
      ...SHARED_TRACKING_PROPS
    })
  }

  function trackOptionClicked({ overlayAction }) {
    track('Overlay Clicked', {
      overlay_name: overlayName,
      overlay_options: MEMBERSHIP_OVERLAY_OPTIONS[overlayName],
      overlay_action: overlayAction,
      origin
    })
  }

  return {
    trackModalViewed,
    trackModalClosed,
    trackOptionClicked
  }
}
