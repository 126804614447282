import { gql, useMutation } from '@apollo/client'

export const CREATE_SKIP_MUTATION = gql`
  mutation createSkip($skip: SkipInput!, $origin: OperationOrigin) {
    createSkip(skip: $skip, origin: $origin) {
      __typename
      ... on Skip {
        id
      }
      ... on OrderCreationError {
        error
      }
    }
  }
`

export function useCreateSkip({ onCompleted = () => {} }) {
  const [createSkip, rest] = useMutation(CREATE_SKIP_MUTATION, {
    onCompleted
  })

  return {
    createSkip: (variables: {
      skip: { date: string; deliveryDate: string }
      origin: string
    }) => createSkip({ variables }),
    ...rest
  }
}
