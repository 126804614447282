import { useVariant } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { useMembershipData } from 'src/api/Membership'

import { MEMBERSHIP_MODAL_WHITELIST } from 'src/constants/feature-flags'
import { getUserId } from 'src/redux-api-bridge/account/selectors'

export function useMembershipWhitelist() {
  const membershipVariant = useVariant(MEMBERSHIP_MODAL_WHITELIST)
  const userId = useSelector(getUserId)

  const { membershipData } = useMembershipData()
  const isUsingUnityPass =
    membershipData?.membership?.discount ||
    membershipData?.activation?.active !== null

  if (membershipVariant.enabled && membershipVariant.payload?.value) {
    const membershipWhitelist = JSON.parse(membershipVariant.payload?.value)
    return membershipWhitelist.userIds.includes(userId) || isUsingUnityPass
  }

  return false
}
