import React, { Component } from 'react'

import Loader from './components/Loader'

class Index extends Component {
  render() {
    window.location = `${this.props.url}`
    return <Loader />
  }
}

export default Index
