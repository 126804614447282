import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { ComponentType } from 'src/types/component'
import { TRatingTab } from 'src/pages/orders/components/RatingTab/RatingTab'
import {
  loadingIndicator,
  TLoadingIndicator
} from 'src/pages/orders/components/RatingTab/components/LoadingIndicator/LoadingIndicator'
import { ActionType, TrackingType } from 'src/utils/components/actions'
import { RoutePaths } from 'src/routes/routePaths'
import {
  EVENT_NAME,
  ORDER_STATUS,
  PAGE_NAMES,
  TAB_RATING_STATUS
} from 'src/utils/tracking'

export enum AVAILABLE_BANNERS {
  uniqueBanner = 'UniqueBanner',
  collectionsBanner = 'CollectionsBanner',
  squareBanners = 'SquareBanners',
  SquareRow = 'SquareRow',
  UniqueRow = 'UniqueRow',
  CollectionsRow = 'CollectionsRow',
  ShortcutsRow = 'ShortcutsRow',
  Carrousel = 'CARROUSEL'
}

export enum AVAILABLE_SWIMLANES_SUBTYPES_V2 {
  SHORTCUTS = 'shortcut',
  BANNERS = 'banner',
  COLLECTIONS = 'collection',
  CHEFS = 'chef',
  MEALS = 'shoppable'
}

export const defaultSwimlanesOrder = [
  { type: AVAILABLE_BANNERS.uniqueBanner },
  { type: AVAILABLE_BANNERS.collectionsBanner },
  { type: AVAILABLE_BANNERS.squareBanners }
]

export const defaultRatingTab: TRatingTab = {
  type: ComponentType.TAB,
  props: {
    dayType: 'lastOrder',
    header: {
      title: 'Rate Order'
    },
    hasMealsToRate: false,
    track: {
      eventName: EVENT_NAME.CALENDAR_TAB_SELECTED,
      extras: {
        order_sequence: '-1',
        order_status: ORDER_STATUS.SHIPPED,
        page_name: PAGE_NAMES.ORDERS,
        tab_rating_status: TAB_RATING_STATUS.NO_RATES_AVAILABLE
      }
    }
  },
  children: [
    {
      type: ComponentType.INFO_CONTAINER,
      props: {
        backButton: false,
        closeButton: false,
        image: {
          name: 'STAR_IMAGE'
        },
        subTitle:
          'Our chefs rely on your feedback to <b>make their meals exceptional</b>. Go ahead and tell them how they did. They’re chefs — they can take the heat.',
        title: 'No meals to rate from your last order'
      },
      children: [
        {
          type: ComponentType.BUTTON,
          props: {
            subType: 'primary',
            actions: [
              {
                type: ActionType.TRACK,
                data: {
                  subType: TrackingType.EVENT,
                  tag: 'Rate previous orders in History Clicked',
                  extras: {}
                }
              },
              {
                type: ActionType.NAVIGATION,
                data: {
                  path: RoutePaths.HISTORY
                }
              }
            ],
            text: 'Rate previous orders in History'
          }
        }
      ]
    }
  ]
}

export const getOrderSwimlanesViews = state =>
  pathOr(null, ['ordersSwimlanes', 'views'], state)

export const getRatingTab = state =>
  pathOr(null, ['ordersSwimlanes', 'ratingTab'], state)

export const isLoadingSwimlanesViews = state =>
  pathOr(null, ['ordersSwimlanes', 'isLoading'], state)

export const isErrorSwimlanesViews = state =>
  pathOr(null, ['ordersSwimlanes', 'isError'], state)

export const getViewsByDate = date =>
  createSelector(
    getOrderSwimlanesViews,
    isLoadingSwimlanesViews,
    isErrorSwimlanesViews,
    (views, isLoading, isError) => {
      if (isError) return defaultSwimlanesOrder
      if (isLoading) return []

      return views && views[date] ? views[date] : defaultSwimlanesOrder
    }
  )

export const getRatingTabSelector = createSelector(
  getRatingTab,
  isLoadingSwimlanesViews,
  isErrorSwimlanesViews,
  (ratingTab, isLoading, isError): TRatingTab | TLoadingIndicator => {
    if (isError) return defaultRatingTab
    if (isLoading) return loadingIndicator

    return ratingTab || defaultRatingTab
  }
)
