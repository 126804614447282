// Utils Date without to use moment
const _MS_PER_DAY = 1000 * 60 * 60 * 24
export const YYYY_MM_DD = 'YYYY-MM-DD'

// dateA and dateB are Date objects
export const dateDiffInDays = (dateA, dateB) => {
  if (dateA instanceof Date && dateB instanceof Date) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      dateA.getFullYear(),
      dateA.getMonth(),
      dateA.getDate()
    )
    const utc2 = Date.UTC(
      dateB.getFullYear(),
      dateB.getMonth(),
      dateB.getDate()
    )

    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
  }
  return ''
}

export const getDate = format => {
  const date = new Date()
  if (format === YYYY_MM_DD) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }
  return date
}

export const getDateLabel = (date, format = {}) => {
  const defaultFormat = {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    timeZone: 'UTC'
  }

  return new Intl.DateTimeFormat('en-US', {
    ...defaultFormat,
    ...format
  }).format(date)
}
