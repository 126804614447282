import createReducer from '../../utils/createReducer'

import * as actions from './actions'

const initialState = {
  options: null,
  user: null,
  isLoading: false,
  isError: false,
  isSaving: false,
  savingError: false,
  mealWishes: null,
  mealAvoids: null
}

export default createReducer(initialState, {
  [actions.fetchPreferencesData]: (state, { withView }) => ({
    ...state,
    options: withView ? null : state.options,
    user: null,
    isLoading: true,
    isError: false,
    mealWishes: null,
    mealAvoids: null
  }),
  [actions.fetchPreferencesDataSucceed]: (state, action) => ({
    ...state,
    isLoading: false,
    options: action.payload.options || state.options,
    user: action.payload.user,
    mealWishes: action.payload.user?.profiles?.map(p => ({
      profileId: p.id,
      mealWishes: p.mealWishes || []
    })),
    mealAvoids: action.payload.user?.profiles?.map(p => ({
      profileId: p.id,
      mealAvoids: p.mealAvoids || []
    })),
    isError: false
  }),
  [actions.fetchPreferencesDataFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true,
    options: null,
    user: null,
    mealWishes: null,
    mealAvoids: null
  }),
  [actions.savePreferencesData]: state => ({
    ...state,
    isLoading: false,
    isError: false,
    user: null,
    isSaving: true,
    mealWishes: null,
    mealAvoids: null
  }),
  [actions.savePreferencesDataSucceed]: state => ({
    ...state,
    isSaving: false,
    savingError: false
  }),
  [actions.savePreferencesDataFailed]: state => ({
    ...state,
    isSaving: false,
    savingError: true
  }),
  [actions.setMealAvoid]: (state, { payload }) => ({
    ...state,
    mealAvoids: state.mealAvoids.map(p =>
      p.profileId === payload.profile
        ? { ...p, mealAvoids: [...p.mealAvoids, { mealId: payload.meal }] }
        : p
    )
  }),
  [actions.setMealWish]: (state, { payload }) => ({
    ...state,
    mealWishes: state.mealWishes.map(p =>
      p.profileId === payload.profile
        ? { ...p, mealWishes: [...p.mealWishes, { mealId: payload.meal }] }
        : p
    )
  })
})
