import { useFlag } from '@unleash/proxy-client-react'
import { useAtom } from 'jotai'

import {
  changePlanModalAtom,
  ChangePlanModalAtom
} from 'src/atoms/modals/changePlanModalAtom.atom'
import { FF_CHANGE_PLAN_MODAL } from 'src/constants/feature-flags'

export function useChangePlanModal() {
  const isModalEnabled = useFlag(FF_CHANGE_PLAN_MODAL)
  const [{ isOpen, type }, setChangePlanModal] = useAtom(changePlanModalAtom)

  function open(type = '') {
    setChangePlanModal({
      isOpen: true,
      type: type as ChangePlanModalAtom['type']
    })
  }

  function close() {
    setChangePlanModal({ isOpen: false, type: '' })
  }

  return {
    isModalEnabled,
    isOpen,
    type,
    open,
    close
  }
}
