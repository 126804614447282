import { pathOr } from 'ramda'

export const getProfilesInfo = state =>
  pathOr(null, ['api', 'subscription', 'info', 'profiles'], state)

export const getSelectedMealId = state =>
  pathOr(null, ['mealDetail', 'likeMeal', 'selectedMealId'], state)

export const getAddMealStatus = state =>
  pathOr(null, ['api', 'addMealWishStatus'], state)

export const getRemoveMealWishStatus = state =>
  pathOr(null, ['api', 'removeMealWishStatus'], state)
