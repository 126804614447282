import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const applyAutoPilotPromotion = state =>
  pathOr(null, ['api', 'applyAutopilotPromotionMutation'], state)

export const applyAutoPilotPromotionSelector = createSelector(
  [applyAutoPilotPromotion],
  response => response
)
