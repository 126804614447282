import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { RatingProductState } from 'src/redux-api-bridge/ratings/reducer'

export const getRatingProductForm = state =>
  pathOr([], ['ratings', 'ratingProductForm'], state)

export const getProducts = state => pathOr([], ['ratings', 'products'], state)

export const getCurrentProductId = state =>
  pathOr([], ['ratings', 'currentProductId'], state)

export const getShowReasonsFeedbackError = state =>
  pathOr([], ['ratings', 'showReasonsFeedbackError'], state)

export const isLoadingRatingProduct = state =>
  pathOr(null, ['ratings', 'isLoading'], state)

export const isErrorRatingProduct = state =>
  pathOr(null, ['ratings', 'isError'], state)

export const getRatingProductSelector = createSelector(
  getProducts,
  getCurrentProductId,
  (
    products: RatingProductState[],
    currentProductId: string | null
  ): RatingProductState | null =>
    !products || currentProductId === null ? null : products[currentProductId]
)
