import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { FF_USE_UNIFIED_CART } from 'src/constants/feature-flags'
import {
  getUnifiedCarts,
  getUnifiedCartsIsLoading,
  isUnifiedCartOperationInFlight
} from 'src/redux-api-bridge/cart/selectors'
import { getActiveOrderDate } from 'src/redux-api-bridge/order/selectors'
import { getUserSegment } from 'src/redux-api-bridge/selectors'

export const useBoxOrderingModel = () => {
  const isUnifiedCartOn = useFlag(FF_USE_UNIFIED_CART)

  const activeOrderDate = useSelector(getActiveOrderDate)
  const unifiedCarts = useSelector(getUnifiedCarts)
  const cartIsLoading = useSelector(getUnifiedCartsIsLoading)
  const operationInCourse = useSelector(isUnifiedCartOperationInFlight)
  const userSegment = useSelector(getUserSegment)

  const currentUnifiedCart =
    isUnifiedCartOn && unifiedCarts ? unifiedCarts[activeOrderDate] : null

  const isBOM = !!currentUnifiedCart?.metadata.pricing.boxPlan

  // We only have one BOX right now. In the future, the user will be able to select the box,
  // and then we will need to find the id of the box in the boxes array
  const currentBox = isBOM
    ? currentUnifiedCart?.metadata.pricing.boxPlan.boxes[0]
    : null

  return {
    isUnifiedCartFFOn: isUnifiedCartOn,
    unifiedCart: currentUnifiedCart,
    isBOM: !!currentUnifiedCart?.metadata.pricing.boxPlan,
    isUserInBOM: !!userSegment?.isBoxPlan,
    pricing: currentUnifiedCart?.metadata.pricing,
    cartIsLoading,
    currentBox,
    operationInCourse: isUnifiedCartOn ? operationInCourse : false
  }
}
