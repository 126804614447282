import { ReactComponent as Pescatarian } from 'src/assets/svg/diets/pescatarian.svg'
import { ReactComponent as Flexitarian } from 'src/assets/svg/diets/flexitarian.svg'
import { ReactComponent as Omnivore } from 'src/assets/svg/diets/omnivore.svg'
import { ReactComponent as Vegan } from 'src/assets/svg/diets/vegan.svg'
import { ReactComponent as Vegetarian } from 'src/assets/svg/diets/vegetarian.svg'

export const DIETS_ICONS_MAPPING = {
  pescatarian: <Pescatarian />,
  flexitarian: <Flexitarian />,
  omnivore: <Omnivore />,
  vegan: <Vegan />,
  vegetarian: <Vegetarian />
}
