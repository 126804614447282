import { useFlag } from '@unleash/proxy-client-react'
import { FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT } from 'src/constants/feature-flags'
import { isIOSApp } from 'src/utils/utils'

export function useShowCancelSubscription() {
  const cancelSubscriptionIntoMyAccount = useFlag(
    FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT
  )

  return !isIOSApp() && !cancelSubscriptionIntoMyAccount
}
