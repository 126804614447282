import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { CuiSpinner } from 'front-lib'
import { ComponentProps } from 'src/types/component'
import { Action, executeActions } from 'src/utils/components/actions'
import {
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import './Button.scss'

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum ButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

interface ButtonProps extends ComponentProps {
  subType: ButtonType
  text: string
  actions: Action[]
  style?: ButtonStyle
  textStyle?: TextStyle
  onClick?: Function
  showLoaderIndicator?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  subType,
  actions,
  text,
  style,
  textStyle,
  onClick,
  showLoaderIndicator = false
}) => {
  const history = useHistory()

  const onClickFn = useCallback(() => {
    onClick && onClick()
    executeActions(actions, { history })
  }, [actions, history, onClick])

  return (
    <button
      className={classNames('button', style || subType)}
      onClick={onClickFn}>
      {showLoaderIndicator ? (
        <div className="button__spinner-overlay">
          <CuiSpinner width="24px" />
        </div>
      ) : (
        <Text value={text} style={textStyle || TextStyle.XS_BOLD} />
      )}
    </button>
  )
}
