import { createAction } from 'redux-actions'

import actionCreator from '../../utils/actionCreator'

export const shareRecommendationReset = createAction(
  'SHARE_RECOMMENDATION_RESET'
)
export const shareRecommendation = actionCreator(
  'SHARE',
  'SHARE_RECOMMENDATION'
)
