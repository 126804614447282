import { useSelector } from 'react-redux'
import { useMembershipData } from 'src/api/Membership/useMembershipData'
import { getUserStatus } from 'src/pages/membership/components/MembershipBenefits/utils'
import { getOrderWithStock } from 'src/redux-api-bridge/order/selectors'

export function useMembershipLimit() {
  const { membershipData, loading } = useMembershipData()
  const status = getUserStatus(membershipData?.activation)
  const mealsCount = useSelector(getOrderWithStock)
  const isMember = status !== 'non-member'

  function getFreePremiumFeesAllowed() {
    if (isMember) {
      return membershipData?.membership.premiumMeals || 0
    }
    return 0
  }

  function hasReachedPremiumLimit(cartMeals) {
    const premiumFeesAllowed = getFreePremiumFeesAllowed()
    let premiumCount = 0

    for (const meal of cartMeals) {
      if (meal.is_premium) {
        premiumCount += meal.qty
      }
    }
    return premiumCount >= premiumFeesAllowed
  }

  function getPremiumFeeIndexesToShowFee(meals): number[] {
    const premiumFeesAllowed = getFreePremiumFeesAllowed()
    let showIndexes: number[] = []
    let premiumCount = 0

    if (!meals || meals.length === 0 || premiumFeesAllowed < 1) {
      return showIndexes
    }

    const premiumMealIndexesArray = meals.map((meal, index) => {
      if (!meal.is_premium) {
        return undefined
      }
      if (premiumCount <= premiumFeesAllowed) {
        premiumCount += meal.qty
      }
      return premiumCount > premiumFeesAllowed ? index : undefined
    })

    return premiumMealIndexesArray
  }

  return {
    showPremiumFee: !loading && hasReachedPremiumLimit(mealsCount),
    getPremiumFeeIndexesToShowFee,
    isMember
  }
}
