import { CuiButton } from 'front-lib'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import {
  MembershipOverlayActions,
  MembershipOverlayName
} from 'src/types/membership'

export function RejoinFooter({ onContinue }) {
  const { trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })
  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.rejoinNow })
    onContinue(MEMBERSHIP_MODAL_STEPS.checkout, true)
  }
  return (
    <div className="footer-container">
      <span className="rejoin">
        Changed your mind? You can rejoin Unity Pass below.
      </span>
      <div className="button-container">
        <CuiButton className="primary-button" onClick={handleContinue}>
          Rejoin now
        </CuiButton>
      </div>
    </div>
  )
}
