import { useEffect } from 'react'
import tracking from 'src/shared/tracking'

export const useSwitchConfirmChangePlanModalTracking = (
  setShowSwitchConfirmChangePlanModal: (
    showSwitchConfirmChangePlanModal: boolean
  ) => void,
  onSwitchBackPlan: () => void
) => {
  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: 'Flex mode - Are you sure?',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Yes, let’s do it',
      overlay_action: null
    })
  }, [])

  function handleClose(): void {
    tracking.track('Overlay Closed', {
      overlay_name: 'Flex mode - Are you sure?',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Yes, let’s do it',
      overlay_action: null
    })

    setShowSwitchConfirmChangePlanModal(false)
  }

  function handleConfirm(): void {
    handleClose()

    tracking.track('Overlay Clicked', {
      overlay_name: 'Flex mode - Are you sure?',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Yes, let’s do it',
      overlay_action: 'Yes, let’s do it'
    })

    onSwitchBackPlan()
  }

  return { handleClose, handleConfirm }
}
