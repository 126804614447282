import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Field } from 'formik'
import './index.scss'

const CustomField = props => {
  const { type, checked, onClick } = props
  return type === 'radio' ? (
    <Fragment>
      <div className="cookunity__input_radio_container">
        <div
          className={classnames('cookunity__input_radio', { checked })}
          onClick={onClick}>
          <div className="checkmark" />
        </div>
      </div>
      <Field {...props} className="cookunity__input_field" />
    </Fragment>
  ) : type === 'checkbox' ? (
    <Fragment>
      <div className="cookunity__input_checkbox_container">
        <div
          className={classnames('cookunity__input_checkbox', { checked })}
          onClick={onClick}
        />
      </div>
      <Field {...props} className="cookunity__input_field" />
    </Fragment>
  ) : (
    <Field {...props} className="cookunity__input_field" />
  )
}

export default CustomField
