import { handleActions } from 'redux-actions'

import * as mealDetail from './actions'

const initialState = {
  data: null,
  loading: false,
  succeed: false,
  failed: false,
  likeMeal: {
    selectedLikeMeal: null,
    inProgress: false
  },
  nutrition: {
    buttonVisibility: false,
    data: {},
    loading: {},
    succeed: {},
    failed: {},
    modal: {
      isOpen: false,
      mealId: null,
      footerData: null
    }
  }
}

export default handleActions(
  {
    [mealDetail.getMealDetail]: state => ({
      ...state,
      data: null,
      loading: true,
      succeed: false,
      failed: false
    }),
    [mealDetail.getMealDetailSuccess]: (state, action) => ({
      ...state,
      data: action.payload,
      loading: false,
      succeed: true,
      failed: false
    }),
    [mealDetail.getMealDetailFail]: state => ({
      ...state,
      data: null,
      loading: false,
      succeed: false,
      failed: true
    }),
    [mealDetail.setLikeMeal]: (state, action) => ({
      ...state,
      likeMeal: {
        selectedMealId: action.payload,
        inProgress: true
      }
    }),
    [mealDetail.cleanLikeMeal]: state => ({
      ...state,
      likeMeal: {
        selectedMealId: null,
        inProgress: false
      }
    }),
    [mealDetail.getMealNutritionInfo]: (state, action) => ({
      ...state,
      nutrition: {
        ...state.nutrition,
        loading: {
          ...state.nutrition.loading,
          [action.payload]: true
        },
        succeed: {
          ...state.nutrition.succeed,
          [action.payload]: false
        },
        failed: {
          ...state.nutrition.failed,
          [action.payload]: false
        }
      }
    }),
    [mealDetail.getMealNutritionInfoSuccess]: (state, action) => ({
      ...state,
      nutrition: {
        ...state.nutrition,
        data: {
          ...state.nutrition.data,
          [action.payload.mealId]: action.payload
        },
        loading: {
          ...state.nutrition.loading,
          [action.payload.mealId]: false
        },
        succeed: {
          ...state.nutrition.succeed,
          [action.payload.mealId]: true
        },
        failed: {
          ...state.nutrition.failed,
          [action.payload.mealId]: false
        }
      }
    }),
    [mealDetail.getMealNutritionInfoFail]: (state, action) => ({
      ...state,
      nutrition: {
        ...state.nutrition,
        loading: {
          ...state.nutrition.loading,
          [action.payload]: false
        },
        succeed: {
          ...state.nutrition.succeed,
          [action.payload]: false
        },
        failed: {
          ...state.nutrition.failed,
          [action.payload]: true
        }
      }
    }),
    [mealDetail.openNutritionModal]: (state, action) => {
      const { mealId, footerData, preloadedNutritionInfo } = action.payload

      let newNutrition = { ...state.nutrition }

      if (preloadedNutritionInfo) {
        newNutrition = {
          ...state.nutrition,
          data: {
            ...state.nutrition.data,
            [mealId]: preloadedNutritionInfo
          },
          loading: {
            ...state.nutrition.loading,
            [mealId]: false
          },
          succeed: {
            ...state.nutrition.succeed,
            [mealId]: true
          },
          failed: {
            ...state.nutrition.failed,
            [mealId]: false
          }
        }
      }

      return {
        ...state,
        nutrition: {
          ...newNutrition,
          modal: {
            isOpen: true,
            mealId,
            footerData
          }
        }
      }
    },
    [mealDetail.closeNutritionModal]: state => ({
      ...state,
      nutrition: {
        ...state.nutrition,
        modal: {
          isOpen: false,
          mealId: null,
          footerData: null
        }
      }
    }),
    [mealDetail.setNutritionInfoButtonVisibility]: (state, action) => ({
      ...state,
      nutrition: {
        ...state.nutrition,
        buttonVisibility: action.payload
      }
    })
  },
  initialState
)
