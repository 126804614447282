import './CardTitle.scss'

const CardTitle = ({ title, subtitle, onClick, size = 'medium' }) => {
  return (
    <div onClick={onClick}>
      <p className={`card-title card-title--${size}`}>{title}</p>
      <p className={`card-subtitle card-subtitle--${size}`}>{subtitle}</p>
    </div>
  )
}

export { CardTitle }
