import { useSelector } from 'react-redux'
import { getUserFirstNameSelector } from 'src/components/Header/selectors'
import { sanitizeHtml } from 'src/modules/Modals/components/ContestModal/utils'
import { getDiscountInfo } from './utils'
import { Membership } from 'src/types/membership'

/**
 * useReplaceHtmlWildCard hook
 */

export const useReplaceHtmlWildCard = (membership: Membership) => {
  const userName = useSelector(getUserFirstNameSelector)
  const { discountRate, finalCost, fullCost } = getDiscountInfo(membership)

  const replaceHtmlWildCard = (textTemplate: string) => {
    const text = textTemplate
      .replace(/{userName}/g, userName)
      .replace(/{discountRate}/g, discountRate.toString())
      .replace(/{finalCost}/g, finalCost)
      .replace(/{fullCost}/g, fullCost)

    return sanitizeHtml(text)
  }

  return { replaceHtmlWildCard }
}
