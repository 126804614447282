import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { CuiButton } from 'front-lib'
import useChallenge from 'src/hooks/useChallenge'
import ProgressBar from '../../ProgressBar/ProgressBar'
import { CHALLENGE_IDS } from 'src/constants/challenge'
import { ChallengeState } from 'src/types/challenge'
import { Tooltip } from 'react-tooltip'

const ChallengePanelHeader = () => {
  const {
    challengeData,
    stepsData,
    claim,
    state,
    claimChallengeReward
  } = useChallenge(CHALLENGE_IDS.FIVE_MILESTONES)
  const [progress, setProgress] = useState(0)
  const { t } = useTranslation()

  const headerClasses = classNames('cookunity__challenge_panel_header', {
    completed: state === ChallengeState.Completed
  })

  useEffect(() => {
    const completed = stepsData.filter(
      step => step.status === ChallengeState.Completed
    )
    const challengeProgress = (100 / stepsData.length) * completed.length
    setProgress(challengeProgress)
  }, [stepsData])

  return (
    <div className={headerClasses}>
      <div className="custom-header-mask" />
      <div className="challenge-promotion">
        <p className="title">
          <Trans
            i18nKey={`challenge.header.five_milestones.title.${state}`}
            components={{
              span: <span></span>,
              br: <br />
            }}
          />
        </p>
        <p className="subtitle">
          {t(`challenge.header.five_milestones.subtitle.${state}`)}
          {state === ChallengeState.Pending && (
            <span data-tooltip-id={'five-milestone-challenge-tooltip'}> ⓘ</span>
          )}
        </p>
        {state === ChallengeState.Pending && (
          <Tooltip id={'five-milestone-challenge-tooltip'} opacity={1}>
            A premium fee is an additional charge for a premium meal. With $12
            in premium fees waived, you can get up to four premium meals at the
            regular price per meal for your plan.
          </Tooltip>
        )}
        {state !== ChallengeState.Pending ? (
          !challengeData?.finishedAt ? (
            <CuiButton
              className={'challenge-promotion-button'}
              onClick={() =>
                claimChallengeReward('Milestones - Claim my reward')
              }>
              {claim?.inFlight ? (
                <ClipLoader color="#000" size={18} />
              ) : (
                'Claim my reward'
              )}
            </CuiButton>
          ) : null
        ) : (
          <ProgressBar progress={progress} icon={'gift'} />
        )}
      </div>
    </div>
  )
}

export default ChallengePanelHeader
