import './wdyr'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react'
import { ApolloProvider } from '@apollo/client'
import store from './store'
import Auth0Provider from './auth0'
import EmbeddedProvider from './embedded'
import history from './shared/history'
import { App } from './App'
import './tools'
import './i18n'
import MaintenanceModeProvider from './maintenanceModeProvider/MaintenanceModeProvider'
import { apolloSubscriptionPrivateClient } from './api/apolloSubscriptionPrivateClient'
import { FullScreenError } from './components/Errors/FullScreenError'
import { ErrorBoundary } from './components/Errors/ErrorBoundary'
import { CoachmarkProvider } from './hooks/useCoachmark'

const onRedirectCallback = appState => {
  // Use the router's history module to replace the url
  history.replace((appState && appState.returnTo) || window.location.pathname)
}

const unleashConfig = {
  url: process.env.REACT_APP_FEATURE_FLAG_PROXY_URL,
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: 0,
  appName: process.env.REACT_APP_FEATURE_FLAG_APP_NAME,
  environment: process.env.REACT_APP_FEATURE_FLAG_ENVIRONMENT
}

export const client = new UnleashClient(unleashConfig)

const root = createRoot(document.getElementById('root'))

root.render(
  <Router history={history}>
    <ApolloProvider client={apolloSubscriptionPrivateClient}>
      <EmbeddedProvider>
        <FlagProvider unleashClient={client} startClient={false}>
          <MaintenanceModeProvider>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              redirectUri={window.location.origin}
              useRefreshTokens
              onRedirectCallback={onRedirectCallback}>
              <Provider store={store}>
                <ErrorBoundary fallback={<FullScreenError />}>
                  <CoachmarkProvider>
                    <App />
                  </CoachmarkProvider>
                </ErrorBoundary>
              </Provider>
            </Auth0Provider>
          </MaintenanceModeProvider>
        </FlagProvider>
      </EmbeddedProvider>
    </ApolloProvider>
  </Router>
)
