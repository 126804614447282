import { useUnleashContext } from '@unleash/proxy-client-react'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'
import { client } from '../..'
import { useSelector } from 'react-redux'
import {
  getRingId,
  getStoreId,
  getUserEmail,
  getUserSegment,
  selectCreatedDateUser
} from 'src/redux-api-bridge/selectors'
import { getPlatform } from 'src/utils/utils'
import { getUserType } from 'src/redux-api-bridge/selectors'
import { useQueryParams } from 'src/hooks/useQueryParams'

const UnleashProvider = ({ children }) => {
  const { user } = useAuth0()
  const userStoreId = useSelector(getStoreId)?.toString()
  const userCreatedDate = useSelector(selectCreatedDateUser)
  const userEmail = useSelector(getUserEmail)
  const userType = useSelector(getUserType)
  const ringId = useSelector(getRingId)
  const userSegment = useSelector(getUserSegment)
  const { query } = useQueryParams()
  const updateContext = useUnleashContext()

  useEffect(() => {
    const asyncProcess = async () => {
      if (user && userEmail && userType) {
        updateContext({
          userId: user && user?.subscription_id?.toString(),
          storeId: userStoreId,
          userCreatedDate: userCreatedDate && moment(userCreatedDate).unix(),
          daysSinceCreation:
            userCreatedDate && moment().diff(moment(userCreatedDate), 'days'),
          userEmail,
          devicePlatform: getPlatform(),
          isB2B: userType === 8,
          ringId,
          utmCampaign: query ? query.get('utm') : undefined,
          isUserInBoxPlan: userSegment?.isBoxPlan ?? false
        })
        client.start()
      }
    }
    asyncProcess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userStoreId, userCreatedDate, userEmail, userType])
  return children
}
export default UnleashProvider
