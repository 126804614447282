import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { FullTextArea } from 'src/pages/orders/components/RatingTab/components/FullTextArea/FullTextArea'
import { MealRatingCardDetail } from 'src/pages/orders/components/RatingTab/components/MealRatingCardDetail/MealRatingCardDetail'
import {
  Text,
  TextColor,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import { MAX_RATING } from 'src/utils/rating'
import { TagsList } from 'src/pages/orders/components/RatingTab/components/TagsList/TagsList'
import { RatingProductFormAttributes } from 'src/redux-api-bridge/ratings/reducer'
import {
  getRatingProductForm,
  getShowReasonsFeedbackError
} from 'src/redux-api-bridge/ratings/selectors'
import { useRatingProduct } from 'src/hooks/useRatingProduct'
import './RatingDetail.scss'

type RatingDetailProps = {
  reasonsTitle: string
  reasonsTitle5Stars: string
  reasons: string[]
  reviewTitle: string
  reviewHint: string
  reviewMaxCharacters: number
  mealProps: {
    id: string
    title: string
    image: {
      name: string
    }
    chef: any
  }
  className?: string
}

export const RatingDetail: React.FC<RatingDetailProps> = ({
  mealProps,
  reasonsTitle,
  reasonsTitle5Stars,
  reasons,
  reviewTitle,
  reviewHint,
  reviewMaxCharacters,
  className
}) => {
  const ratingProductForm = useSelector(getRatingProductForm)
  const showReasonsFeedbackError: boolean = useSelector(
    getShowReasonsFeedbackError
  )
  const { updateRatingProductModal } = useRatingProduct(
    mealProps.id,
    mealProps.title
  )

  const onClickTag = useCallback(
    clickedReason => {
      const newReasons = ratingProductForm[
        RatingProductFormAttributes.REASONS
      ].includes(clickedReason)
        ? ratingProductForm[RatingProductFormAttributes.REASONS].filter(
            reason => reason !== clickedReason
          )
        : ratingProductForm[RatingProductFormAttributes.REASONS].concat([
            clickedReason
          ])
      updateRatingProductModal(RatingProductFormAttributes.REASONS, newReasons)
    },
    [updateRatingProductModal, ratingProductForm]
  )

  return (
    <div className={classnames('rating-detail', className)}>
      <MealRatingCardDetail
        id={mealProps.id}
        title={mealProps.title}
        image={mealProps.image}>
        {mealProps.chef}
      </MealRatingCardDetail>
      <div className="rating-detail__section rating-detail__reasons-section">
        <Text
          className="rating-detail__title-section"
          value={
            ratingProductForm[RatingProductFormAttributes.RATING] === MAX_RATING
              ? reasonsTitle5Stars
              : reasonsTitle
          }
          style={TextStyle.H5}
        />
        <TagsList
          tags={reasons.map(reason => ({ id: reason, label: reason }))}
          selectedTags={ratingProductForm[RatingProductFormAttributes.REASONS]}
          onClick={onClickTag}
        />
        <Text
          className={classnames('rating-detail__reasons-error', {
            visible:
              showReasonsFeedbackError &&
              ratingProductForm[RatingProductFormAttributes.REASONS].length ===
                0
          })}
          value="Please, select at least one option."
          style={TextStyle.XS_BOLD}
          color={TextColor.FEEDBACK_ERROR}
        />
      </div>
      <div className="rating-detail__section rating-detail__review-section">
        <Text
          className="rating-detail__title-section"
          value={reviewTitle}
          style={TextStyle.H5}
        />
        <FullTextArea
          className="rating-detail__review-textarea"
          name={`rating-detail__${RatingProductFormAttributes.REVIEW}`}
          value={ratingProductForm[RatingProductFormAttributes.REVIEW]}
          onChange={event =>
            updateRatingProductModal(
              RatingProductFormAttributes.REVIEW,
              event.target.value
            )
          }
          placeholder={reviewHint}
          maxCharacter={reviewMaxCharacters}
          showMaxCharacter={true}
        />
      </div>
    </div>
  )
}
