import Fuse, { FuseResult, FuseResultMatch } from 'fuse.js'

const MATCHING_KEYS = {
  INGREDIENTS: 'ingredients',
  MEAL_NAME: 'mealName',
  CHEF_NAME: 'chefName',
  MEAL_DESCRIPTION: 'mealDescription',
  CUISINES: 'cuisines',
  DIETS: 'diets',
  MERCHANDISING: 'merchandising',
  PROTEINS: 'proteins'
}

interface MealsSuggestion {
  suggestedMeals: string[]
  exactMatchMeals: string[]
  chefs: string[]
  cuisines: string[]
  ingredients: string[]
  diets: string[]
  proteins: string[]
}

const filterMealsByShortDescription = (
  meals: any[],
  searchInputText: string
) => {
  return searchInputText.length
    ? meals.filter(meal => {
        if (meal.mealDescription) {
          return meal.mealDescription
            .toLowerCase()
            .includes(searchInputText.toLowerCase())
        }
        return false
      })
    : meals
}

const filterMealsByMealName = (meals: any[], searchInputText: string) => {
  return searchInputText.length
    ? meals.filter(meal =>
        meal.mealName.toLowerCase().includes(searchInputText.toLowerCase())
      )
    : meals
}

const findExactMatch = (meals: any[], searchInput: string) => {
  if (!searchInput) return []
  const mealsByMealName = filterMealsByMealName(meals, searchInput)
  const mealsByShortDescription = filterMealsByShortDescription(
    meals,
    searchInput
  )
  const joinFilteredMeals = mealsByMealName.concat(mealsByShortDescription)

  return joinFilteredMeals
    .filter((item, index) => {
      return joinFilteredMeals.indexOf(item) === index
    })
    .map(foundMeals => foundMeals.inventoryId)
}

export const findSuggestionsByUserSearch = (
  inputValue: string,
  searchByParams
): MealsSuggestion => {
  const fuse = new Fuse(searchByParams || [], {
    includeScore: true,
    minMatchCharLength: 3,
    keys: [
      { name: MATCHING_KEYS.MEAL_NAME, weight: 100 },
      { name: MATCHING_KEYS.CHEF_NAME, weight: 1 },
      { name: MATCHING_KEYS.MEAL_DESCRIPTION, weight: 1 },
      { name: MATCHING_KEYS.CUISINES, weight: 1 },
      { name: MATCHING_KEYS.INGREDIENTS, weight: 1 },
      { name: MATCHING_KEYS.DIETS, weight: 1 },
      { name: MATCHING_KEYS.MERCHANDISING, weight: 1 },
      { name: MATCHING_KEYS.PROTEINS, weight: 1 }
    ],
    ignoreLocation: true,
    threshold: 0.3,
    includeMatches: true
  })
  const result = fuse.search<any>(inputValue)
  const exactMatches = findExactMatch(searchByParams, inputValue)
  return buildSuggestions(result, exactMatches)
}

const buildSuggestions = (
  fuseResult: FuseResult<any>[],
  exactMatches: any[]
): MealsSuggestion => {
  const suggestedMeals = fuseResult
    .map((item: any) => item.item.inventoryId)
    .filter(
      resultInventoryId =>
        !exactMatches.find(exactMatch => exactMatch === resultInventoryId)
    )
  const suggestions: MealsSuggestion = {
    exactMatchMeals: exactMatches,
    suggestedMeals: suggestedMeals,
    chefs: [],
    cuisines: [],
    ingredients: [],
    diets: [],
    proteins: []
  }

  for (const result of fuseResult) {
    const { matches } = result
    if (!matches) return suggestions

    for (const match of matches) {
      if (match.key === MATCHING_KEYS.INGREDIENTS) {
        handleIngredientsSuggestions(suggestions.ingredients, match)
      }
      switch (match.key) {
        case MATCHING_KEYS.INGREDIENTS:
          handleIngredientsSuggestions(suggestions.ingredients, match)
          break
        case MATCHING_KEYS.CHEF_NAME:
          handleChefsSuggestions(suggestions.chefs, match)
          break
        case MATCHING_KEYS.CUISINES:
          handleCuisinesSuggestions(suggestions.cuisines, match)
          break
        case MATCHING_KEYS.DIETS:
          handleDietsSuggestions(suggestions.diets, match)
          break
        case MATCHING_KEYS.PROTEINS:
          handleProteinsSuggestions(suggestions.proteins, match)
          break
        default:
          break
      }
    }
  }

  return suggestions
}

const handleIngredientsSuggestions = (
  ingredients: string[],
  ingredientResult: FuseResultMatch
) => {
  if (
    ingredientResult.value &&
    !ingredients.find(prevValue => prevValue === ingredientResult.value)
  ) {
    ingredients.push(ingredientResult.value)
  }
}

const handleChefsSuggestions = (
  chefs: string[],
  chefResult: FuseResultMatch
) => {
  if (
    chefResult.value &&
    !chefs.find(prevValue => prevValue === chefResult.value)
  ) {
    chefs.push(chefResult.value)
  }
}
const handleCuisinesSuggestions = (
  cuisines: string[],
  cuisineResult: FuseResultMatch
) => {
  if (
    cuisineResult.value &&
    !cuisines.find(prevValue => prevValue === cuisineResult.value)
  ) {
    cuisines.push(cuisineResult.value)
  }
}
const handleDietsSuggestions = (
  diets: string[],
  dietResult: FuseResultMatch
) => {
  if (
    dietResult.value &&
    !diets.find(prevValue => prevValue === dietResult.value)
  ) {
    diets.push(dietResult.value)
  }
}

const handleProteinsSuggestions = (
  proteins: string[],
  proteinResult: FuseResultMatch
) => {
  if (
    proteinResult.value &&
    !proteins.find(prevValue => prevValue === proteinResult.value)
  ) {
    proteins.push(proteinResult.value)
  }
}

export const inputHasSuggestions = (suggestions: MealsSuggestion): boolean => {
  if (!suggestions) return false
  return (
    suggestions?.chefs?.length > 0 ||
    suggestions?.cuisines.length > 0 ||
    suggestions?.ingredients.length > 0 ||
    suggestions?.diets.length > 0 ||
    suggestions?.proteins.length > 0
  )
}

export const combineSuggestions = (suggestions: MealsSuggestion): string[] => {
  return [
    ...suggestions?.chefs,
    ...suggestions?.cuisines,
    ...suggestions?.ingredients,
    ...suggestions?.diets,
    ...suggestions?.proteins
  ]
}

export const addRecentSearch = (
  recentSearches: Array<string>,
  value: string
) => {
  const trimmedValue = value.trim()
  let recents = [...recentSearches]

  if (recentSearches.includes(trimmedValue)) {
    recents = recentSearches.filter((search: string) => search !== trimmedValue)
  }

  return [...recents, trimmedValue]
}
