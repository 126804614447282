import { gql, useMutation } from '@apollo/client'
import { GET_USER_DELIVERY_QUERY } from 'src/api/DeliveryDay/useGetUserDeliveries'
import { useSelector } from 'react-redux'
import { getUpcomingDeliveries } from 'src/modules/MyDeliveries/selectors'

const CHANGE_USER_PLAN_MUTATION = gql`
  mutation changeUserPlan($planId: Int!) {
    changeUserPlan(planId: $planId)
  }
`

export function useChangeUserPlan({
  onError,
  onSuccess
}: {
  onError?: () => void
  onSuccess?: (response: { changeDeliveryPlan: boolean }) => void
}) {
  const deliveryDays = useSelector(getUpcomingDeliveries)
  const [mutation, { loading, ...rest }] = useMutation(
    CHANGE_USER_PLAN_MUTATION,
    {
      onError,
      onCompleted: onSuccess,
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_DELIVERY_QUERY,
          variables: { shippingDates: deliveryDays.map(d => d.date) }
        }
      ]
    }
  )

  function changeUserPlan({ planId }) {
    mutation({ variables: { planId } })
  }

  return {
    changeUserPlan,
    loading,
    ...rest
  }
}
