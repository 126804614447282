import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useQueryParams } from '../../hooks/useQueryParams'

import { useDispatch, useSelector } from 'react-redux'
import { applyAutoPilotPromotion } from 'src/redux-api-bridge'
import { applyAutoPilotPromotionSelector } from './selector'
import Loader from '../../common/Loader'
const PromotionPage = () => {
  const { query } = useQueryParams()
  const generalCoupon = query.get('generalCoupon')
  const origin = query.get('origin')
  const dispatch = useDispatch()
  const applyAutoPilotPromotionState = useSelector(
    applyAutoPilotPromotionSelector
  )

  useEffect(() => {
    if (generalCoupon && origin) {
      dispatch(applyAutoPilotPromotion({ generalCoupon, origin }))
    }
  }, [generalCoupon, origin, dispatch])

  if (!applyAutoPilotPromotionState.inFlight) {
    return <Redirect to="/nextOrder" />
  }

  return <Loader />
}

export default PromotionPage
