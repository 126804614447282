import { handleActions } from 'redux-actions'
import {
  openChallengePanel,
  claimRewardFail,
  claimRewardStart,
  claimRewardSuccess,
  closeChallengePanel,
  fetchUserChallengesFail,
  fetchUserChallengesStart,
  fetchUserChallengesSuccess,
  fetchChallengeWithStepsFail,
  fetchChallengeWithStepsStart,
  fetchChallengeWithStepsSuccess
} from './actions'
import { Challenge, State } from '../../types/challenge'

const initialState: State = {
  isOpen: false,
  inFlight: false,
  error: false,
  succeded: false,
  active: [] as Challenge[],
  data: null,
  claim: {
    id: null,
    inFlight: false,
    error: false,
    succeded: false
  }
}

export const challengeReducer = handleActions(
  {
    [fetchUserChallengesFail as any]: (state): State => {
      return {
        ...state,
        inFlight: false,
        error: true
      }
    },
    [fetchUserChallengesStart as any]: (state): State => {
      return {
        ...state,
        inFlight: true,
        error: false,
        succeded: false
      }
    },
    [fetchUserChallengesSuccess as any]: (state, { payload }): State => {
      const active = (payload.challenges || []) as Challenge[]
      return {
        ...state,
        inFlight: false,
        succeded: true,
        active
      }
    },
    [fetchChallengeWithStepsFail as any]: (state): State => {
      return {
        ...state,
        inFlight: false,
        error: true
      }
    },
    [fetchChallengeWithStepsStart as any]: (state): State => {
      return {
        ...state,
        inFlight: true,
        succeded: false,
        error: false
      }
    },
    [fetchChallengeWithStepsSuccess as any]: (state, { payload }): State => {
      const challengeById = payload.activeChallengeWithSteps.reduce(
        (prev, { challenge, steps, finishedAt }) => {
          return {
            ...prev,
            [challenge.challengeId]: {
              id: challenge.challengeId,
              steps,
              finishedAt
            }
          }
        },
        {}
      )
      return {
        ...state,
        inFlight: false,
        succeded: true,
        data: {
          ...state.data,
          ...challengeById
        }
      }
    },
    [claimRewardStart as any]: (state, { payload }): State => {
      return {
        ...state,
        claim: {
          inFlight: true,
          error: false,
          succeded: false,
          id: payload.challengeId
        }
      }
    },
    [claimRewardSuccess as any]: (state, { payload }): State => {
      const challengeId = state.claim.id
      return {
        ...state,
        data: {
          ...state.data,
          [challengeId]: {
            ...state.data[challengeId],
            finishedAt: payload.finishedAt
          }
        },
        claim: {
          ...state.claim,
          inFlight: false,
          succeded: true
        }
      }
    },
    [claimRewardFail as any]: (state): State => {
      return {
        ...state,
        claim: {
          ...state.claim,
          inFlight: false,
          error: true
        }
      }
    },
    [openChallengePanel as any]: (state): State => {
      return {
        ...state,
        isOpen: true
      }
    },
    [closeChallengePanel as any]: (state): State => {
      return {
        ...state,
        isOpen: false
      }
    }
  },
  initialState
)
