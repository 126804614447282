import React from 'react'
import './hero-cui.scss'

export default function HeroCui({ children, className, style, image }) {
  return (
    <div className={`hero-cui ${className} no-background`} style={style}>
      <div className="half-container-row">
        <div className="container-cui">
          <div className="content-left">{children}</div>
        </div>

        <div className="half-container-image">
          <img src={image} alt="hero" />
        </div>
      </div>
    </div>
  )
}
