import * as api from '../../api/challenge'
import * as actions from './actions'

export const fetchUserChallenges = () => dispatch => {
  dispatch(actions.fetchUserChallengesStart())
  api
    .fetchUserChallenges()
    .then(({ data: { activeChallenge } }) => {
      dispatch(
        actions.fetchUserChallengesSuccess({
          challenges: activeChallenge
        })
      )
    })
    .catch(() => {
      dispatch(actions.fetchUserChallengesFail())
    })
}

export const fetchChallengeWithSteps = challengeId => dispatch => {
  if (!challengeId) {
    return
  }

  dispatch(actions.fetchChallengeWithStepsStart())
  api
    .fetchChallengeWithSteps({ challengeId })
    .then(({ data: activeChallengeWithSteps }) => {
      dispatch(actions.fetchChallengeWithStepsSuccess(activeChallengeWithSteps))
    })
    .catch(() => {
      dispatch(actions.fetchChallengeWithStepsFail())
    })
}

export const claimReward = challengeId => dispatch => {
  dispatch(actions.claimRewardStart({ challengeId }))
  api
    .claimReward({ challengeId })
    .then(({ data: finishedAt }) => {
      dispatch(actions.claimRewardSuccess({ finishedAt }))
    })
    .catch(e => {
      dispatch(actions.claimRewardFail())
    })
}
