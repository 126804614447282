import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'
import { formatNumber } from 'src/utils/utils'

export function usePlanConfiguration() {
  const { t } = useTranslation()

  const [planName, setPlanName] = useState('')

  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)

  const deliveriesPerWeek = subscriptionPlan?.deliveries ?? 1
  const mealsPerDelivery = subscriptionPlan?.mealsPerDelivery ?? 1
  const mealsPerWeek = mealsPerDelivery * deliveriesPerWeek

  const price = subscriptionPlan?.price ?? ''
  const pricePerMeal = formatNumber(price / mealsPerWeek)

  const isPlanWithDeliveryFee =
    typeof subscriptionPlan?.deliveryFee === 'number'

  useEffect(() => {
    const plural = mealsPerWeek > 1 ? 's' : ''
    const name = t('pages.my_plan.meals_per_week', { mealsPerWeek, plural })
    setPlanName(name)
  }, [t, mealsPerWeek])

  return {
    deliveriesPerWeek,
    mealsPerDelivery,
    mealsPerWeek,
    pricePerMeal,
    isPlanWithDeliveryFee,
    planName
  }
}
