import React from 'react'
import { CuiTextArea } from 'front-lib'
import './TextArea.scss'

export const TextArea: React.FC<React.TextareaHTMLAttributes<
  HTMLTextAreaElement
>> = ({ name, value, onChange, placeholder, ...props }) => (
  <CuiTextArea
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    {...props}
  />
)
