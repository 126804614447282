import { filter } from 'ramda'

import tracking from '../../shared/tracking'

export const getSelectedProfileById = (profiles, id) => {
  const filterProfiles = filter(profile => profile.id === id, profiles)
  return filterProfiles && filterProfiles.length > 0 ? filterProfiles[0] : null
}

export const trackEventWishes = (event, meal) => {
  tracking.track(event, {
    id: meal.id,
    sku: meal.sku,
    name: meal.name,
    chef_name: meal.chef_name || `${meal.chef_firstname} ${meal.chef_lastname}`,
    chef_id: meal.chef_id,
    specification: meal.specifications_detail,
    ingredients: meal.ingredients,
    price: meal.price,
    image_url: meal.image ? meal.image.full_path : meal.image_url,
    rating: meal.starts,
    nutritional_info: meal.nutritional_info
  })
}
