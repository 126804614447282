import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import classNames from 'classnames'
import { CuiBottomSheet, CuiInput } from 'front-lib'
import { FormItemHeader } from '../FormItemHeader/FormItemHeader'
import OpenIndicator from 'src/assets/icons/dropdown-arrow-down.png'
import CloseIndicator from 'src/assets/icons/dropdown-arrow-up.png'
import Close from 'src/assets/icons/xclose.png'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { OptionItem, YES_NO_IDS, YesNoOptionType } from 'src/types/preferences'
import { YES_NO_OPTIONS } from 'src/pages/preferences/utils'
import RadioButtonList from 'src/pages/preferences/components/RadioButtonList/RadioButtonList'

import './DropdownSelector.scss'

export type DropdownSelectorItem = {
  id: string
  label: string
}

type DropdownSelectorProps = {
  title?: string
  subtitle?: string
  info?: string
  items: DropdownSelectorItem[]
  disabled: boolean
  selected: DropdownSelectorItem[]
  isNoPreferenceSelected: boolean
  onChange: Function
  onNoPreferenceSelection: Function
  name: string
  withSearchInput: boolean
  onInputClicked: Function
  question?: string
  clarification?: string
  showOptInOptions: boolean
  withYesNoPlaceholder: boolean
  allowMultipleSelection: boolean
  placeholder: string
}

const DropdownSelector = ({
  title,
  info,
  subtitle,
  items,
  disabled,
  selected,
  isNoPreferenceSelected,
  onChange,
  onNoPreferenceSelection,
  name,
  withSearchInput,
  onInputClicked,
  showOptInOptions,
  placeholder,
  withYesNoPlaceholder,
  allowMultipleSelection,
  question,
  clarification
}: DropdownSelectorProps) => {
  const isMobile = useMobileDevice()
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false)
  const [filterInput, setFilterInput] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<OptionItem[]>([])
  const [options, setOptions] = useState<OptionItem[]>([])
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>([])
  const [notSelectedOptions, setNotSelectedOptions] = useState<OptionItem[]>([])

  const mapOptions = option => ({
    ...option,
    value: option.id,
    name: option.label
  })

  const mapSelectedOptions = element => {
    const options = items?.map(mapOptions)
    const item = options?.find(e => e.id === element.id) || {}
    return { ...item, ...element }
  }

  useEffect(() => {
    if (filterInput) {
      const filtered = notSelectedOptions.filter(o =>
        o.label.toLowerCase().includes(filterInput.toLowerCase())
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(notSelectedOptions)
    }
  }, [filterInput, notSelectedOptions])

  useEffect(() => {
    const selectedOptions = selected?.map(mapSelectedOptions)
    const options = items?.map(mapOptions)
    const notSelectedOptions = options?.filter(
      option => !selectedOptions?.find(o => o.id === option.id)
    )
    setSelectedOptions(selectedOptions)
    setOptions(options)
    setNotSelectedOptions(notSelectedOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selected])

  const IndicatorSeparator = () => null
  const MultiValueRemove = () => null
  const ClearIndicator = () => null

  const DropdownIndicator = props => {
    const {
      selectProps: { menuIsOpen }
    } = props
    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <img alt="close-dropdown" src={CloseIndicator} />
        ) : (
          <img alt="open-dropdown" src={OpenIndicator} />
        )}
      </components.DropdownIndicator>
    )
  }

  const MultiValueContainer = ({ selectProps, data }) => {
    const values = selectProps.value
    return !!options?.find(o => o.id === data.id) ? (
      <span className="preferences-dropdown__multivalue-list-item">
        {values[values.length - 1].label === data.label
          ? data.label
          : data.label + ', '}
      </span>
    ) : null
  }

  const MultiValueLabel = ({ children, innerProps }) => (
    <div className="preferences-dropdown__multivalue-label" {...innerProps}>
      <div>{children}</div>
    </div>
  )

  const CustomMenu = ({ children, innerProps }) => {
    if (isMobile) {
      return <></>
    }
    return (
      <div className="preferences-dropdown__input" {...innerProps}>
        <div className="preferences-dropdown__input__container">
          {question && (
            <FormItemHeader
              title={question}
              subtitle={clarification}
              className="question-title"
            />
          )}
          {!!selectedOptions && selectedOptions.length > 0 && (
            <div className="preferences-dropdown__input-selected-list">
              {selectedOptions?.map(option =>
                !!options?.find(o => o.id === option.id) ? (
                  <div
                    className="preferences-dropdown__input-selected-list-item"
                    role="button"
                    key={option.id}
                    onClick={() =>
                      onChange(
                        selectedOptions?.filter(o => o.id !== option.id),
                        name
                      )
                    }>
                    {option.label}
                    <img src={Close} alt="remove" />
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>

        {children}
      </div>
    )
  }

  return (
    <div
      className={classNames('preferences-dropdown', {
        'with-yes-no-options-placeholder':
          !showOptInOptions && withYesNoPlaceholder
      })}>
      {title && (
        <FormItemHeader title={title} subtitle={subtitle} info={info} />
      )}
      {showOptInOptions ? (
        <RadioButtonList
          options={YES_NO_OPTIONS}
          checkedOptionId={
            isNoPreferenceSelected ? YES_NO_IDS.NO : YES_NO_IDS.YES
          }
          onChange={(option: YesNoOptionType): void => {
            if (option.id === YES_NO_IDS.NO) {
              onNoPreferenceSelection(name, null)
            } else if (option.id === YES_NO_IDS.YES) {
              onNoPreferenceSelection(name, [])
            }
          }}
          name={name}
          direction="row"
          allowEmptyResponse={false}
        />
      ) : (
        <div className="preferences-dropdown__yes-no-options-placeholder" />
      )}
      <div className="preferences-dropdown__selector-check">
        <div
          className="preferences-dropdown__list"
          onClick={e => {
            if (disabled) return
            if (isMobile) {
              e.preventDefault()
              e.stopPropagation()
              setBottomSheetOpen(bottomSheetOpen => !bottomSheetOpen)
            }
          }}>
          <Select
            classNamePrefix="preferences-dropdown-select"
            options={options}
            key={isMobile ? selected?.length : `select-${name}`}
            components={{
              Menu: CustomMenu,
              DropdownIndicator,
              IndicatorSeparator,
              ClearIndicator,
              MultiValueLabel,
              MultiValueContainer,
              MultiValueRemove
            }}
            isSearchable={withSearchInput}
            isMulti={allowMultipleSelection}
            placeholder={placeholder}
            value={selected?.map(mapSelectedOptions)}
            onChange={selection => onChange(selection, name)}
            closeMenuOnSelect={!allowMultipleSelection}
            isDisabled={disabled}
            onMenuOpen={() => {
              if (disabled) return
              onInputClicked(title)
              isMobile &&
                setBottomSheetOpen(bottomSheetOpen => !bottomSheetOpen)
            }}
          />
        </div>
      </div>
      <CuiBottomSheet isOpen={bottomSheetOpen}>
        <CuiBottomSheet.Header
          onRequestClose={() => setBottomSheetOpen(false)}
          className="preferences-bottom-sheet__header">
          {placeholder}
        </CuiBottomSheet.Header>
        <CuiBottomSheet.Body
          className={classNames('preferences-bottom-sheet__body', {
            'multiple-selection': allowMultipleSelection
          })}>
          <FormItemHeader title={question} subtitle={clarification} />
          {withSearchInput && (
            <CuiInput
              color="white"
              className={`form-input`}
              type="text"
              value={filterInput}
              onChange={e => setFilterInput(e.target.value)}
              placeholder=""
            />
          )}
          {allowMultipleSelection ? (
            <>
              {!!selectedOptions && selectedOptions.length > 0 && (
                <div className="preferences-dropdown__input-selected-list">
                  {selectedOptions?.map(option => (
                    <div
                      className="preferences-dropdown__input-selected-list-item"
                      key={option.id}
                      role="button"
                      onClick={() => {
                        onChange(
                          selectedOptions?.filter(o => o.id !== option.id),
                          name
                        )
                      }}>
                      {option.label}
                      <img src={Close} alt="remove" />
                    </div>
                  ))}
                </div>
              )}
              <div className="preferences-bottom-sheet__list">
                {filteredOptions?.map((option, index) => (
                  <div
                    key={`${option.id}-${index}`}
                    role="button"
                    className="preferences-bottom-sheet__list-item"
                    onClick={() => {
                      onChange([...selectedOptions, option], name)
                    }}>
                    {option.label}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="preferences-bottom-sheet__radio-buttons-list">
              <RadioButtonList
                options={options}
                checkedOptionId={
                  selectedOptions.length > 0 ? selectedOptions[0].id : undefined
                }
                onChange={onChange}
                name={name}
                direction="column"
                allowEmptyResponse={true}
              />
            </div>
          )}
        </CuiBottomSheet.Body>
      </CuiBottomSheet>
    </div>
  )
}

export { DropdownSelector }
