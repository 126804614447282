import { loadState, setLocalStorage } from '../localStorage'
import createReducer from '../utils/createReducer'

const localstorage = loadState()

export default createReducer(localstorage ? localstorage : {}, {
  [setLocalStorage.succeed.type]: (state, { payload }) => ({
    ...state,
    ...payload
  })
})
