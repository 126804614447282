import { put, call, takeEvery, all } from 'redux-saga/effects'

import { GQL } from '../services'
import {
  GET_SAMPLE_MENU_INIT,
  GET_SAMPLE_MENU_SUCCESS,
  GET_SAMPLE_MENU_FAILURE
} from '../actions/types'

export function* getSampleMenu({ payload }) {
  try {
    const sampleMenuData = yield call(GQL.getSampleMenu, payload)
    yield put({
      type: GET_SAMPLE_MENU_SUCCESS,
      payload: sampleMenuData
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    yield put({
      type: GET_SAMPLE_MENU_FAILURE,
      error
    })
  }
}

export default function* rootInitialSaga() {
  yield all([takeEvery(GET_SAMPLE_MENU_INIT, getSampleMenu)])
}
