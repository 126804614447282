import React from 'react'
import classnames from 'classnames'
import { CuiButton, CuiIcon } from 'front-lib'

import './RemoveSetting.scss'

const RemoveSetting = props => {
  const {
    className,
    style,
    label,
    disabled,
    onClick,
    actionIsActive,
    actionComponent,
    isLoading
  } = props

  return (
    <div
      className={classnames('cookunity__remove_setting', className, {
        loading: isLoading
      })}
      style={style}>
      {!actionIsActive ? (
        <CuiButton
          fill="clear"
          size="large"
          className={classnames('label', { disabled })}
          onClick={() => {
            if (!disabled) {
              onClick()
            }
          }}>
          <CuiIcon name="trash" />
          <div className="cookunity__remove_label">{label}</div>
        </CuiButton>
      ) : (
        actionComponent
      )}
    </div>
  )
}

export default RemoveSetting
