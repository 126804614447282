import React from 'react'

const Title = ({ label, children, ...props }) => (
  <div className="cookunity__form_title_container" {...props}>
    <p className="cookunity__form_title">{children}</p>
    {label && <span className="label">{label}</span>}
  </div>
)

export default Title
