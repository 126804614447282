import { ADDONS_CATEGORIES, MEALS_CATEGORY } from 'src/constants/menu'
import { BUNDLE_TAG } from 'src/modules/MyPlan/constants'
import { formatFixedPrice } from 'src/utils/utils'

export const getChefs = bundle => {
  const chefs = []
  bundle?.meals?.forEach(meal => {
    if (!chefs.find(c => c.id === meal?.chef?.id)) {
      chefs.push({
        id: meal?.chef?.id,
        image: meal?.chef?.profileImageUrl,
        name: `${meal?.chef?.firstName || ''} ${meal?.chef?.lastName || ''}`
      })
    }
  })
  return chefs
}

// FIXME Unify with the code below
export const getChefsUpcomingDays = bundle => {
  const chefs = []
  const bundlesItems = bundle.items

  bundlesItems.forEach(item => {
    if (chefs.find(chef => chef.id === item.product.chef_id)) {
      return
    }
    chefs.push({
      id: item.product.chef_id,
      image: item.product.bannerpic,
      name: `${item.product.chef_firstname || ''} ${item.product
        .chef_lastname || ''}`
    })
  })
  return chefs
}

const MAIN_TAGS = {
  NEW: {
    label: 'NEW',
    color: 'yellow'
  },
  BUNDLE_SAVE: {
    label: BUNDLE_TAG,
    color: 'blue'
  }
}

export const getMainTags = (bundle, max) => {
  const result = []
  if (bundle.isNew) {
    result.push(MAIN_TAGS.NEW)
  }
  if (+bundle.finalPrice < +bundle.price) {
    result.push(MAIN_TAGS.BUNDLE_SAVE)
  }

  return result?.length > max ? result.splice(0, max) : result
}

export const getBundleAddButtonText = (
  bundle,
  planIsCompleted,
  isExtra,
  noExtraMealPlanIsCompleted,
  isBOM
) => {
  const isMealBundle = bundle.category_id === MEALS_CATEGORY
  const isExtraBundle = bundle.category_id === ADDONS_CATEGORIES[0]

  const finalPrice =
    bundle.finalPrice || bundle.price + (bundle.premium_fee || 0)

  switch (true) {
    case isBOM:
      return `Add for ${formatFixedPrice(finalPrice)}`
    case noExtraMealPlanIsCompleted && !isExtra:
      return `Add meal`
    case isMealBundle && !planIsCompleted:
      return `Add meal + ${formatFixedPrice(bundle.priceWithoutPlanMeal)}`
    case isMealBundle && planIsCompleted:
      return `Add meal for ${formatFixedPrice(finalPrice)}`
    case isExtraBundle:
      return `Add extra for ${formatFixedPrice(finalPrice)}`
    default:
      return `Add meal`
  }
}

export const bundleStrikethroughPrice = (bundle, planIsCompleted) => {
  const showStrikethroughPrice = +bundle.finalPrice < +bundle.price
  const isMealBundle = bundle.category_id === MEALS_CATEGORY

  if (!showStrikethroughPrice) return null

  if (showStrikethroughPrice && isMealBundle && !planIsCompleted) {
    return formatFixedPrice(bundle.originalPriceWithoutPlanMeal)
  }

  return formatFixedPrice(bundle.price)
}
