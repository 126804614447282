import React from 'react'
import { ComponentProps } from 'src/types/component'
import {
  Icon,
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import './InfoContainer.scss'

export const InfoContainer: React.FC<ComponentProps> = ({
  image,
  title,
  subTitle,
  children
}) => {
  return (
    <div className="info-container">
      <Icon name={image.name} className="info-container__icon" />
      <Text
        value={title}
        style={TextStyle.H1}
        className="info-container__title"
      />
      <Text
        value={subTitle}
        style={TextStyle.MEDIUM_REGULAR}
        className="info-container__subtitle"
      />
      {children}
    </div>
  )
}
