import { createAction } from 'redux-actions'

export const fetchPreferencesData = createAction('FETCH_PREFERENCES_DATA')
export const fetchPreferencesDataSucceed = createAction(
  'FETCH_PREFERENCES_DATA_SUCCEED'
)
export const fetchPreferencesDataFailed = createAction(
  'FETCH_PREFERENCES_DATA_FAILED'
)

export const savePreferencesData = createAction('SAVE_PREFERENCES_DATA')
export const savePreferencesDataSucceed = createAction(
  'SAVE_PREFERENCES_DATA_SUCCEED'
)
export const savePreferencesDataFailed = createAction(
  'SAVE_PREFERENCES_DATA_FAILED'
)

export const removeUserProfile = createAction('REMOVE_USER_PROFILE')
export const removeUserProfileSucceed = createAction(
  'REMOVE_USER_PROFILE_SUCCEED'
)
export const removeUserProfileFailed = createAction(
  'REMOVE_USER_PROFILE_FAILED'
)

export const setMealWish = createAction('PREFERENCES_SET_MEAL_WISH')
export const setMealAvoid = createAction('PREFERENCES_SET_MEAL_AVOID')
