import { useEffect, useRef } from 'react'
import './FocusableWrapper.scss'

const FocusableWrapper = ({ isFocused, children }) => {
  const formItemRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isFocused && formItemRef?.current) {
      formItemRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isFocused, formItemRef])

  return isFocused ? (
    <div className="focused-input" ref={formItemRef}>
      {children}
    </div>
  ) : (
    <div>{children}</div>
  )
}

export { FocusableWrapper }
