import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { ReactComponent as Alert } from '../../../../assets/svg/alert-circle.svg'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { formatMembershipDate } from '../MembershipBenefits/utils'
import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'

import './MembershipCancel.scss'
import { useStopMembership } from 'src/api/Membership'

interface Props {
  onClose: () => void
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipCancel({
  onClose,
  refetch,
  onContinue,
  membershipData
}: Props) {
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.cancel
  })
  const { stopMembership, loading } = useStopMembership({
    onCompleted,
    onError
  })
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onStopMembership() {
    if (membershipData?.activation.membershipId) {
      setErrorMessage('')
      stopMembership({ membershipId: membershipData?.activation.membershipId })
      trackOptionClicked({
        overlayAction: MembershipOverlayActions.yesCancelNow
      })
    }
  }

  function onCompleted(response) {
    if (response.stopMembership.success) {
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
    } else {
      onError(response)
    }
  }

  function onError(response) {
    setErrorMessage(
      response?.stopMembership?.error ||
        'Sorry, we encountered an error. Please try again.'
    )
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="cancel-container">
        {loading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="title-container">
          <span className="title">
            Are you sure you want to give up your Unity Pass perks?
          </span>
        </div>
        <div className="description-container">
          <span>
            You’re currently enjoying $0 delivery fees, exclusive discounts, and
            three premium meals per order at no extra fee. By canceling now, you
            will lose these benefits after{' '}
            {formatMembershipDate(membershipData?.activation.endDate)}.
          </span>
        </div>
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="button-container">
          <CuiButton className="secondary-button" onClick={onStopMembership}>
            Yes, cancel now
          </CuiButton>
          <CuiButton className="primary-button" onClick={() => onClose()}>
            Never mind
          </CuiButton>
        </div>
      </div>
    </>
  )
}
