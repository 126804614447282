import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import moment from 'moment'

export const getUserCards = state => pathOr(null, ['payments', 'cards'], state)

export const userCardsStatus = state =>
  pathOr(null, ['payments', 'userCardsStatus'], state)

export const createCardStatus = state =>
  pathOr(null, ['payments', 'createCardStatus'], state)

export const redeemGiftCardStatus = state =>
  pathOr(null, ['payments', 'redeemGiftCardStatus'], state)

export const defaultCardStatus = state =>
  pathOr(null, ['payments', 'defaultCardStatus'], state)

export const removeCardStatus = state =>
  pathOr(null, ['payments', 'removeCardStatus'], state)

export const getUserInfo = state => pathOr(null, ['api', 'user', 'info'], state)

export const getDefaultCard = createSelector([getUserCards], cards => {
  if (cards && cards.length) {
    const card = cards.find(card => card.isDefault) || cards[0]

    return { ...card, ...(card.card || {}), id: card.id }
  }
  return null
})

export const paymentsIsLoading = createSelector(
  [
    userCardsStatus,
    createCardStatus,
    removeCardStatus,
    defaultCardStatus,
    getUserCards
  ],
  (cardsStatus, create, remove, defaultCard, cards) => {
    return (
      cardsStatus.inFlight ||
      !cardsStatus.succeeded ||
      create.inFlight ||
      remove.inFlight ||
      defaultCard.inFlight ||
      !cards
    )
  }
)

export const getCards = createSelector([getUserCards], cards => {
  if (cards) {
    return cards.reduce((acc, curr) => {
      if (curr.isDefault) {
        return [{ ...curr, ...(curr.card || {}), id: curr.id }, ...acc]
      }
      return [...acc, { ...curr, ...(curr.card || {}), id: curr.id }]
    }, [])
  }
  return [{}]
})

export const isValidCC = createSelector([getUserCards], cards => {
  let valid = false
  if (cards && cards.length) {
    let defaultCard = cards.find(card => card.isDefault) || cards[0]

    defaultCard = {
      ...defaultCard,
      ...(defaultCard.card || {}),
      id: defaultCard.id
    }

    const expirationDate = `${defaultCard.expirationYear}${defaultCard.expirationMonth}`
    if (expirationDate > moment().format('YYYYMM')) valid = true
  }

  return valid
})

export const creatingCard = createSelector(
  createCardStatus,
  status => status.inFlight
)

export const redeemingGiftCard = createSelector(
  redeemGiftCardStatus,
  status => status.inFlight
)
