import { createAction } from 'redux-actions'

export const changePasswordStart = createAction('CHANGE_PASSWORD_STARTED')
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCEED')
export const changePasswordError = createAction('CHANGE_PASSWORD_FAILED')

export const deleteAccountStart = createAction('DELETE_ACCOUNT_STARTED')
export const deleteAccountSuccess = createAction('DELETE_ACCOUNT_SUCCEED')
export const deleteAccountError = createAction('DELETE_ACCOUNT__FAILED')

export const updatePersonalInfoStart = createAction(
  'UPDATE_PERSONAL_INFO_STARTED'
)
export const updatePersonalInfoSuccess = createAction(
  'UPDATE_PERSONAL_INFO_SUCCEED'
)
export const updatePersonalInfoError = createAction(
  'UPDATE_PERSONAL_INFO_FAILED'
)
export const updatePersonalInfoClean = createAction(
  'UPDATE_PERSONAL_INFO_CLEAN'
)
