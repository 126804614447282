import { useFlag } from '@unleash/proxy-client-react'
import { useAllowedToSkipMembership } from 'src/api/Membership/useAllowedToSkipMembership'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import {
  MembershipOverlayActions,
  MembershipOverlayName
} from 'src/types/membership'
import { FF_ENABLE_SKIP_MEMBERSHIP_FEATURE } from 'src/constants/feature-flags'

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

export function ActiveFooter({ onContinue }) {
  const isSkipFeatureEnabled = useFlag(FF_ENABLE_SKIP_MEMBERSHIP_FEATURE)
  const { isAllowed } = useAllowedToSkipMembership()

  const { trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })

  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.cancel })

    if (isSkipFeatureEnabled && isAllowed) {
      return onContinue(MEMBERSHIP_MODAL_STEPS.pause)
    }

    return onContinue(MEMBERSHIP_MODAL_STEPS.cancel)
  }

  return (
    <div className="footer-container">
      <span className="cancel-terms">
        Click{' '}
        <a target="_blank" href={`${BASE_URL}/terms`} rel="noreferrer">
          here
        </a>{' '}
        to view the Unity Pass Terms and Conditions.
      </span>
      <span className="cancel" onClick={handleContinue}>
        Cancel Unity Pass
      </span>
    </div>
  )
}
