import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChallengePanel } from 'src/components/ChallengePanel/ChallengePanel'
import { getChallenge } from 'src/redux-api-bridge/challenge/selectors'
import { closeChallengePanel } from 'src/redux-api-bridge/challenge/actions'
import useChallenge from 'src/hooks/useChallenge'

interface Props {
  challengeId: number
}

export const WiredChallengePanel: FC<Props> = ({ challengeId }) => {
  const dispatch = useDispatch()
  const { isOpen } = useSelector(getChallenge)
  const {
    challengeData,
    stepsData,
    trackChallengeExperimentState,
    trackChallengeExperiment
  } = useChallenge(challengeId)

  useEffect(() => {
    if (challengeData.steps) {
      trackChallengeExperimentState('Subscription - Milestones Completed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeData.steps])

  function handleClose() {
    dispatch(closeChallengePanel())
  }

  function handleClick(ctaText: string) {
    trackChallengeExperiment('CTA Button Clicked', {
      cta_text: `Milestones - ${ctaText}`
    })
  }

  return (
    <ChallengePanel
      isOpen={isOpen}
      onClose={handleClose}
      onClick={handleClick}
      stepsData={stepsData}
    />
  )
}
