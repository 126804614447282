import classNames from 'classnames'
import { useLocation, Link } from 'react-router-dom'

const MenuLink = ({ to, url, onClick, className = '', children }) => {
  const { pathname } = useLocation()
  const linkClasses = classNames('full-width d-contents', {
    bold: to === pathname
  })

  return (
    (url && (
      <button className={className} onClick={onClick}>
        <a href={url} className={'menu_item_link'}>
          {children}
        </a>
      </button>
    )) || (
      <Link className={className} to={to} onClick={onClick}>
        <span className={linkClasses}>{children}</span>
      </Link>
    )
  )
}

export default MenuLink
