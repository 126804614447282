import {
  getDaysWithMenuAvailable,
  isEligibleZeroOrders
} from '../../modules/MyDeliveries/selectors'
import { getInitials, getUserFirstName } from './utils'

import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import { selectSchedulePanelIsOpen } from '../../redux-api-bridge/schedule/selectors'

const getUserNameSelector = state =>
  pathOr(null, ['api', 'subscription', 'info', 'name'], state)

const getInitialsSelector = createSelector([getUserNameSelector], fullName =>
  getInitials(fullName)
)

const getUserFirstNameSelector = createSelector(
  [getUserNameSelector],
  fullName => getUserFirstName(fullName)
)

const isEligibleZeroOrdersSelector = isEligibleZeroOrders

const getDaysWithMenuAvailableSelector = getDaysWithMenuAvailable

const selectSchedulePanelIsOpenSelector = selectSchedulePanelIsOpen

export {
  getInitialsSelector,
  getUserFirstNameSelector,
  isEligibleZeroOrdersSelector,
  getDaysWithMenuAvailableSelector,
  selectSchedulePanelIsOpenSelector
}
