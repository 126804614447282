import { CuiBottomSheet, CuiIcon } from 'front-lib'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onClose: () => void
}

export function ChangePlanSuccessBottomSheet({
  isOpen,
  onClose,
  children,
  ...rest
}: Props) {
  return (
    <CuiBottomSheet isOpen={isOpen} {...rest}>
      <CuiBottomSheet.Header onRequestClose={onClose} />
      <CuiBottomSheet.Body>
        <CuiIcon name="checkmarkDouble" />
        {children}
      </CuiBottomSheet.Body>
    </CuiBottomSheet>
  )
}
