import { useState, useEffect } from 'react'
import moment from 'moment'
import { CuiTag } from 'front-lib'
import { useSelector } from 'react-redux'
import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import {
  getSubscriptionPlanSelector,
  getExpireDateSelector
} from 'src/modules/MyAccount/selectors'
import { economicPlanModalAtom } from 'src/atoms/modals/economicPlanModal.atom'
import Info from '../../../../assets/icons/info.png'

export function EconomicPlanTag() {
  const { t } = useTranslation()
  const [economicPlanTag, setEconomicPlanTag] = useState('')
  const setEconomicPlanModal = useSetAtom(economicPlanModalAtom)

  const expireDate = useSelector(getExpireDateSelector)
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)

  useEffect(() => {
    let planTag = ''

    if (subscriptionPlan?.segmentId === 4) {
      planTag = t('pages.my_plan.economic_meal_plan')
    }

    if ([6, 11].includes(subscriptionPlan?.segmentId)) {
      planTag = t('pages.my_plan.economic_meal_plan_8.5')
    }

    if ([8, 12].includes(subscriptionPlan?.segmentId)) {
      const mealsPerDelivery = subscriptionPlan?.mealsPerDelivery ?? 1

      if (mealsPerDelivery === 4) {
        planTag = t('pages.my_plan.economic_meal_plan_9')
      }

      planTag = t('pages.my_plan.economic_meal_plan_8.5')
    }

    setEconomicPlanTag(planTag)
  }, [t, subscriptionPlan?.segmentId, subscriptionPlan?.mealsPerDelivery])

  return (
    <div className="plan-tag-container">
      <CuiTag color="blue">{economicPlanTag}</CuiTag>
      {expireDate && (
        <div className="expire-date">
          {`${t('pages.my_plan.expiresOn')} ${moment(expireDate).format(
            'MM/DD/YY'
          )}`}
        </div>
      )}
      <div className="plan-info">
        <img
          src={Info}
          className="cui-meal-info"
          alt="info"
          onClick={() => setEconomicPlanModal({ isOpen: true })}
        />
      </div>
    </div>
  )
}
