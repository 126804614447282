import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { ReactComponent as Alert } from '../../../../assets/svg/alert-circle.svg'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'

import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'

import './MembershipPause.scss'
import { usePauseMembership } from 'src/api/Membership/usePauseMembership'

interface Props {
  onClose: () => void
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipPause({
  onClose,
  refetch,
  onContinue,
  membershipData
}: Props) {
  const { pauseMembership, loading: startLoading } = usePauseMembership({
    onCompleted,
    onError
  })

  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.pause
  })

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onPauseMembership() {
    if (membershipData?.activation.membershipId) {
      setErrorMessage('')
      pauseMembership({ membershipId: membershipData?.activation.membershipId })
      onContinue(MEMBERSHIP_MODAL_STEPS.paused)
      trackOptionClicked({
        overlayAction: MembershipOverlayActions.pause
      })
    }
  }

  function onCompleted(response) {
    if (response.stopMembership.success) {
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.paused)
    } else {
      onError(response)
    }
  }

  function onCancel() {
    onContinue(MEMBERSHIP_MODAL_STEPS.cancel)
  }

  function onError(response) {
    setErrorMessage(
      response?.stopMembership?.error ||
        'Sorry, we encountered an error. Please try again.'
    )
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="pause-container">
        {startLoading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="title-container">
          <span className="title">
            Need a break? <br />
            Just hit pause.
          </span>
        </div>
        <div className="description-container">
          <span>
            Pause your Unity Pass for one month, hassle-free. You won’t be
            charged during the pause, and you can jump back in effortlessly — no
            need to sign up again!
          </span>
        </div>
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="button-container">
          <CuiButton className="secondary-button" onClick={() => onClose()}>
            Go back to homepage
          </CuiButton>
          <CuiButton className="primary-button" onClick={onPauseMembership}>
            Pause Unity Pass for one month
          </CuiButton>
        </div>
      </div>
      <div>
        <div className="footer-container">
          <button className="cancel" onClick={onCancel}>
            <span className="label">Cancel Unity Pass</span>
          </button>
        </div>
      </div>
    </>
  )
}
