import { handleActions, createAction } from 'redux-actions'

export const openTrackDeliveryPanel: () => any = createAction(
  'OPEN_TRACK_DELIVERY_PANEL'
)
export const closeTrackDeliveryPanel: () => any = createAction(
  'CLOSE_TRACK_DELIVERY_PANEL'
)

export interface State {
  isOpen: boolean
}

export const trackDeliveryPanelReducer = handleActions(
  {
    [openTrackDeliveryPanel as any]: (): State => {
      return {
        isOpen: true
      }
    },
    [closeTrackDeliveryPanel as any]: (): State => {
      return {
        isOpen: false
      }
    }
  },
  {
    isOpen: false
  } as State
)
