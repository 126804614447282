import { gql, useQuery } from '@apollo/client'
import moment from 'moment'

import { PauseData } from 'src/types/pauseData'

export const GET_PAUSE_DATA = gql`
  query pauseData($fromLastDays: Int!) {
    pauseData(fromLastDays: $fromLastDays) {
      startDate
      endDate
      days {
        date
        type
      }
    }
  }
`

export function usePauseData() {
  const { data, ...rest } = useQuery<{
    pauseData: PauseData
  }>(GET_PAUSE_DATA, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      fromLastDays: moment().day()
    }
  })

  return { data: data?.pauseData, ...rest }
}
