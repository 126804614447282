import { CuiFabButton } from 'front-lib'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getInitialsSelector, getUserFirstNameSelector } from '../../selectors'

const MenuGreeting = () => {
  const { t } = useTranslation()
  const initials = useSelector(getInitialsSelector)
  const userFirstName = useSelector(getUserFirstNameSelector)

  return (
    <div className="menu_section user_info">
      <CuiFabButton showBorder size="large">
        {initials}
      </CuiFabButton>
      <p className="greeting">
        {`${t('header.user_menu.hi_again')}${userFirstName ? ',' : ''}`}
        <br />
        {userFirstName && <span className="user_name">{userFirstName}!</span>}
      </p>
    </div>
  )
}

export default MenuGreeting
