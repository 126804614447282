import { useAtom } from 'jotai'

import { useMobileDevice } from '../../../../../../hooks/useMobileDevice'
import { ConfirmationPlanChangeModalMobile } from './ConfirmationPlanChangeModalMobile'
import { ConfirmationPlanChangeModalDesktop } from './ConfirmationPlanChangeModalDesktop'
import { useConfirmationPlanChangeTracking } from './useConfirmationPlanChangeTracking'
import { confirmationPlanChangeModalAtom } from 'src/atoms/modals/confirmationPlanChangeModal.atom'

export function ConfirmationPlanChangeModalOverlay() {
  const isMobileDevice = useMobileDevice()

  const [confirmationPlanChangeModal, setConfirmationPlanChangeModal] = useAtom(
    confirmationPlanChangeModalAtom
  )
  const { handleClose } = useConfirmationPlanChangeTracking(value =>
    setConfirmationPlanChangeModal({ isOpen: value })
  )

  const ConfirmationPlanChangeModal = isMobileDevice
    ? ConfirmationPlanChangeModalMobile
    : ConfirmationPlanChangeModalDesktop

  return (
    <ConfirmationPlanChangeModal
      isOpen={confirmationPlanChangeModal.isOpen}
      handleClose={handleClose}
    />
  )
}
