import { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { useFlag, IToggle, useFlags } from '@unleash/proxy-client-react'

import { ResponsiveModalOrBottomSheet } from 'src/components/ResponsiveModalOrBottomSheet/ResponsiveModalOrBottomSheet'

import { MembershipIntroduction } from './components/MembershipIntroduction/MembershipIntroduction'
import { MembershipConfirmation } from './components/MembershipConfirmation/MembershipConfirmation'
import { MembershipBenefits } from './components/MembershipBenefits/MembershipBenefits'
import { MembershipCheckout } from './components/MembershipCheckout/MembershipCheckout'
import { MembershipCancel } from './components/MembershipCancel/MembershipCancel'
import { MembershipPause } from './components/MembershipPause/MembershipPause'
import { MembershipPaused } from './components/MembershipPaused/MembershipPaused'

import { membershipModalAtom } from 'src/atoms/modals/membershipModal.atom'
import { useMembershipData } from 'src/api/Membership/useMembershipData'
import { useMarkModalAsViewed } from 'src/api/Modal/markModalAsViewed'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from './useTrackMembershipModal'

import { Step } from 'src/types/membership'

import { UnityPassIntroduction } from './components/UnityPass/UnityPassIntroduction/UnityPassIntroduction'
import { UnityPassBenefits } from './components/UnityPass/UnityPassBenefits/UnityPassBenefits'
import { UnityPassConfirmation } from './components/UnityPass/UnityPassConfirmation/UnityPassConfirmation'
import { UnityPassCheckout } from './components/UnityPass/UnityPassCheckout/UnityPassCheckout'
import { UnityPassCancel } from './components/UnityPass/UnityPassCancel/UnityPassCancel'
import { UnityPassPause } from './components/UnityPass/UnityPassPause/UnityPassPause'

import { ModalType } from 'src/types/nextModal'
import { UnityPassPaused } from './components/UnityPass/UnityPassPaused/UnityPassPaused'
import { FF_ENABLE_SKIP_MEMBERSHIP_FEATURE } from 'src/constants/feature-flags'

export const getFeatureFlagVariant = (featureFlagToggle: IToggle) => {
  const variant = featureFlagToggle.variant
  if (variant.payload !== undefined) {
    return variant.payload
  }
  return null
}

export function MembershipModal({ upselling = false }) {
  const isSkipFeatureEnabled = useFlag(FF_ENABLE_SKIP_MEMBERSHIP_FEATURE)
  const [{ isOpen, step }, setMembership] = useAtom(membershipModalAtom)
  const { membershipData, refetch, loading } = useMembershipData()
  const flags = useFlags()
  const { viewModal } = useMarkModalAsViewed()
  const { trackModalClosed } = useTrackMembershipModal({ overlayName: step })

  function handleClose() {
    trackModalClosed()
    setMembership(prev => ({
      ...prev,
      isOpen: false,
      step: MEMBERSHIP_MODAL_STEPS.closed
    }))
  }

  function handleContinue(step: Step, isRejoin: boolean = false) {
    setMembership(prev => ({ ...prev, step, isRejoin }))
  }

  useEffect(() => {
    if (upselling) {
      setMembership(prev => ({
        ...prev,
        step: MEMBERSHIP_MODAL_STEPS.introduction,
        isOpen: true
      }))
      viewModal({
        type: ModalType.MembershipModal
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const useChefMembership = Boolean(membershipData?.membership?.discount)

  const getSpecialOffer = (flags, source) => {
    if (source?.startsWith('CMK_PROMO')) {
      const variant = flags.find(flag => flag.name === source)?.variant.payload
        ?.value
      if (variant) {
        return JSON.parse(variant)
      }
    }
    return null
  }

  const specialOffer = useMemo(() => {
    const source = membershipData?.membership?.discount?.source
    return getSpecialOffer(flags, source)
  }, [flags, membershipData?.membership?.discount?.source])

  const bannerText = useMemo(() => {
    if (specialOffer?.bannerText) {
      return specialOffer.bannerText
    }
    return `a special invitation from Chef ${membershipData?.membership?.discount?.source}`
  }, [specialOffer, membershipData?.membership?.discount?.source])

  if (!membershipData) return null

  return useChefMembership ? (
    <ResponsiveModalOrBottomSheet
      className="unity-pass"
      onClose={handleClose}
      isOpen={isOpen}>
      {step === MEMBERSHIP_MODAL_STEPS.introduction && (
        <UnityPassIntroduction
          membershipData={membershipData}
          bannerText={bannerText}
          specialOffer={specialOffer}
          onContinue={handleContinue}
        />
      )}
      {step === MEMBERSHIP_MODAL_STEPS.benefits && (
        <UnityPassBenefits
          membershipData={membershipData}
          bannerText={bannerText}
          onContinue={handleContinue}
          onClose={handleClose}
          loading={loading}
        />
      )}
      {step === MEMBERSHIP_MODAL_STEPS.confirmation && (
        <UnityPassConfirmation
          membershipData={membershipData}
          bannerText={bannerText}
          onClose={handleClose}
        />
      )}
      {step === MEMBERSHIP_MODAL_STEPS.checkout && (
        <UnityPassCheckout
          membershipData={membershipData}
          bannerText={bannerText}
          specialOffer={specialOffer}
          onContinue={handleContinue}
        />
      )}
      {isSkipFeatureEnabled && step === MEMBERSHIP_MODAL_STEPS.pause && (
        <UnityPassPause
          membershipData={membershipData}
          bannerText={bannerText}
          onContinue={handleContinue}
          onClose={handleClose}
        />
      )}
      {isSkipFeatureEnabled && step === MEMBERSHIP_MODAL_STEPS.paused && (
        <UnityPassPaused onClose={handleClose} />
      )}
      {step === MEMBERSHIP_MODAL_STEPS.cancel && (
        <UnityPassCancel
          membershipData={membershipData}
          bannerText={bannerText}
          onContinue={handleContinue}
          onClose={handleClose}
        />
      )}
    </ResponsiveModalOrBottomSheet>
  ) : (
    <ResponsiveModalOrBottomSheet
      className="membership"
      onClose={handleClose}
      isOpen={isOpen}>
      {step === MEMBERSHIP_MODAL_STEPS.introduction && (
        <MembershipIntroduction
          membershipData={membershipData}
          onContinue={handleContinue}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.benefits && (
        <MembershipBenefits
          membershipData={membershipData}
          onContinue={handleContinue}
          onClose={handleClose}
          loading={loading}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.checkout && (
        <MembershipCheckout
          membershipData={membershipData}
          onContinue={handleContinue}
          refetch={refetch}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.confirmation && (
        <MembershipConfirmation onClose={handleClose} />
      )}

      {isSkipFeatureEnabled && step === MEMBERSHIP_MODAL_STEPS.pause && (
        <MembershipPause
          membershipData={membershipData}
          onContinue={handleContinue}
          onClose={handleClose}
          refetch={refetch}
        />
      )}

      {isSkipFeatureEnabled && step === MEMBERSHIP_MODAL_STEPS.paused && (
        <MembershipPaused
          membershipData={membershipData}
          onClose={handleClose}
          refetch={refetch}
          onContinue={handleContinue}
        />
      )}

      {step === MEMBERSHIP_MODAL_STEPS.cancel && (
        <MembershipCancel
          membershipData={membershipData}
          onContinue={handleContinue}
          onClose={handleClose}
          refetch={refetch}
        />
      )}
    </ResponsiveModalOrBottomSheet>
  )
}
