import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import howCanISkipDesktop from 'src/assets/img/modals/how-can-i-skip/how-can-i-skip-desktop.gif'
import howCanISkipMobile from 'src/assets/img/modals/how-can-i-skip/how-can-i-skip-mobile.gif'
import Modal from 'src/components/NewModal'
import { useIsMobile } from 'src/hooks/useIsMobile'
import tracking from 'src/shared/tracking'
import { howCanISkipModalAtom } from 'src/atoms/modals/howCanISkipModal.atom'

import './HowCanISkipModal.scss'

export function HowCanISkipModal() {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [{ isOpen }, setHowCanISkipModal] = useAtom(howCanISkipModalAtom)

  useEffect(() => {
    tracking.track('Skip Education', {
      action: 'Overlay Viewed',
      page_name: 'My Plan',
      overlay_name: 'Skip Education',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      overlay_trigger: 'manual',
      overlay_step: '1/1',
      overlay_options: '(CTA name)'
    })
  }, [])

  function handleClose() {
    tracking.track('Skip Education', {
      action: 'Overlay Closed',
      page_name: 'My Plan',
      overlay_name: 'Skip Education',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      overlay_trigger: 'manual',
      overlay_step: '1/1'
    })

    setHowCanISkipModal({ isOpen: false })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      mediumSize
      className="modal-how-can-i-skip">
      <div className="container">
        <div className="title">{t('pages.my_plan.how_can_i_skip.title')}</div>
        <div className="description">
          {t(
            `pages.my_plan.how_can_i_skip.description${
              isMobile ? '_mobile' : ''
            }`
          )}
        </div>
        <div className="gif-container">
          <div className="gif">
            <img
              alt="How can I skip an order?"
              src={isMobile ? howCanISkipMobile : howCanISkipDesktop}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
