import ReactPixel from 'react-facebook-pixel'

export const pixel = {
  init() {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID!)
  },
  trackPageView() {
    ReactPixel.pageView()
  },
  trackEvent({ name, data }: { name: string; data: any }) {
    ReactPixel.track(name, data)
  }
}
