import './ComparePlansModal.scss'

import { CuiButton } from 'front-lib'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const WEEKLY_PLAN_PRICE = '$13.59'

interface Props {
  handleConfirm: () => void
}

export const WeeklyPlan: FC<Props> = ({ handleConfirm }) => {
  const { t } = useTranslation()

  return (
    <div className="flex-mode__compare-modal__plan-container">
      <div className="flex-mode__compare-modal__plan-description-container">
        <h1 className="flex-mode__compare-modal__plan-title">
          {t('pages.my_plan.flex_mode.modal_compare.weekly_plan')}
        </h1>
        <h3 className="flex-mode__compare-modal__plan-subtitle">
          {t('pages.my_plan.flex_mode.modal_compare.save_on_meals_and_time')}
        </h3>
        <p className="flex-mode__compare-modal__plan-description">
          {t('pages.my_plan.flex_mode.modal_compare.receive_a_delivery_every')}
        </p>
      </div>
      <div className="flex-mode__compare-modal__plan-price-container">
        <span className="flex-mode__compare-modal__plan-price-prefix">
          {t('pages.my_plan.flex_mode.modal_compare.starting_at')}
        </span>
        <span className="flex-mode__compare-modal__plan-price">
          {WEEKLY_PLAN_PRICE}
          <span className="flex-mode__compare-modal__plan-price-suffix">
            / {t('pages.my_plan.flex_mode.modal_compare.meal')}
          </span>
        </span>
      </div>
      <div className="flex-mode__compare-modal__plan-button-container">
        <CuiButton onClick={handleConfirm}>
          {t('pages.my_plan.flex_mode.modal_compare.switch_to_weekly_plan')}
        </CuiButton>
      </div>
      <ul className="flex-mode__compare-modal__plan-feature-container">
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.recommendation_engine')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.15%_off_per_meal')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.receive_meals_every_week')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.option_to_skip')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.option_to_pause')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.exclusive_discounts')}
        </li>
      </ul>
    </div>
  )
}
