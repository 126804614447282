import { useEffect } from 'react'
import tracking from 'src/shared/tracking'

export const useComparePlansTourModalTrackingTracking = () => {
  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: 'Flex mode - Plan Onboarding',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'Switch to Weekly Plan',
      overlay_trigger: 'automatic',
      overlay_step: '1/2',
      overlay_options: 'Continue'
    })
  }, [])

  const handleContinueTracking = (): void => {
    tracking.track('Overlay Clicked', {
      overlay_name: 'Flex mode - Onboarding',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      overlay_trigger: 'automatic',
      overlay_options: 'Continue',
      overlay_action: 'Continue',
      page_name: 'Switch to Weekly Plan',
      overlay_step: '1/2'
    })
  }

  const handleCloseTracking = (): void => {
    tracking.track('Overlay Closed', {
      overlay_name: 'Flex mode - Plan Onboarding',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'Switch to Weekly Plan',
      overlay_trigger: 'automatic',
      overlay_step: '1/2'
    })
  }

  return { handleCloseTracking, handleContinueTracking }
}
