import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { CoachmarkTooltip } from 'src/componentsV2/Coachmark/CoachmarkTooltip'
import { CoachmarkContextValues, CoachmarkState } from 'src/types/coachmark'
import { useLocalStorage } from './useLocalStorage'

const CoachmarkContext = createContext<CoachmarkContextValues | null>(null)

const PADDING_Y = 6
const PADDING_X = 10

export const CoachmarkProvider = ({ children }: any) => {
  const [coachmark, setCoachmark] = useState<CoachmarkState | null>(null)
  const [shouldShow, setShouldShow] = useLocalStorage<boolean>(
    coachmark?.localStorageId ?? '',
    true
  )

  const dom = coachmark?.ref?.current?.getBoundingClientRect()

  useEffect(() => {
    if (shouldShow && coachmark?.ref?.current) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [shouldShow, coachmark?.ref])

  const removeCoachmark = () => {
    setShouldShow(false)
    setCoachmark(null)
    document.body.classList.remove('no-scroll')
  }

  const onClick = () => {
    removeCoachmark()
    coachmark?.onClick()
  }

  const computeStyles = useCallback(() => {
    if (!dom) {
      return {}
    }

    return {
      top: dom.top - PADDING_Y,
      left: dom.left - PADDING_X,
      height: dom.height + PADDING_Y * 2,
      width: dom.width + PADDING_X * 2
    }
  }, [dom])

  return (
    <CoachmarkContext.Provider value={{ coachmark, setCoachmark }}>
      {children}
      {coachmark?.ref?.current && shouldShow && (
        <>
          <div
            className="coachmark-overlay"
            style={computeStyles()}
            onClick={onClick}>
            <div onClick={e => e.stopPropagation()}>
              <CoachmarkTooltip
                coachmark={coachmark}
                onClose={removeCoachmark}
              />
            </div>
            <div className="coachmark-overlay-arrow"></div>
          </div>
          <div
            className="coachmark-overlay-helper"
            onClick={removeCoachmark}></div>
        </>
      )}
    </CoachmarkContext.Provider>
  )
}

export const useCoachmark = (coachmark: CoachmarkState) => {
  const ctx = useContext(CoachmarkContext)

  if (!ctx) {
    throw Error('Missing coachmark provider')
  }

  useEffect(() => {
    ctx?.setCoachmark(coachmark)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachmark.ref, window.innerWidth])

  return null
}
