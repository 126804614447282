import mobileAndTabletcheck from '../utils/mobileAndTabletcheck'

/**
 * Used in most apis to define if the request is coming from mobile or desktop.
 */
export function getOrigin() {
  const mobile = mobileAndTabletcheck()
  if (mobile) {
    return 'customer_web_mobile'
  }
  return 'customer_web_desktop'
}
