import { useMobileDevice } from 'src/hooks/useMobileDevice'
import tracking from 'src/shared/tracking'
import { toast } from 'react-toastify'

export const useShareProduct = () => {
  const isMobile = useMobileDevice()

  const BASE_URL = process.env.REACT_APP_SITE_BASE_URL
  const onShare = async (productId, productName) => {
    const url = `${BASE_URL}/meals/${productId}`
    try {
      if (isMobile) {
        await navigator.share({
          url,
          text: productName || 'Product detail',
          title: 'Cookunity'
        })
      } else {
        navigator.clipboard.writeText(url)
        toast('Copied on clipboard!')
      }

      tracking.track('Product Share Clicked', {
        product_id: productId,
        product_name: productName,
        label: 'Copy link'
      })
    } catch (error) {
      console.error(error)
    }
  }

  return {
    onShare
  }
}
