import { createAction } from 'redux-actions'

export const fetchUserChallengesStart = createAction(
  'FETCH_USER_CHALLENGES_STARTED'
)
export const fetchUserChallengesSuccess = createAction(
  'FETCH_USER_CHALLENGES_SUCCEDED'
)
export const fetchUserChallengesFail = createAction(
  'FETCH_USER_CHALLENGES_FAILED'
)

export const fetchChallengeWithStepsStart = createAction(
  'FETCH_CHALLENGE_WITH_STEPS_STARTED'
)
export const fetchChallengeWithStepsSuccess = createAction(
  'FETCH_CHALLENGE_WITH_STEPS_SUCCEDED'
)
export const fetchChallengeWithStepsFail = createAction(
  'FETCH_CHALLENGE_WITH_STEPS_FAILED'
)

export const openChallengePanel: () => any = createAction(
  'OPEN_CHALLENGE_PANEL'
)
export const closeChallengePanel: () => any = createAction(
  'CLOSE_CHALLENGE_PANEL'
)
export const claimRewardStart = createAction('CLAIM_REWARD_START')
export const claimRewardSuccess = createAction('CLAIM_REWARD_SUCEEDED')
export const claimRewardFail = createAction('CLAIM_REWARD_FAILED')
