import { useState } from 'react'

const SafeImage = ({ imageSrc, fallbackSrc, alt, ...props }) => {
  const [src, setSrc] = useState(imageSrc || fallbackSrc)
  const [errored, setErrored] = useState(false)

  const onError = () => {
    if (!errored) {
      setSrc(fallbackSrc)
      setErrored(true)
    }
  }
  return <img src={src} onError={onError} alt={alt} {...props} />
}

export { SafeImage }
