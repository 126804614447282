import * as R from 'ramda'

import { ADDONS_CATEGORIES } from 'src/modules/MyPlan/utils'
import { activeDeliveryDate, getUpcomingDays } from '../selectors'
import { createSelector } from 'reselect'
import { getMealsCount } from './utils'
import { getMenu } from '../menu/selectors'
import { buildImgUrl, mealsAreEquals } from 'src/utils/utils'
import {
  getUseUnifiedCart,
  productsWithStockInCartSelector
} from '../cart/selectors'
import { getUserPlanSelector } from 'src/redux-api-bridge/selectors'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const CHEF_IMG_URL_SOURCE = process.env.REACT_APP_CHEF_IMG_URL_SOURCE

const MEAT_TYPES = [
  'Beef',
  'Chicken',
  'Other fish',
  'Other meat',
  'Pork',
  'Salmon',
  'Seafood',
  'Vegan',
  'Vegetarian'
]

const getCategory = meal => {
  const meatTag =
    meal.specifications_detail &&
    meal.specifications_detail
      .filter(tag => tag && tag.label.trim())
      .find(tag => MEAT_TYPES.indexOf(tag) !== -1)
  const meatLabel =
    meal.meat_type || (typeof meatTag === 'undefined' ? meatTag : null)

  const meats = ['Duck', 'Lamb', 'Other meat', 'Beef', 'Pork']
  const seafood = [
    'Catfish',
    'Cod',
    'Crab',
    'Other fish',
    'Salmon',
    'Scallop',
    'Seafood',
    'Shrimp',
    'Snapper',
    'Sole',
    'Tilapia',
    'Trout',
    'Tuna',
    'fish '
  ]
  const poultry = ['Chicken', 'Turkey']
  const vegan = ['Vegan']
  const vegetarian = ['Vegetarian']

  if (meats.includes(meatLabel)) {
    return 'meat'
  } else if (seafood.includes(meatLabel)) {
    return 'seafood'
  } else if (poultry.includes(meatLabel)) {
    return 'poultry'
  } else if (vegan.includes(meatLabel)) {
    return 'vegan'
  } else if (vegetarian.includes(meatLabel)) {
    return 'vegetarian'
  }

  return null
}

export const formatMealData = meal => {
  let imageUrl =
    meal.image?.full_path || (meal.image || '').replace('no_selection', '')
  if (imageUrl)
    imageUrl = buildImgUrl(IMG_URL_SOURCE, meal.image_path, imageUrl)
  return {
    ...meal,
    category: getCategory(meal),
    ...(!meal.hasOwnProperty('menu_category_id') && {
      menu_category_id: meal.category && meal.category.id
    }),
    ...(!meal.hasOwnProperty('menu_category_label') && {
      menu_category_label: meal.category && meal.category.label
    }),
    title: meal.name,
    subtitle: meal.subtitle || meal.short_description,
    imageUrl,
    magento_id: +meal.entity_id,
    reservedStock: +meal.qty,
    chefName: [
      ...[meal.chef_firstname ? meal.chef_firstname.trim() : ''],
      ...[meal.chef_lastname ? meal.chef_lastname.trim() : '']
    ]
      .join(' ')
      .trim(),
    chef: {
      id: meal.chef_id,
      firstname: meal.chef_firstname,
      lastname: meal.chef_lastname,
      image: CHEF_IMG_URL_SOURCE + meal.logopic
    },
    tags:
      meal.specifications_detail &&
      meal.specifications_detail.filter(tag => tag && tag.label.trim())
  }
}

const sortOrder = (m1, m2) => {
  // If its a m1 is meal is always 1
  if (-1 === ADDONS_CATEGORIES.indexOf(m1.category_id)) {
    if (-1 === ADDONS_CATEGORIES.indexOf(m2.category_id)) {
      return 0
    }
    return -1
  } else {
    if (1 === ADDONS_CATEGORIES.indexOf(m2.category_id)) {
      return 0
    }
    return 1
  }
}

// Used in menu page to get the active shipping date in the url
// similar to what src/redux/slices/selectedDelivery/selectors.ts does in orders page
export const getActiveOrderDate = state => state.orders.active

export const getActiveOrder = state =>
  state.orders.data[getActiveOrderDate(state)] || null

export const isActiveOrderConfirmed = state => {
  const activeOrder = getActiveOrder(state)
  return (
    activeOrder &&
    Array.isArray(activeOrder.confirmedItems) &&
    activeOrder.confirmedItems.length
  )
}

export const isExtraDeliveryOrder = state => {
  const activeOrder = getActiveOrderDate(state)
  const upcomingDays = getUpcomingDays(state)
  const currentUpcomingDay = upcomingDays.find(
    upcomingDate => upcomingDate.date === activeOrder
  )
  return !currentUpcomingDay.scheduled
}

const getOrderItemsByKey = key =>
  createSelector(
    getActiveOrder,
    order => (order && order[key]?.map(formatMealData).sort(sortOrder)) || []
  )

export const getCurrentOrder = getOrderItemsByKey('items')
export const getConfirmedOrder = getOrderItemsByKey('confirmedItems')

export const getFirstSyncReady = createSelector(
  getActiveOrder,
  activeOrder => activeOrder?.firstSyncReady || false
)

export const getOrderWithStock = createSelector(
  getCurrentOrder,
  getConfirmedOrder,
  getMenu,
  getUseUnifiedCart,
  getFirstSyncReady,
  (currentOrder, confirmedOrder, menu, useUnifiedCart, firstSyncReady) => {
    // This is to avoid showing in cart items from orders, and then from unifiedCart.
    // Once we are 100% ON with unified cart, we should remove it.
    if (useUnifiedCart && !firstSyncReady) return []

    const allItems = [...(menu?.meals || []), ...(menu?.bundles || [])]
    const order = currentOrder.filter(item => {
      const isItemConfirmed = confirmedOrder.find(itemConfirmed =>
        mealsAreEquals(itemConfirmed, item)
      )
      return (
        isItemConfirmed ||
        allItems.find(meal => (mealsAreEquals(item, meal) ? meal : null))
          ?.stock > 0
      )
    })

    return order.map(item => ({
      ...item,
      ...allItems.find(meal => (mealsAreEquals(item, meal) ? meal : null)),
      qty: item.qty
    }))
  }
)

export const getOrderOutOfStock = createSelector(
  getCurrentOrder,
  getConfirmedOrder,
  getMenu,
  (currentOrder, confirmedOrder) => {
    return currentOrder.filter(item => {
      const isItemConfirmed = confirmedOrder.find(
        itemConfirmed => itemConfirmed.entity_id === item.entity_id
      )
      return !isItemConfirmed && item.stock <= 0
    })
  }
)

export const getOrderMealsCountWithStock = createSelector(
  getOrderWithStock,
  items => getMealsCount(items)
)

export const getCarts = state => state.orders.data

export const shouldSaveToCart = createSelector(
  isActiveOrderConfirmed,
  activeDeliveryDate,
  isExtraDeliveryOrder,
  (isActiveOrderConfirmed, activeDeliveryDate, isExtraDeliveryOrder) => {
    if (
      isActiveOrderConfirmed ||
      activeDeliveryDate.skip ||
      isExtraDeliveryOrder
    )
      return false
    return true
  }
)

export const getQtyPlanMeals = state => {
  return R.path(['orderDetail', 'qtyPlanMeals'], getActiveOrder(state)) || 0
}
export const getTotalPlanMeals = state => {
  const subtotal = R.path(
    ['orderDetail', 'totalPlanPrice'],
    getActiveOrder(state)
  )
  return subtotal ? subtotal.toFixed(2) : 0.0
}
export const getTotalTaxes = state => {
  const taxes = R.path(['orderDetail', 'totalTaxes'], getActiveOrder(state))
  return taxes ? taxes.toFixed(2) : 0.0
}
export const getTotalDeliveryFee = state => {
  const deliveryFee = R.path(
    ['orderDetail', 'totalDeliveryFee'],
    getActiveOrder(state)
  )
  return deliveryFee ? deliveryFee.toFixed(2) : 0.0
}
export const getTotalExtraMeals = state => {
  const extra = R.path(
    ['orderDetail', 'totalExtraMeals'],
    getActiveOrder(state)
  )
  return extra ? extra.toFixed(2) : 0.0
}
export const getGifts = state =>
  R.path(['orderDetail', 'gifts'], getActiveOrder(state))

export const getTotalDiscount = state => {
  const promo = R.path(
    ['orderDetail', 'totalPromoDiscount'],
    getActiveOrder(state)
  )
  return promo ? promo.toFixed(2) : 0.0
}
export const getMembershipDiscount = state => {
  const discount = R.path(
    ['orderDetail', 'membershipDiscount'],
    getActiveOrder(state)
  )
  return discount || null
}
export const getTotalOrder = state => {
  const total = R.path(['orderDetail', 'totalOrder'], getActiveOrder(state))
  return total ? total.toFixed(2) : 0.0
}

export const getSubTotalOrder = state => {
  const total = R.path(['orderDetail', 'subTotalOrder'], getActiveOrder(state))
  return total ? total.toFixed(2) : 0.0
}

export const getAvailableCreditToApply = state => {
  const availableCredits = R.path(
    ['orderDetail', 'availableCredits'],
    getActiveOrder(state)
  )
  return availableCredits ? availableCredits.toFixed(2) : 0.0
}

export const getTotalOrderWithCreditsSubtracted = state => {
  const totalOrderWithCreditsSubtracted = R.path(
    ['orderDetail', 'totalOrderWithCreditsSubtracted'],
    getActiveOrder(state)
  )
  return totalOrderWithCreditsSubtracted
    ? totalOrderWithCreditsSubtracted.toFixed(2)
    : 0.0
}

export const getOrderOrCartMealsCount = createSelector(
  [
    getMenu,
    getOrderMealsCountWithStock,
    selectedDeliverySelector,
    state => state
  ],
  (menu, orderMealsCount, selectedDelivery, state) => {
    if (menu.length > 0 || orderMealsCount > 0) {
      return orderMealsCount
    } else {
      const productsWithStockInCart = productsWithStockInCartSelector(
        selectedDelivery?.date
      )(state)
      return getMealsCount(productsWithStockInCart)
    }
  }
)

export const getNoExtraMealPlanIsCompleted = createSelector(
  [getUserPlanSelector, getOrderOrCartMealsCount],
  (userPlan, mealsCount) => {
    const allowExtraMeals = userPlan?.segment.allowExtraMeals ?? true
    const mealsPerDelivery = userPlan?.mealsPerDelivery ?? 1
    if (!allowExtraMeals) {
      return mealsCount >= mealsPerDelivery
    }
    return false
  }
)
