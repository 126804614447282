import React from 'react'
import classnames from 'classnames'
import { pathOr } from 'ramda'

import './SettingsCard.scss'

const SettingsCard = props => {
  const {
    id,
    className,
    style,
    title,
    subtitle,
    hideTitle = false,
    hideSubtitle = false,
    children,
    isLoading,
    withAction,
    actionLabel = 'Edit',
    onActionClick,
    testId = null
  } = props

  const _children =
    React.Children.count(children) > 1 ? [...children] : children

  const showTitle = !hideTitle
  const showSubtitle = !hideSubtitle

  return (
    <div
      id={id}
      data-test-id={testId}
      className={classnames('cookunity__settings_card', className, {
        loading: isLoading
      })}
      style={style}>
      {title && showTitle && (
        <h2 className="title">
          {title}{' '}
          {!isLoading && withAction && (
            <span className="action_label" onClick={onActionClick}>
              {actionLabel}
            </span>
          )}{' '}
        </h2>
      )}
      {subtitle && showSubtitle && <p className="subtitle">{subtitle}</p>}
      {React.Children.map(_children, child => {
        if (child && pathOr('', ['type', 'name'], child) === 'SettingSection') {
          return React.cloneElement(child, { isLoading: isLoading })
        }
        return child
      })}
    </div>
  )
}

export default SettingsCard
