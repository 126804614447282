import { handleActions } from 'redux-actions'

import { debugModeOn, debugModeOff } from './actions'

const initialState = {
  debug: false
}
export default handleActions(
  {
    [debugModeOn]: state => {
      return {
        ...state,
        debug: true
      }
    },
    [debugModeOff]: state => {
      return {
        ...state,
        debug: false
      }
    }
  },
  initialState
)
