import * as actions from './actions'
import {
  addProduct,
  addProductsToUnifiedCart,
  getCartForDate,
  getUnifiedCartForDate,
  removeAllProducts,
  removeProduct,
  removeProductFromUnifiedCart
} from '../../api/sduiService'
import { deserializeUnifiedCart, mapProductProps } from './utils'
import * as orderActions from '../order/actions'
import { cartByDateSelector } from './selectors'

export const fetchCartForDate = (date, syncOrderCart = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  dispatch(actions.fetchCartForDate({ date }))
  getCartForDate(date)
    .then(res => res.json())
    .then(({ data }) => {
      const cartProducts = data?.products?.map(mapProductProps)
      if (syncOrderCart) {
        const existingCart = cartByDateSelector(date)(state)

        const modifiedProducts = []

        cartProducts.forEach(newProduct => {
          const existingProduct = existingCart.find(
            p => p.inventoryId === newProduct.inventoryId
          )

          if (existingProduct) {
            const quantityDifference =
              newProduct.quantity - existingProduct.quantity
            if (quantityDifference !== 0) {
              modifiedProducts.push({
                product: newProduct,
                qty: quantityDifference
              })
            }
          } else {
            modifiedProducts.push({
              product: newProduct,
              qty: newProduct.quantity
            })
          }
        })

        existingCart.forEach(existingProduct => {
          if (
            !cartProducts.some(
              newProduct =>
                newProduct.inventoryId === existingProduct.inventoryId
            )
          ) {
            modifiedProducts.push({
              product: existingProduct,
              quantityChange: -existingProduct.quantity
            })
          }
        })

        modifiedProducts.forEach(item => {
          if (item.qty > 0) {
            dispatch(
              orderActions.addProduct({
                product: item.product,
                quantity: item.qty
              })
            )
          } else {
            dispatch(
              orderActions.removeProduct({
                product: item.product,
                quantity: item.qty
              })
            )
          }
        })
      }

      dispatch(
        actions.fetchCartForDateSucceed({
          date,
          cart: cartProducts,
          gifts: data?.gifts || []
        })
      )
    })
    .catch(() => {
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductToCart = (date, product, quantity) => dispatch => {
  dispatch(actions.addProductToCart())
  addProduct(date, product.inventoryId, quantity, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.addProductToCartSucceed({
          date,
          product: { ...product, ...productInCart }
        })
      )
      // to sync menu cart
      dispatch(orderActions.addProduct({ product, quantity }))
    })
    .catch(() => {
      dispatch(actions.addProductToCartFailed())
    })
}

export const removeProductFromCart = (date, product) => dispatch => {
  dispatch(actions.removeProductFromCart())
  removeProduct(date, product.inventoryId, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.removeProductFromCartSucceed({
          date,
          product: productInCart,
          inventoryId: product.inventoryId
        })
      )
      // to sync menu cart
      dispatch(orderActions.removeProduct({ product, quantity: 1 }))
    })
    .catch(() => {
      dispatch(actions.removeProductFromCartFailed())
    })
}

export const clearCart = date => dispatch => {
  dispatch(actions.clearCart())
  removeAllProducts(date)
    .then(() => {
      dispatch(
        actions.clearCartSucceed({
          date
        })
      )
      // to sync menu cart
      dispatch(orderActions.clearOrder())
    })
    .catch(() => {
      dispatch(actions.clearCartFailed())
    })
}

export const fetchUnifiedCartForDate = date => dispatch => {
  dispatch(actions.fetchUnifiedCartForDate({ date }))
  getUnifiedCartForDate(date)
    .then(res => res.json())
    .then(data => {
      const unifiedCart = deserializeUnifiedCart(data)
      dispatch(
        actions.fetchUnifiedCartForDateSucceed({
          date,
          unifiedCart: unifiedCart
        })
      )
    })
    .catch(error => {
      console.log('error', error)
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductsToUnifiedCartForCartId = async (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.addProductToUnifiedCart())
  return addProductsToUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.addProductToUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.addProductToUnifiedCartFailed())
    })
}

export const removeProductsFromUnifiedCartForCartId = (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.removeProductFromUnifiedCart())
  return removeProductFromUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => {
      if (res.ok) return res
      throw new Error('something went wrong trying to delete product.')
    })
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.removeProductFromUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.removeProductFromUnifiedCartFailed())
      throw new Error('something went wrong trying to delete product.')
    })
}
