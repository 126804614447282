import actionCreator from '../../utils/actionCreator'

export const PFX = 'USER'

export const createCard = actionCreator(PFX, 'create_cards')
export const setDefaultPayment = actionCreator(PFX, 'set_default_payment')

export const getPaymentMethods = actionCreator(PFX, 'get_payment_methods')
export const redeemGiftCard = actionCreator(PFX, 'redeem_gift_card')
export const removePaymentMethod = actionCreator(PFX, 'remove_payment_method')
