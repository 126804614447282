export enum ModalType {
  AddAndSave = 'add-and-save',
  ChangeDelivery = 'change-delivery',
  CoachMarkCalendarView = 'coach-mark-calendar-view',
  EarlyUserOnboardingCoachmark = 'early-user-onboarding-coachmark',
  EconomicPlanExpired = 'economic-plan-expired',
  EconomicPlanForeverModal = 'economic-plan-forever-modal',
  MonthlyPlanModal = 'monthly-plan-modal',
  MonthlyPrepayOnboardingModal = 'monthly-prepay-onboarding-modal',
  PromotionAwareness = 'promotion-awareness',
  RateFirstDelivery = 'rate-first-delivery',
  SkipAutopilotOnSecondWeek = 'skip-autopilot-on-second-week',
  UpgradePlanWithPromotionModal = 'upgrade-plan-with-promotion-modal',
  YouAreOnFlexMode = 'you-are-on-flex-mode',
  ContestModal = 'contest-modal',
  SwitchToStandardPlanWithPromotionModal = 'switch-from-value-to-standard-plan-with-promotion-modal',
  AnnouncementModal = 'announcement-modal',
  MembershipModal = 'membership-modal',
  BoxSizePlanChangeModal = 'box-size-plan-change-modal',
  BoxSizePlanOfferModal = 'box-size-plan-offer-modal',
  NewResurrectionFlowModal = 'new-resurrection-flow-modal'
}

interface ModalTemplate {
  id: number
  key: string
  title?: string
  description?: string
  imageUrl?: string
  disclaimer?: string
  availableFrom?: Date
  availableTo?: Date
  ctaButtonLabel?: string
  ctaButtonUrl?: string
}
export interface ContestModalTemplate extends ModalTemplate {
  storeId?: number
  alertMessage?: string
  instagramSlug?: string
  instagramUrl?: string
  tiktokSlug?: string
  tiktokUrl?: string
  youtubeSlug?: string
  youtubeUrl?: string
}

export interface AnnouncementModalTemplate extends ModalTemplate {}

export interface NextModal {
  type: ModalType
  payload?: {
    promotionId?: number | string
    challengeId?: number | string
    contestModalTemplate?: ContestModalTemplate
    announcementModalTemplate?: AnnouncementModalTemplate
  }
}
