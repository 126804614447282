import React from 'react'
import classnames from 'classnames'

const ButtonRow = ({ children, className }) => (
  <div className={classnames('cookunity__button_row', className)}>
    {children}
  </div>
)

export default ButtonRow
