import { toast } from 'react-toastify'

import moment from 'moment'
import { path } from 'ramda'

import * as actions from './actions'
import * as filtersActions from './filters/actions'
import * as helpers from './helpers'
import * as menuActions from './menu/actions'
import { getActiveOrderDate } from './order/selectors'
import * as selectors from './selectors'
import * as api from '../api'
import * as cartClient from '../api/cart'
import { getClientSurvey, saveSurvey } from '../api/Survey'
import { trackEventWishes } from '../components/AddWishes/utils'
import tracking from '../shared/tracking'
import { trackEventReview } from '../utils/rating'
import store from 'src/store'
import { toaster } from 'src/utils/toaster'
import { getOrdersSwimlanes } from 'src/redux-api-bridge/orders-swimlanes/effects'

export const setValuesOnboarding = values => dispatch => {
  dispatch(actions.setValuesOnboarding.succeed(values))
}

export const fetchMyPlan = dispatch => {
  dispatch(actions.getUpcomingDays.start())
  return api
    .getUpcoming()
    .then(({ data }) => {
      dispatch(actions.getUpcomingDays.succeed(data))
      return data
    })
    .catch(err => catchError(err, actions.getUpcomingDays))
}

export const catchError = (err, action, id) => {
  if (window.Raven) {
    window.Raven.captureException(err)
  } else {
    console.log(err) // eslint-disable-line no-console
  }
  // if (process.env.REACT_APP_LOG_LEVEL === 'debug') console.log(err)
  return action.failed({ err, id })
}

export const getUserData = () => dispatch => {
  dispatch(actions.getUserData.start())
  return api
    .getUser()
    .then(info =>
      dispatch(actions.getUserData.succeed({ info: info.data.user }))
    )
    .catch(err => dispatch(catchError(err, actions.getUserData)))
}

export const getUserSettings = dispatch => {
  dispatch(actions.getUserSettings.start())
  return api
    .getUserSettings()
    .then(({ data: { settings } }) =>
      dispatch(actions.getUserSettings.succeed({ settings }))
    )
    .catch(err => {
      dispatch(catchError(err, actions.getUserSettings))
    })
}

export const getSubscriptionData = dispatch => {
  dispatch(actions.getSubscriptionData.start())

  return api
    .getSubscription()
    .then(
      ({
        data: {
          users,
          plans: availablePlans,
          lastOrderForReview: lastOrder,
          settings,
          stores
        }
      }) => {
        const info = users[0]
        const userPlan = availablePlans.find(plan => plan.id === info.plan_id)

        tracking.identify(info.magento_id, { settings })

        dispatch(
          actions.getSubscriptionData.succeed({
            info,
            userPlan,
            lastOrder,
            settings
          })
        )
        dispatch(
          actions.getStores.succeed({
            stores
          })
        )
      }
    )
    .catch(err => dispatch(catchError(err, actions.getSubscriptionData)))
}

export const getProfilesData = dispatch => {
  dispatch(actions.getProfilesData.start())

  return api
    .getProfiles()
    .then(({ data: { users } }) => {
      const profiles = users[0].profiles
      const name = users[0].name

      return dispatch(
        actions.getProfilesData.succeed({
          name,
          profiles
        })
      )
    })
    .catch(err => {
      dispatch(catchError(err, actions.getProfilesData))
    })
}

const shouldFetchPreferencesData = () => {
  const state = store.getState()
  return !state.api.getPreferencesDataStatus.succeeded
}

export const getPreferencesData = () => dispatch => {
  if (!shouldFetchPreferencesData()) return
  dispatch(actions.getPreferencesData.start())
  dispatch(filtersActions.getFilterTypesStart())
  return api
    .getPreferencesData()
    .then(
      ({
        data: {
          meatTypes,
          diets,
          dietaryPreferences,
          tastes,
          restrictions,
          goals,
          cuisines
        }
      }) => {
        dispatch(
          filtersActions.getFilterTypesSuccess({
            diets,
            preferences: dietaryPreferences,
            proteins: meatTypes,
            cuisines
          })
        )

        dispatch(
          actions.getPreferencesData.succeed({
            meatTypes,
            diets,
            dietaryPreferences,
            tastes,
            restrictions,
            goals
          })
        )
      }
    )
    .catch(() => {
      dispatch(filtersActions.getFilterTypesFail())
      dispatch(actions.getPreferencesData.failed())
    })
}

export const cleanProfileStatus = () => dispatch => {
  dispatch(actions.getProfilesData.clean())
}

export const initApiStore = () => dispatch => {
  getSubscriptionData(dispatch).then(() => {
    const parallelize = [fetchMyPlan(dispatch)]

    Promise.all(parallelize)
      .then(() => dispatch(getTimeSlots()))
      .then(() => dispatch(actions.initApp.succeed()))
      .catch(err => dispatch(catchError(err, actions.initApp)))
  })
}

export const getUserPastOrders = ({ from, to }) => dispatch => {
  dispatch(actions.getUserPastOrders.start())
  return api
    .getPastOrders({ from, to })
    .then(pastOrders => {
      dispatch(actions.getUserPastOrders.succeed(pastOrders))
      return true
    })
    .catch(err => {
      dispatch(catchError(err, actions.getUserPastOrders))
      return false
    })
}

export const getTimeSlots = zipCodeParam => (dispatch, getState) => {
  const zipcode = zipCodeParam || selectors.getDefaultAddressZipcode(getState())
  api
    .getTimeSlots({ zipcode })
    .then(timeslots => {
      const filtered = timeslots.filter(
        timeslot => !timeslot.isLunch && timeslot.label.indexOf('ASAP') === -1
      )
      dispatch(actions.getTimeSlots.succeed({ timeslots: filtered }))
    })
    .catch(err => dispatch(catchError(err, actions.getTimeSlots)))
}

export const updateOrder = order => (dispatch, getState) => {
  dispatch(actions.updateOrder.start())

  return api
    .updateOrder(order)
    .then(response => {
      if (response && response.data && response.data.updateOrder) {
        let error = path(['data', 'updateOrder', 'error'], response)

        if (error) {
          dispatch(catchError(error, actions.updateOrder))
          return { updateOrderSuccess: false }
        }

        return fetchMyPlan(dispatch, getState).then(() => {
          return getUserSettings(dispatch).then(() => {
            dispatch(actions.updateOrder.succeed())
            return { updateOrderSuccess: true }
          })
        })
      }
    })
    .catch(err => dispatch(catchError(err, actions.updateOrder)))
    .then(updateOrderEvent => {
      return updateOrderEvent
    })
}

export const createOrder = order => (dispatch, getState) => {
  dispatch(actions.updateOrder.start())
  return api
    .createOrder(order)
    .then(response => {
      if (
        response &&
        response.data &&
        response.data.createOrder &&
        response.data.createOrder.id &&
        !isNaN(response.data.createOrder.id)
      ) {
        return getSubscriptionData(dispatch).then(() => {
          return fetchMyPlan(dispatch, getState).then(() => {
            return getUserSettings(dispatch).then(() => {
              dispatch(actions.updateOrder.succeed())
              return {
                createOrderSuccess: true,
                createOrderId: response.data.createOrder.id,
                comment: order.comment
              }
            })
          })
        })
      }

      let error = path(['data', 'createOrder', 'error'], response)

      dispatch(catchError(error, actions.updateOrder))
      return { createOrderSuccess: false, createOrderId: null, comment: null }
    })
    .catch(err => dispatch(catchError(err, actions.updateOrder)))
    .then(createOrderEvent => {
      return createOrderEvent
    })
}

export const getChefDetail = chef_id => dispatch => {
  // TODO: fetch currentDate from redux to handle the case of GNY
  dispatch(actions.getChefDetail.start({ chef_id }))

  Promise.all([
    api.getChefById({ chef_id }),
    api.getReviewsByChefId({ chef_id })
  ])
    .then(([responseChef, responseReviews]) => {
      dispatch(
        actions.getChefDetail.succeed({
          chef: {
            ...responseChef.data.chef,
            reviews: responseReviews.data.chefReviews.reviews,
            reviewsCount: responseReviews.data.chefReviews.reviews_count
          },
          menu: []
        })
      )
    })
    .catch(err => dispatch(actions.getChefDetail.failed({ err })))
}

export const getMenuByFilters = filter => dispatch => {
  // TODO: fetch currentDate from redux to handle the case of GNY
  dispatch(actions.getMenuByFilters.start())

  api
    .getMenuByFilters(filter)
    .then(menu =>
      dispatch(actions.getMenuByFilters.succeed({ menu: menu.meals }))
    )
    .catch(err => dispatch(actions.getMenuByFilters.failed({ err })))
}

export const rescheduleOrder = payload => (dispatch, getState) => {
  dispatch(actions.rescheduleOrder.start())

  return api
    .rescheduleOrder(payload)
    .then(() => fetchMyPlan(dispatch, getState))
    .then(() => {
      dispatch(
        actions.rescheduleOrder.succeed({
          currentDate: payload.currentDeliveryDate
        })
      )
      return true
    })
    .catch(err => {
      dispatch(catchError(err, actions.rescheduleOrder))
      return false
    })
}

export const unskipOrder = payload => (dispatch, getState) => {
  const { reloadMyPlan = true, ...rest } = payload
  dispatch(actions.unskipOrder.start(rest))
  return api
    .unskip(rest)
    .then(() => {
      if (reloadMyPlan) {
        fetchMyPlan(dispatch, getState)
      }
    })
    .then(() => dispatch(actions.unskipOrder.succeed()))
    .catch(err => dispatch(catchError(err, actions.unskipOrder)))
}

export const getAddresses = dispatch => {
  dispatch(actions.getAddresses.start())

  return api
    .getUserAddresses()
    .then(({ data: { users } }) =>
      dispatch(
        actions.getAddresses.succeed({
          ring_id: users[0].ring_id,
          addresses: users[0].addresses
        })
      )
    )
    .catch(err => dispatch(catchError(err, actions.getAddresses)))
}

export const getAvailableDeliveryDaysByZipcode = zipcode => dispatch => {
  dispatch(actions.getAvailableDeliveryDays.start())
  return api
    .getAvailableDeliveryDaysByZipcode({ zipcode })
    .then(({ data: { deliveryDays } }) =>
      dispatch(
        actions.getAvailableDeliveryDays.succeed({
          availableDeliveryDays: helpers.formatDeliveryDays(
            deliveryDays.deliveryDays
          ),
          zipcode: zipcode
        })
      )
    )
    .catch(err => dispatch(catchError(err, actions.getAvailableDeliveryDays)))
}

export const updateAddress = address => (dispatch, getState) => {
  dispatch(actions.updateAddress.start())
  return api
    .updateUserAddress(address)
    .then(() => {
      dispatch(actions.updateAddress.succeed())
      getAddresses(dispatch, getState)
    })
    .then(() => getAddresses(dispatch, getState))
    .catch(err => dispatch(catchError(err, actions.updateAddress)))
}

export const cleanUpdateAddressStatus = () => dispatch => {
  dispatch(actions.updateAddress.clean())
}

export const cleanUpdateSubscriptionDaysStatus = () => dispatch => {
  dispatch(actions.updateSubscriptionDays.clean())
}

// Soon to be deprecated - use useSetDefaultAddress hook instead
export const setDefaultAddress = payload => (dispatch, getState) => {
  dispatch(actions.setDefaultAddress.start())
  return api
    .setDefaultAddress(payload)
    .then(() => {
      dispatch(actions.setDefaultAddress.succeed())
      getAddresses(dispatch, getState)
    })
    .catch(err => dispatch(actions.setDefaultAddress.failed({ err })))
}

// Soon to be deprecated - use useDeleteUserAddress hook instead.
export const deleteAddress = address => (dispatch, getState) => {
  dispatch(actions.deleteAddress.start())
  api
    .deleteUserAddress(address.toString())
    .then(address => dispatch(actions.deleteAddress.succeed({ address })))
    .then(() => getAddresses(dispatch, getState))
    .catch(err => dispatch(catchError(err, actions.deleteAddress)))
}

export const updateUserInfo = data => (dispatch, getState) => {
  dispatch(actions.updateUserInfo.start())
  api
    .updateUserInfo(data)
    .then(() =>
      getUserData()(dispatch, getState).then(info =>
        dispatch(actions.updateUserInfo.succeed({ info }))
      )
    )
    .then(() => getProfilesData(dispatch, getState))
    .catch(err => dispatch(catchError(err, actions.updateUserInfo)))
}

export const cleanUserInfoState = () => dispatch => {
  dispatch(actions.updateUserInfo.clean())
}

export const getInvoices = date => dispatch => {
  dispatch(actions.getInvoices.start())
  api
    .getInvoices(date)
    .then(invoices => dispatch(actions.getInvoices.succeed({ invoices })))
    .catch(err => dispatch(catchError(err, actions.getInvoices)))
}

export const getLastInvoices = () => dispatch => {
  dispatch(actions.getInvoices.start())
  api
    .getLastInvoices()
    .then(invoices => {
      const filterInvoices = helpers.filterByDateInvoices(invoices)
      dispatch(actions.getInvoices.succeed({ invoices: filterInvoices }))
    })
    .catch(err => dispatch(catchError(err, actions.getInvoices)))
}

export const updateSubscriptionDays = subscriptionDays => (
  dispatch,
  getState
) => {
  dispatch(actions.updateSubscriptionDays.start())
  api
    .updateDeliveryOptions(subscriptionDays.deliveryOptions)
    .then(() => api.updateSubscriptionDays(subscriptionDays))
    .then(({ data: { changeDeliveryDays } }) =>
      Promise.all([
        dispatch(getOrdersSwimlanes()),
        fetchMyPlan(dispatch, getState)
      ]).then(() => {
        dispatch(
          actions.updateSubscriptionDays.succeed({
            subscription: changeDeliveryDays
          })
        )
      })
    )
    .catch(err => dispatch(catchError(err, actions.updateSubscriptionDays)))
}

export const setDeliveryInfoToCheck = deliveryInfo => dispatch => {
  dispatch(
    actions.setDeliveryInfoToCheck.succeed({
      deliveryInfo
    })
  )
}

export const changeSubscriptionPreferences = payload => (
  dispatch,
  getState
) => {
  const state = getState()
  const orderDate = getActiveOrderDate(state)
  dispatch(actions.changeSubscriptionPreferences.start())
  return api
    .changeSubscriptionPreferences(payload)
    .then(({ data: { changeUserProfile } }) => {
      dispatch(
        actions.changeSubscriptionPreferences.succeed({
          payload: changeUserProfile
        })
      )
      return true
    })
    .then(() => getProfilesData(dispatch, getState))
    .then(() => dispatch(menuActions.resetUserMenu()))
    .then(() => (!!orderDate ? cartClient.emptyCart(orderDate) : true))
    .catch(err => {
      dispatch(actions.changeSubscriptionPreferences.failed(err))
      return false
    })
}

export const removeProfile = profileId => dispatch => {
  dispatch(actions.removeProfile.start({ profileId }))
  api
    .removeProfile(profileId)
    .then(() => {
      dispatch(actions.removeProfile.succeed())
    })
    .catch(err => {
      dispatch(actions.removeProfile.failed(err))
    })
}

export const setMealReviewForOrder = (product, orderId, review) => dispatch => {
  dispatch(actions.setMealReviewForOrder.start())
  api
    .setMealReviewForOrder(product.id, orderId, review)
    .then(({ data: { createUserReview } }) => {
      dispatch(actions.setMealReviewForOrder.succeed({ review }))
      trackEventReview(product, review, createUserReview.result)
    })
    .catch(err => dispatch(catchError(err, actions.setMealReviewForOrder)))
}

export const editUserReview = (product, review) => dispatch => {
  dispatch(actions.editUserReview.start())
  api
    .editUserReview(review)
    .then(() => {
      dispatch(actions.editUserReview.succeed(review))
      trackEventReview(product, review, review.id)
    })
    .catch(err => dispatch(catchError(err, actions.editUserReview)))
}

export const setOrderShownForReview = id => dispatch => {
  api.setOrderShownForReview(id)
  dispatch(
    actions.getSubscriptionData.succeed({
      lastOrder: null
    })
  )
}

export const chefDropline = payload => dispatch => {
  dispatch(actions.chefDropline.start())
  api
    .chefDropline(payload)
    .then(() => dispatch(actions.chefDropline.succeed()))
    .catch(() => dispatch(actions.chefDropline.failed()))
}

export const getPublicData = dispatch => {
  dispatch(actions.getPublicData.start())
  api
    .getPublicData()
    .then(({ data }) => dispatch(actions.getPublicData.succeed({ data })))
    .catch(({ error }) => dispatch(actions.getPublicData.failed({ error })))
}

export const changeTip = tip => dispatch => {
  dispatch(actions.changeTip.start())
  api
    .changeTip(tip)
    .then(() => dispatch(actions.changeTip.succeed({ tip })))
    .catch(() => dispatch(actions.changeTip.failed()))
}

export const unsubscribe = selectedReason => dispatch => {
  dispatch(actions.unsubscribe.start())
  const reason = {
    reasonId: selectedReason.id,
    text: selectedReason.text || selectedReason.zipCode
  }
  const date = moment().format('YYYY-MM-DD')
  api
    .createSubscriptionEvent({ eventType: 'unsubscribe', reason, date })
    .then(data => dispatch(actions.unsubscribe.succeed({ data })))
    .catch(error => dispatch(actions.unsubscribe.failed({ error })))
}

export const getUnsubscribeReasons = () => dispatch => {
  dispatch(actions.getUnsubscribeReasons.start())
  api
    .getUnsubscribeReasons()
    .then(response =>
      dispatch(
        actions.getUnsubscribeReasons.succeed(
          helpers.formatUnsubscribeReasonsData(response.data)
        )
      )
    )
    .catch(() => dispatch(actions.getUnsubscribeReasons.failed()))
}

export const get25Off = () => dispatch => {
  dispatch(actions.get25Off.start())
  return api
    .get25Off()
    .then(response => {
      if (
        response.data &&
        response.data.consumeCoupon &&
        response.data.consumeCoupon.error
      ) {
        dispatch(actions.get25Off.failed())
        return false
      }

      dispatch(actions.get25Off.succeed(response))
      return true
    })
    .catch(() => {
      dispatch(actions.get25Off.failed())
      return false
    })
}

export const clearNotification = key => dispatch => {
  dispatch(actions.clearNotification.start({ key }))

  return api
    .clearNotification(key)
    .then(response => dispatch(actions.clearNotification.succeed(response)))
    .catch(error => dispatch(actions.clearNotification.failed(error)))
}

export const getUpcomingDays = () => (dispatch, getState) => {
  return fetchMyPlan(dispatch, getState)
}

export const setSetting = ({ key, value }) => dispatch => {
  dispatch(actions.setSetting.start({ key, value }))
  return api
    .setSetting({ key, value })
    .then(() => dispatch(actions.setSetting.succeed()))
    .catch(() => dispatch(actions.setSetting.failed()))
}

export const getZipcodesByDeliveryZone = () => dispatch => {
  dispatch(actions.getZipcodesByDeliveryZone.start())
  return api
    .getZipcodesByDeliveryZone()
    .then(res => dispatch(actions.getZipcodesByDeliveryZone.succeed(res)))
    .catch(() => dispatch(actions.getZipcodesByDeliveryZone.failed()))
}

export const createSubscriptionEvent = event => dispatch => {
  if (
    ['pause', 'unpause', 'subscribe', 'unsubscribe', 'hold', 'unhold'].includes(
      event
    )
  ) {
    dispatch(actions.createSubscriptionEvent.start())
    const date = moment().format('YYYY-MM-DD')
    api
      .createSubscriptionEvent({ eventType: event, date })
      .then(({ data: { publicCreateSubscriptionEvent } }) =>
        dispatch(
          actions.createSubscriptionEvent.succeed(publicCreateSubscriptionEvent)
        )
      )
      .catch(error =>
        dispatch(actions.createSubscriptionEvent.failed({ error }))
      )
  }
}

export const sendNutritionMessage = args => dispatch => {
  dispatch(actions.sendNutritionMessage.start())
  return api
    .sendNutritionMessage(args)
    .then(() => dispatch(actions.sendNutritionMessage.succeed()))
    .catch(() => dispatch(actions.sendNutritionMessage.failed()))
}

export const getTrackingInfo = orderId => dispatch => {
  dispatch(actions.getTrackingInfo.start())
  return api
    .getTrackingInfo(orderId)
    .then(({ data: { trackOrder } }) => {
      return dispatch(actions.getTrackingInfo.succeed(trackOrder))
    })
    .catch(error => actions.getTrackingInfo.failed(error))
}

export const addMealWish = ({ meal, profile }) => dispatch => {
  dispatch(actions.addMealWish.start())

  return api
    .addMealWish(meal, profile)
    .then(res => {
      const { mealWishes } = res.data.createMealWish
      const mealAdded = mealWishes.find(elem => elem.mealId === meal)
      trackEventWishes('Product Added to Favorites', {
        ...mealAdded.mealData
      })
      return dispatch(actions.addMealWish.succeed({ mealWishes, profile }))
    })
    .catch(() => {
      toaster.error('Error adding favorite', {
        position: toast.POSITION.TOP_RIGHT
      })
      return dispatch(actions.addMealWish.failed())
    })
}

export const cleanAddMealWishStatus = () => dispatch => {
  dispatch(actions.addMealWish.clean())
}

export const removeMealWish = ({ meal, profile }) => (dispatch, getState) => {
  dispatch(actions.removeMealWish.start())
  return api
    .removeMealWish(meal.mealId, profile)
    .then(res => {
      const { mealWishes } = res.data.removeMealWish
      const state = getState()
      const profileData =
        state.api.subscription.info.profiles &&
        state.api.subscription.info.profiles.find(elem => elem.id === profile)
      const mealRemoved = profileData.mealWishes.find(
        elem => elem.mealId === meal.mealId
      )
      trackEventWishes('Product Removed from Favorites', {
        ...mealRemoved.mealData
      })
      return dispatch(actions.removeMealWish.succeed({ mealWishes, profile }))
    })
    .catch(() => {
      return dispatch(actions.removeMealWish.failed())
    })
}

export const cleanRemoveMealWishStatus = () => dispatch => {
  dispatch(actions.removeMealWish.clean())
}

export const addMealAvoid = ({ meal, profile }) => dispatch => {
  dispatch(actions.addMealAvoid.start())

  return api
    .addMealAvoid(meal, profile)
    .then(res => {
      const { mealAvoids } = res.data.createMealAvoid
      return dispatch(actions.addMealAvoid.succeed({ mealAvoids, profile }))
    })
    .catch(() => {
      return dispatch(actions.addMealAvoid.failed())
    })
}

export const removeMealAvoid = ({ meal, profile }) => dispatch => {
  dispatch(actions.removeMealAvoid.start())

  return api
    .removeMealAvoid(meal, profile)
    .then(res => {
      const { mealAvoids } = res.data.removeMealAvoid
      return dispatch(actions.removeMealAvoid.succeed({ mealAvoids, profile }))
    })
    .catch(() => {
      return dispatch(actions.removeMealAvoid.failed())
    })
}

export const getAvailableMeals = payload => dispatch => {
  const ts = +moment()
  dispatch(actions.getAvailableMeals.start({ ts }))
  return api
    .getAvailableMeals(payload)
    .then(res => {
      const { availableMeals } = res.data
      dispatch(actions.getAvailableMeals.succeed({ availableMeals, ts }))
      return availableMeals
    })
    .catch(() => {
      dispatch(actions.getAvailableMeals.failed({ ts }))
      return null
    })
}

export const addMealWishRequest = ({ text, profile }) => dispatch => {
  dispatch(actions.addMealWishRequest.start())

  return api
    .addMealWishRequest(text, profile)
    .then(res => {
      const { mealWishRequests } = res.data.createMealWishRequest
      return dispatch(
        actions.addMealWishRequest.succeed({ mealWishRequests, profile })
      )
    })
    .catch(() => {
      return dispatch(actions.addMealWishRequest.failed())
    })
}

export const sendSatisfactionResponse = args => dispatch => {
  dispatch(actions.sendSatisfactionResponse.start())
  return api
    .sendSatisfactionResponse({ ...args, experience: Boolean(args.experience) })
    .then(() => dispatch(actions.sendSatisfactionResponse.succeed()))
    .catch(() => dispatch(actions.sendSatisfactionResponse.failed()))
}

export const cleanUpdateOrderStatus = () => dispatch => {
  dispatch(actions.updateOrder.clean())
}

export const updateOrderComment = args => dispatch => {
  dispatch(actions.updateOrderComment.start())
  return api
    .updateOrderComment(args)
    .then(() => dispatch(actions.updateOrderComment.succeed()))
    .catch(() => dispatch(actions.updateOrderComment.failed()))
}

export const getAllOrders = args => dispatch => {
  dispatch(actions.getAllOrders.start())
  return api
    .getUserAllOrders(args)
    .then(({ data }) => dispatch(actions.getAllOrders.succeed(data.allOrders)))
    .catch(() => dispatch(actions.getAllOrders.failed()))
}

export const getUserReferrals = args => dispatch => {
  dispatch(actions.getUserReferrals.start())
  return api
    .getUserReferrals(args)
    .then(({ data }) =>
      dispatch(actions.getUserReferrals.succeed(data.userReferrals))
    )
    .catch(() => dispatch(actions.getUserReferrals.failed()))
}

export const getUserCurrentCredit = args => dispatch => {
  dispatch(actions.getUserCurrentCredit.start())
  return api
    .getUserCurrentCredit(args)
    .then(({ data }) => {
      dispatch(
        actions.getUserCurrentCredit.succeed(data.users[0].currentCredit)
      )
    })
    .catch(() => dispatch(actions.getUserCurrentCredit.failed()))
}

export const getUserSurvey = () => dispatch => {
  dispatch(actions.getUserSurvey.start())

  return getClientSurvey()
    .then(({ data }) => {
      const { surveyClient } = data
      dispatch(actions.getUserSurvey.succeed(surveyClient))
      return surveyClient
    })
    .catch(err => catchError(err, actions.getUserSurvey))
}

export const submitSurvey = data => dispatch => {
  dispatch(actions.submitSurvey.start())
  return saveSurvey(data)
    .then(() => {
      dispatch(actions.submitSurvey.succeed(data.name))
    })
    .catch(err => catchError(err, actions.submitSurvey))
}

export const getPromotions = () => (dispatch, getState) => {
  dispatch(actions.getUserPromotions.start())
  const state = getState()
  const userId = state.api?.subscription?.info?.id
  return api
    .getPromotions(userId)
    .then(data => {
      dispatch(actions.getUserPromotions.succeed(data))
      return data
    })
    .catch(err => catchError(err, actions.getUserPromotions))
}

export const getIdleDeliveryDays = args => dispatch => {
  dispatch(actions.getIdleDeliveryDays.start())
  return api
    .getIdleDeliveryDays(args)
    .then(({ data }) => {
      dispatch(actions.getIdleDeliveryDays.succeed(...data.idleDeliveryDays))
    })
    .catch(() => dispatch(actions.getIdleDeliveryDays.failed()))
}

export const applyAutoPilotPromotion = args => dispatch => {
  dispatch(actions.applyAutoPilotPromotion.start())
  return api
    .creatAutopilotPromotion(args)
    .then(({ data }) => {
      dispatch(actions.applyAutoPilotPromotion.succeed(data))
      return data
    })
    .catch(err => {
      dispatch(actions.applyAutoPilotPromotion.failed())
      catchError(err, actions.applyAutoPilotPromotion)
    })
}
