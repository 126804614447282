import { Fragment } from 'react'
import './CharacteristicsL2.scss'

const CharacteristicsL2 = ({ list, size = 'medium', classNames }) => {
  const addSeparator = index => index < list.length - 1
  return (
    <div className={`characteristics-l2 ${classNames}`}>
      {list.map((item, index) => (
        <Fragment key={`ch-l2-${index}`}>
          <div
            className={`characteristics-l2__item characteristics-l2__item--${size}`}>
            {item.icon} {item.label}
          </div>
          {addSeparator(index) && (
            <div className="characteristics-l2__separator" />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export { CharacteristicsL2 }
