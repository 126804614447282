import createReducer from '../../utils/createReducer'

import * as actions from './actions'

const initialState = {
  views: null,
  ratingTab: null,
  isLoading: false,
  isError: false
}

export default createReducer(initialState, {
  [actions.fetchSwimlanes]: state => ({
    ...state,
    views: null,
    ratingTab: null,
    isLoading: true,
    isError: false
  }),
  [actions.fetchSwimlanesSucceed]: (state, action) => ({
    ...state,
    isLoading: false,
    isError: false,
    views: action.payload.views,
    ratingTab: action.payload.ratingTab
  }),
  [actions.fetchSwimlanesFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true,
    views: null,
    ratingTab: null
  })
})
