export const SECTIONS = {
  ALLERGENS: 'allergies',
  DIET: 'diet',
  LIFESTYLE: 'lifestyle',
  PROTEINS: 'proteins',
  INGREDIENTS: 'ingredients',
  CUISINES: 'cuisines'
}

const TITLES = [
  {
    limit: 0.24,
    text: 'Help us get to know you!'
  },
  {
    limit: 0.49,
    text: 'You’re off to a great start!'
  },
  {
    limit: 0.74,
    text: 'Let’s get into the details.'
  },
  {
    limit: 0.99,
    text: 'Almost done!'
  },
  {
    limit: 1,
    text: 'Your Taste Profile is all set!'
  }
]

export const COMPLETED_SUBTITLE =
  'We can’t wait to help you discover new favorite meals and chefs.'

export const getTitle = percentage => {
  for (let title of TITLES) {
    if (percentage <= title.limit) return title.text
  }
}

export const isCompleted = percentage => percentage > 0.99

export const SECTIONS_PRIORITIES = [
  {
    section: SECTIONS.ALLERGENS,
    profileIndex: 0,
    percentage: {
      single: 0.2,
      double: 0.12
    },
    subtitle:
      'Complete your profile so we can recommend more meals you’ll love.',
    cta: 'Add {name}’s allergy info'
  },
  {
    section: SECTIONS.ALLERGENS,
    profileIndex: 1,
    percentage: {
      single: 0.2,
      double: 0.12
    },
    subtitle:
      'Complete your profile so we can recommend more meals you’ll love.',
    cta: 'Add {name}’s allergy info'
  },
  {
    section: SECTIONS.DIET,
    profileIndex: 0,
    percentage: {
      single: 0.2,
      double: 0.12
    },
    subtitle:
      'Tell us the kind of meals you like and we’ll prioritize them on your menu.',
    cta: 'Add {name}’s diet preferences'
  },
  {
    section: SECTIONS.DIET,
    profileIndex: 1,
    percentage: {
      single: 0.2,
      double: 0.12
    },
    subtitle:
      'Tell us the kind of meals you like and we’ll prioritize them on your menu.',
    cta: 'Add {name}’s diet preferences'
  },
  {
    section: SECTIONS.LIFESTYLE,
    profileIndex: 0,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle: 'What should or shouldn’t be on your menu? We’ll get on it!',
    cta: 'Add {name}’s nutritional goals '
  },
  {
    section: SECTIONS.PROTEINS,
    profileIndex: 0,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle: 'What should or shouldn’t be on your menu? We’ll get on it!',
    cta: 'Add {name}’s favorite proteins'
  },
  {
    section: SECTIONS.INGREDIENTS,
    profileIndex: 0,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle: 'What should or shouldn’t be on your menu? We’ll get on it!',
    cta: 'Add {name}’s ingredients to avoid'
  },
  {
    section: SECTIONS.CUISINES,
    profileIndex: 0,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle:
      'We’re so close to having what we need to curate your perfect menu.',
    cta: 'Add {name}’s favorite cuisines'
  },
  {
    section: SECTIONS.LIFESTYLE,
    profileIndex: 1,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle:
      'We’re so close to having what we need to curate your perfect menu.',
    cta: 'Add {name}’s nutritional goals'
  },
  {
    section: SECTIONS.PROTEINS,
    profileIndex: 1,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle:
      'We’re so close to having what we need to curate your perfect menu.',
    cta: 'Add {name}’s favorite proteins'
  },
  {
    section: SECTIONS.INGREDIENTS,
    profileIndex: 1,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle:
      'We’re so close to having what we need to curate your perfect menu.',
    cta: 'Add {name}’s ingredients to avoid'
  },
  {
    section: SECTIONS.CUISINES,
    profileIndex: 1,
    percentage: {
      single: 0.15,
      double: 0.065
    },
    subtitle:
      'We’re so close to having what we need to curate your perfect menu.',
    cta: 'Add {name}’s favorite cuisines'
  }
]
