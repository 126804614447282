import { handleActions } from 'redux-actions'

import { helperStatus } from '../helpers'

import * as actions from './actions'

const initialState = {
  inFlight: false,
  succeeded: false,
  error: false,
  data: [],
  collections: []
}

export default handleActions(
  {
    [actions.getAllMerchandiseSetsStart]: state => {
      return {
        ...state,
        ...helperStatus({ inFlight: true, succeeded: false, error: false })
      }
    },
    [actions.getAllMerchandiseSetsSuccess]: (state, { payload }) => {
      return {
        ...state,
        data:
          payload.merchandiseSets && payload.merchandiseSets.length > 0
            ? [
                ...state.data,
                {
                  deliveryDate: payload.date,
                  merchandiseSets: payload.merchandiseSets
                }
              ]
            : state.data,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.getAllMerchandiseSetsFail]: (state, { payload }) => {
      return {
        ...state,
        data: [],
        ...helperStatus({
          inFlight: false,
          succeeded: false,
          error: payload.err
        })
      }
    },
    [actions.getCollectionsStart]: state => {
      return {
        ...state,
        collections: [],
        ...helperStatus({ inFlight: true, succeeded: false, error: false })
      }
    },
    [actions.getCollectionsSuccess]: (state, { payload }) => {
      return {
        ...state,
        collections:
          payload.menu &&
          payload.menu.categories &&
          payload.menu.categories[0] &&
          payload.menu.categories[0].merchandised_new
            ? {
                deliveryDate: payload.date,
                merchandiseSets: payload.menu.categories[0].merchandised_new
              }
            : state.collections,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.getMenuCollectionsSuccess]: (state, { payload }) => {
      return {
        ...state,
        collections: payload.collections,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.getCollectionsFail]: (state, { payload }) => {
      return {
        ...state,
        collections: [],
        ...helperStatus({
          inFlight: false,
          succeeded: false,
          error: payload.err
        })
      }
    }
  },
  initialState
)
