import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

import * as paymentsSelectors from '../../redux-api-bridge/payments/selectors'

export const getSubscriptionStatusEvent = state =>
  pathOr(null, ['api', 'subscription', 'info', 'status_event', 'event'], state)

export const createSubscriptionEventInFlight = state =>
  pathOr(false, ['api', 'createSubscriptionEvent', 'inFlight'], state)

export const isLoading = createSelector(
  [
    paymentsSelectors.userCardsStatus,
    paymentsSelectors.defaultCardStatus,
    paymentsSelectors.getUserCards,
    createSubscriptionEventInFlight
  ],
  (cardsStatus, defaultCard, cards, createEvent) =>
    cardsStatus.inFlight ||
    !cardsStatus.succeeded ||
    defaultCard.inFlight ||
    !cards ||
    createEvent
)
