import './EconomicPlanModal.scss'

import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { CuiModal } from 'front-lib'
import { useTranslation } from 'react-i18next'

import { getPlatformText } from '../../../../utils/utils'
import tracking from '../../../../shared/tracking'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { useEconomicPlanPriceLabel } from './useEconomicPlanPriceLabel'
import { economicPlanModalAtom } from 'src/atoms/modals/economicPlanModal.atom'

const SUPPORT_EMAIL = 'support@cookunity.com'

export function EconomicPlanModal() {
  const [{ isOpen }, setEconomicPlanModal] = useAtom(economicPlanModalAtom)
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const platform = getPlatformText(isMobile)
  const price = useEconomicPlanPriceLabel()

  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: `Economic plan - Learn more modal`,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'My Plan',
      overlay_trigger: 'manual'
    })
  }, [])

  const handleClose = () => {
    tracking.track('Overlay Closed', {
      overlay_name: `Economic plan - Learn more modal`,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'My Plan',
      overlay_trigger: 'manual'
    })
    setEconomicPlanModal({ isOpen: false })
  }

  return (
    <CuiModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      iconClassName="economic-plan-modal__close-icon">
      <div className="economic-plan-modal__title-container">
        <div className="economic-plan-modal__title">
          {t('pages.my_plan.economic_meal_modal.title', { price })}
        </div>
      </div>
      <div className="economic-plan-modal__text-container">
        <div className="economic-plan-modal__text">
          {t(`pages.my_plan.economic_meal_modal.text_1.${platform}`, { price })}
        </div>
        <div className="economic-plan-modal__text">
          {t(`pages.my_plan.economic_meal_modal.text_2.${platform}`, { price })}
          <a target="_new" href="mailto:support@cookunity.com">
            <u>{SUPPORT_EMAIL}</u>
          </a>
        </div>
      </div>
    </CuiModal>
  )
}
