import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CuiIcon } from 'front-lib'
import MenuLink from './MenuLink'

const CategoryLink = ({ category, onMenuTabClick }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const handleClickCategory = () => {
    setIsOpen(!isOpen)
  }

  const linksClasses = classNames('full-width', {
    hidden: !isOpen
  })

  const chevronClasses = classNames('fas fa-xs', {
    'fa-chevron-down': !isOpen,
    'fa-chevron-up': isOpen
  })

  const labelCategoryClasses = classNames({
    bold: isOpen || category.links.some(link => link.pathname === pathname)
  })

  return (
    <>
      <div
        key={category.label}
        className={'menu_item'}
        onClick={handleClickCategory}>
        <CuiIcon name={category.icon} />{' '}
        <span className={labelCategoryClasses}>{t(category.label)}</span>
        <i className={chevronClasses} />
      </div>
      <div className={linksClasses}>
        {category.links.map(link => {
          return (
            <MenuLink
              key={link.label}
              className={'menu_item'}
              to={link.pathname}
              onClick={event => onMenuTabClick(event, link)}>
              <div className={'menu_item_sublink'}>{t(link.label)}</div>
            </MenuLink>
          )
        })}
      </div>
    </>
  )
}

export default CategoryLink
