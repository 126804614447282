import React, { useState, useEffect, createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

const EmbeddedContext = createContext({
  isAppEmbedded: false
})

export const useEmbedded = () => useContext(EmbeddedContext)

export const withEmbedded = Component => props => (
  <EmbeddedContext.Consumer>
    {({ isAppEmbedded }) => (
      <Component isAppEmbedded={isAppEmbedded} {...props} />
    )}
  </EmbeddedContext.Consumer>
)

const EmbeddedProvider = props => {
  const { children } = props
  const { search } = useLocation()
  const [isAppEmbedded, setIsAppEmbedded] = useState(false)
  const { embedded } = parse(search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    if (embedded === 'true') {
      setIsAppEmbedded(true)
    }
  }, [embedded])

  return (
    <EmbeddedContext.Provider value={{ isAppEmbedded }}>
      {children}
    </EmbeddedContext.Provider>
  )
}

export default EmbeddedProvider
