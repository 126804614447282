import createReducer from '../../utils/createReducer'

import * as actions from './actions'

const initialState = {
  shareRecommendationStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  }
}

export default createReducer(initialState, {
  [actions.shareRecommendation.start.type]: state => ({
    ...state,
    shareRecommendationStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.shareRecommendation.succeed.type]: state => ({
    ...state,
    shareRecommendationStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.shareRecommendation.failed.type]: state => ({
    ...state,
    shareRecommendationStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.shareRecommendationReset]: state => ({
    ...state,
    shareRecommendationStatus: {
      inFlight: false,
      succeeded: false,
      error: null
    }
  })
})
