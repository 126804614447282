import { useDispatch } from 'react-redux'

import { useCreateUnskip } from 'src/api/UnskipOrder/useCreateUnskip'
import { fetchMyPlan } from 'src/redux-api-bridge'
import mobileAndTabletCheck from 'src/utils/mobileAndTabletcheck'

export function useUnpause() {
  const dispatch = useDispatch()
  const { createUnskip, loading } = useCreateUnskip({ onCompleted })

  function unpausePlan({ date }) {
    const origin = getUnpauseOrigin()
    createUnskip({ unskip: { date }, origin })
  }

  function onCompleted() {
    dispatch(fetchMyPlan)
  }

  function getUnpauseOrigin() {
    return mobileAndTabletCheck()
      ? 'customer_web_mobile'
      : 'customer_web_desktop'
  }

  return { unpausePlan, loading }
}
