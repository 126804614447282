import { handleActions } from 'redux-actions'

import * as recipes from './actions'

const initialState = {
  loading: false,
  succeed: false,
  failed: false,
  recipe: null
}

export default handleActions(
  {
    [recipes.getRecipeStarted]: state => ({
      ...state,
      loading: true,
      succeed: false,
      failed: false
    }),
    [recipes.getRecipeSuccess]: (state, action) => ({
      ...state,
      recipe: action.payload,
      loading: false,
      succeed: true,
      failed: false
    }),
    [recipes.getRecipeFail]: state => ({
      ...state,
      loading: false,
      succeed: false,
      failed: true
    })
  },
  initialState
)
