import { useSelector } from 'react-redux'
import moment from 'moment'
import momentTZ from 'moment-timezone'

import { useGetLastSkips } from 'src/api/LastSkips/getLastSkips'
import {
  getUpcomingDeliveries,
  getRing
} from 'src/modules/MyDeliveries/selectors'
import { getUserDeliveryInfo } from 'src/modules/MyAccount/components/Settings/selectors'

const DATE_FORMAT = 'MM/DD/YYYY'

export function useLastSkips() {
  const { lastSkips, loading } = useGetLastSkips()
  const upcomingDeliveries = useSelector(getUpcomingDeliveries)
  const ring = useSelector(getRing)
  const userDeliveryInfo = useSelector(getUserDeliveryInfo)

  const planIsPaused =
    lastSkips.length > 0 && lastSkips.some(skip => skip.isPaused)
  const [firstPausedSkip] = lastSkips.slice(-1)
  const pauseResumeDate = getPauseResumeDate()

  function getPauseResumeDate() {
    if (lastSkips.length > 0) {
      return moment(lastSkips[0].date)
        .add(1, 'day')
        .format(DATE_FORMAT)
    }

    return null
  }

  function getPauseResumeData() {
    if (!pauseResumeDate || !upcomingDeliveries || !ring || !userDeliveryInfo) {
      return {
        date: '',
        displayDate: '',
        timezone: '',
        cutoff: ''
      }
    }

    const userTimeZone =
      upcomingDeliveries && upcomingDeliveries[0]?.cutoff.userTimeZone
    const userDeliveryDay = userDeliveryInfo?.selectedDeliveryDays[0]?.day

    return calculatePauseResumeData({
      userDeliveryDay,
      shippingDays: Number(ring.shipping_days),
      userTimeZone
    })
  }

  function calculatePauseResumeData({
    userDeliveryDay,
    shippingDays,
    userTimeZone
  }) {
    const daysToDelivery = getDaysToDelivery({
      userDeliveryDay,
      pauseResumeDate
    })

    const date = moment(pauseResumeDate).format('MM/DD/YY')

    const displayDate = moment(pauseResumeDate)
      .add(daysToDelivery, 'days')
      .format('MM/DD/YY')

    const cutoff = moment(displayDate)
      .subtract(4 + shippingDays, 'days')
      .format('MM/DD/YY')

    const [zone, , time] = userTimeZone
      ? momentTZ()
          .tz(userTimeZone)
          .zoneAbbr()
          .split('')
      : ['', '', '']

    return {
      date,
      displayDate,
      timezone: `${zone}${time}`,
      cutoff
    }
  }

  function getDaysToDelivery({
    userDeliveryDay,
    pauseResumeDate
  }: {
    userDeliveryDay: number
    pauseResumeDate: string | null
  }) {
    const unpauseDateNumber = moment(pauseResumeDate).isoWeekday()
    // How many days from unpause date to next delivery?
    const daysToAdd = (userDeliveryDay - unpauseDateNumber + 7) % 7
    return daysToAdd
  }

  return {
    lastSkips,
    loading,
    planIsPaused,
    firstPausedSkip: firstPausedSkip?.date || null,
    resumeData: getPauseResumeData()
  }
}
