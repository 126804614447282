import React from 'react'
import './text-image-row-cui.scss'

const TextImageRowCui = ({
  children,
  image,
  alt,
  reverse,
  className,
  videoUrlH264,
  videoPoster,
  autoPlay = true,
  reverseMobile,
  black
}) => {
  return (
    <div
      className={`text-image-row-cui rebrand ${className ? className : ''} ${
        black ? 'black-background' : ''
      }`}>
      <div
        className={`container-cui container-cui-row ${
          reverse ? 'reverse' : ''
        } ${reverseMobile ? 'reverse-mobile' : ''}`}>
        <div className="col-text">{children}</div>
        <div className="col-media">
          {videoUrlH264 ? (
            <div className="video-background">
              <video
                autoPlay={autoPlay}
                muted
                loop
                controls={false}
                playsInline
                poster={videoPoster}>
                <source src={videoUrlH264} type="video/mp4" />
              </video>
            </div>
          ) : (
            <img src={image} alt={alt} />
          )}
        </div>
      </div>
    </div>
  )
}

export default TextImageRowCui
