import { gql, useMutation } from '@apollo/client'

export const CREATE_UNSKIP_MUTATION = gql`
  mutation createUnskip($unskip: SkipInput!, $origin: OperationOrigin) {
    createUnskip(unskip: $unskip, origin: $origin) {
      __typename
      ... on Skip {
        id
      }
      ... on OrderCreationError {
        error
      }
    }
  }
`

export function useCreateUnskip({
  onCompleted = () => {}
}: {
  onCompleted?: () => void
}) {
  const [createUnskip, rest] = useMutation(CREATE_UNSKIP_MUTATION, {
    onCompleted,
    refetchQueries: ['lastSkips']
  })

  return {
    createUnskip: (variables: { unskip: any; origin: string }) =>
      createUnskip({ variables }),
    ...rest
  }
}
