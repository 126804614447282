const handler = namespace => payload => ({ payload, type: namespace })

const createActionCreator = TYPES => {
  const res = handler(TYPES.name)

  res.type = TYPES.name

  res.start = handler(TYPES.start)
  res.start.type = TYPES.start

  res.onLoadChange = handler(TYPES.onLoadChange)
  res.onLoadChange.type = TYPES.onLoadChange

  res.succeed = handler(TYPES.succeed)
  res.succeed.type = TYPES.succeed

  res.failed = handler(TYPES.failed)
  res.failed.type = TYPES.failed

  res.clean = handler(TYPES.clean)
  res.clean.type = TYPES.clean

  return res
}

const actionCreator = (pfx, action_name) => {
  const name = `${pfx}_${action_name}`.toUpperCase()
  const TYPES = {
    name,
    start: `${name}_START`,
    onLoadChange: `${name}_ON_LOAD_CHANGE`,
    succeed: `${name}_SUCCEED`,
    failed: `${name}_FAILED`,
    clean: `${name}_CLEAN`
  }

  return createActionCreator(TYPES)
}

export default actionCreator
