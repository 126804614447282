import { createAction } from 'redux-actions'

export const getAllMerchandiseSetsStart = createAction(
  'GET_ALL_MERCHANDISE_MEALS_START'
)
export const getAllMerchandiseSetsSuccess = createAction(
  'GET_ALL_MERCHANDISE_MEALS_SUCCESS'
)
export const getAllMerchandiseSetsFail = createAction(
  'GET_ALL_MERCHANDISE_MEALS_FAIL'
)

export const getCollectionsStart = createAction('GET_COLLECTIONS_START')
export const getCollectionsSuccess = createAction('GET_COLLECTIONS_SUCCESS')
export const getCollectionsFail = createAction('GET_COLLECTIONS_FAIL')

export const getMenuCollectionsSuccess = createAction(
  'GET_MENU_COLLECTIONS_SUCCESS'
)

export const getMenuAllMerchandiseSetsSuccess = createAction(
  'GET_MENU_ALL_MERCHANDISE_SETS_SUCCESS'
)
