import { gql, useMutation } from '@apollo/client'

export const UPGRADE_TO_STANDARD_PLAN_MUTATION = gql`
  mutation upgradeToStandardPlan {
    upgradeToStandardPlan {
      success
    }
  }
`

export function useUpgradeToStandardPlanMutation() {
  const [upgradeToStandardPlan, rest] = useMutation(
    UPGRADE_TO_STANDARD_PLAN_MUTATION
  )

  return {
    upgradeToStandardPlan,
    rest
  }
}
