import { handleActions } from 'redux-actions'

import * as scheduleActions from './actions'

const initialState = {
  mode: null,
  isOpen: false
}

export default handleActions(
  {
    [scheduleActions.toggleSchedulePanel]: (state, { payload }) => {
      return {
        ...state,
        mode: payload.mode,
        selectedDate: payload.selectedDate,
        timeslot: payload.timeslot,
        cutOff: payload.cutOff,
        newTimeslot: payload.newTimeslot,
        isOpen: payload.isOpen
      }
    }
  },
  initialState
)
