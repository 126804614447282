import tracking from '../shared/tracking'
import { IconName } from 'src/pages/orders/components/RatingTab/components'

export const MAX_RATING = 5
export const RATINGS = [1, 2, 3, 4, 5]

export const getRatingText = rating =>
  rating === RATINGS[0]
    ? 'Very Bad'
    : rating === RATINGS[1]
    ? 'Bad'
    : rating === RATINGS[2]
    ? 'Good'
    : rating === RATINGS[3]
    ? 'Very Good'
    : rating === RATINGS[4]
    ? 'Amazing'
    : ''

export const isMaxRating = rating => rating === MAX_RATING

export const trackEventReview = (product, review, reviewId) => {
  tracking.track('Product Rated', {
    product_id: product.id,
    name: product.name,
    product_premium: product.premium_special,
    rating: review.rating,
    reasons: review.reasons,
    review_body: review.review,
    review_id: reviewId
  })
}

export const getStarIcon = (
  currentStar: number,
  currentHoveredStar: number | null,
  ratingFromForm: number | null,
  ratingSubmitted: number | null,
  isCurrentProduct: boolean
) => {
  if (ratingSubmitted) {
    return ratingSubmitted < currentStar
      ? IconName.EMPTY_BOLD_STAR
      : IconName.FULL_BOLD_STAR
  }

  if (currentHoveredStar) {
    return currentHoveredStar < currentStar
      ? IconName.EMPTY_BOLD_STAR
      : IconName.FULL_BOLD_STAR
  }

  if (ratingFromForm) {
    return !isCurrentProduct || ratingFromForm < currentStar
      ? IconName.EMPTY_BOLD_STAR
      : IconName.FULL_BOLD_STAR
  }

  return IconName.EMPTY_BOLD_STAR
}
