import { useSetAtom } from 'jotai'

import { useDeflectionWithPromoStrategy } from './useDeflectionWithPromoStrategy'
import { pauseDeflectionModalAtom } from 'src/atoms/modals/pauseDeflectionModal.atom'

export function usePausePlanDeflection() {
  const setPauseDeflectionModal = useSetAtom(pauseDeflectionModalAtom)
  const {
    enabled: deflectionStrategyEnabled,
    triggerStrategy
  } = useDeflectionWithPromoStrategy()

  function handleOnPause({ weeks }) {
    if (deflectionStrategyEnabled) {
      triggerStrategy({
        type: 'pause',
        payload: { weeks },
        onContinue: () => openPauseDeflectionModal({ weeks })
      })
    } else {
      openPauseDeflectionModal({ weeks })
    }
  }

  function openPauseDeflectionModal({ weeks }) {
    setPauseDeflectionModal({ isOpen: true, pauseDuration: weeks })
  }

  return {
    handleOnPause
  }
}
