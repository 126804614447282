import {
  FEATURED_CATEGORY,
  IMG_URL_SOURCE,
  MEALS_CATEGORY_TAB_ID
} from './constants'
import { compose, sortBy, uniqBy } from 'ramda'

import { getCategory } from './selectors'
import { buildImgUrl, mealsAreEquals } from 'src/utils/utils'
import { isBundle } from 'src/common/MealMenu/utils'
import { MEALS_CATEGORY } from 'src/constants/menu'
import { FILTER_IDS } from './components/MenuBy/Filters/constants'

export const ADDONS_CATEGORIES = [6, 11, 13]

export const mapMeals = (meal, cat) => {
  let imageUrl = (meal.image || '').replace('no_selection', '')
  if (imageUrl)
    imageUrl = buildImgUrl(IMG_URL_SOURCE, meal.image_path, imageUrl)
  return {
    ...meal,
    category: getCategory(meal),
    imageUrl,
    sidedish: meal.sidedish && meal.sidedish.id,
    magento_id: +meal.entity_id,
    menu_category_id: cat.id,
    menu_category_label: cat.label,
    reservedStock: meal.qty || 0,
    tags:
      meal.specifications_detail &&
      meal.specifications_detail.filter(tag => tag && tag.label.trim()),
    qty: 1,
    id: meal.id || meal.entity_id,
    isNewMeal: meal.isNewMeal || meal.new_meal,
    inventoryId: meal.inventoryId
  }
}

export const mapCollectionBundle = bundle => {
  const category_id = bundle.meals.some(
    meal => meal.categoryId === MEALS_CATEGORY
  )
    ? MEALS_CATEGORY
    : ADDONS_CATEGORIES[0]
  return {
    ...bundle,
    inventoryId: bundle.inventoryId,
    meals: bundle.meals,
    id: bundle.id, // TODO: REMOVE
    category_id,
    inStock: bundle.stock > 0,
    price: bundle.price,
    finalPrice: bundle.finalPrice,
    originalPriceWithoutPlanMeal: bundle.originalPriceWithoutPlanMeal,
    priceWithoutPlanMeal: bundle.priceWithoutPlanMeal,
    shortDescription: bundle.shortDescription,
    isNew: bundle.isNewBundle
  }
}

export const mapMenuMeals = meal => ({
  ...meal,
  id: meal.id,
  entity_id: meal.id,
  magento_id: meal.id,
  batch_id: meal.batchId,
  inventoryId: meal.inventoryId,
  short_description: meal.shortDescription,
  calories: +meal.nutritionalFacts.calories,
  image_path: meal.imagePath,
  is_premium: !!meal.premiumFee,
  is_back_in_the_menu: !!meal.isBackInTheMenu,
  premium_fee: meal.premiumFee,
  premium_special: meal.premiumSpecial,
  category_id: meal.categoryId,
  chef_id: meal.chef && meal.chef.id,
  chef_firstname: meal.chef && meal.chef.firstName,
  chef_lastname: meal.chef && meal.chef.lastName,
  is_celebrity_chef: meal.chef && meal.chef.isCelebrityChef,
  warnings: {
    message: meal.warnings && meal.warnings.message,
    restrictions_applied: meal.warnings && meal.warnings.restrictionsApplied,
    diets_not_matching: meal.warnings && meal.warnings.dietsNotMatching,
    allergens_not_matching: meal.warnings && meal.warnings.allergensNotMatching
  },
  nutritional_facts: meal.nutritionalFacts && {
    calories: meal.nutritionalFacts.calories
  },
  logopic: meal.chef && meal.chef.logoPic,
  bannerpic: meal.chef && meal.chef.bannerPic,
  inStock: meal.stock > 0,
  meat_type: meal.meatType,
  user_rating: meal.userRating,
  sidedish: meal.sideDish && {
    id: meal.sideDish.id
  },
  quantity: meal.qty || 0,
  imageUrl: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_meal_image: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_chef_image: `${IMG_URL_SOURCE}${meal.chef.bannerPic}`,
  protein_type: meal.meatType,
  secondary_image: (meal.media && meal.media.secondaryImage) || null,
  specifications_detail: meal.specificationsDetails
    ? meal.specificationsDetails.map(specification => ({
        ...specification
      }))
    : [],
  relatedMeal: meal.relatedMeal
    ? {
        ...meal.relatedMeal,
        entity_id: meal.relatedMeal.id,
        batch_id: meal.relatedMeal.batchId,
        magento_id: meal.relatedMeal.id,
        is_related_meal: true,
        image_path: meal.relatedMeal.imagePath,
        category_id: meal.relatedMeal.categoryId
      }
    : null,
  all_tags: meal.tags,
  showInBundlesOnly: !!meal.showInBundlesOnly
})

export const menuCollectionsMapper = collections => {
  return {
    merchandiseSets: collections.map(collection => {
      let col = {
        ...collection,
        showCollectionInOrders: !collection.hideInSubscriptionHome,
        heroPhoto:
          collection.heroPhoto &&
          `${IMG_URL_SOURCE}/media/merchandiseSets/${collection.heroPhoto}`,
        coverPhoto: `${IMG_URL_SOURCE}/media/merchandiseSets/${collection.coverPhoto}`,
        items: [
          ...(collection.bundles?.map(bundle => mapCollectionBundle(bundle)) ||
            []),
          ...(collection.meals?.map(meal => mapMenuMeals(meal)) || []).filter(
            meal => !meal.showInBundlesOnly
          )
        ]
      }
      delete col.meals
      return col
    })
  }
}

export const getCategoryMeals = (
  confirmedOrder,
  currentMenu,
  selectedCategoryId
) => {
  const isEditingOrder = confirmedOrder && confirmedOrder.length

  const selectedCategory =
    currentMenu.find(category => category.id === selectedCategoryId) || {}
  if (selectedCategory && selectedCategory.meals) {
    return isEditingOrder
      ? compose(
          sortBy(meal => !confirmedOrder.find(m => mealsAreEquals(m, meal))),
          uniqBy(meal => meal.inventoryId || meal.magento_id)
        )([...selectedCategory.meals])
      : selectedCategory.meals
  }
  return []
}

const getMealFee = meal =>
  isBundle(meal) ? meal.priceWithoutPlanMeal : meal.premium_fee

const getAddOnFee = addOn =>
  isBundle(addOn) ? addOn.finalPrice : addOn.price + (addOn.premium_fee || 0)

export const getAddonsAndFees = (addons, meals) => {
  const premiumFeesTotal = meals.reduce(
    (accumulator, meal) => accumulator + meal.qty * (getMealFee(meal) || 0),
    0
  )
  const addonsExtraTotal = addons.reduce(
    (accumulator, addon) =>
      addon.qty && addon.price
        ? accumulator + addon.qty * (getAddOnFee(addon) || 0)
        : accumulator,
    0
  )
  return premiumFeesTotal + addonsExtraTotal
}

export const getItemsPrice = (items, pricePerMeal) => {
  const totalPrice = items.reduce((total, currentItem) => {
    if (isAddOn(currentItem)) {
      const addOnPrice = (+currentItem.price || 0) * currentItem.qty
      return total + addOnPrice
    }

    const itemPrice =
      (+pricePerMeal + +currentItem.premiumFee || 0) * currentItem.qty
    return total + itemPrice
  }, 0)

  return totalPrice || 0
}

export const countItems = items =>
  items.reduce(
    (total, item) => (item.quantity ? total + item.quantity : total),
    0
  )

export const calculateTotalFromSubtotal = ({
  subtotal,
  totalTaxes,
  totalDiscount,
  totalDeliveryFee,
  isFlexPlan
}) => {
  if (isFlexPlan) {
    const totalOrderFlex = (
      +subtotal +
      +totalDeliveryFee +
      +totalTaxes -
      +totalDiscount
    ).toFixed(2)

    return totalOrderFlex
  }

  return subtotal
}

export const calculateTotal = ({
  meals,
  addons,
  totalTaxes = 0,
  totalDiscount = 0,
  totalDeliveryFee = 0,
  plan,
  isFlexPlan
}) => {
  if (isFlexPlan) {
    const pricePerMeal = plan.price / plan.mealsPerDelivery
    const totalMealsFlexible = getItemsPrice(meals, pricePerMeal)
    const totalAddonsFlexible = getItemsPrice(addons, pricePerMeal)

    const totalOrderFlexible = (
      +totalMealsFlexible +
      +totalAddonsFlexible +
      +totalDeliveryFee +
      +totalTaxes -
      +totalDiscount
    ).toFixed(2)

    return totalOrderFlexible
  }

  const totalAddonsAndFees = getAddonsAndFees(addons, meals)
  return totalAddonsAndFees
}

export const getFlexSubtotal = (addons, meals) => {
  const premium_fees = meals.reduce(
    (sub, meal) => sub + meal.qty * (meal.price + (meal.premium_fee || 0)),
    0
  )
  const addons_sub = addons.reduce(
    (sub, addon) =>
      addon.qty && addon.price
        ? sub + addon.qty * (addon.price + (addon.premium_fee || 0))
        : sub,
    0
  )
  return premium_fees + addons_sub
}

export const formatProduct = products => {
  return products.map(elem => {
    return {
      id: elem.entity_id,
      sku: elem.sku,
      category: elem.category,
      name: elem.title,
      chef: elem.chefName,
      price: elem.price,
      url: `/products/${elem.inventoryId || elem.entity_id}`,
      imageUrl: elem.imageUrl
    }
  })
}

export const isFeaturedCategory = category => category === FEATURED_CATEGORY
export const isFullMenuCollectionQf = qf =>
  qf?.id === FILTER_IDS.FULL_MENU_COLLECTIONS
export const isMealsCategory = category => category === MEALS_CATEGORY_TAB_ID

export const createMerchandiseItem = (merchandiseSets, mealsFormated) =>
  merchandiseSets &&
  merchandiseSets.reduce(
    (accum, merchandise) => [
      ...accum,
      {
        ...merchandise,
        items: merchandise.items
          .map(elem =>
            mealsFormated.find(meal => meal.entity_id === elem.entity_id)
          )
          // send meals out of stock to the end of the list
          .sort((meal1, meal2) => {
            if (meal1.stock <= 0) return 1
            if (meal2.stock <= 0) return -1
            return 0
          })
      }
    ],
    []
  )

export const isAddOn = meal => {
  if (
    ADDONS_CATEGORIES.includes(meal.category_id) ||
    ADDONS_CATEGORIES.includes(meal.categoryId) ||
    (meal.category &&
      meal.category.id &&
      ADDONS_CATEGORIES.includes(meal.category.id))
  )
    return true
  return false
}

// Main product => included in plan
export const isMainProduct = meal => !isAddOn(meal)

export const groupOrderItems = (
  mealsInDelivery,
  orderWithStock,
  isFlexPlan
) => {
  if (isFlexPlan) {
    return groupOrderItemsForFlexibleSegment(orderWithStock)
  }

  return groupOrderItemsForNonFlexibleSegment(mealsInDelivery, orderWithStock)
}

export const groupOrderItemsForNonFlexibleSegment = (
  mealsInDelivery,
  orderWithStock
) => {
  return orderWithStock.reduce(
    (dataAccumulator, currentMeal) => {
      const isAddonOrTheCartIsFull =
        isAddOn(currentMeal) ||
        dataAccumulator.mealQtyOrdered >= mealsInDelivery

      if (isAddonOrTheCartIsFull) {
        // Add the item to the addons list
        return {
          ...dataAccumulator,
          addons: [...dataAccumulator.addons, { ...currentMeal, extra: true }]
        }
      }

      const remainingMealsToOrder =
        mealsInDelivery - dataAccumulator.mealQtyOrdered
      const doesMealFitInCart = remainingMealsToOrder - currentMeal.qty >= 0
      if (doesMealFitInCart) {
        // Add the item to the meals list
        return {
          ...dataAccumulator,
          meals: [...dataAccumulator.meals, { ...currentMeal, included: true }],
          mealQtyOrdered: dataAccumulator.mealQtyOrdered + currentMeal.qty
        }
      }

      const extras = currentMeal.qty - remainingMealsToOrder
      // Add some items to meals and the remaining to addons
      return {
        ...dataAccumulator,
        meals: [
          ...dataAccumulator.meals,
          { ...currentMeal, qty: currentMeal.qty - extras, included: true }
        ],
        addons: [
          ...dataAccumulator.addons,
          { ...currentMeal, qty: extras, extra: true }
        ],
        mealQtyOrdered:
          dataAccumulator.mealQtyOrdered + currentMeal.qty - extras
      }
    },
    { meals: [], addons: [], mealQtyOrdered: 0 }
  )
}

export const groupOrderItemsForFlexibleSegment = orderWithStock => {
  return orderWithStock.reduce(
    (dataAccumulator, currentMeal) => {
      if (isAddOn(currentMeal)) {
        return {
          ...dataAccumulator,
          addons: [...dataAccumulator.addons, { ...currentMeal, extra: true }]
        }
      }

      return {
        ...dataAccumulator,
        meals: [...dataAccumulator.meals, { ...currentMeal, included: true }],
        mealQtyOrdered: dataAccumulator.mealQtyOrdered + currentMeal.qty
      }
    },
    { meals: [], addons: [], mealQtyOrdered: 0 }
  )
}
