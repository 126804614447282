import { createSelector } from 'reselect'

import {
  REACTIVATE_SUBSCRIPTION_INIT,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE
} from '../actions/types'

const initialState = {
  inFlight: false,
  succeeded: false,
  error: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REACTIVATE_SUBSCRIPTION_INIT:
      return {
        ...state,
        inFlight: true,
        succeeded: false,
        error: null
      }
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        inFlight: false,
        succeeded: true,
        error: null
      }
    case REACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        inFlight: false,
        succeeded: false,
        error: action.error
      }
    default:
      return state
  }
}

export const getReactivation = state => state.reactivation
export const getReactivating = createSelector(
  getReactivation,
  reactivation => reactivation.inFlight
)
