import './SwitchConfirmChangePlanModal.scss'

import { CuiButton, CuiModal } from 'front-lib'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwitchConfirmChangePlanModalTracking } from './useSwitchConfirmChangePlanModalTracking'

interface Props {
  showSwitchConfirmChangePlanModal: boolean
  setShowSwitchConfirmChangePlanModal: (
    showSwitchConfirmChangePlanModal: boolean
  ) => void
  onSwitchBackPlan: () => void
}

export const SwitchConfirmChangePlanModalDesktop: FC<Props> = ({
  showSwitchConfirmChangePlanModal,
  setShowSwitchConfirmChangePlanModal,
  onSwitchBackPlan
}) => {
  const { t } = useTranslation()
  const {
    handleClose,
    handleConfirm
  } = useSwitchConfirmChangePlanModalTracking(
    setShowSwitchConfirmChangePlanModal,
    onSwitchBackPlan
  )

  return (
    <CuiModal
      isOpen={showSwitchConfirmChangePlanModal}
      onRequestClose={handleClose}
      className="flex-mode__switch-modal__modal-content"
      iconClassName="flex-mode__switch-modal__close-icon">
      <div className="flex-mode__switch-modal__container">
        <div className="flex-mode__switch-modal__title-container">
          <h1 className="flex-mode__switch-modal__title">
            {t(
              `pages.my_plan.flex_mode.modal_switch.are_you_sure_you_want_to_switch`
            )}
          </h1>
        </div>
        <div className="flex-mode__switch-modal__text-container">
          <p className="flex-mode__switch-modal__text">
            {t('pages.my_plan.flex_mode.modal_switch.by_switching.desktop')}
          </p>
        </div>
        <div className="flex-mode__switch-modal__buttons-container">
          <CuiButton fill="outline" onClick={handleClose}>
            {t('pages.my_plan.flex_mode.modal_switch.cancel')}
          </CuiButton>
          <CuiButton fill="solid" color="primary" onClick={handleConfirm}>
            {t('pages.my_plan.flex_mode.modal_switch.yes_lets_do_it')}
          </CuiButton>
        </div>
      </div>
    </CuiModal>
  )
}
