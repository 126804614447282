import { calculateMealCategory, formatNumber } from 'src/utils/utils'
import { MEALS_CATEGORY } from 'src/constants/menu'
const CHEF_IMG_URL_SOURCE = process.env.REACT_APP_CHEF_IMG_URL_SOURCE

export const splitMealsAndAddons = products => {
  const meals = []
  const addons = []

  products.forEach(product => {
    if (calculateMealCategory(product) === MEALS_CATEGORY) {
      meals.push(product)
    } else {
      addons.push(product)
    }
  })

  return [meals, addons]
}

export const mapMeals = meal => {
  return {
    ...meal,
    id: meal.magento_id,
    mealName: `${meal.title ? meal.title : ''}`.trim(),
    mealDescription: `${meal.subtitle ? meal.subtitle : ''}`.trim(),
    name: `${meal.title ? meal.title : ''} 
    ${meal.subtitle ? meal.subtitle : ''}`.trim(),
    image: meal.imageUrl,
    chefName: `${meal.chef.firstname ? meal.chef.firstname : ''} 
    ${meal.chef.lastname ? meal.chef.lastname : ''}`.trim(),
    chefId: meal.chef.id,
    chefImage: CHEF_IMG_URL_SOURCE + meal.bannerpic,
    isCelebrity: meal.is_celebrity_chef,
    quantity: meal.qty,
    meatType: meal.meat_type,
    tags: meal.calories ? [{ label: `${meal.calories} cal` }] : [],
    total_price: `+ $${formatNumber(
      (+meal.price + +meal.premium_fee) * meal.qty
    )}`,
    extra_fee: `+ $${formatNumber(+meal.premium_fee * meal.qty)}`,
    menu_category_id: meal.menu_category_id,
    menu_category_label: meal.menu_category_label,
    user_rating: meal.user_rating
  }
}

export const mapMealsForMembershipRefetch = orderWithStock => {
  const mappedOrderWithStock = orderWithStock.map(mapMeals)
  const [meals, addons] = splitMealsAndAddons(mappedOrderWithStock)
  const items = [...meals, ...addons]
  return items.map(meal => {
    return {
      entityId: meal.entity_id,
      quantity: meal.qty,
      inventoryId: meal.inventoryId
    }
  })
}
