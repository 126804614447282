import { useState, useEffect } from 'react'
import classnames from 'classnames'
import './ChefAvatar.scss'

const ChefAvatar = ({
  image,
  fallbackImage,
  name,
  onClick,
  className,
  size = 'medium',
  inlineStyles = {}
}) => {
  const defaultImage =
    'https://cu-media.imgix.net/media/chefs/main-photos/main/chef-not-found.png?w=30&fm=webp'
  const [src, setSrc] = useState(defaultImage)

  useEffect(() => {
    const checkImage = (src, onSuccess, onError) => {
      const img = new Image()
      img.src = src
      img.onload = onSuccess
      img.onerror = onError
    }

    checkImage(
      image,
      () => setSrc(image),
      () =>
        checkImage(
          fallbackImage,
          () => setSrc(fallbackImage),
          () => setSrc(defaultImage)
        )
    )
  }, [image, fallbackImage])

  return (
    <div
      className={classnames(className, 'chef-avatar', `chef-avatar--${size}`)}
      onClick={onClick}
      style={inlineStyles}
      role="button">
      <img
        src={src}
        alt={name}
        className={`chef-avatar__img chef-avatar__img--${size}`}
      />
      {!!name && (
        <div className={`chef-avatar__name chef-avatar__name--${size}`}>
          {name}
        </div>
      )}
    </div>
  )
}

export { ChefAvatar }
