import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { parse, stringify } from 'qs'
import Loader from 'src/common/Loader'
import { useEffect } from 'react'

export function RedirectToLogin() {
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()
  const { errorMessage, cuToken, ...search } = parse(location.search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}?${stringify(search)}`
      },
      errorMessage
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loader />
}
