import { handleActions } from 'redux-actions'
import * as actions from './actions'

const initialState = {
  resurrectionCampaign: null,
  featuredMeals: null
}

export default handleActions(
  {
    [actions.getResurrectionCampaignStart]: state => {
      return {
        ...state,
        resurrectionCampaign: {
          inFlight: true,
          succeeded: false,
          data: null
        }
      }
    },
    [actions.getResurrectionCampaignSuccess]: (state, { payload }) => {
      return {
        ...state,
        resurrectionCampaign: {
          inFlight: false,
          succeeded: true,
          data: payload
        }
      }
    },
    [actions.getResurrectionCampaignFail]: (state, { payload }) => {
      return {
        ...state,
        resurrectionCampaign: {
          inFlight: false,
          succeeded: false,
          error: payload.err
        }
      }
    },
    [actions.getFeaturedMealsStart]: state => {
      return {
        ...state,
        featuredMeals: {
          inFlight: true,
          succeeded: false,
          data: null
        }
      }
    },
    [actions.getFeaturedMealsSuccess]: (state, { payload }) => {
      return {
        ...state,
        featuredMeals: {
          inFlight: false,
          succeeded: true,
          data: payload
        }
      }
    },
    [actions.getFeaturedMealsFail]: (state, { payload }) => {
      return {
        ...state,
        featuredMeals: {
          inFlight: false,
          succeeded: false,
          error: payload.err
        }
      }
    }
  },
  initialState
)
