import './ReturnToLastRegularPlan.scss'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { SettingSection } from '../../../../components/SettingsCard'
import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'
import { formatNumber } from '../../../../utils/utils'
import { ConfirmationModal } from './ConfirmationModal'
import { toaster } from 'src/utils/toaster'
import { useReturnToLastRegularPlanMutation } from '../../../../api/plan/returnToLastRegularPlanMutation'
import { initApiStore } from 'src/redux-api-bridge'

export function ReturnToLastRegularPlan() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)
  const price = subscriptionPlan?.price ?? ''
  const planPrice = formatNumber(price)
  const [reloadUserData, setReloadUserData] = useState(false)
  const {
    returnToLastRegularPlan,
    rest: { loading }
  } = useReturnToLastRegularPlanMutation()

  function handleOpenConfirmationModal() {
    setShowConfirmationModal(true)
  }

  function handleCloseConfirmationModal() {
    setShowConfirmationModal(false)
  }

  useEffect(() => {
    if (reloadUserData) {
      dispatch(initApiStore())
      setReloadUserData(false)
    }
  }, [reloadUserData, dispatch])

  async function handleUpgradePlan() {
    try {
      await returnToLastRegularPlan()
      toaster.success(
        t('pages.my_plan.return_to_last_regular.action_success'),
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true
        }
      )
      setShowConfirmationModal(false)
      setReloadUserData(true)
    } catch (error) {
      const message = `${t(
        'pages.my_plan.return_to_last_regular.action_error'
      )} ${error}`
      toaster.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true
      })
    }
  }

  return (
    <>
      {showConfirmationModal && (
        <ConfirmationModal
          onCloseHandler={handleCloseConfirmationModal}
          onConfirmHandler={handleUpgradePlan}
          isOpen={showConfirmationModal}
          inRequest={loading}
        />
      )}

      <SettingSection
        className="cookunity__my_plan_section upgrade_to_standard_section"
        title={
          <span className="title_new_plan_meals">
            {t('pages.my_plan.return_to_last_regular.title')}
          </span>
        }
        withActionButton={true}
        buttonLabel={t('pages.my_plan.return_to_last_regular.cta')}
        onActionClick={handleOpenConfirmationModal}
        subtitle={
          <div className="upgrade_to_standard_subtitle">
            <p className="">
              <span className="advice">
                {t('pages.my_plan.return_to_last_regular.advice', {
                  planPrice
                })}
              </span>{' '}
              /&nbsp;
              <span className="note">
                {t('pages.my_plan.return_to_last_regular.note')}
              </span>
            </p>
          </div>
        }></SettingSection>
    </>
  )
}
