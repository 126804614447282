import { gql, useMutation } from '@apollo/client'

const START_MEMBERSHIP = gql`
  mutation startMembership($membershipId: Int!) {
    startMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

export function useStartMembership({ onCompleted, onError }) {
  const [mutate, rest] = useMutation(START_MEMBERSHIP, {
    refetchQueries: ['UserMembershipActivation'],
    onCompleted,
    onError
  })

  return {
    startMembership: variables => mutate({ variables }),
    ...rest
  }
}
