import { CuiButton } from 'front-lib'
import './MembershipConfirmation.scss'

interface Props {
  onClose: () => void
}

export function MembershipConfirmation({ onClose }: Props) {
  return (
    <div className="membership-confirmation">
      <div className="unity-pass-banner" />
      <div className="title-container">
        <span className="title">All set!</span>
        <span className="title">Delicious perks are coming your way.</span>
        <span className="subtitle">
          Start enjoying Unity Pass benefits on your next order.
        </span>
      </div>
      <div className="confirmation-buttons">
        <CuiButton onClick={onClose}>Let's go!</CuiButton>
      </div>
    </div>
  )
}
