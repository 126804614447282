import { useCallback } from 'react'
import {
  closeRatingProductForm,
  openRatingProductForm,
  submitRatingProductForm,
  updateRatingProductForm
} from 'src/redux-api-bridge/ratings/actions'
import tracking from 'src/shared/tracking'
import { RatingProductFormAttributes } from 'src/redux-api-bridge/ratings/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { getRatingProductForm } from 'src/redux-api-bridge/ratings/selectors'
import { sendRateProduct } from 'src/redux-api-bridge/ratings/effects'
import { getRatingTabSelector } from 'src/redux-api-bridge/orders-swimlanes/selectors'

export const useRatingProduct = (id, title) => {
  const dispatch = useDispatch()
  const ratingProductForm = useSelector(getRatingProductForm)
  const ratingTab = useSelector(getRatingTabSelector)

  const openRatingProductModal = useCallback(
    rating => {
      dispatch(openRatingProductForm({ rating, productId: id }))
      tracking.track('Rating Detail Modal - Open Modal', {
        rating: ratingProductForm[RatingProductFormAttributes.RATING],
        reasons: ratingProductForm[RatingProductFormAttributes.REASONS],
        review: ratingProductForm[RatingProductFormAttributes.REVIEW],
        product_id: id,
        product_name: title
      })
    },
    [dispatch, id, title, ratingProductForm]
  )

  const closeRatingProductModal = useCallback(() => {
    dispatch(closeRatingProductForm())
    tracking.track('Rating Detail Modal - Close Modal', {
      rating: ratingProductForm[RatingProductFormAttributes.RATING],
      reasons: ratingProductForm[RatingProductFormAttributes.REASONS],
      review: ratingProductForm[RatingProductFormAttributes.REVIEW],
      product_id: id,
      product_name: title
    })
  }, [dispatch, id, title, ratingProductForm])

  const updateRatingProductModal = useCallback(
    (
      attributeName: RatingProductFormAttributes,
      newValue: number | string | string[]
    ) => {
      dispatch(updateRatingProductForm({ attributeName, newValue }))
      tracking.track('Rating Detail Modal - Field updated', {
        product_id: id,
        product_name: title,
        updated_attribute_name: attributeName,
        updated_attribute_value: newValue
      })
    },
    [dispatch, id, title]
  )

  const submitRatingProductModal = useCallback(() => {
    if (ratingProductForm[RatingProductFormAttributes.REASONS].length === 0) {
      dispatch(submitRatingProductForm())
    } else if (
      ratingTab?.props?.orderId &&
      ratingProductForm[RatingProductFormAttributes.RATING]
    ) {
      dispatch(
        sendRateProduct(
          ratingTab.props.orderId,
          id,
          title,
          ratingProductForm[RatingProductFormAttributes.RATING],
          ratingProductForm[RatingProductFormAttributes.REASONS],
          ratingProductForm[RatingProductFormAttributes.REVIEW]
        )
      )
    } else {
      console.error(
        'Rating Tab - Order id and score are required to submit a rating'
      )
    }

    tracking.track('Rating Detail Modal - Submit Button Clicked', {
      rating: ratingProductForm[RatingProductFormAttributes.RATING],
      reasons: ratingProductForm[RatingProductFormAttributes.REASONS],
      review: ratingProductForm[RatingProductFormAttributes.REVIEW],
      product_id: id,
      product_name: title
    })
  }, [dispatch, ratingTab?.props?.orderId, id, title, ratingProductForm])

  return {
    openRatingProductModal,
    closeRatingProductModal,
    updateRatingProductModal,
    submitRatingProductModal
  }
}
