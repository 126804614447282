export const PAUSE_DEFLECTION_WEEKS = [2, 3]
export const PAUSE_DEFLECTION_REASON = 'Deflection modal ignored'

export const PAUSE_PLAN_TARGET_MEAL_QTY = 6

export const DEFAULT_PAUSE_OPTIONS = [
  { label: '1 week', value: 1 },
  { label: '2 weeks', value: 2 },
  { label: '3 weeks', value: 3 },
  { label: '4 weeks', value: 4 },
  { label: '5 weeks', value: 5 },
  { label: '6 weeks', value: 6 },
  { label: '7 weeks', value: 7 },
  { label: '8 weeks', value: 8 }
]

export function getPauseDeflectionChangePlanTitle({ planMealsQty }) {
  if (planMealsQty > PAUSE_PLAN_TARGET_MEAL_QTY) {
    return 'pauseDeflection.changePlan.downgrade_plan_title'
  }
  return 'pauseDeflection.changePlan.title'
}
