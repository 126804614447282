import React from 'react'
import classnames from 'classnames'

const Label = ({ strong, inline, className, ...rest }) => (
  <span
    className={classnames(
      'cookunity__form_label',
      { strong, inline },
      className
    )}
    {...rest}
  />
)

export default Label
