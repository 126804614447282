import React from 'react'
import moment from 'moment'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CuiButton } from 'front-lib'
import { formatFixedPrice } from 'src/utils/utils'
import { useGetCreditsResume } from 'src/api/creditsAndPromotionsPage/getCreditsResumeQuery'
import { reactivateSubscriptionInit } from 'src/shared/actions/reactivation'

import { CreditTable } from './CreditTable'
import Header from 'src/components/Header/Header'
import './ResurrectionCreditsLandingPage.scss'

export function ResurrectionCreditsLandingPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    creditsResume,
    loading: loadingGetCreditsResume
  } = useGetCreditsResume()

  const amount =
    creditsResume?.creditsAvailable === 0 ? 15 : creditsResume?.creditsAvailable

  const credit = {
    amount: Number(amount),
    source: 'Pending Credit',
    expirationDate: moment()
      .add(2, 'weeks')
      .format('YYYY-MM-DD')
  }

  const handleResubscribe = async () => {
    try {
      dispatch(
        reactivateSubscriptionInit({
          coupon: 'RES-35X6'
        })
      )
      history.push('/nextMenu')
    } catch (error) {
      console.error('Error reactivating subscription:', error)
    }
  }

  return (
    <div className="page__container resurrection-landing">
      <Header />
      <main className="page__main page__horizontal-space page__vertical-space">
        <div className="headline">
          <div className="titles">
            <h1 className="title">Credits &amp; Promotions</h1>
            <p className="subtitle">
              Easily keep track of your available promotions and credits below.
              <Link to="/how-to-use-credits" className="anchor">
                How do I use them?
              </Link>
            </p>
          </div>
          <div className="resume">
            <div className="row">
              <div className="cell">
                <span className="label">Credits available:</span>
                <span className="bold">
                  {loadingGetCreditsResume ? '...' : formatFixedPrice(amount)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="outline-tabs">
          <NavLink
            to="/resurrection-35x6"
            className="outline-tabs__tab"
            activeClassName="active">
            Credits
          </NavLink>
        </div>

        <CreditTable
          credit={{
            amount: Number(credit.amount),
            source: credit.source,
            expirationDate: credit.expirationDate
          }}
        />

        <div className="message">
          <h2 className="title">
            Resubscribe to access your credit, and as a bonus <br /> we will
            give you 35% off your next 6 weeks
          </h2>
          <CuiButton
            onClick={handleResubscribe}
            label="Resubscribe now"
            size="large"
            color="dark"
            data-testid="resubscribe-button">
            Resubscribe now
          </CuiButton>
        </div>
      </main>
    </div>
  )
}
