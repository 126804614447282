export const isResurrectionCampaignLoading = state => {
  return state?.resurrection?.resurrectionCampaign?.inFlight || false
}

export const getResurrectionCampaignSelector = state => {
  const campaign = state?.resurrection?.resurrectionCampaign
  if (campaign?.data?.enabled) {
    return campaign.data
  }
  return null
}

export const getFeaturedMealsSelector = state => {
  return state?.resurrection?.featuredMeals
}
