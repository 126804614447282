import { CuiModal } from 'front-lib'
import { useTranslation, Trans } from 'react-i18next'
import { useEffect } from 'react'
import tracking from 'src/shared/tracking'

const commonPropertiesToTrack = {
  overlay_name: 'Switch To Standard Info',
  overlay_class: 'modal',
  overlay_source: 'custom',
  overlay_type: 'custom',
  page_name: 'My Plan Page',
  overlay_trigger: 'manual'
}

export function InformationModal({ onCloseHandler, isOpen }) {
  const { t } = useTranslation()
  const modalContent = (
    <div className="information-modal__content">
      <h2 className="title">
        {t('pages.my_plan.value_plan.information_modal.title')}
      </h2>
      <div className="content">
        <span>{t('pages.my_plan.value_plan.information_modal.content')}</span>
      </div>
      <div className="contact">
        <Trans
          i18nKey="pages.my_plan.value_plan.information_modal.contact"
          components={{ bold: <strong /> }}
        />
      </div>
    </div>
  )
  useEffect(() => {
    if (isOpen) {
      tracking.track('Overlay Viewed', commonPropertiesToTrack)
    }
  }, [isOpen])

  function onClose() {
    tracking.track('Overlay Closed', commonPropertiesToTrack)
    onCloseHandler()
  }

  return (
    <CuiModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="economic-plan-forever-menu-modal__desktop">
      {modalContent}
    </CuiModal>
  )
}
