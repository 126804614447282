import { gql, useQuery } from '@apollo/client'

export const GET_LAST_SKIPS = gql`
  query lastSkips($user: Int, $fromLastDays: Int) {
    lastSkips(user: $user, fromLastDays: $fromLastDays) {
      id
      date
      isPaused
    }
  }
`

export function useGetLastSkips() {
  const { data, ...rest } = useQuery<{
    lastSkips: { date: string; isPaused: boolean }[]
  }>(GET_LAST_SKIPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    variables: {
      user: null,
      fromLastDays: 0
    }
  })

  return { lastSkips: data?.lastSkips || [], ...rest }
}
