import React from 'react'
import { ErrorMessage } from 'formik'

import './index.scss'

const CustomErrorMessage = props => (
  <ErrorMessage {...props} className="cookunity__form_error_message" />
)

export default CustomErrorMessage
