import React from 'react'
import { CuiButton } from 'front-lib'

import Modal from '../../../../components/NewModal'
import './DeletePartner.scss'

const DeletePartner = props => {
  const { isOpen, onRequestClose, removeProfile } = props

  return (
    <Modal mediumSize isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="cookunity__delete_partner_modal">
        <h2 className="title">
          Are you sure you want to delete your Partner’s Preferences?
        </h2>
        <div className="button_row">
          <CuiButton onClick={removeProfile}>
            Delete Partner’s Preferences
          </CuiButton>
          <CuiButton fill="clear" onClick={onRequestClose}>
            Cancel
          </CuiButton>
        </div>
      </div>
    </Modal>
  )
}

export default DeletePartner
