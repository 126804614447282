import React from 'react'
import { ComponentProps } from 'src/types/component'
import './Carrousel.scss'

export enum CarrouselType {
  MEAL_RATING = 'mealRating'
}

export enum Layout {
  LIST = 'LIST',
  GRID = 'GRID'
}

export enum Orientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL'
}

interface CarrouselProps extends ComponentProps {
  subType: CarrouselType
  layout: Layout
  orientation: Orientation
}

export const Carrousel: React.FC<CarrouselProps> = ({ children }) => {
  return <div className="carrousel">{children}</div>
}
