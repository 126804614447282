import { gql, useQuery } from '@apollo/client'

export const GET_CREDITS_RESUME_QUERY = gql`
  query getCreditsResume {
    creditsAndPromotionsPageCreditsResume {
      creditsAvailable
      creditsPendingDebit
      currentCredit
    }
  }
`
export function useGetCreditsResume() {
  const { data, ...rest } = useQuery<{
    creditsAndPromotionsPageCreditsResume: {
      creditsAvailable: number
      creditsPendingDebit: number
      currentCredit: number
    }
  }>(GET_CREDITS_RESUME_QUERY, {
    notifyOnNetworkStatusChange: true
  })

  return {
    creditsResume: data?.creditsAndPromotionsPageCreditsResume,
    ...rest
  }
}
