import React from 'react'
import {
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { ComponentProps } from 'src/types/component'
import './TitleBar.scss'

export interface TitleBarProps extends ComponentProps {
  title: string
  subTitle: string
}

export const TitleBar: React.FC<TitleBarProps> = ({ title, subTitle }) => {
  const isMobile = useMobileDevice()

  return (
    <div className="title-bar">
      <Text
        value={title}
        style={isMobile ? TextStyle.H3 : TextStyle.H1}
        className="title"
      />
      <Text
        value={subTitle}
        style={isMobile ? TextStyle.SMALL_BOLD : TextStyle.MEDIUM_REGULAR}
        className="subtitle"
      />
    </div>
  )
}
