import { Membership } from 'src/types/membership'
import { getDiscountInfo } from '../utils'
import { getUserFirstNameSelector } from 'src/components/Header/selectors'
import { useSelector } from 'react-redux'

/**
 * Types
 */

interface Props {
  membership: Membership
}

/**
 * ResurrectedBody component
 */

export const ResurrectedBody = ({ membership }: Props) => {
  const userName = useSelector(getUserFirstNameSelector)
  const { discountRate, finalCost, fullCost } = getDiscountInfo(membership)

  return (
    <>
      <div className="title-container">
        <span className="title">
          Welcome, {userName}! I’m giving you
          <br />
          <strong>{discountRate}% OFF</strong> to try Unity Pass{' '}
          <strong>as an additional to your current discount!</strong>
        </span>
      </div>
      <div className="description-container">
        <span className="description">
          Experience the best of CookUnity with Unity Pass — enjoy free
          delivery, exclusive discounts, and three premium meals per order at no
          extra cost. Only ${finalCost} your first month, then ${fullCost}
          /month after.
        </span>
      </div>
    </>
  )
}
