import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactDOM from 'react-dom'
import './Toaster.scss'

export const Toaster = () => {
  return ReactDOM.createPortal(
    <ToastContainer transition={Slide} position="top-center" />,
    document.getElementById('toast-container')
  )
}
