import { CuiButton } from 'front-lib'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import {
  Membership,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'
import { getDiscountInfo } from '../UnityPass/utils'

interface Props {
  onContinue: (step: Step) => void
  onClose: () => void
  membership?: Membership
}

export function DefaultFooter({ onContinue, onClose, membership }: Props) {
  const { trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })

  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.joinNow })
    onContinue(MEMBERSHIP_MODAL_STEPS.checkout)
  }

  const getTitle = () => {
    const { fullCost, finalCost } = getDiscountInfo(membership)
    return (
      <>
        ALL THIS FOR {fullCost !== finalCost && <s>{`$${fullCost} / MO`}</s>}
        {` $${finalCost} / MO`}
      </>
    )
  }

  const getSubtitle = () => {
    const discountRate = membership?.discount?.rate
    if (!discountRate || discountRate < 0.5) {
      return 'PAYS FOR ITSELF IN 2 ORDERS!'
    }

    if (discountRate === 1) {
      return 'UNITY PASS PAYS FOR IT'
    }
    return 'PAYS FOR ITSELF IN 1 ORDER!'
  }

  return (
    <div className="footer-container">
      <span className="footer-header">{getTitle()}</span>
      <span className="footer-subheader">{getSubtitle()}</span>
      <div className="button-container">
        <CuiButton className="secondary-button" onClick={() => onClose()}>
          No thanks
        </CuiButton>
        <CuiButton className="primary-button" onClick={handleContinue}>
          Join now
        </CuiButton>
      </div>
    </div>
  )
}
