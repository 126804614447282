import { CuiIcon } from 'front-lib'
import './ProgressBar.scss'

interface Props {
  progress?: number
  icon?: string
}

const ProgressBar: React.FC<Props> = ({ progress = 0, icon }) => {
  return (
    <div className="container_progress_bar">
      <div className="content_progres_bar">
        <span
          className="progress-bar"
          style={{
            opacity: 1,
            width: `${progress}%`
          }}>
          {icon && <CuiIcon name={icon} className={'progress-bar--icon'} />}
        </span>
      </div>
    </div>
  )
}

export default ProgressBar
