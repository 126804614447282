export function ProgressBadge({ percent }: { percent: number }) {
  return (
    <div className="challenge-resume--progress">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="64"
          viewBox="0 0 34 64"
          fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.84248 6.50847C1.40364 6.50847 0.643851 6.5194 0.210938 6.54096V0.0405462C0.751643 0.0136139 1.29561 0 1.84248 0C19.5858 0 33.9999 14.3102 33.9999 32C33.9999 48.5964 21.3126 62.218 5.09189 63.8386C3.30352 64.0172 1.84248 62.543 1.84248 60.7458C1.84248 58.9485 3.30686 57.5131 5.09014 57.2893C17.735 55.7023 27.4915 44.9709 27.4915 32C27.4915 17.9382 16.0248 6.50847 1.84248 6.50847Z"
            fill="url(#paint0_radial_11004_2528)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_11004_2528"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(4.12182 61.8305) rotate(-90) scale(60.2034 40.6651)">
              <stop stopColor="#AFFFDB" />
              <stop offset="0.666667" stopColor="#35D28A" />
              <stop offset="1" stopColor="#00874D" />
            </radialGradient>
          </defs>
        </svg>
        {percent}% <span>complete</span>
      </div>
    </div>
  )
}
