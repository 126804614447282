const size = {
  mobile: '414px',
  tablet: '768px',
  notebook: '1440px',
  notebookMid: '1280px'
}
export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  notebook: `(max-width: ${size.notebook})`,
  notebookMid: `(max-width: ${size.notebook})`
}
