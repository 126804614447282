import * as actions from './actions'

export const toggleSchedulePanel = (
  mode,
  isOpen,
  selectedDate,
  timeslot,
  cutOff,
  newTimeslot
) => dispatch => {
  dispatch(
    actions.toggleSchedulePanel({
      mode,
      selectedDate,
      timeslot,
      cutOff,
      newTimeslot,
      isOpen
    })
  )
}
