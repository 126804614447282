import moment from 'moment'

import { mealsSelectedCountSelector } from 'src/components/DeliveryCard/selectors'
import { UpcomingDelivery } from 'src/types/upcomingDelivery'

import { getUpcomingDeliveries } from '../MyDeliveries/selectors'
import { AppState } from 'src/types/appState'

/**
 * 1. Look for first order with RECOMMENDATIONS
 * 2. If there is none, Look for first AVAILABLE order (menu available, status empty or partial, not confirmed, not in delivery)
 * 3. If there is none, look for first SKIP
 * 4. If there is none, go to CURRENT WEEK order
 *
 * For order status conditions, see src/components/DeliveryCard/selectors.ts#copyStatusSelector
 */
export function nextOrderDeliveryDateIndexSelector(state) {
  const upcomingDays: UpcomingDelivery[] = getUpcomingDeliveries(state)

  if (!upcomingDays || !upcomingDays.length) {
    return undefined
  }

  const nextRecommendationIndex = upcomingDays.findIndex(
    day => day.recommendation && day.canEdit
  )

  if (nextRecommendationIndex >= 0) {
    return nextRecommendationIndex
  }

  const nextOrderIndex = upcomingDays.findIndex((day, index) => {
    const shipping = moment().isSameOrAfter(day.date)
    const confirmed = !!(day.order && !day.canEdit)
    const skipped = day.skip || day.isPaused
    const mealsCount = mealsSelectedCountSelector(state, day.displayDate)
    const mealsPerDelivery = state.api?.subscription?.userPlan?.mealsPerDelivery
    const empty = mealsCount === 0
    const partial = mealsCount > 0 && mealsCount < mealsPerDelivery
    const emptyOrPartial = empty || partial
    const menuAvailable = !!day.menuAvailable

    return (
      menuAvailable && emptyOrPartial && !confirmed && !skipped && !shipping
    )
  })

  if (nextOrderIndex >= 0) {
    return nextOrderIndex
  }

  const nextSkipIndex = upcomingDays.findIndex(day => day.skip || day.isPaused)

  if (nextSkipIndex >= 0) {
    return nextSkipIndex
  }

  return null
}

export function nextOrderDeliverySelector(
  state: AppState
): UpcomingDelivery | null {
  const index = nextOrderDeliveryDateIndexSelector(state)

  if (index === null) {
    return null
  }

  return getUpcomingDeliveries(state)[index]
}
