import { useMobileDevice } from '../../hooks/useMobileDevice'

const path = process.env.REACT_APP_IMG_URL_SOURCE

export function generateChefImageUrl({
  photo,
  firstName,
  lastName,
  isMobile,
  isCloseUp
}) {
  if (photo) {
    // TODO: Improve this to handle different image size.
    return `${path}/${photo}`
  }
  const chefName = getChefName({ firstName, lastName })
  const screen = isMobile ? 'mobile' : 'desktop'
  if (!chefName) {
    return getPlaceHolder(isCloseUp, screen)
  }

  const resolution = isMobile ? '152' : '1280'
  return isCloseUp
    ? `${path}/media/chefs/close-up/${chefName}-closeup-1080.jpg`
    : `${path}/media/chefs/main-photos/${resolution}/${chefName}-chefmain-${resolution}.jpg`
}

function getChefName({ firstName, lastName }) {
  try {
    // For CU logo, we are receiving the last name as " ",
    // so we need to consider this edge case
    if (sanitize(firstName).includes('cookunity')) {
      return 'cookunity'
    }

    if (firstName && lastName) {
      return `${sanitize(firstName)}-${sanitize(lastName)}`
    }

    return ''
  } catch (e) {
    return ''
  }
}

function sanitize(name) {
  if (!name) {
    return ''
  }

  return name
    .toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll('®', 'r')
    .replaceAll('ö', 'o')
}

function getPlaceHolder(closeUp, screen) {
  return closeUp
    ? `${path}/media/chefs/close-up/placeholder/placeholder-closeup-1080.jpg`
    : `${path}/media/chefs/${screen}/rebranding-default.jpg`
}

/**
 *
 * @param {{photo, firstName, lastName,isCloseUp}} props
 * @returns
 */
export function useChefImage(props) {
  const isMobile = useMobileDevice()
  return generateChefImageUrl({ ...props, isMobile })
}
