import { useSelector } from 'react-redux'
import { useGetUserDeliveries } from 'src/api/DeliveryDay/useGetUserDeliveries'
import { getUpcomingDeliveries } from 'src/modules/MyDeliveries/selectors'

export function useGetUserDelivery({ shippingDate }) {
  const deliveryDays = useSelector(getUpcomingDeliveries)

  const { userDeliveries, ...rest } = useGetUserDeliveries({
    shippingDates: deliveryDays.map(delivery => delivery.date)
  })

  return {
    delivery: userDeliveries.find(
      delivery => delivery.shippingDate === shippingDate
    ),
    ...rest
  }
}
