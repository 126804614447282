export const PERCENT = 'percent'
export const ABSOLUT_AMOUNT = 'absoluteAmount'

export enum DiscountType {
  Percent = 'percent',
  AbsoluteAmount = 'absoluteAmount'
}

export interface Discount {
  type: DiscountType
  rate: number
}

export interface CompoundPromotion extends Discount {
  orderCount: number
}

export interface CompoundPromotionUses {
  deliveryDate: string | null
  orderCount: number
}

export interface Promotion {
  title: string
  origin: string
  promotionType: PromotionType
  discount: Discount
  compoundPromotions: CompoundPromotion[]
  compoundPromotionUses: CompoundPromotionUses[]
  orderCount: number | null
}
export interface PromotionsByDate {
  promotions: Promotion[]
}

export type PromotionType =
  | 'discountPromotion'
  | 'deliveryFeePromotion'
  | 'premiumMealPromotions'
  | 'referralByPlanPromotion'
  | 'vipCreditPromotion'
  | 'lowPricePromotion'
  | 'deliveryAndPlanB2B'
  | 'discountAndFreeShipping'
  | 'compoundPromotion'
  | 'creditPromotion'
  | 'sidePromotion'
  | 'productDiscountPromotion'
  | 'categoryDiscountPromotion'
