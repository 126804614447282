import React, { useCallback, useState } from 'react'
import { TextArea } from 'src/components/TextArea/TextArea'
import './FullTextArea.scss'
import {
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string
  maxCharacter?: number
  showMaxCharacter?: boolean
}

export const FullTextArea: React.FC<TextAreaProps> = ({
  onChange,
  maxCharacter,
  showMaxCharacter = false,
  ...props
}) => {
  const [text, setText] = useState(props.value)

  const onChangeFn = useCallback(
    event => {
      setText(event.target.value)
      onChange && onChange(event)
    },
    [setText, onChange]
  )

  const textarea = (
    <TextArea
      onChange={onChangeFn}
      {...props}
      maxLength={maxCharacter}
      value={text}
    />
  )

  if (showMaxCharacter) {
    return (
      <>
        {textarea}
        <Text
          className="full-text-area__counter"
          value={`${text.length} / ${maxCharacter}`}
          style={TextStyle.XXS_BOLD}
        />
      </>
    )
  }

  return textarea
}
