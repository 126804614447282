import { useState } from 'react'
import { SaveChangesModal } from './SaveChangesModal'
import { SAVE_MODAL_ACTIONS } from '../../utils'

const useConfirm = () => {
  const [open, setOpen] = useState(false)
  const [resolver, setResolver] = useState({ resolver: null })

  const createPromise = () => {
    let resolver
    return [
      new Promise(resolve => {
        resolver = resolve
      }),
      resolver
    ]
  }

  const getConfirmationAction = async () => {
    setOpen(true)
    const [promise, resolve] = await createPromise()
    setResolver({ resolve })
    return promise
  }

  const onClick = async action => {
    setOpen(false)
    resolver.resolve(action)
  }

  const ConfirmationPrompt = () => (
    <SaveChangesModal
      isOpen={open}
      onSave={() => onClick(SAVE_MODAL_ACTIONS.SAVE)}
      onClose={() => onClick(SAVE_MODAL_ACTIONS.CLOSE)}
      onProceed={() => onClick(SAVE_MODAL_ACTIONS.PROCEED)}
    />
  )

  return { getConfirmationAction, ConfirmationPrompt }
}

export default useConfirm
