import './ComparePlansModal.scss'

import { CuiModal } from 'front-lib'
import { FC, useEffect } from 'react'
import { FlexModePlan } from './FlexModePlan'
import { WeeklyPlan } from './WeeklyPlan'
import { getPlatformText } from 'src/utils/utils'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { useTranslation } from 'react-i18next'
import tracking from 'src/shared/tracking'

interface Props {
  showComparePlansModal: boolean
  setShowComparePlansModal: (showComparePlansModal: boolean) => void
  onSwitchBackPlan: () => void
}

export const ComparePlansModal: FC<Props> = ({
  showComparePlansModal,
  setShowComparePlansModal,
  onSwitchBackPlan
}) => {
  const { t } = useTranslation()
  const isMobile = useMobileDevice()
  const platform = getPlatformText(isMobile)

  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: 'Flex mode - Comparison',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Switch to Weekly Plan',
      overlay_action: null
    })
  }, [])

  const handleClose = (): void => {
    tracking.track('Overlay Closed', {
      overlay_name: 'Flex mode - Comparison',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Switch to Weekly Plan',
      overlay_action: null
    })

    setShowComparePlansModal(false)
  }

  const handleConfirm = (): void => {
    handleClose()

    tracking.track('Overlay Clicked', {
      overlay_name: 'Flex mode - Comparison',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '1/2',
      overlay_options: 'Switch to Weekly Plan',
      overlay_action: 'Switch to Weekly Plan'
    })

    onSwitchBackPlan()
  }

  return (
    <CuiModal
      isOpen={showComparePlansModal}
      onRequestClose={handleClose}
      className="flex-mode__compare-modal__modal-content"
      iconClassName="flex-mode__compare-modal__close-icon">
      <div className="flex-mode__compare-modal__container">
        <div className="flex-mode__compare-modal__header-container">
          <h1 className="flex-mode__compare-modal__title">
            {t(
              `pages.my_plan.flex_mode.modal_compare.flex_mode_vs_weekly_plan.${platform}`
            )}
          </h1>
          <p className="flex-mode__compare-modal__description">
            {t('pages.my_plan.flex_mode.modal_compare.flex_mode_offers')}
          </p>
        </div>
        <div className="flex-mode__compare-modal__plans-container">
          {isMobile ? (
            <>
              <WeeklyPlan handleConfirm={handleConfirm} />
              <FlexModePlan />
            </>
          ) : (
            <>
              <FlexModePlan />
              <WeeklyPlan handleConfirm={handleConfirm} />
            </>
          )}
        </div>
      </div>
    </CuiModal>
  )
}
