import { useSelector } from 'react-redux'
import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'

export function useEconomicPlanPriceLabel() {
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)

  if (subscriptionPlan?.segmentId === 4) {
    return '$8'
  }

  if ([6, 11].includes(subscriptionPlan?.segmentId)) {
    return '$8.50'
  }

  if ([8, 12].includes(subscriptionPlan?.segmentId)) {
    if (subscriptionPlan?.mealsPerDelivery === 4) {
      return '$9'
    }
    return '$8.50'
  }

  return '?'
}
