import { CuiButton, CuiLink } from 'front-lib'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from 'src/hooks/useIsMobile'
import { useCancelDeflection } from 'src/hooks/useCancelDeflection'

import './CancelSubscription.scss'

export function CancelSubscription({ isButton = false, pageName, disabled }) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { handleCancelSubscription } = useCancelDeflection({
    pageName
  })

  function handleCancel() {
    if (!window.Brightback || disabled) {
      return
    }
    handleCancelSubscription()
  }

  return (
    <div className="cancel_subscription">
      {isButton ? (
        <CuiButton
          size="large"
          color="dark"
          fill="outline"
          disabled={disabled}
          onClick={handleCancel}>
          {t('pages.my_plan.cancelSubscriptionButton')}
        </CuiButton>
      ) : (
        <div className="action_link">
          <CuiLink
            color="primary"
            fill="clear"
            showUnderline={!isMobile}
            icon={null}
            size="medium"
            disabled={disabled}
            type="button"
            onClick={handleCancel}>
            {t('pages.my_plan.cancelSubscriptionButton')}
          </CuiLink>
        </div>
      )}
    </div>
  )
}
