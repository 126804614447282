import * as cartClient from '../../api/cart'
import { getMealPrices } from 'src/api/sduiService'
import * as orderActions from './actions'
import * as actions from 'src/redux-api-bridge/cart/actions'
import { getActiveOrderDate, shouldSaveToCart } from './selectors'
import {
  getUnifiedCarts,
  isUnifiedCartOperationInFlight
} from '../cart/selectors'
import {
  addProductsToUnifiedCartForCartId,
  removeProductsFromUnifiedCartForCartId
} from '../cart/effects'

export const syncProductsFromUnifiedCart = (
  products,
  unifiedCart
) => dispatch => {
  dispatch(
    orderActions.syncProducts({
      menuMeals: products,
      cartProducts: unifiedCart.products
    })
  )
}

export const addProduct = (product, quantity = 1, useUnifiedCart = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const orderDate = getActiveOrderDate(state)
  const entity_id = product.entity_id
  const batch_id = product.batch_id
  const inventory_id = product.inventoryId
  product.source = null

  if (useUnifiedCart) {
    // THIS VALIDATION AVOIDS MULTIPLE ADDS
    const anotherOpInCourse = isUnifiedCartOperationInFlight(state)
    if (anotherOpInCourse) return

    const carts = getUnifiedCarts(state)
    const cart = carts[orderDate]
    addProductToUnifiedCart(cart, product, quantity, dispatch).then(
      unifiedCart => {
        dispatch(
          orderActions.syncProducts({
            menuMeals: [
              ...(state.menu.menu.meals || []),
              ...(state.menu.menu.bundles || [])
            ],
            cartProducts: unifiedCart.products
          })
        )
      }
    )
    return
  }

  if (shouldSaveToCart(state)) {
    cartClient
      .addProductToCart({
        orderDate,
        batch_id,
        quantity,
        inventory_id
      })
      .catch(error => {
        console.error(
          `Error adding product to cart: [orderDate: ${orderDate}] [entity_id: ${entity_id}] [batch_id: ${batch_id}] [quantity: ${quantity}]`,
          error
        )
      })
      .then(response => {
        if (response) {
          // response.data.addMeal is an array of cart products
          dispatch(
            orderActions.syncProducts({
              menuMeals: [
                ...(state.menu.menu.meals || []),
                ...(state.menu.menu.bundles || [])
              ],
              cartProducts: response?.data?.addMeal
            })
          )

          const productInCart = response?.data?.addMeal?.find(
            p => p.inventoryId === product.inventoryId
          )
          dispatch(
            actions.addProductToCartSucceed({
              date: orderDate,
              product: {
                ...product,
                ...productInCart,
                quantity: productInCart.qty
              }
            })
          )
        }
      })
  } else {
    dispatch(
      actions.addProductToCartSucceed({
        date: orderDate,
        product: { ...product, quantity }
      })
    )
    dispatch(orderActions.addProduct({ product, quantity }))
  }
}

export const addProductFromPage = (id, quantity, useUnifiedCart) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { categories } = state.menu.menu

  const products = categories.reduce(
    (meals, category) => [
      ...meals,
      ...category.meals,
      ...(category.personalized
        ? category.personalized.reduce(
            (meals, profile) => [...meals, ...profile.meals],
            []
          )
        : [])
    ],
    []
  )
  const product = products.find(
    p => p.inventoryId === id || p.entity_id === id || p.id === id
  )
  dispatch(addProduct(product, quantity, useUnifiedCart))
}

export const removeProduct = (product, quantity = 1, useUnifiedCart) => (
  dispatch,
  getState
) => {
  const state = getState()
  const orderDate = getActiveOrderDate(state)
  const entity_id = product.entity_id
  const withRelatedMeal = product.withRelatedMeal
  const inventory_id = product.inventoryId

  if (useUnifiedCart) {
    const carts = getUnifiedCarts(state)
    const cart = carts[orderDate]
    removeProductsFromUnifiedCart(cart, [{ ...product, quantity }], dispatch)
      .then(unifiedCart => {
        dispatch(
          orderActions.syncProducts({
            menuMeals: [
              ...(state.menu.menu.meals || []),
              ...(state.menu.menu.bundles || [])
            ],
            cartProducts: unifiedCart.products
          })
        )
      })
      .catch(error => {
        console.error(
          `Error emptying unified cart: [orderDate: ${orderDate}]`,
          error
        )
      })

    return
  }

  if (shouldSaveToCart(state)) {
    cartClient
      .removeProductFromCart({
        orderDate,
        quantity,
        inventory_id,
        withRelatedMeal
      })
      .catch(error => {
        console.error(
          `Error removing product from cart: [orderDate: ${orderDate}] [entity_id: ${entity_id}] [quantity: ${quantity}]`,
          error
        )
      })
      .then(response => {
        if (response) {
          // response.data.deleteMeal is an array of cart products
          dispatch(
            orderActions.syncProducts({
              menuMeals: [
                ...(state.menu.menu.meals || []),
                ...(state.menu.menu.bundles || [])
              ],
              cartProducts: response?.data?.deleteMeal
            })
          )

          const productInCart = response?.data?.deleteMeal?.find(
            p => p.inventoryId === product.inventoryId
          )
          dispatch(
            actions.removeProductFromCartSucceed({
              date: orderDate,
              product: productInCart,
              inventoryId: product.inventoryId
            })
          )
        }
      })
  } else {
    dispatch(orderActions.removeProduct({ product, quantity }))
  }
}

export const clearOrder = useUnifiedCart => async (dispatch, getState) => {
  const state = getState()
  const orderDate = getActiveOrderDate(state)

  if (useUnifiedCart) {
    const carts = getUnifiedCarts(state)
    const cart = carts[orderDate]

    removeProductsFromUnifiedCart(cart, cart.products, dispatch)
      .then(unifiedCart => {
        dispatch(
          orderActions.syncProducts({
            menuMeals: [
              ...(state.menu.menu.meals || []),
              ...(state.menu.menu.bundles || [])
            ],
            cartProducts: unifiedCart.products
          })
        )
      })
      .catch(error => {
        console.error(
          `Error emptying unified cart: [orderDate: ${orderDate}]`,
          error
        )
      })

    return
  }
  cartClient.emptyCart(orderDate).catch(error => {
    console.error(`Error emptying cart: [orderDate: ${orderDate}]`, error)
  })
  dispatch(
    actions.clearCartSucceed({
      date: orderDate
    })
  )
  dispatch(orderActions.clearOrder())
}

export const fetchOrderDetail = meals => async (dispatch, getState) => {
  const state = getState()
  const date = getActiveOrderDate(state)

  try {
    const response = await getMealPrices(date, meals)
    dispatch(orderActions.getOrderDetail(response.getOrderDetail))
  } catch (e) {
    dispatch(orderActions.getOrderDetail(e))
  }
}

const addProductToUnifiedCart = async (cart, product, quantity, dispatch) => {
  const cartId = cart.cartId
  return await addProductsToUnifiedCartForCartId(
    cartId,
    [{ inventory_id: product.inventoryId, quantity }],
    dispatch
  )
}

const removeProductsFromUnifiedCart = (cart, products, dispatch) => {
  const cartId = cart.cartId
  return removeProductsFromUnifiedCartForCartId(cartId, products, dispatch)
}
