import gqlClient from '../gqlClient'
import menuService from '../menuService'

/**
 * Get quick filters
 */
export const getQuickFilters = () =>
  gqlClient.publicQuery(
    `
    query quickFilters {
        quickFilters {
            filters {
                id
                label
                pattern
                image
            }
        }
    }
  `
  )

/**
 * Get already requested meals
 */
export const getUserAlreadyRequestedMeals = date =>
  menuService.query(
    `
    query getMenu($date: String!, $filters: MenuFilters!) {
      menu(date: $date, filters: $filters) {
        categories{
          id
          title
          subtitle
          label
          tag
          coverInfo {
              title
              text 
              image
              imageMobile
          }
      }
      meals {
        id
        batchId
        name
        shortDescription
        image
        imagePath
        primaryImageUrl
        price
        finalPrice
        premiumFee
        premiumSpecial
        sku
        source
        stock
        isNewMeal
        isBackInTheMenu
        isMealRestaurantExclusive
        hasRecipe
        userRating
        searchBy {
          cuisines
          chefFirstName
          chefLastName
          dietTags
          ingredients
          mealName
          merchandisingFilters
          preferences
          proteinTags
        }
        ingredients {
          id
          name
          value
        }
        showInBundlesOnly
        warning
        warnings {
          message
          restrictionsApplied {
            name
          }
          dietsNotMatching {
            name
          }
          allergensNotMatching {
            name
          }
        }
        dietsMatching {
          name
        }
        reviews
        stars
        qty
        categoryId
        category{
            id
            title
            label
        }
        chef{
            id
            firstName
            lastName
            bannerPic
            profileImageUrl
        }
        meatType
        meatCategory
        nutritionalFacts{
            calories
            fat
            carbs
            sodium
            fiber
        }
        specificationsDetails{
            label
        }
        sideDish{
            id
            name
        }
        feature {
          name
          description
          icon
          color
          background
        }
        weight
        filter_by
        cuisines
        sidesSubCategoryNames
        media {
          secondaryImage
        }
        categories {
          label
          id
        }
        relatedMeal {
          id
          categoryId
          name
          shortDescription
          image
          imagePath
          price
          premiumFee
          premiumSpecial
          sku
          stock
          isNewMeal
          userRating
          warning
          batchId
        }
        typesTags
        tags {
          type
          name
        }
        inventoryId
        promotions {
          amount {
            value
            type
          }
          type
          constraints {
            categoryId
            subCategoryId
            capAmount {
              type
              value
            }
          }
        }
      }
      filters{
          selectedChefs
      }
      sorting {
        type
        sort
        sortedMealBundles
      }
    }
  }
`,
    { date, filters: { onlyAlreadyRequested: true } },
    true
  )
