import { AutopilotTabQuestion } from 'src/types/preferences'
import { AutopilotQuestionComponentByType } from 'src/pages/preferences/utils'
import { AutopilotFormData } from 'src/pages/preferences/PreferencesPage'

export type AutopilotQuestionProps = AutopilotTabQuestion & {
  autopilotFormData: AutopilotFormData
  onUpdate: Function
  onQuestionClicked: Function
  isDisabled: boolean
}

const AutopilotQuestion = ({
  type,
  title,
  subtitle,
  info,
  question,
  clarification,
  allowMultipleSelection,
  showOptInOptions,
  options,
  userAttribute,
  autopilotFormData,
  onUpdate,
  onQuestionClicked,
  isDisabled
}: AutopilotQuestionProps) => {
  const TypedQuestion = AutopilotQuestionComponentByType[type]

  return (
    <TypedQuestion
      title={title}
      subtitle={subtitle}
      info={info}
      question={question}
      clarification={clarification}
      allowMultipleSelection={allowMultipleSelection}
      showOptInOptions={showOptInOptions}
      options={options}
      userAttribute={userAttribute}
      onUpdate={onUpdate}
      onQuestionClicked={onQuestionClicked}
      autopilotFormData={autopilotFormData}
      isDisabled={isDisabled}
    />
  )
}

export default AutopilotQuestion
