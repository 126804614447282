import { CuiIcon } from 'front-lib'
import { useTranslation } from 'react-i18next'

export function MenuItem({ icon, label }) {
  const { t } = useTranslation()

  return (
    <>
      <CuiIcon name={icon} /> {t(label)}
    </>
  )
}
