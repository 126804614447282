import { FC, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { CuiButton } from 'front-lib'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside'
import './ComparePlansTourModal.scss'
import { TOUR_HIGHLIGHT_TARGET_SELECTOR_COMPARE_PLANS } from './constants'
import { useComparePlansTourModalTrackingTracking } from './ComparePlansTourModalTracking'
import { useTranslation } from 'react-i18next'
import { COMPARE_PLANS_LOCALSTORAGE_KEY } from 'src/modules/Modals/constants'

interface Props {
  handleCloseComparePlansTourStepModal: () => void
}

export function useShouldShowComparePlansHighligthModal() {
  const [isViewed] = useLocalStorage(COMPARE_PLANS_LOCALSTORAGE_KEY, false)
  return !isViewed
}

export const ComparePlansTourModal: FC<Props> = ({
  handleCloseComparePlansTourStepModal
}) => {
  const clickOutsideRef = useRef(null)
  const { t } = useTranslation()

  useOnClickOutside(clickOutsideRef, () => {
    handleClose()
  })

  useEffect(() => {
    const targets = document.getElementsByClassName(
      TOUR_HIGHLIGHT_TARGET_SELECTOR_COMPARE_PLANS
    )
    if (!targets.length) {
      console.warn(
        'Modal shown but no highlight target found, lets try with requestFrame or MutationObserver'
      )
      return
    }
    const target = targets[0]
    target.classList.add('compare-plans__tour-highlight')
    return () => {
      target.classList.remove('compare-plans__tour-highlight')
    }
  }, [])

  const {
    handleCloseTracking,
    handleContinueTracking
  } = useComparePlansTourModalTrackingTracking()

  function handleClose() {
    handleCloseTracking()
    handleCloseComparePlansTourStepModal()
  }

  function handleContinue() {
    handleContinueTracking()
    handleCloseComparePlansTourStepModal()
  }

  return (
    <ReactModal
      isOpen
      className="compare-plans-tour-step-modal"
      overlayClassName="compare-plans-tour-step-modal__overlay"
      portalClassName="compare-plans-tour-step-modal"
      onRequestClose={handleClose}>
      <div className="content-wrapper">
        <div className="content" ref={clickOutsideRef}>
          <div className="title">
            {t(
              'pages.my_plan.flex_mode.modal_compare_plans_tour.flex_vs_weekly'
            )}
          </div>
          <div className="description">
            {t(
              'pages.my_plan.flex_mode.modal_compare_plans_tour.plan_comparison'
            )}
          </div>
          <div className="cta-container">
            <CuiButton onClick={handleContinue}>
              {t('pages.my_plan.flex_mode.modal_compare_plans_tour.continue')}
            </CuiButton>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
