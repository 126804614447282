import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { getPreferencesOptionsSelector } from 'src/redux-api-bridge/preferences/selectors'
import './DietSelector.scss'
import { FormItemHeader } from '../FormItemHeader/FormItemHeader'
import { DIETS_ICONS_MAPPING } from 'src/utils/iconsMapping'

const DietSelector = ({ profileDiets, selectDiet }) => {
  const preferencesOptions = useSelector(getPreferencesOptionsSelector)
  return (
    <div className="preferences-diets">
      <FormItemHeader
        title={preferencesOptions?.diets?.title}
        subtitle={preferencesOptions?.diets?.subtitle}
        info={preferencesOptions?.diets?.info}
      />
      <div className="preferences-diets__list">
        {preferencesOptions?.diets?.items.map(diet => (
          <div
            key={diet.id}
            role="button"
            onClick={() => selectDiet(diet)}
            className={classnames('preferences-diets__item', {
              selected: profileDiets?.find(d => d.id === diet.id)
            })}>
            {DIETS_ICONS_MAPPING[diet.label.toLowerCase()]}
            <div>
              <div>{diet.label}</div>
              <div className="preferences-diets__item-description">
                {diet.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export { DietSelector }
