import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Field } from 'formik'

import './index.scss'
import './css/Field.scss'

// TODO: this should be refactored
const CustomField = ({ children, ...props }) => {
  const { type, checked, onClick } = props
  const fieldClassName = classnames('cookunity__input_field', props.className)

  if (type === 'radio') {
    return (
      <Fragment>
        <div className="cookunity__input_radio_container">
          <div
            className={classnames('cookunity__input_radio', { checked })}
            onClick={onClick}>
            <div className="checkmark" />
          </div>
        </div>
        <Field {...props} className={fieldClassName} />
      </Fragment>
    )
  }

  if (type === 'checkbox') {
    return (
      <div className="cui-checkbox">
        <Field {...props} />
        <span className="cui-checkbox-label">{children}</span>
      </div>
    )
  }

  return (
    <Field
      {...props}
      className="cui-input cui-input-white cui-input-small cui-input-white-solid"
    />
  )
}

export default CustomField
