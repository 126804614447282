import { useMemo, useEffect } from 'react'
import { Formik } from 'formik'
import { CuiButton, CuiSelect } from 'front-lib'
import { useTranslation } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import Form, { ButtonRow, Col, Grid, Label } from 'src/components/Form'
import { DEFAULT_PAUSE_OPTIONS } from '../utils'
import { getDevice, getDeviceOS } from 'src/utils/utils'
import { FF_PAUSE_PLAN_REMOVE_ONE_WEEK } from 'src/constants/feature-flags'
import { usePausePlanDeflection } from 'src/hooks/usePausePlanDeflection'
import tracking from 'src/shared/tracking'

export function PausePlanForm({ onCancel }) {
  const { t } = useTranslation()
  const removeFirstWeekFlag = useFlag(FF_PAUSE_PLAN_REMOVE_ONE_WEEK)
  const { handleOnPause } = usePausePlanDeflection()

  useEffect(() => {
    tracking.track('Pause Modal', {
      action: 'Open Modal',
      overlay_name: 'Manage Your Order - Pause',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      page_name: 'My Plan',
      device: getDevice(),
      device_os: getDeviceOS()
    })
  }, [])

  const pauseOptions = useMemo(() => {
    if (removeFirstWeekFlag) {
      return DEFAULT_PAUSE_OPTIONS.slice(1)
    }
    return DEFAULT_PAUSE_OPTIONS
  }, [removeFirstWeekFlag])

  function onSubmit(values) {
    const weeks = values.duration.value
    handleOnPause({ weeks })
    onCancel()
  }

  return (
    <Formik
      initialValues={{
        duration: pauseOptions[0]
      }}
      onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue, isValid }) => (
        <Form className="">
          <h3 className="subtitle">{t('pages.my_plan.pause_plan')}</h3>
          <Label strong={undefined} inline={undefined} className="">
            {t('pages.my_plan.select_a_duration')}
          </Label>
          <Grid>
            <Col>
              <CuiSelect
                value={values.duration}
                isDisabled={isSubmitting}
                options={pauseOptions}
                onChange={selection => {
                  setFieldValue('duration', selection, true)
                }}
              />
            </Col>
          </Grid>
          <ButtonRow className="">
            <CuiButton type="submit" disabled={isSubmitting || !isValid}>
              {t('pages.my_plan.pause')}
            </CuiButton>
            <CuiButton fill="outline" onClick={onCancel}>
              {t('pages.my_plan.cancel')}
            </CuiButton>
          </ButtonRow>
        </Form>
      )}
    </Formik>
  )
}
