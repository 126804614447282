import React from 'react'
import {
  updateBasedOnNoPreferenceOption,
  mapSelectedOptions,
  TypedAutopilotQuestionProps
} from 'src/pages/preferences/utils'
import { DropdownSelector } from 'src/pages/preferences/components/DropdownSelector/DropdownSelector'

import './ProductBasedQuestion.scss'

const ProductsBasedQuestion = ({
  title,
  subtitle,
  info,
  question,
  clarification,
  allowMultipleSelection,
  showOptInOptions,
  options,
  userAttribute,
  autopilotFormData,
  onUpdate,
  onQuestionClicked,
  isDisabled
}: TypedAutopilotQuestionProps) => {
  const onChange = selectedOptions => {
    const newValue = allowMultipleSelection
      ? updateBasedOnNoPreferenceOption(selectedOptions)
      : selectedOptions?.id || null
    return onUpdate(userAttribute, newValue)
  }

  return (
    <div className="product-based-question">
      <DropdownSelector
        title={title}
        subtitle={subtitle}
        info={info}
        items={options}
        selected={mapSelectedOptions(options, autopilotFormData[userAttribute])}
        isNoPreferenceSelected={autopilotFormData[userAttribute] === null}
        disabled={
          isDisabled ||
          (showOptInOptions && autopilotFormData[userAttribute] === null)
        }
        onChange={onChange}
        onNoPreferenceSelection={onUpdate}
        name={userAttribute}
        withSearchInput={false}
        onInputClicked={onQuestionClicked}
        showOptInOptions={showOptInOptions}
        question={question}
        clarification={clarification}
        allowMultipleSelection={allowMultipleSelection}
        placeholder="Select an option"
        withYesNoPlaceholder={!showOptInOptions}
      />
    </div>
  )
}

export default ProductsBasedQuestion
