import { gql, useQuery } from '@apollo/client'

export const GET_USER_DATA_BEFORE_CANCEL_QUERY = gql`
  query {
    weeksSinceFirstDeliveryWhileActive
    countOrdersCompleted
  }
`

export function useGetUserDataBeforeCancel() {
  return useQuery(GET_USER_DATA_BEFORE_CANCEL_QUERY, {
    fetchPolicy: 'cache-first'
  })
}
