import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import { useBrightback } from './useBrightback'
import { toaster } from 'src/utils/toaster'
import Analytics from 'src/utils/analytics'

export function useCancelSubscription() {
  const { variables } = useBrightback()

  const user = useSelector(state => pathOr({}, ['api', 'user', 'info'], state))

  async function cancelSubscription({ pageName }) {
    try {
      const res = await window.Brightback.handleDataPromise(variables)
      if (res.valid) {
        Analytics.track('Cancel Plan', {
          email: user.email,
          first_name: user.firstname,
          last_name: user.lastname,
          cancel: true,
          page_name: pageName
        })
        window.location.href = res.url
      }
    } catch (error) {
      console.log(error)
      toaster.error('Oops! Something went wrong')
    }
  }

  return { cancelSubscription }
}
