import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { mealsAreEquals } from 'src/utils/utils'

export const getMenu = state => pathOr([], ['menu', 'menu'], state)
export const getMenuDate = state => pathOr(null, ['menu', 'date'], state)
export const getCollections = state =>
  pathOr([], ['merchandiseSets', 'collections', 'merchandiseSets'], state)

export const isPrefetchLoading = state =>
  pathOr(false, ['menu', 'prefetchInFlight'], state)

export const menuMeals = state =>
  pathOr(false, ['menu', 'menu', 'meals'], state)

export const getMealById = (state, mealId) =>
  createSelector(
    menuMeals,
    meals =>
      meals &&
      meals.find(
        meal =>
          mealsAreEquals(meal, { id: mealId }) ||
          mealsAreEquals(meal, { inventoryId: mealId })
      )
  )(state)

export const menuBundles = state =>
  pathOr(false, ['menu', 'menu', 'bundles'], state)

export const menuMealsAndBundles = createSelector(
  menuMeals,
  menuBundles,
  (meals, bundles) => {
    if (!meals || !bundles) return []
    return [...meals, ...bundles]
  }
)

export const isCrossSellingModalOpen = state =>
  pathOr(false, ['menu', 'isCrossSellingModalOpen'], state)

export const getCategories = state =>
  pathOr([], ['menu', 'menu', 'categories'], state)

export const getInitialScrollTo = state =>
  pathOr(null, ['menu', 'initialScrollTo'], state)

export const getFullMenuMealInitialPosition = state =>
  pathOr(null, ['menu', 'fullMenuMealInitialPosition'], state)

export const getMenuViewMode = state =>
  pathOr(null, ['menu', 'viewMode'], state)

export const getCrossSellingState = state =>
  pathOr(null, ['menu', 'crossSelling'], state)

export const getCrossSellingSeen = state =>
  pathOr(null, ['menu', 'crossSellingModalSeen'], state)

export const getMenuPromotions = state =>
  pathOr(null, ['menu', 'menu', 'promotions'], state)

export const getYouHaveTriedLoading = state =>
  pathOr(null, ['menu', 'alreadyRequestedMeals', 'inFlight'], state)

export const getSearchByValues = state =>
  pathOr([], ['menu', 'menu', 'searchByValues'], state)

export const getMenuSearch = state => pathOr([], ['menu', 'searchView'], state)

export const mealsSearchRelatedResults = createSelector(
  menuMeals,
  getMenuSearch,
  (meals, search) => {
    if (meals && search?.suggestions?.suggestedMeals) {
      return search.suggestions.suggestedMeals
        .map(suggestedMealInventoryId =>
          meals.find(meal =>
            mealsAreEquals(meal, { inventoryId: suggestedMealInventoryId })
          )
        )
        .filter(meal => !!meal)
    }
    return []
  }
)

export const mealsSearchExactMatchResults = createSelector(
  menuMeals,
  getMenuSearch,
  (meals, search) => {
    if (meals && search?.suggestions?.exactMatchMeals) {
      return search.suggestions.exactMatchMeals
        .map(matchedInventoryId =>
          meals.find(meal =>
            mealsAreEquals(meal, { inventoryId: matchedInventoryId })
          )
        )
        .filter(meal => !!meal)
    }
    return []
  }
)

export const getFreePremiumMealsState = state =>
  pathOr([], ['menu', 'freePremiumMeals'], state)

export const getIsActiveFreePremiumMeals = state =>
  pathOr(false, ['menu', 'freePremiumMeals', 'isActive'], state)

export const getFreePremiumMealsAvailableQuantity = state =>
  pathOr(0, ['menu', 'freePremiumMeals', 'amountToChoose'], state)

export const getAddedFreePremiumMeals = state =>
  pathOr([], ['menu', 'freePremiumMeals', 'addedMeals'], state)

export const addedAllFreePremiumMeals = createSelector(
  getFreePremiumMealsState,
  getFreePremiumMealsState => {
    const totalFreePremiumMealsAdded = getFreePremiumMealsState.addedMeals.reduce(
      (prev, curr) => prev + curr.quantity,
      0
    )
    return (
      getFreePremiumMealsState.isActive &&
      getFreePremiumMealsState.amountToChoose === totalFreePremiumMealsAdded
    )
  }
)

export const getAvailableFreePremiumMeals = createSelector(
  getFreePremiumMealsState,
  freePremiumMeals => {
    return freePremiumMeals.availableMeals
      .map(availableMeal => {
        const freePremiumQuantity =
          freePremiumMeals.addedMeals.find(addedMeal =>
            mealsAreEquals(addedMeal, availableMeal)
          )?.quantity ?? 0
        return {
          ...availableMeal,
          quantity: freePremiumQuantity,
          isFreePremiumMeal: true
        }
      })
      .filter(meal => meal.stock > 0)
  }
)
