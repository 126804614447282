import { createAction } from 'redux-actions'

export const rateProductStart = createAction('RATE_PRODUCT_START')
export const rateProductSucceed = createAction('RATE_PRODUCT_SUCCEED')
export const rateProductFailed = createAction('RATE_PRODUCT_FAILED')

export const openRatingProductForm = createAction('OPEN_RATING_PRODUCT_FORM')
export const updateRatingProductForm = createAction(
  'UPDATE_RATING_PRODUCT_FORM'
)
export const closeRatingProductForm = createAction('CLOSE_RATING_PRODUCT_FORM')
export const submitRatingProductForm = createAction(
  'SUBMIT_RATING_PRODUCT_FORM'
)
