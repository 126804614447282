import './Footer.scss'

import React from 'react'

import AppStoreLogo from '../../assets/img/AppStore.png'
import GooglePlayLogo from '../../assets/img/googlePlayLogo.png'
import { HashLink as Link } from 'react-router-hash-link'
import moment from 'moment'
import { openChat } from '../../utils/kustomer'
import tracking from '../../shared/tracking'
import { useEmbedded } from '../../embedded'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  isEconomicPlanSelector,
  isFlexPlanSelector
} from 'src/redux-api-bridge/selectors'

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

const Footer = () => {
  const { isAppEmbedded } = useEmbedded()
  const isEligibleForReferral = useSelector(isEconomicPlanSelector)
  const isFlexPlan = useSelector(isFlexPlanSelector)
  const { t } = useTranslation()

  if (isAppEmbedded) {
    return null
  }

  return (
    <footer>
      <div className="row">
        <div className="column">
          <div className="flex-start">
            <div className="column-footer">
              <ul className="ul-footer">
                <li>
                  <a target="_new" href={`${BASE_URL}/how-it-works`}>
                    {t('footerLinks.howItWorks')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/why-cookunity`}>
                    {t('footerLinks.whyCookunity')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/terms`}>
                    {t('footerLinks.terms')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/privacy-policy`}>
                    {t('footerLinks.privacyPolicy')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/careers`}>
                    {t('footerLinks.careers')}
                  </a>
                </li>
                <div className="mobile-p">
                  {!isEligibleForReferral && !isFlexPlan && (
                    <li>
                      <Link
                        target="_new"
                        to="/referral"
                        onClick={() => {
                          tracking.track('Referral Link', {
                            category: 'Referral',
                            action: 'click on footer'
                          })
                        }}>
                        {t('footerLinks.referFriend')}
                      </Link>
                    </li>
                  )}
                  <li>
                    <a target="_new" href={`${BASE_URL}/delivery-map`}>
                      {t('footerLinks.deliveryMap')}
                    </a>
                  </li>
                </div>
              </ul>
            </div>
            <div className="column-footer column-none">
              <ul className="ul-footer">
                {!isEligibleForReferral && !isFlexPlan && (
                  <li>
                    <Link
                      target="_new"
                      to="/referral"
                      onClick={() => {
                        tracking.track('Referral Link', {
                          category: 'Referral',
                          action: 'click on footer'
                        })
                      }}>
                      {t('footerLinks.referFriend')}
                    </Link>
                  </li>
                )}
                <li>
                  <a target="_new" href={`${BASE_URL}/become-a-chef`}>
                    {t('footerLinks.becomeChef')}
                  </a>
                </li>
                <li>
                  <a
                    target="_new"
                    href="https://support.cookunity.com"
                    rel="noopener noreferrer">
                    FAQ
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/delivery-map`}>
                    {t('footerLinks.deliveryMap')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/gift-cards`}>
                    {t('footerLinks.giftCards')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="column-footer column-end">
              <ul className="ul-footer">
                <div className="column-mobile">
                  <li>
                    <a target="_new" href={`${BASE_URL}/become-a-chef`}>
                      {t('footerLinks.becomeChef')}
                    </a>
                  </li>
                  <li>
                    <a target="_new" href={`${BASE_URL}/gift-cards`}>
                      {t('footerLinks.giftCards')}
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      href="https://support.cookunity.com"
                      target="_new"
                      rel="noopener noreferrer">
                      {t('footerLinks.faq')}
                    </a>
                  </li>
                </div>
                <li>
                  <a target="_new" href={`${BASE_URL}/sustainability`}>
                    {' '}
                    {t('footerLinks.sustainability')}
                  </a>
                </li>
                <li>
                  <a target="_new" href={`${BASE_URL}/special-lines`}>
                    {t('footerLinks.specialLines')}
                  </a>
                </li>
                <li>
                  <span className="footer-link" onClick={openChat}>
                    {t('footerLinks.chat')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-row">
            <div className="row">
              <div className="column">
                <p>
                  &copy; {moment().format('Y')} {t('footerLinks.companyName')}
                </p>
              </div>
              <div className="column first-check-column">
                <p>
                  <span>{t('footerLinks.description.firstLine')}</span>
                  <i className="fa fa-check" />
                </p>
              </div>
              <div className="column">
                <p>
                  <span>{t('footerLinks.description.secondLine')}</span>
                  <i className="fa fa-check" />
                </p>
              </div>
              <div className="column">
                <p>
                  <span>{t('footerLinks.description.thirdLine')}</span>
                  <i className="fa fa-check" />
                </p>
              </div>
              <div className="column">
                <p>
                  <span>{t('footerLinks.description.fourthLine')}</span>
                  <i className="fa fa-check" />{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-end">
          <ul className="ul-footer row-mobile">
            <div>
              <li className="line-social">
                <a target="_new" href="https://instagram.com/cookunity">
                  {t('footerLinks.socialNetworks.instagram')}
                </a>
              </li>
              <li className="line-social">
                <a target="_new" href="https://twitter.com/Cookunity">
                  {t('footerLinks.socialNetworks.twitter')}
                </a>
              </li>
              <li className="line-social">
                <a target="_new" href="https://www.facebook.com/Cookunity.us">
                  {t('footerLinks.socialNetworks.facebook')}
                </a>
              </li>
              <li>
                <span className="mobile-p">
                  <p>
                    &copy; {moment().format('Y')} {t('footerLinks.companyName')}
                  </p>
                </span>
              </li>
            </div>
            <div>
              <li>
                <a target="_new" href="mailto:support@cookunity.com">
                  {t('footerLinks.email')}
                </a>
              </li>
              <li>
                <a
                  target="_new"
                  href="https://itunes.apple.com/us/app/cookunity/id1103061273?mt=8">
                  <img
                    alt="appstore"
                    className="cookunity-app-store"
                    src={AppStoreLogo}
                  />
                </a>
              </li>
              <li>
                <a
                  target="_new"
                  href="https://play.google.com/store/apps/details?id=com.cookunity.android.app">
                  <img
                    alt="google play logo"
                    className="cookunity-play-store"
                    src={GooglePlayLogo}
                  />
                </a>
              </li>
            </div>
          </ul>
          <ul className="ul-footer row-mobile">
            <li className="recaptcha-terms">
              <Trans
                i18nKey="footerLinks.recaptchaTerms"
                components={{
                  strong: <strong />,
                  privacyLink: (
                    <a
                      href="https://www.google.com/intl/en/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer">
                      {' '}
                    </a>
                  ),
                  termsLink: (
                    <a
                      href="https://www.google.com/intl/en/policies/terms/"
                      target="_blank"
                      rel="noopener noreferrer">
                      {' '}
                    </a>
                  )
                }}></Trans>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
