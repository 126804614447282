import { FC, useEffect, useState } from 'react'
import { CuiButton, CuiModal, CuiImage } from 'front-lib'
import { Link } from 'react-router-dom'
import { useIsMobile } from 'src/hooks/useIsMobile'
import HTFDesktop from 'src/assets/gifs/how-to-fav-desktop.gif'
import HTFMobile from 'src/assets/gifs/how-to-fav-mobile.gif'

interface Props {
  text: string
  to: string
  onClick: (text: string) => void
}

const HowToFavoriteModal: FC<Props> = ({
  to,
  text,
  onClick = () => {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState(false)
  const [modalCTA] = useState('Go to next order menu')
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      setImage(HTFMobile)
    } else {
      setImage(HTFDesktop)
    }
  }, [isMobile])

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    onClick(text)
  }

  return (
    <>
      <CuiButton onClick={handleClick} {...props}>
        {text}
      </CuiButton>
      <CuiModal
        className={'challenge-modal'}
        isOpen={isOpen}
        onRequestClose={handleClose}>
        <h2 className={'challenge-modal--title'}>
          Start saving meals you're interested in trying out in the coming
          weeks.
        </h2>
        <CuiImage
          src={image}
          alt={'how-to-favorite-meals'}
          className={'challenge-modal--img'}
        />
        <div className={'challenge-modal--btn'}>
          <Link to={to} onClick={() => onClick(modalCTA)}>
            <CuiButton>{modalCTA}</CuiButton>
          </Link>
        </div>
      </CuiModal>
    </>
  )
}

export default HowToFavoriteModal
