import { useSelector } from 'react-redux'
import {
  isEconomicPlanSelector,
  isFlexPlanSelector,
  isPrepaidPlanSelector
} from 'src/redux-api-bridge/selectors'
import { getMenuLinks } from './MenuLinks'
import CategoryLink from './CategoryLink'
import MenuLink from './MenuLink'
import { useFlag } from '@unleash/proxy-client-react'
import { FF_EATER_PROFILE_UI } from 'src/constants/feature-flags'
import { useMembershipWhitelist } from 'src/pages/membership/useMembershipWhitelist'
import { getMenuItem } from '../../utils'

const MenuSection = ({ onMenuTabClick }) => {
  const isFlexPlan = useSelector(isFlexPlanSelector)
  const isPrepaidPlan = useSelector(isPrepaidPlanSelector)
  const notEligibleToReferral = useSelector(isEconomicPlanSelector)
  const eaterProfileEnabled = useFlag(FF_EATER_PROFILE_UI)
  const isUserEligibleForMembership = useMembershipWhitelist()

  return (
    <div className="menu_section">
      {getMenuLinks(
        isUserEligibleForMembership,
        eaterProfileEnabled,
        isPrepaidPlan,
        isFlexPlan
      ).map(link => {
        if (link.pathname === '/referral' && notEligibleToReferral) {
          return null
        }

        if (link.links) {
          return (
            <CategoryLink
              key={link.label}
              category={link}
              onMenuTabClick={onMenuTabClick}
            />
          )
        }

        return (
          <MenuLink
            key={link.label}
            className={'menu_item'}
            to={link.pathname}
            url={link.url}
            onClick={event => onMenuTabClick(event, link)}>
            {getMenuItem(link.icon, link.label)}
          </MenuLink>
        )
      })}
    </div>
  )
}

export default MenuSection
