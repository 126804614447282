import { CuiBottomSheet, CuiModal, CuiButton } from 'front-lib'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import tracking from 'src/shared/tracking'

const commonPropertiesToTrack = {
  overlay_name: 'Return To Last Regular Plan From Box Plan',
  overlay_class: 'modal',
  overlay_source: 'custom',
  overlay_type: 'custom',
  page_name: 'My Plan Page',
  overlay_trigger: 'manual',
  overlay_options: ['Got it, confirm', 'Never mind']
}

export function ConfirmationModal({
  onCloseHandler,
  onConfirmHandler,
  isOpen,
  inRequest
}) {
  const { t } = useTranslation()
  const isMobile = useMobileDevice()

  function onCancel() {
    tracking.track('Overlay Clicked', {
      ...commonPropertiesToTrack,
      overlay_action: 'Never mind'
    })
    onCloseHandler()
  }

  function onClose() {
    tracking.track('Overlay Closed', commonPropertiesToTrack)
    onCloseHandler()
  }

  function onConfirm() {
    tracking.track('Overlay Clicked', {
      ...commonPropertiesToTrack,
      overlay_action: 'Got it, confirm'
    })
    onConfirmHandler()
  }

  useEffect(() => {
    if (isOpen) {
      tracking.track('Overlay Viewed', commonPropertiesToTrack)
    }
  }, [isOpen])

  const modalContent = (
    <div className="confirmation-modal__content">
      <h2 className="title">
        {t('pages.my_plan.return_to_last_regular.confirmation_modal.title')}
      </h2>
      <div className="content">
        <span>
          {t('pages.my_plan.return_to_last_regular.confirmation_modal.content')}
        </span>
      </div>
      <div className="action-buttons">
        <CuiButton size="large" onClick={onConfirm} disabled={inRequest}>
          {t('pages.my_plan.return_to_last_regular.confirmation_modal.confirm')}
        </CuiButton>
        <CuiButton
          fill="outline"
          size="large"
          onClick={onCancel}
          disabled={inRequest}>
          {t('pages.my_plan.return_to_last_regular.confirmation_modal.cancel')}
        </CuiButton>
      </div>
    </div>
  )
  if (isMobile) {
    return (
      <CuiBottomSheet className="modal__mobile" isOpen onRequestClose={onClose}>
        <CuiBottomSheet.Header onRequestClose={onClose}></CuiBottomSheet.Header>
        <CuiBottomSheet.Body>{modalContent}</CuiBottomSheet.Body>
      </CuiBottomSheet>
    )
  }
  return (
    <CuiModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal__desktop">
      {modalContent}
    </CuiModal>
  )
}
