import React from 'react'
import { Form } from 'formik'
import classnames from 'classnames'
import './index.scss'

const CustomForm = ({ className, ...props }) => {
  className = classnames('cookunity__my_account_form', className)
  return <Form className={className} {...props} />
}

export default CustomForm
