import { Membership } from 'src/types/membership'
import { getDiscountInfo } from '../utils'
import { getUserFirstNameSelector } from 'src/components/Header/selectors'
import { useSelector } from 'react-redux'

/**
 * Types
 */

interface Props {
  membership: Membership
}

/**
 * DefaultBody component
 */

export const DefaultBody = ({ membership }: Props) => {
  const userName = useSelector(getUserFirstNameSelector)
  const { discountRate, finalCost, fullCost } = getDiscountInfo(membership)
  const chefName = membership?.discount?.source

  return (
    <>
      <div className="title-container">
        <span className="title">
          Hi, {userName}! I’m unlocking an exclusive offer for you!
          <br />
          <strong>{discountRate}% OFF</strong> with{' '}
          <strong>Chef {chefName}</strong>
        </span>
      </div>
      <div className="description-container">
        <span className="description">
          As a special thank you for being one of my top customers, I’m excited
          to give you {discountRate}% OFF your first month of Unity Pass — enjoy
          free delivery, bigger Add & Save discounts, and three premium meals
          per order at no extra cost. Only ${finalCost} your first month, then $
          {fullCost}
          /month after.
        </span>
      </div>
    </>
  )
}
