import { createSelector } from 'reselect'

import {
  GET_SAMPLE_MENU_INIT,
  GET_SAMPLE_MENU_SUCCESS,
  GET_SAMPLE_MENU_FAILURE
} from '../actions/types'

const initialState = {
  loading: true,
  sampleMenu: [],
  error: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SAMPLE_MENU_INIT:
      return { ...state, loading: true }
    case GET_SAMPLE_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        sampleMenu: action.payload
      }
    case GET_SAMPLE_MENU_FAILURE:
      return { ...state, loading: false, error: { ...action.error } }
    default:
      return state
  }
}

const getMealsMenu = state => state.mealsMenu
export const getSampleMenuLoading = createSelector(
  getMealsMenu,
  mealsMenu => mealsMenu.loading
)
export const getSampleMenu = createSelector(
  getMealsMenu,
  mealsMenu => mealsMenu.sampleMenu
)
