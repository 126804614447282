import { Trans, useTranslation } from 'react-i18next'
import { Step } from 'src/components/Step/Step'
import { ChallengeState, ChallengeStepWithCopy } from 'src/types/challenge'

interface Props {
  stepsData: ChallengeStepWithCopy[]
  openStep: number
  handleSelectStep: (step: number) => void
  onClick: (ctaText: string) => void
}

const ChallengePanelContent: React.FC<Props> = ({
  stepsData,
  openStep,
  handleSelectStep,
  onClick
}) => {
  const { t } = useTranslation()
  return (
    <div className="cookunity__challenge_panel">
      {stepsData &&
        stepsData.map(step => {
          const {
            stepNumber,
            status,
            isLoading,
            TITLE,
            DESCRIPTION,
            CTA,
            REDIRECT,
            VALUES,
            ICON,
            Component
          } = step

          const buttonFill =
            status === ChallengeState.Completed ? 'outline' : 'solid'

          return (
            <Step
              key={stepNumber}
              index={stepNumber}
              status={status}
              title={t(TITLE)}
              icon={ICON}
              openStep={openStep}
              handleSelectStep={handleSelectStep}
              isLoading={isLoading}>
              {DESCRIPTION && (
                <>
                  <Trans
                    i18nKey={DESCRIPTION}
                    values={VALUES}
                    components={{
                      span: <span></span>,
                      div: <div></div>,
                      strong: <strong></strong>,
                      br: <br />
                    }}
                  />
                  {Component && (
                    <Component
                      to={t(REDIRECT, VALUES)}
                      text={t(CTA)}
                      fill={buttonFill}
                      size={'small'}
                      onClick={onClick}
                    />
                  )}
                </>
              )}
            </Step>
          )
        })}
    </div>
  )
}

export default ChallengePanelContent
