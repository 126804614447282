import { useTranslation } from 'react-i18next'
import { CuiButton } from 'front-lib'

import { ChallengeStepWithCopy } from 'src/types/challenge'

export function ChallengeIncentive({
  step,
  claimReward
}: {
  step: ChallengeStepWithCopy | null
  claimReward: (cta_text: string) => {}
}) {
  const { t } = useTranslation()

  return (
    <>
      {step && (
        <div>
          <div className="challenge-resume--title">{t(step.TITLE)}</div>
          <div className="challenge-resume--description">
            {t(`challenge.incentive.${step.type}`)}
          </div>
          {step.Component && (
            <step.Component
              className={'challenge-resume--cta'}
              to={t(step.REDIRECT, step.VALUES)}
              text={t(step.CTA)}
              fill={'clear'}
              size="small"
            />
          )}
        </div>
      )}
      {!step && (
        <div>
          <div className="challenge-resume--title">
            Congrats! You did it! 🙌
          </div>
          <div className="challenge-resume--description">
            {t('challenge.incentive.completed')}
          </div>
          <CuiButton
            onClick={() => claimReward('Claim your reward')}
            className={'challenge-resume--cta'}
            fill={'clear'}
            size="small">
            Claim your reward
          </CuiButton>
        </div>
      )}
    </>
  )
}
