import * as api from '../../api/economicPlanForever'
import * as actions from './actions'

export const changePlanToEconomicPlanForever = userId => dispatch => {
  dispatch(actions.changePlanToEconomicPlanForeverStart({ userId }))
  api
    .changePlanToEconomicPlanForever({ userId })
    .then(() => {
      dispatch(actions.changePlanToEconomicPlanForeverSuccess())
    })
    .catch(e => {
      dispatch(actions.changePlanToEconomicPlanForeverFail())
    })
}
