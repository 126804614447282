import gqlClient from '../gqlClient'

/**
 * Get user challenges
 */
export const fetchUserChallenges = () =>
  gqlClient.query(
    `
    query activeChallenge {
        activeChallenge {
            id
            enabled
            variant
            challengeId
            finishedAt
            expireAt
        }
    }
  `
  )

/**
 * Get challenge steps
 */
export const fetchChallengeWithSteps = ({ challengeId }) =>
  gqlClient.query(
    `
  query activeChallengeWithSteps ($challengeId: Int!) {
    activeChallengeWithSteps (challengeId: $challengeId) {
          challenge {
            challengeId
          }
          steps {
            stepNumber
            type
            status
          }
      }
  }
`,
    { challengeId }
  )

/**
 * Claim reward
 */
export const claimReward = ({ challengeId }) =>
  gqlClient.mutation(
    `
      mutation claimReward ($challengeId: Int!) {
        claimReward (challengeId: $challengeId) {
          promotionActivationId
          finishedAt
        }
      }
    `,
    { challengeId }
  )
