let identify = console.log
let page = console.log
let track = console.log
let alias = console.log
let group = console.log
let debug = console.log

let Analytics = {
  identify,
  page,
  track,
  alias,
  group,
  debug
}

if (window.analytics) {
  identify = (...props) => window.analytics.identify(...props)
  page = (...props) => window.analytics.page(...props)
  track = (...props) => window.analytics.track(...props)
  alias = (...props) => window.analytics.alias(...props)
  group = (...props) => window.analytics.group(...props)
  debug = (...props) => window.analytics.debug(...props)
  Analytics = window.analytics
} else {
  console.log('window analytics not initialized')
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...Analytics,
  identify,
  page,
  track,
  alias,
  group,
  debug
}
