import gqlClient from '../gqlClient'

export const getResurrectionCampaign = ({ campaignSlug }) =>
  gqlClient.publicQuery(
    `
    query getResurrectionCampaign ($campaignSlug: String!){
      getResurrectionCampaign(campaignSlug: $campaignSlug) {
            id,
            enabled,
            chefId,
            chefBrandId,
            highlightedMealsIds,
            heroTitle,
            heroSubtitle,
            heroPromotionCopy,
            heroImageUrl,
            conditionsCopy,
            couponCode,
            prepaidCampaign,
            menuSectionTitle
      }
    }
  `,
    {
      campaignSlug
    }
  )

export const getFeaturedMeals = (attrs, source) =>
  gqlClient.publicQuery(
    `
    query featuredMeals($source: Source, $attrs: Attrs) {
      featuredMeals(source: $source, attrs: $attrs) {
        entity_id
        name
        short_description
        image_url
        chef_id
        chef_firstname
        chef_lastname
        new_meal
        new_chef
        logopic
        bannerpic
        meat_type
        meat_category
        stars
        reviews
      }
    }
  `,
    {
      source: source || 'STATIC',
      attrs: attrs || null
    }
  )
