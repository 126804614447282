import { handleActions } from 'redux-actions'

import * as accountActions from './actions'

const initialState = {
  changePassword: {
    loading: false,
    success: false,
    error: false
  },
  deleteAccount: {
    loading: false,
    success: false,
    error: false
  },
  updatePersonalInfo: {
    loading: false,
    success: false,
    error: false,
    message: ''
  }
}

export default handleActions(
  {
    [accountActions.changePasswordStart]: state => {
      return {
        ...state,
        changePassword: {
          loading: true,
          success: false,
          error: false
        }
      }
    },
    [accountActions.changePasswordSuccess]: state => {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: true,
          error: false
        }
      }
    },
    [accountActions.changePasswordError]: state => {
      return {
        ...state,
        changePassword: {
          loading: false,
          success: false,
          error: true
        }
      }
    },
    [accountActions.deleteAccountStart]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: true,
          success: false,
          error: false
        }
      }
    },
    [accountActions.deleteAccountSuccess]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: false,
          success: true,
          error: false
        }
      }
    },
    [accountActions.deleteAccountError]: state => {
      return {
        ...state,
        deleteAccount: {
          loading: false,
          success: false,
          error: true
        }
      }
    },
    [accountActions.updatePersonalInfoStart]: state => {
      return {
        ...state,
        updatePersonalInfo: {
          inFlight: true,
          succeeded: false,
          error: null,
          message: ''
        }
      }
    },
    [accountActions.updatePersonalInfoSuccess]: state => {
      return {
        ...state,
        updatePersonalInfo: {
          inFlight: false,
          succeeded: true,
          error: null,
          message: ''
        }
      }
    },
    [accountActions.updatePersonalInfoError]: (state, action) => {
      return {
        ...state,
        updatePersonalInfo: {
          inFlight: false,
          succeeded: false,
          error: true,
          message: action.payload
        }
      }
    },
    [accountActions.updatePersonalInfoClean]: state => {
      return {
        ...state,
        updatePersonalInfo: {
          inFlight: false,
          succeeded: false,
          error: false,
          message: ''
        }
      }
    }
  },
  initialState
)
