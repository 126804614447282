import { Tooltip } from 'react-tooltip'
import { useState } from 'react'

import './CharacteristicsL1.scss'
import { useIsMobile } from 'src/hooks/useIsMobile'

const CharacteristicsL1 = ({ list, mealId, size = 'medium' }) => {
  return (
    <div className="characteristics-l1">
      {list.map((item, index) => (
        <CharacteristicL1Icon
          item={item}
          mealId={mealId}
          size={size}
          index={index}
          key={index}
        />
      ))}
    </div>
  )
}

const CharacteristicL1Icon = ({ item, mealId, size, index }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const isMobile = useIsMobile()

  const onClickTooltip = () => {
    if (isMobile) {
      if (isTooltipOpen) {
        setIsTooltipOpen(false)
      } else {
        setIsTooltipOpen(true)
        setTimeout(() => {
          setIsTooltipOpen(false)
        }, 3000)
      }
    }
  }

  const onHoverTooltip = open => {
    if (!isMobile) {
      setIsTooltipOpen(open)
    }
  }

  return item.tooltip ? (
    <>
      <div
        className={`characteristics-l1__item characteristics-l1__item--${size}`}
        style={{ backgroundColor: item.color }}
        data-tooltip-id={`warning-${index}-${mealId}`}
        onMouseEnter={() => onHoverTooltip(true)}
        onMouseLeave={() => onHoverTooltip(false)}
        onClick={e => {
          e.stopPropagation()
          onClickTooltip()
        }}>
        {item.icon}
      </div>
      <Tooltip
        id={`warning-${index}-${mealId}`}
        content={item.tooltip}
        className="characteristics-l1__tooltip"
        isOpen={isTooltipOpen}
        opacity={1}
      />
    </>
  ) : (
    <div
      key={`ch-l1-${mealId}-${index}`}
      className={`characteristics-l1__item characteristics-l1__item--${size}`}
      style={{ backgroundColor: item.color }}>
      {item.icon}
    </div>
  )
}

export { CharacteristicsL1 }
