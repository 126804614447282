import React from 'react'
import classNames from 'classnames'
import { ReactComponent as EmptyStateStar } from 'src/assets/svg/rating/empty-state-star.svg'
import { ReactComponent as EmptyStar } from 'src/assets/svg/rating/star.svg'
import { ReactComponent as EmptyBoldStar } from 'src/assets/svg/rating/empty-bold-star.svg'
import { ReactComponent as FullBoldStar } from 'src/assets/svg/rating/full-bold-star.svg'
import { ReactComponent as EmptyHeart } from 'src/assets/svg/rating/empty-heart.svg'
import { ReactComponent as FullHeart } from 'src/assets/svg/rating/full-heart.svg'
import { ReactComponent as Sharing } from 'src/assets/svg/rating/share.svg'
import { ReactComponent as Checkmark } from 'src/assets/svg/rating/checkmark.svg'
import './Icon.scss'

export enum IconName {
  STAR_IMAGE = 'STAR_IMAGE',
  EMPTY_STAR = 'EMPTY_STAR',
  EMPTY_BOLD_STAR = 'EMPTY_BOLD_STAR',
  FULL_BOLD_STAR = 'FULL_BOLD_STAR',
  FULL_STAR = 'FULL_STAR',
  EMPTY_FAVORITE = 'EMPTY_FAVORITE',
  FULL_FAVORITE = 'FULL_FAVORITE',
  SHARING = 'SHARING',
  CHECKMARK = 'CHECKMARK'
}

type IconProps = React.SVGProps<SVGSVGElement> & {
  name: IconName
  className?: string
}

export const iconByName: Record<IconName, React.ElementType> = {
  [IconName.STAR_IMAGE]: EmptyStateStar,
  [IconName.EMPTY_STAR]: EmptyStar,
  [IconName.EMPTY_BOLD_STAR]: EmptyBoldStar,
  [IconName.FULL_BOLD_STAR]: FullBoldStar,
  [IconName.FULL_STAR]: EmptyStar,
  [IconName.EMPTY_FAVORITE]: EmptyHeart,
  [IconName.FULL_FAVORITE]: FullHeart,
  [IconName.SHARING]: Sharing,
  [IconName.CHECKMARK]: Checkmark
}

export const classNameByIconName: Record<IconName, string> = {
  [IconName.STAR_IMAGE]: '',
  [IconName.EMPTY_STAR]: 'empty-star',
  [IconName.EMPTY_BOLD_STAR]: 'empty-bold-star',
  [IconName.FULL_BOLD_STAR]: 'full-bold-star',
  [IconName.FULL_STAR]: 'full-star',
  [IconName.EMPTY_FAVORITE]: 'empty-heart',
  [IconName.FULL_FAVORITE]: 'full-heart',
  [IconName.SHARING]: 'sharing',
  [IconName.CHECKMARK]: 'checkmark'
}

export const Icon: React.FC<IconProps> = ({
  name,
  className = '',
  ...props
}) => {
  const IconToRender = iconByName[name]
  const classNameByName = classNameByIconName[name]

  return (
    <IconToRender
      {...props}
      className={classNames('icon', classNameByName, className)}
    />
  )
}
