export interface Benefit {
  benefit: string
  detail: string
}

export interface SpecialOfferMembership {
  title: string
  description: string
  bannerText: string
  checkoutSource: string
  primaryCta: string
}

export interface MembershipData {
  activation: MembershipActivation
  membership: Membership
}

export interface MembershipUsedAllowedToSkip {
  isAllowedToSkipMembership: {
    isAllowed: boolean
  }
}

export interface MembershipDiscount {
  rate: number
  imageUrl: string
  source: string
  expiration: string
  maxUses: number
  totalWithDiscount: number
}

export interface Membership {
  id?: number | null
  name?: string | null
  cost?: number
  active?: boolean | null
  freeDelivery?: boolean | null
  premiumMeals?: number | null
  addAndSave?: boolean | null
  discount?: MembershipDiscount | null
}

export interface MembershipActivation {
  membershipId: number | null
  active: boolean | null
  startDate: string | null
  endDate: string | null
  stopDate: string | null
  accumulatedSavings: number | null
}

export type Step =
  | 'introduction'
  | 'benefits'
  | 'checkout'
  | 'confirmation'
  | 'cancel'
  | 'closed'
  | 'pause'
  | 'paused'

export interface State {
  isOpen: boolean
  membership: Membership[]
  inFlight: boolean
  error: boolean
  succeded: boolean
}

export interface MembershipType {
  type: Membership
  payload?: {
    membership?: number | string
  }
}

export enum MembershipOverlayName {
  introduction = 'UnityPass Introduction',
  benefits = 'UnityPass Benefits',
  checkout = 'UnityPass Checkout',
  welcome = 'UnityPass Welcome',
  cancel = 'UnityPass Cancel',
  pause = 'UnityPass Pause',
  paused = 'UnityPass Paused'
}

export enum MembershipOverlayActions {
  discover = 'Discover Unity Pass benefits',
  cancel = 'Cancel Unity Pass',
  noThanks = 'No Thanks',
  joinNow = 'Join now',
  rejoinNow = 'Rejoin now',
  confirm = 'Confirm purchase',
  nevermind = 'Never mind',
  yesCancelNow = 'Yes, cancel now',
  pause = 'Pause Unity Pass for one month',
  goBackToHomePage = 'Go back to home page',
  gotIt = 'Got it'
}
