import { matchPath } from 'react-router-dom'
import routes from 'src/routes'

export const getRouteName = pathname => {
  let routeName = pathname
  function findRoute(routes) {
    for (const route of routes) {
      if (route.routes) findRoute(route.routes)
      if (routeName !== pathname) break
      if (matchPath(pathname, route)) routeName = route.trackAs || route.name
    }
  }

  findRoute(routes)
  return routeName
}
