import React, { Fragment } from 'react'

import Footer from '../../../common/Footer'

const withLayout = Component => {
  return props => (
    <Fragment>
      <Component {...props} />
      <Footer />
    </Fragment>
  )
}

export default withLayout
