import { gql, useMutation } from '@apollo/client'

export const MARK_MODA_AS_VIEWED_MUTATION = gql`
  mutation markModalAsViewed($type: String!, $payload: Any!) {
    markModalAsViewed(type: $type, payload: $payload)
  }
`

export function useMarkModalAsViewed(config?: { onCompleted?: () => void }) {
  const [mutate, rest] = useMutation(MARK_MODA_AS_VIEWED_MUTATION, config)

  return {
    viewModal: ({ type, payload = {} }: { type: string; payload?: any }) =>
      mutate({ variables: { type, payload } }),
    ...rest
  }
}
