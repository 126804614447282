import { useSelector } from 'react-redux'
import { CATEGORY_PROMOTIONS_MAP } from 'src/modules/MyPlan/components/Filters/constants'
import { getMenuPromotions } from 'src/redux-api-bridge/menu/selectors'
export const SIDE_PROMOTION_TYPE = 'sidePromotion'
export const CHEF_SKU_PROMOTION = 'productDiscountPromotion'
export const PROMOTION_AMOUNT_TYPE_PERCENT = 'percent'
export const PROMOTION_AMOUNT_TYPE_FIXED = 'fixed'

const PROMOTIONS = {
  [CHEF_SKU_PROMOTION]: {
    amount: (discount: string) => `Save ${discount}`,
    ribbonText: () => ``,
    tooltipText: () => `You'll see all discounts applied in the next step.`
  },
  [SIDE_PROMOTION_TYPE]: {
    amount: (discount: string) => `${discount} off`,
    ribbonText: (promotion: any) => formatDealsRibbonText(promotion),
    tooltipText: (promotion: any) => formatDealsTooltipText(promotion),
    icon: null
  }
}

const formatPromotionDiscount = ({ type, value }): string => {
  if (type.toLowerCase() === PROMOTION_AMOUNT_TYPE_PERCENT) {
    return `${value * 100}%`
  }

  if (type.toLowerCase() === PROMOTION_AMOUNT_TYPE_FIXED) {
    return `$${value}`
  }

  return `$${value}`
}

export const formatPromotionAmount = (promotion): string | null => {
  if (!promotion) return null
  const promotionDiscount = formatPromotionDiscount(promotion.amount)
  return PROMOTIONS[promotion.type].amount(promotionDiscount)
}

const formatDealsRibbonText = (promotion): string | null => {
  if (!promotion || !promotion?.constraints?.categoryId) return null
  const getCategoryDiscountName =
    CATEGORY_PROMOTIONS_MAP[promotion.constraints?.categoryId]
  const promotionDiscount = formatPromotionDiscount(promotion.amount)
  return `This week ${getCategoryDiscountName} ${promotionDiscount} off!`
}

const formatDealsTooltipText = (promotion): string | null => {
  if (!promotion || !promotion?.constraints?.capAmount) return null
  const promotionDiscount = formatPromotionDiscount(
    promotion.constraints?.capAmount
  )
  return `Discount up to ${promotionDiscount}. You'll see it applied in the next step.`
}

export const useMenuPromotion = () => {
  const menuPromotions = useSelector(getMenuPromotions)

  if (!menuPromotions || menuPromotions.length === 0) return []

  if (!PROMOTIONS[menuPromotions[0].type]) return []

  return menuPromotions.map(promotion => mapMenuPromotion(promotion))
}

export const mapMenuPromotion = promotion => ({
  hasPromotion: !!promotion,
  type: promotion.type,
  formattedAmount: PROMOTIONS[promotion.type]?.amount(
    formatPromotionDiscount(promotion.amount)
  ),
  formattedRibbon: PROMOTIONS[promotion.type]?.ribbonText(promotion),
  tooltipText: PROMOTIONS[promotion.type]?.tooltipText(promotion),
  categoryId: promotion?.constraints?.categoryId,
  subCategoryId: promotion?.constraints?.subCategoryId,
  icon: PROMOTIONS[promotion.type]?.icon
})
