import { gql, useQuery } from '@apollo/client'

const GET_LIFESPAN = gql`
  query getUserLifeSpan {
    users {
      lifespan {
        status
        wasResurrected
        weeks
      }
    }
  }
`

export function useLifeSpan({
  onCompleted,
  onError
}: {
  onCompleted?: () => void
  onError?: (error: any) => void
} = {}) {
  const { data, loading, error } = useQuery(GET_LIFESPAN, {
    onCompleted,
    onError
  })

  return {
    lifespan: data?.users[0]?.lifespan || null,
    loading,
    error
  }
}
