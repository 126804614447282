import React from 'react'

// eslint-disable-next-line import/no-anonymous-default-export
export default queryNames => Component => ({ location, ...props }) => {
  queryNames.forEach(queryName => {
    const paramValue = new URLSearchParams(location.search).get(queryName)
    if (paramValue !== null && paramValue !== undefined) {
      props[queryName] = paramValue
    }
  })
  return <Component {...{ ...props, location }} />
}
