import { createAction } from 'redux-actions'

export const getResurrectionCampaignStart = createAction(
  'GET_RESURRECTION_CAMPAIGN_START'
)
export const getResurrectionCampaignFail = createAction(
  'GET_RESURRECTION_CAMPAIGN_FAIL'
)
export const getResurrectionCampaignSuccess = createAction(
  'GET_RESURRECTION_CAMPAIGN_SUCCESS'
)

export const getFeaturedMealsStart = createAction('GET_FEATURED_MEALS_START')
export const getFeaturedMealsFail = createAction('GET_FEATURED_MEALS_FAIL')
export const getFeaturedMealsSuccess = createAction(
  'GET_FEATURED_MEALS_SUCCESS'
)
