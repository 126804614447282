import classnames from 'classnames'
import { useEffect, useState } from 'react'

import { CalendarDate } from 'src/types/calendarDate'
import { useIsMobile } from 'src/hooks/useIsMobile'
import CalendarBox from './CalendarBox'

const WEEKS_PER_PAGE = 4

interface CalendarProps {
  days: CalendarDate[]
}

const Calendar = ({ days = [] }: CalendarProps) => {
  const isMobile = useIsMobile()

  const [calendarWeeks, setCalendarWeeks] = useState<any>()
  const [currentPage, setCurrentPage] = useState<any>(0)

  useEffect(() => {
    if (!days?.length) return

    const calendarDays = days
    const weeks: any[] = []

    for (let i = 0; i < calendarDays.length; i += 7) {
      const subarray = calendarDays.slice(i, i + 7)
      weeks.push(subarray)
    }

    setCalendarWeeks(weeks)
  }, [days])

  function handleLeftPagination() {
    setCurrentPage(currentPage - 1)
  }

  function handleRightPagination() {
    setCurrentPage(currentPage + 1)
  }

  if (!calendarWeeks) return null

  const showPagination = calendarWeeks.length > WEEKS_PER_PAGE
  const showLeftPagination = showPagination && currentPage > 0
  const showRightPagination =
    showPagination && currentPage < calendarWeeks.length / WEEKS_PER_PAGE - 1

  return (
    <div className="after-pausing-calendar">
      {!isMobile && (
        <div
          className={classnames(
            'calendar-pagination calendar-pagination-left',
            {
              visible: showLeftPagination
            }
          )}>
          <button onClick={handleLeftPagination}>
            <i className="fas fa-chevron-left fa-xs" />
          </button>
        </div>
      )}

      <div className="calendar-weeks-scroll">
        <div className="calendar-weeks">
          {(isMobile
            ? calendarWeeks
            : calendarWeeks.slice(
                currentPage * WEEKS_PER_PAGE,
                currentPage * WEEKS_PER_PAGE + WEEKS_PER_PAGE
              )
          ).map((week, index) => {
            return (
              <div className="calendar-week" key={index}>
                {week.map(day => (
                  <CalendarBox key={day.date} date={day.date} type={day.type} />
                ))}
              </div>
            )
          })}
        </div>
      </div>

      {!isMobile && (
        <div
          className={classnames(
            'calendar-pagination calendar-pagination-right',
            {
              visible: showRightPagination
            }
          )}>
          <button onClick={handleRightPagination}>
            <i className="fas fa-chevron-right fa-xs" />
          </button>
        </div>
      )}
    </div>
  )
}

export default Calendar
