import React, { useState, useEffect, useReducer } from 'react'
import { Auth0Context } from '@auth0/auth0-react'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { useLocation } from 'react-router-dom'
import { getCookie } from '../utils/browserCookies'
import { IMPERSONATION_COOKIE_NAME } from '../constants/application'
import { parse } from 'qs'
import * as jose from 'jose'

import {
  toAuth0ClientOptions,
  toAuth0LoginRedirectOptions,
  hasAuthParams,
  loginError,
  wrappedGetToken,
  defaultOnRedirectCallback,
  tokenError,
  adaptUserData
} from './utils'

const impersonationCookie = getCookie(IMPERSONATION_COOKIE_NAME)
const hasImpersonationCookie = impersonationCookie !== null
let _client_

let _isImpersonating_ = false
let _impersonateEmail_
let _impersonateSecret_

let tokenFromParams = null

const getAuth0Client = opts => {
  if (!_client_) {
    _client_ = new Auth0Client(opts)
  }
  return _client_
}

export const getTokenSilently = async opts => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      return 'mocked-token'
    }
    return tokenFromParams || (await _client_.getTokenSilently(opts))
  } catch (error) {
    throw tokenError(error)
  }
}

export const getImpersonator = () => {
  if (hasImpersonationCookie) {
    return {
      cookieValue: impersonationCookie
    }
  } else {
    return (
      _isImpersonating_ && {
        email: _impersonateEmail_,
        secret: _impersonateSecret_
      }
    )
  }
}

const Auth0Provider = props => {
  const {
    children,
    onRedirectCallback = defaultOnRedirectCallback,
    ...clientOpts
  } = props

  const [client] = useState(() =>
    getAuth0Client(toAuth0ClientOptions(clientOpts))
  )

  const { pathname, search } = useLocation()
  const { p, e, cuToken } = parse(search, {
    decoder: c => c,
    ignoreQueryPrefix: true
  })

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'LOGIN_POPUP_STARTED':
          return {
            ...state,
            isLoading: true
          }
        case 'LOGIN_POPUP_COMPLETE':
        case 'INITIALISED':
          return {
            ...state,
            isAuthenticated: action.isAuthenticated,
            user: adaptUserData(action.user),
            isLoading: false,
            error: undefined
          }
        case 'ERROR':
          return {
            ...state,
            isLoading: false,
            error: action.error
          }
        case 'IMPERSONATION_INITIALISED':
          return {
            ...state,
            isLoading: false,
            error: undefined,
            isAuthenticated: true,
            isImpersonating: true,
            user: adaptUserData({ email: action.impersonateEmail }),
            impersonateEmail: action.impersonateEmail,
            impersonateSecret: action.impersonateSecret
          }
        case 'INITIALISED_WITH_TOKEN_FROM_PARAMS':
          return {
            ...state,
            isLoading: false,
            error: undefined,
            isAuthenticated: true,
            isAuthenticatedWithParams: true,
            user: adaptUserData(action.user)
          }
        default:
          return state
      }
    },
    {
      isAuthenticated: false,
      isLoading: typeof window !== 'undefined',
      isAuthenticatedWithParams: false
    }
  )

  const decodeJWT = token => {
    try {
      return JSON.parse(window.atob(token.split('.')[1]))
    } catch (e) {
      return {}
    }
  }

  useEffect(() => {
    async function authenticateWithAuth0() {
      try {
        if (hasAuthParams()) {
          const { appState } = await client.handleRedirectCallback()
          onRedirectCallback(appState)
        } else {
          await client.checkSession()
        }
        const isAuthenticated = await client.isAuthenticated()
        const user = isAuthenticated && (await client.getUser())
        dispatch({ type: 'INITIALISED', isAuthenticated, user })
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) })
      }
    }

    if (cuToken) {
      tokenFromParams = cuToken

      return dispatch({
        type: 'INITIALISED_WITH_TOKEN_FROM_PARAMS',
        user: decodeJWT(cuToken)
      })
    } else {
      if (pathname === '/impersonate') {
        _impersonateEmail_ = e
        _impersonateSecret_ = p
        _isImpersonating_ = true

        return dispatch({
          type: 'IMPERSONATION_INITIALISED',
          impersonateEmail: e,
          impersonateSecret: p
        })
      } else {
        if (hasImpersonationCookie) {
          const decodedJWT = jose.decodeJwt(impersonationCookie)
          _impersonateEmail_ = decodedJWT.userEmail
          _isImpersonating_ = true
          return dispatch({
            type: 'IMPERSONATION_INITIALISED',
            impersonateEmail: decodedJWT.userEmail
          })
        }
      }
    }
    authenticateWithAuth0()
  }, [client, onRedirectCallback]) // eslint-disable-line react-hooks/exhaustive-deps

  const loginWithPopup = async options => {
    dispatch({ type: 'LOGIN_POPUP_STARTED' })
    try {
      await client.loginWithPopup(options)
    } catch (error) {
      dispatch({ type: 'ERROR', error: loginError(error) })
      return
    }
    const isAuthenticated = await client.isAuthenticated()
    const user = isAuthenticated && (await client.getUser())
    dispatch({ type: 'LOGIN_POPUP_COMPLETE', isAuthenticated, user })
  }

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        getAccessTokenSilently: wrappedGetToken(opts =>
          client.getTokenSilently(opts)
        ),
        getAccessTokenWithPopup: wrappedGetToken(opts =>
          client.getTokenWithPopup(opts)
        ),
        getIdTokenClaims: opts => client.getIdTokenClaims(opts),
        loginWithRedirect: opts =>
          client.loginWithRedirect(toAuth0LoginRedirectOptions(opts)),
        loginWithPopup: opts => loginWithPopup(opts),
        logout: opts => client.logout(opts)
      }}>
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
