import {
  MENU_CATEGORIES,
  ADDONS_CATEGORIES,
  MEALS_CATEGORY,
  FREE_PREMIUM_MEAL_PROMOTION
} from 'src/constants/menu'
import * as api from '../../api'
import { getUserAlreadyRequestedMeals } from '../../api/Filters'
import * as actions from './actions'
import {
  CATEGORY_LABEL,
  CATEGORY_PATTERN
} from 'src/modules/MyPlan/components/MenuBy/FiltersExperimentV3/constantsV3'
import { buildImgUrl, mealsAreEquals } from 'src/utils/utils'
import { uniqBy } from 'ramda'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE

const menuMapper = menu => {
  const sortingMap = new Map()

  menu.sorting.forEach(sorting => {
    sorting.sortedMealBundles?.forEach((id, index) => {
      const sortIndex = index + 1
      const mealSortingFromMap = sortingMap.get(id)
      if (!mealSortingFromMap) {
        sortingMap.set(id, {
          [`${sorting.type}`]: sortIndex
        })
      } else {
        sortingMap.set(id, {
          ...mealSortingFromMap,
          [`${sorting.type}`]: sortIndex
        })
      }
    })
  })

  const macronutrientsMaxValues = {
    calories: 0,
    carbs: 0,
    protein: 0,
    fat: 0,
    sodium: 0,
    sugar: 0
  }

  let searchByValues = []

  menu.categories.forEach(category => {
    category.meals = []

    if (category.coverInfo) {
      category.cover_title = category.coverInfo.title
      category.cover_text = category.coverInfo.text
      category.cover_image = category.coverInfo.image
      category.cover_image_mobile = category.coverInfo.imageMobile
      category.cover_image_macro = category.coverInfo.imageMacro
    }
  })

  menu.meals.forEach((meal, index) => {
    const mealCategory = getCategory(menu.categories, meal)
    const mealSort = sortingMap.get(meal.inventoryId)
    const formattedMeal = formatMeal(meal, mealSort)

    if (!!meal.showInBundlesOnly) return

    updateMacronutrients(macronutrientsMaxValues, meal)
    searchByValues.push(buildMealSearchBy(formattedMeal))

    menu.meals[index] = formattedMeal

    if (mealCategory && !mealCategory.meals?.some(m => m.id === meal.id)) {
      mealCategory.meals.push(formattedMeal)
    }
  })

  if (menu.bundles?.length > 0) {
    const bundlesCategory = menu.categories.find(
      c => +c.id === MENU_CATEGORIES.BUNDLES
    )
    const bundlesWithMeals = menu.bundles.map(bundle => {
      const bundleMeals = bundle.meals.map(meal =>
        mealFromMenu(menu, meal.mealExternalId)
      )
      if (bundleMeals.some(meal => !meal)) {
        return null
      }

      const bundleSorting = sortingMap.get(bundle.inventoryId)
      const category_id = bundleMeals.some(
        meal => meal.categoryId === MEALS_CATEGORY
      )
        ? MEALS_CATEGORY
        : ADDONS_CATEGORIES[0]
      return {
        ...bundle,
        inventoryId: bundle.inventoryId,
        meals: bundleMeals,
        id: bundle.inventoryId,
        category_id,
        inStock: bundle.stock > 0,
        price: bundle.price,
        finalPrice: bundle.finalPrice,
        priceWithoutPlanMeal: bundle.priceWithoutPlanMeal,
        originalPriceWithoutPlanMeal: bundle.originalPriceWithoutPlanMeal,
        shortDescription: bundle.description,
        isNew: bundle.isNewBundle,
        categories: bundle.categories,
        category: bundle.category,
        sorting: bundleSorting,
        filter_by: [...(bundle.filter_by || []), ...getCategoryTag(bundle)]
      }
    })
    const availableBundles = bundlesWithMeals.filter(bundle => !!bundle)
    if (!!bundlesCategory) {
      bundlesCategory.meals = []
    }
    menu.bundles = availableBundles

    menu.bundles.forEach(bundle => {
      const category = getCategory(menu.categories, bundle)
      if (
        category &&
        !category.meals?.some(m => m.inventoryId === bundle.inventoryId)
      ) {
        category.meals.push(bundle)
      }
      if (
        !bundlesCategory.meals?.some(b => b.inventoryId === bundle.inventoryId)
      ) {
        bundlesCategory.meals.push(bundle)
      }
    })
  }

  menu.macronutrients = macronutrientsMaxValues
  menu.searchByValues = searchByValues

  menu.freePremiumMeals = buildFreePremiumMealPromotion(
    menu?.promotions ?? [],
    menu.meals
  )

  return { ...menu, available: true }
}

const mealFromMenu = (menu, id) => {
  const mealInMenu = menu.meals.find(m => m.id === id)
  return mealInMenu // No need to format again, since meals have been formated above.
}

const formatMeal = (meal, mealSort) => ({
  ...meal,
  all_tags: meal.tags,
  entity_id: meal.id,
  magento_id: meal.id,
  batch_id: meal.batchId,
  short_description: meal.shortDescription,
  calories: +meal.nutritionalFacts.calories,
  image_path: meal.imagePath,
  is_premium: !!meal.premiumFee,
  is_back_in_the_menu: !!meal.isBackInTheMenu,
  is_meal_restaurant_exclusive: meal.isMealRestaurantExclusive,
  premium_fee: meal.premiumFee,
  premium_special: meal.premiumSpecial,
  category_id: meal.categoryId,
  chef_id: meal.chef && meal.chef.id,
  chef_firstname: meal.chef && meal.chef.firstName,
  chef_lastname: meal.chef && meal.chef.lastName,
  is_celebrity_chef: meal.chef && meal.chef.isCelebrityChef,
  warnings: {
    message: meal.warnings && meal.warnings.message,
    restrictions_applied: meal.warnings && meal.warnings.restrictionsApplied,
    allergens_not_matching: meal.warnings && meal.warnings.allergensNotMatching,
    diets_not_matching: meal.warnings && meal.warnings.dietsNotMatching
  },
  nutritional_facts: meal.nutritionalFacts && {
    calories: meal.nutritionalFacts.calories
  },
  logopic: meal.chef && meal.chef.logoPic,
  bannerpic: meal.chef && meal.chef.bannerPic,
  inStock: meal.stock > 0,
  filter_by: [...meal.filter_by, ...getCategoryTag(meal)],
  meat_type: meal.meatType,
  user_rating: meal.userRating,
  sidedish: meal.sideDish && {
    id: meal.sideDish.id
  },
  quantity: meal.qty || 0,
  imageUrl: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_meal_image: buildImgUrl(IMG_URL_SOURCE, meal.imagePath, meal.image),
  full_path_chef_image: `${IMG_URL_SOURCE}${meal.chef.bannerPic}`,
  protein_type: meal.meatType,
  secondary_image: (meal.media && meal.media.secondaryImage) || null,
  specifications_detail: meal.specificationsDetails
    ? meal.specificationsDetails.map(specification => ({
        ...specification
      }))
    : [],
  relatedMeal: meal.relatedMeal
    ? {
        ...meal.relatedMeal,
        entity_id: meal.relatedMeal.id,
        batch_id: meal.relatedMeal.batchId,
        magento_id: meal.relatedMeal.id,
        is_related_meal: true,
        image_path: meal.relatedMeal.imagePath,
        category_id: meal.relatedMeal.categoryId
      }
    : null,
  inventoryId: meal.inventoryId,
  promotions: meal.promotions,
  sorting: mealSort,
  showInBundlesOnly: !!meal.showInBundlesOnly,
  ingredients: meal.ingredients || []
})

const getCategory = (categories, meal) => {
  if (
    +meal.category?.id === MENU_CATEGORIES.SIDES ||
    +meal.category?.id === MENU_CATEGORIES.BREAKFAST
  ) {
    return categories.find(category => +category.id === MENU_CATEGORIES.MEALS)
  } else {
    return categories.find(category => +category.id === +meal.category?.id)
  }
}

const getCategoryTag = meal => {
  const tags = []
  if (!!meal.categories?.find(c => c.label === CATEGORY_LABEL.SIDES)) {
    tags.push(CATEGORY_PATTERN.SIDES)
    if (!!meal.meals) tags.push('sides_without_proteins')
  }
  if (!!meal.categories?.find(c => c.label === CATEGORY_LABEL.BREAKFAST)) {
    tags.push(CATEGORY_PATTERN.BREAKFAST)
  }
  return tags
}

const updateMacronutrients = (macronutrients, meal) => {
  if (parseInt(meal.nutritionalFacts.calories ?? 0) > macronutrients.calories) {
    macronutrients.calories = parseInt(meal.nutritionalFacts.calories)
  }
  if (parseInt(meal.nutritionalFacts.carbs ?? 0) > macronutrients.carbs) {
    macronutrients.carbs = parseInt(meal.nutritionalFacts.carbs)
  }
  if (parseInt(meal.nutritionalFacts?.protein ?? 0) > macronutrients.protein) {
    macronutrients.protein = parseInt(meal.nutritionalFacts.protein)
  }
  if (parseInt(meal.nutritionalFacts?.fat ?? 0) > macronutrients.fat) {
    macronutrients.fat = parseInt(meal.nutritionalFacts.fat)
  }
  if (parseInt(meal.nutritionalFacts?.sodium ?? 0) > macronutrients.sodium) {
    macronutrients.sodium = parseInt(meal.nutritionalFacts.sodium)
  }
  if (parseInt(meal.nutritionalFacts?.sugar ?? 0) > macronutrients.sugar) {
    macronutrients.sugar = parseInt(meal.nutritionalFacts.sugar)
  }
}

const buildMealSearchBy = meal => {
  return {
    mealId: meal.id,
    inventoryId: meal.inventoryId,
    mealName: meal.name,
    mealDescription: meal.short_description,
    ingredients: meal.ingredients?.map(ingredient => ingredient.name) || [],
    cuisines: meal.searchBy?.cuisines?.split(', '),
    chefName: `${meal.chef.firstName} ${meal.chef.lastName}`,
    searchBy: [meal.meat_type, ...meal.filter_by].filter(value => !!value),
    diets: meal.searchBy?.dietTags?.split(', ') ?? '',
    merchandising: meal.searchBy?.merchandisingFilters?.split(', ') ?? '',
    proteins: meal.searchBy?.proteinTags?.split(', ') ?? ''
  }
}

const buildFreePremiumMealPromotion = (promotions, meals) => {
  const freePremiumMealPromotion = promotions?.find(
    promo => promo.type === FREE_PREMIUM_MEAL_PROMOTION
  )

  const availableMeals = freePremiumMealPromotion
    ? freePremiumMealPromotion.constraints.items.map(item =>
        meals.find(meal => mealsAreEquals(meal, { id: item }))
      )
    : []

  return {
    isActive: !!freePremiumMealPromotion,
    amountToChoose: freePremiumMealPromotion
      ? freePremiumMealPromotion.constraints.amountToChoose
      : 0,
    availableMeals
  }
}

export const getUserMenuByDate = (
  date,
  fromPrefetch,
  isAfterCutoff
) => dispatch => {
  dispatch(actions.getUserMenuStart({ fromPrefetch, date }))

  const filters = {
    onlyRegularMeals: isAfterCutoff ?? false
  }

  return api
    .getUserMenu({ date, filters }, true)
    .then(async ({ data }) => {
      const menuData = menuMapper(data.menu)
      dispatch(
        actions.getUserMenuSuccess({
          menu: menuData,
          date
        })
      )
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.getUserMenuFail(err))
    })
}

export const getAlreadyRequestedMeals = date => (dispatch, getState) => {
  const state = getState()
  if (
    state.menu.alreadyRequestedMeals &&
    state.menu.alreadyRequestedMeals.date === date
  ) {
    return state.menu.alreadyRequestedMeals.meals
  }
  dispatch(actions.getUserAlreadyRequestedMealsStart())

  return getUserAlreadyRequestedMeals(date)
    .then(async ({ data }) => {
      const menu = menuMapper(data.menu, false)
      const meals =
        (menu &&
          menu.available &&
          menu.meals?.filter(meal => !meal.showInBundlesOnly)) ||
        []
      const uniqueMeals = meals ? uniqBy(meal => meal.inventoryId)(meals) : []
      dispatch(
        actions.getUserAlreadyRequestedMealsSuccess({
          meals: uniqueMeals,
          date
        })
      )
      return uniqueMeals
    })
    .catch(err => dispatch(actions.getUserAlreadyRequestedMealsFail(err)))
}

export const getCrossSelling = (date, cartItems) => dispatch => {
  dispatch(actions.getCrossSellingItemsStart())
  return api
    .getCrossSelling({ date, cartItems })
    .then(async ({ data }) => {
      dispatch(
        actions.getCrossSellingItemsSuccess({
          date,
          items: [
            ...(data?.crossSellingItems?.meals ?? []),
            ...(data?.crossSellingItems?.bundles ?? [])
          ],
          category: data?.crossSellingItems?.category,
          title: data?.crossSellingItems?.title,
          subTitle: data?.crossSellingItems?.subTitle
        })
      )
    })
    .catch(err => dispatch(actions.getCrossSellingItemsFail(err)))
}
