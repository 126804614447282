import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useQueryParams() {
  const { search, pathname } = useLocation()
  const history = useHistory()

  const query = React.useMemo(() => new URLSearchParams(search), [search])

  function deleteQueryParam(key: string) {
    // Removes the query param from the react-router state but not from URL
    query.delete(key)
    // Updates URL without refreshing the page
    history.replace({
      pathname,
      search: query.toString()
    })
  }

  return {
    query,
    deleteQueryParam
  }
}
