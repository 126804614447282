import createReducer from '../../utils/createReducer'
import * as actions from './actions'

const initialState = {
  meals: null,
  title: null,
  isLoading: false,
  isError: false
}

export default createReducer(initialState, {
  [actions.fetchProductsList]: state => ({
    ...state,
    title: null,
    meals: null,
    isLoading: true,
    isError: false
  }),
  [actions.fetchProductsListSucceed]: (
    state,
    { payload: { title, meals } }
  ) => ({
    ...state,
    title,
    meals,
    isLoading: false,
    isError: false
  }),
  [actions.fetchProductsListFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true
  })
})
