import {
  FIVE_MILESTONE_CHALLENGE_EXPERIMENT,
  THREE_ORDERS_CHALLENGE_EXPERIMENT
} from './experiments'

export const CHALLENGE_IDS = {
  FIVE_MILESTONES: 1,
  THREE_ORDERS: 2
}

export const CHALLENGE_EXPERIMENTS = {
  [CHALLENGE_IDS.FIVE_MILESTONES]: FIVE_MILESTONE_CHALLENGE_EXPERIMENT,
  [CHALLENGE_IDS.THREE_ORDERS]: THREE_ORDERS_CHALLENGE_EXPERIMENT
}
