import {
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'

export const COMPARE_PLANS_LOCALSTORAGE_KEY = 'tour-compare-plans-modal-viewed'
export const MONTHLY_MODAL_STEPS = {
  PAY_IN_ADVANCE: 'PAY_IN_ADVANCE',
  CONFIRM_YOUR_PLAN: 'CONFIRM_YOUR_PLAN',
  ACTIVATE: 'ACTIVATE',
  REACTIVATED: 'REACTIVATED',
  FAQ: 'FAQ'
}
export const MEMBERSHIP_MODAL_STEPS: Record<Step, Step> = {
  introduction: 'introduction',
  benefits: 'benefits',
  checkout: 'checkout',
  confirmation: 'confirmation',
  cancel: 'cancel',
  closed: 'closed',
  pause: 'pause',
  paused: 'paused'
}

export const MEMBERSHIP_OVERLAY_OPTIONS = {
  [MembershipOverlayName.introduction]: [MembershipOverlayActions.discover],
  [MembershipOverlayName.benefits]: [
    MembershipOverlayActions.joinNow,
    MembershipOverlayActions.noThanks,
    MembershipOverlayActions.cancel
  ],
  [MembershipOverlayName.checkout]: [
    MembershipOverlayActions.confirm,
    MembershipOverlayActions.nevermind
  ],
  [MembershipOverlayName.pause]: [MembershipOverlayActions.pause],
  [MembershipOverlayName.paused]: [MembershipOverlayActions.gotIt],
  [MembershipOverlayName.cancel]: [MembershipOverlayActions.cancel]
}
