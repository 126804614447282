import { atom } from 'jotai'

export interface ChangePlanModalAtom {
  isOpen: boolean
  type: 'upgrade' | 'one-time' | ''
}

export const changePlanModalAtom = atom<ChangePlanModalAtom>({
  isOpen: false,
  type: ''
})
