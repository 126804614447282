import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { useCreateSkipPeriod } from 'src/api/plan/useCreateSkipPeriod'
import { usePlanConfiguration } from 'src/pages/myPlan/hooks/usePlanConfiguration'
import { fetchMyPlan } from 'src/redux-api-bridge'
import { toaster } from 'src/utils/toaster'
import Analytics from 'src/utils/analytics'
import mobileAndTabletcheck from 'src/utils/mobileAndTabletcheck'

interface Props {
  onCompleted?: () => void
  onError?: () => void
}

export function usePausePlan({ onCompleted, onError }: Props) {
  const dispatch = useDispatch()
  const { createSkipPeriod, loading } = useCreateSkipPeriod({
    onCompleted: onCompleted || onPausedSuccess,
    onError: onError || onPausedError
  })
  const { planName } = usePlanConfiguration()

  function getPauseOrigin() {
    const isMobile = mobileAndTabletcheck()
    return isMobile ? 'customer_web_mobile' : 'customer_web_desktop'
  }

  function pausePlan({ reason, weeks }) {
    const origin = getPauseOrigin()

    Analytics.track('Pause-Plan', {
      plan_name: planName,
      is_paused: true,
      pause_duration: `${weeks} Weeks`,
      reason: reason,
      origin: 'my-plan'
    })

    createSkipPeriod({ reason, origin, weeks })
  }

  function onPausedSuccess() {
    dispatch(fetchMyPlan)
  }

  function onPausedError() {
    toaster.error('Error pausing plan', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: true
    })
  }

  return {
    pausePlan,
    loading
  }
}
