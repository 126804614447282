import { gql, useQuery } from '@apollo/client'
import { MembershipUsedAllowedToSkip } from 'src/types/membership'

export const GET_ALLOWED_TO_SKIP_MEMBERSHIP_QUERY = gql`
  query isAllowedToSkipMembership {
    isAllowedToSkipMembership {
      isAllowed
    }
  }
`

export function useAllowedToSkipMembership() {
  const { data, ...rest } = useQuery<MembershipUsedAllowedToSkip>(
    GET_ALLOWED_TO_SKIP_MEMBERSHIP_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first'
    }
  )
  return {
    isAllowed: data?.isAllowedToSkipMembership?.isAllowed,
    ...rest
  }
}
