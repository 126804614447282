import createReducer from '../../utils/createReducer'
import * as actions from './actions'
import * as ordersSwimlanesActions from 'src/redux-api-bridge/orders-swimlanes/actions'
import { ComponentType } from 'src/types/component'

export enum RatingProductFormAttributes {
  RATING = 'rating',
  REASONS = 'reasons',
  REVIEW = 'review'
}

export type RatingProductForm = {
  [RatingProductFormAttributes.RATING]: number | null
  [RatingProductFormAttributes.REASONS]: string[]
  [RatingProductFormAttributes.REVIEW]: string
}

export const ratingProductFormInitialState: RatingProductForm = {
  [RatingProductFormAttributes.RATING]: null,
  [RatingProductFormAttributes.REASONS]: [],
  [RatingProductFormAttributes.REVIEW]: ''
}

export interface RatingProduct {
  id: string
  title: string
  isFavorite: boolean
  image: {
    name: string
  }
}

export interface RatingProductState extends RatingProduct {
  rating: number | null
}

type RatingsState = {
  ratingProductForm: RatingProductForm
  products: Record<string, RatingProductState>
  currentProductId: string | null
  showReasonsFeedbackError: boolean
  isLoading: boolean
  isError: boolean
}

const initialState: RatingsState = {
  ratingProductForm: ratingProductFormInitialState,
  products: {},
  currentProductId: null,
  showReasonsFeedbackError: false,
  isLoading: false,
  isError: false
}

export default createReducer(initialState, {
  [actions.openRatingProductForm]: (
    state,
    { payload: { rating, productId } }
  ) => ({
    ...state,
    ratingProductForm: {
      ...state.ratingProductForm,
      rating
    },
    currentProductId: productId
  }),
  [actions.updateRatingProductForm]: (
    state,
    { payload: { attributeName, newValue } }
  ) => ({
    ...state,
    ratingProductForm: {
      ...state.ratingProductForm,
      [attributeName]: newValue
    },
    showReasonsFeedbackError:
      attributeName === RatingProductFormAttributes.REASONS
        ? false
        : state.showReasonsFeedbackError
  }),
  [actions.closeRatingProductForm]: state => ({
    ...initialState,
    products: state.products
  }),
  [actions.submitRatingProductForm]: state => ({
    ...state,
    showReasonsFeedbackError:
      state.ratingProductForm[RatingProductFormAttributes.REASONS].length === 0
  }),
  [ordersSwimlanesActions.fetchSwimlanesSucceed]: (state, action) => ({
    ...state,
    products:
      action.payload.ratingTab?.children
        ?.find(child => child?.type === ComponentType.CARROUSEL)
        ?.children?.reduce((products, child) => {
          return child?.props?.id
            ? {
                ...products,
                [child?.props?.id]: {
                  ...child?.props,
                  rating: null
                }
              }
            : products
        }, {}) || []
  }),
  [actions.rateProductStart]: state => ({
    ...state,
    isLoading: true,
    isError: false
  }),
  [actions.rateProductSucceed]: state => ({
    ...initialState,
    products: {
      ...state.products,
      [state.currentProductId]: {
        ...state.products[state.currentProductId],
        rating: state.ratingProductForm[RatingProductFormAttributes.RATING]
      }
    }
  }),
  [actions.rateProductFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true
  })
})
