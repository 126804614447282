import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'
import { ReactComponent as Alert } from 'src/assets/svg/alert-circle.svg'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'

import './UnityPassPause.scss'

import UnityPassImage from 'src/assets/img/unitypass.png'
import { usePauseMembership } from 'src/api/Membership/usePauseMembership'

/**
 * Types
 */

interface Props {
  onClose: () => void
  onContinue: (step: Step) => void
  membershipData: MembershipData
  bannerText: string
}

/**
 * UnityPassPause component
 */

export function UnityPassPause({
  onClose,
  onContinue,
  membershipData,
  bannerText
}: Props) {
  const { pauseMembership, loading } = usePauseMembership({
    onCompleted,
    onError
  })
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.cancel
  })
  const { membership, activation } = membershipData

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onCompleted(response) {
    if (response.stopMembership.success) {
      onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
    } else {
      onError(response)
    }
  }

  async function onPauseMembership() {
    if (membershipData?.activation.membershipId) {
      setErrorMessage('')
      pauseMembership({ membershipId: membershipData?.activation.membershipId })
      onContinue(MEMBERSHIP_MODAL_STEPS.paused)
      trackOptionClicked({
        overlayAction: MembershipOverlayActions.pause
      })
    }
  }

  function onError(response) {
    const error = response?.stopMembership?.error
    const isUnhandledError = response?.graphQLErrors?.length > 0 || !error
    setErrorMessage(
      isUnhandledError
        ? 'Sorry, we encountered an error. Please try again.'
        : error
    )
  }

  if (!membership || !activation) {
    return null
  }

  function onCancel() {
    onContinue(MEMBERSHIP_MODAL_STEPS.cancel)
  }

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">{bannerText}</div>
      </div>
      <div className="cancel-container">
        {loading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <div className="title-container">
          <span className="title">
            Need a break? <br />
            Just hit pause.
          </span>
        </div>
        <div className="description-container">
          <span>
            Pause your Unity Pass for one month, hassle-free. You won’t be
            charged during the pause, and you can jump back in effortlessly — no
            need to sign up again!
          </span>
        </div>
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="button-container">
          <CuiButton className="secondary-button" onClick={onClose}>
            Go back to homepage
          </CuiButton>
          <CuiButton className="primary-button" onClick={onPauseMembership}>
            Pause Unity Pass for one month
          </CuiButton>
        </div>
        <div>
          <div className="footer-container">
            <button className="cancel" onClick={onCancel}>
              <span className="label">Cancel Unity Pass</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
