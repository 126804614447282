export const FREE_PREMIUM_MEAL_PROMOTION = 'discountPromotion'

export const MENU_VIEW_MODE = {
  CONDENSED: 'condensed',
  REGULAR: 'regular'
}

export const MENU_CATEGORIES = {
  ANY_PRODUCTS: -1,
  MEALS: 0,
  SIDES: 3632,
  BREAKFAST: 3110,
  SAUCES: 5808,
  DRINKS: 33,
  TREATS: 34,
  BUNDLES: 6,
  SNACKS: 5860
}

export const SORTING_TYPES = {
  FOR_YOU: 'FOR_YOU'
}

export const SORTING_MEAL_PROPERTIES = {
  FOR_YOU: 'FOR_YOU',
  DEFAULT: 'DEFAULT',
  ALL: 'ALL'
}

export const SURVEY_NAMES = {
  FOR_YOU: 'for_you'
}

export const ADDONS_CATEGORIES = [6, 11, 13]
export const MEALS_CATEGORY = 3

export const INVENTORY_ID_PREFIX = {
  MEAL: 'ii',
  BUNDLE: 'bd'
}

export const FILTER_IDS_URL = {
  1: 'cuisines',
  2: 'chef',
  3: 'diets',
  4: 'proteins',
  5: 'preferences'
}

export const CHEF_FILTER_ID = 2

export const SEARCH_RECENTS_STORAGE_ID = 'search-recents'
