import { useState, useEffect } from 'react'
import { CuiIcon, CuiMealCardLoading } from 'front-lib'
import classNames from 'classnames'
import { ChallengeState } from 'src/types/challenge'
import './Step.scss'

interface Props {
  status: string
  index: number
  title: string
  icon: string | null
  openStep: number
  isLoading: boolean
  handleSelectStep: (step: number) => void
  children?: React.ReactNode
}

export function Step({
  index,
  status,
  title,
  icon,
  openStep,
  isLoading,
  handleSelectStep,
  children
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(index === openStep)
  }, [openStep, index])

  const handleClick = () => {
    if (isOpen) {
      handleSelectStep(0)
    } else {
      handleSelectStep(index)
    }
  }

  const chevronClasses = classNames('fas fa-xs', {
    'fa-chevron-down': children && !isOpen,
    'fa-chevron-up': children && isOpen
  })

  const indexClasses = classNames({
    pending: status === ChallengeState.Pending,
    checkmark: status === ChallengeState.Completed,
    disabled: !Object.values(ChallengeState).includes(status as ChallengeState)
  })

  const containerClasses = classNames('step-container', {
    loading: isLoading
  })

  return (
    <div className={containerClasses}>
      {isLoading ? (
        <CuiMealCardLoading />
      ) : (
        <div className="header" onClick={handleClick}>
          <div className="icon-status-container">
            {status === ChallengeState.Completed ? (
              <CuiIcon className={indexClasses} name={'checkmark'} />
            ) : (
              <div className={indexClasses}>
                <span>{index}</span>
              </div>
            )}
          </div>
          <div className="icon-separator-container">
            <div className="step-title-container">
              <span className="label">{title}</span>
              {icon && <CuiIcon name={icon} />}
            </div>
            <i className={chevronClasses} />
          </div>
        </div>
      )}

      {isOpen && <div className={'step-body'}>{children}</div>}
    </div>
  )
}
