import { MembershipMenuItem } from './components/userMenu/MembershipMenuItem'
import { MenuItem } from './components/userMenu/MenuItem'

function getInitials(fullName) {
  if (!fullName) {
    return ''
  }

  const [firstNameInitial, lastNameInitial] = fullName
    .split(' ')
    .filter(e => e) // Filter empties
    .slice(0, 2) // Ger first letter

  return [
    ...(firstNameInitial ? [firstNameInitial.trim()[0]] : ['']),
    ...(lastNameInitial ? [lastNameInitial.trim()[0]] : [''])
  ]
    .join('')
    .trim()
}

function getUserFirstName(fullName) {
  if (!fullName) {
    return ''
  }

  return fullName.split(' ')[0].trim()
}

function getMenuItem(icon: string, label: string) {
  switch (label) {
    case 'header.tab.unity_pass':
      return MembershipMenuItem()
    default:
      return MenuItem({ icon, label })
  }
}

export { getInitials, getUserFirstName, getMenuItem }
