import { useVariant } from '@unleash/proxy-client-react'

const MaintenanceModeProvider = ({ children }) => {
  const variant = useVariant('maintenance-mode')
  if (variant && variant.enabled) {
    window.location = variant.payload.value
  } else {
    return children
  }
}

export default MaintenanceModeProvider
