import { put, call, takeEvery, all } from 'redux-saga/effects'
import { GQL } from '../services'
import history from '../history'
import {
  REACTIVATE_SUBSCRIPTION_INIT,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE
} from '../actions/types'
import { toaster } from '../../utils/toaster'
import { pixel } from 'src/utils/pixel'
import { gtm } from 'src/utils/googleTagManager'
import { trackGrossResurrectionEvent } from 'src/utils/tracking'

function removeEmpty(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})
}

function getRedirectUrl(route, params) {
  const cleanParams = removeEmpty(params)
  const query = new URLSearchParams(cleanParams).toString()
  return `${route}?${query}`
}

export function* reactivateSubscription({ payload }) {
  try {
    const resurrectionCampaign = payload?.resurrectionCampaign
      ? {
          id: payload.resurrectionCampaign.id || null,
          chefId: payload.resurrectionCampaign.chefId || null,
          chefBrandId: payload.resurrectionCampaign.chefBrandId || null,
          highlightedMealsIds:
            payload.resurrectionCampaign.highlightedMealsIds || [],
          couponCode: payload.resurrectionCampaign.couponCode || null,
          prepaidCampaign: payload.resurrectionCampaign.prepaidCampaign || null,
          chefBrandIds: payload.resurrectionCampaign.chefBrandIds || null
        }
      : null

    const subscriptionData = yield call(
      GQL.reactivateSubscription,
      resurrectionCampaign
    )

    const urlParams = new URLSearchParams(window.location.search)
    const mealIds = urlParams.get('mealIds')

    let prepaidResponse = {}
    let redirectToDeliveryDate
    let chefIds

    const couponCode = resurrectionCampaign?.couponCode || payload?.coupon
    const prepaidOffer =
      resurrectionCampaign?.prepaidCampaign || payload?.prepaidOfferId

    const isResurrected = couponCode !== 'RES-35X6'

    if (couponCode) {
      try {
        yield call(GQL.consumeCoupon, couponCode, isResurrected)
      } catch (error) {
        console.error(error)
      }
    }

    if (prepaidOffer) {
      prepaidResponse = yield call(GQL.assignPrepaidOffer, prepaidOffer)
      if (prepaidResponse?.assignPrepaidOffer?.status) {
        toaster.success(
          <span>
            Your prepaid offer was successfully applied to your subscription.
          </span>,
          { autoClose: 5000 }
        )
      } else {
        toaster.error(
          <span>
            There was an error with your prepaid offer. Please contact support.
          </span>,
          { autoClose: 5000 }
        )
      }
    }

    if (resurrectionCampaign?.id) {
      try {
        const chefCartResponse = yield call(
          GQL.generateCartFromResurrectionCampaign,
          {
            campaignSlug: resurrectionCampaign.id,
            chefId: resurrectionCampaign.chefId,
            chefBrandId: resurrectionCampaign.chefBrandId,
            chefBrandIds: resurrectionCampaign.chefBrandIds,
            highlightedMealsIds: resurrectionCampaign.highlightedMealsIds,
            noPopulateCart: urlParams.get('npc') === '1'
          }
        )
        redirectToDeliveryDate =
          chefCartResponse?.generateCartFromResurrectionCampaign?.deliveryDate

        chefIds =
          chefCartResponse?.generateCartFromResurrectionCampaign?.chefIds
      } catch (error) {
        console.error(error)
      }
    }

    toaster.success(
      <span>
        Your subscription was successfully <b>reactivated</b>. See what's new
        and order your next delivery!
      </span>,
      { autoClose: 5000 }
    )

    pixel.trackPageView()
    /*const pixelData = {
      name: 'Purchase',
      properties: {
        isResurrected: true,
        resurrectionCampaignId: resurrectionCampaign?.id || null,
        couponCode: couponCode || null
      }
    }

    pixel.trackEvent(pixelData)*/

    gtm.trackEvent({
      name: 'conversion',
      properties: {
        event_category: 'subscription',
        event_action: 'resubscribe',
        campaign_id: resurrectionCampaign?.id,
        coupon_code: couponCode
      }
    })

    // Ensure consistent data structure
    const resurrectionData = {
      resurrectionCampaign,
      couponCode: resurrectionCampaign?.couponCode || payload?.coupon || null,
      prepaidOfferId:
        resurrectionCampaign?.prepaidCampaign ||
        payload?.prepaidOfferId ||
        null,
      subscriptionData: subscriptionData?.publicCreateSubscriptionEvent
        ? {
            userId: subscriptionData.publicCreateSubscriptionEvent.userId,
            isReactivation: Boolean(
              subscriptionData.publicCreateSubscriptionEvent.isReactivation
            ),
            resurrectionCount:
              Number(
                subscriptionData.publicCreateSubscriptionEvent.resurrectionCount
              ) || 0,
            lastResurrectionDate:
              subscriptionData.publicCreateSubscriptionEvent
                .lastResurrectionDate,
            email: subscriptionData.publicCreateSubscriptionEvent.email || null
          }
        : null
    }

    if (!resurrectionData.subscriptionData) {
      console.error('Failed to get subscription data from GraphQL')
    }

    trackGrossResurrectionEvent(resurrectionData)

    yield put({
      type: REACTIVATE_SUBSCRIPTION_SUCCESS,
      payload: subscriptionData
    })

    // TODO Find a way to reload subscription data using redux from this saga or remove sagas.
    setTimeout(() => {
      if (redirectToDeliveryDate) {
        const url = getRedirectUrl('/meals', {
          date: redirectToDeliveryDate,
          chefIds,
          mealIds
        })
        history.push(url)
      } else {
        history.push('/validate-cc')
      }
      window.location.reload()
      // Enough time to read the toast message
    }, 5000)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Reactivation error:', error)
    yield put({
      type: REACTIVATE_SUBSCRIPTION_FAILURE,
      error
    })
  }
}

export default function* rootInitialSaga() {
  yield all([takeEvery(REACTIVATE_SUBSCRIPTION_INIT, reactivateSubscription)])
}
