import './MyPlanPage.scss'

import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SettingsCard from '../../components/SettingsCard'
import { getUserData } from 'src/redux-api-bridge'
import {
  getUserSelector,
  myAccountIsLoadingSelector
} from 'src/modules/MyAccount/selectors'
import { planIsLoading as planIsLoadingSelector } from './selectors'

import Analytics from '../../utils/analytics'
import Header from 'src/components/Header/Header'
import Loader from 'src/common/Loader'
import { MyFlexModePlan } from './components/MyFlexModePlan/MyFlexModePlan'
import { View } from '../../modules/MyAccount/components/View/View'
import { useMobileDevice } from '../../hooks/useMobileDevice'
import { isFlexPlanSelector } from 'src/redux-api-bridge/selectors'
import { PausePlanSettings } from './components/PausePlanSettings/PausePlanSettings'
import { ChangePlanSettings } from './components/ChangePlanSettings/ChangePlanSettings'
import { useQueryParams } from '../../hooks/useQueryParams'
import { MyPlanSettingsHeader } from './components/MyPlanSettingsHeader/MyPlanSettingsHeader'
import { ModalsContainer } from './components/ModalsContainer/ModalsContainer'
import { usePlanConfiguration } from './hooks/usePlanConfiguration'

export const MyPlanPage: FC = () => {
  const { t } = useTranslation()
  const user = useSelector(getUserSelector)
  const isFlexPlan = useSelector(isFlexPlanSelector)

  const myAccountIsLoading = useSelector(myAccountIsLoadingSelector)
  const planIsLoading = useSelector(planIsLoadingSelector)
  const { query } = useQueryParams()

  const dispatch = useDispatch()
  const isMobileDevice = useMobileDevice()

  const { mealsPerWeek, planName } = usePlanConfiguration()

  useEffect(() => {
    if (!user) {
      dispatch(getUserData())
    }
  }, [user, dispatch])

  useEffect(() => {
    if (query.get('weeks') !== null && mealsPerWeek > 1) {
      Analytics.track('Pause-Plan', {
        plan_name: planName,
        is_paused: true,
        pause_duration: `${query.get('weeks')} Weeks`,
        origin: 'brightback',
        reason: transformReason(query.get('reason'))
      })
    }
  }, [planName, mealsPerWeek, query])

  const transformReason = brightbackReason => {
    const brightbackReasonsMapper = {
      i_am_not_happy_with_the_service: "i'm not happy with the service",
      ill_try_out_another_service: 'I will try out other service',
      pricing_dont_see_value: 'I am not happy with the service',
      i_just_need_a_break: 'I just need a break',
      i_need_to_cut_down_my_budge: 'I need to cut down my budget',
      other_: 'I’ll be out of town'
    }

    for (const key in brightbackReasonsMapper) {
      const regex = new RegExp(`${key}_\\d+`)
      if (regex.test(brightbackReason)) {
        return brightbackReasonsMapper[key]
      }
    }
    return brightbackReason
  }

  if (myAccountIsLoading) {
    return <Loader />
  }

  if (isFlexPlan) {
    return <MyFlexModePlan />
  }

  return (
    <>
      <Header />
      <ModalsContainer />
      <div className="cookunity__account_settings_container">
        {!isMobileDevice && (
          <div className="cookunity__account_settings_header">
            <MyPlanSettingsHeader />
          </div>
        )}

        <div className="cookunity__account_settings">
          <View hideBack={true}>
            <SettingsCard
              hideTitle={!isMobileDevice}
              title={t('pages.my_plan.my_plan')}
              isLoading={planIsLoading}>
              <ChangePlanSettings />
              <PausePlanSettings />
            </SettingsCard>
          </View>
        </div>
      </div>
    </>
  )
}
