export const limitNavegation = (history, eventSubs, location, routes) => {
  const routeHold = routes.find(elem => elem.name === 'Hold')

  if (
    eventSubs === routeHold.name.toLowerCase() &&
    location.pathname !== routeHold.path
  ) {
    history.push(routeHold.path)
  }
}
