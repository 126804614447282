import { FC } from 'react'
import { CuiIcon } from 'front-lib'
import { useDispatch } from 'react-redux'
import { openChallengePanel } from 'src/redux-api-bridge/challenge/actions'

import './ChallengeMenu.scss'

const ChallengeMenu: FC = () => {
  const dispatch = useDispatch()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(openChallengePanel())
  }

  return (
    <>
      <div className={'challenge-menu'}>
        <div className={'challenge-menu--container'}>
          <button onClick={handleClick} className={'challenge-menu--button'}>
            <CuiIcon name={'gift'} />
          </button>
        </div>
      </div>
    </>
  )
}

export default ChallengeMenu
