import './ConfirmationPlanChangeModal.scss'

import { CuiBottomSheet, CuiButton, CuiIcon } from 'front-lib'
import { useTranslation, Trans } from 'react-i18next'

export function ConfirmationPlanChangeModalMobile({ isOpen, handleClose }) {
  const { t } = useTranslation()

  return (
    <CuiBottomSheet isOpen={isOpen}>
      <CuiBottomSheet.Body>
        <button
          className={
            'flex-mode__confirmation-modal__close-icon flex-mode__confirmation-modal__close-icon-bottom-sheet'
          }
          onClick={handleClose}>
          <CuiIcon name="close" />
        </button>
        <div className="flex-mode__confirmation-modal__container">
          <div className="flex-mode__confirmation-modal__title-container">
            <h1 className="flex-mode__confirmation-modal__title">
              {t(
                'pages.my_plan.flex_mode.modal_confirmation.you_ve_switched_back.mobile'
              )}
            </h1>
          </div>
          <div className="flex-mode__confirmation-modal__text-container">
            <p className="flex-mode__confirmation-modal__text">
              <Trans t={t}>
                {t(
                  'pages.my_plan.flex_mode.modal_confirmation.your_plan_is_currently_4_meals.mobile'
                )}
              </Trans>
            </p>
          </div>
          <div className="flex-mode__confirmation-modal__buttons-container">
            <CuiButton
              fill="solid"
              size="large"
              color="primary"
              onClick={handleClose}>
              {t('pages.my_plan.flex_mode.modal_confirmation.got_it')}
            </CuiButton>
          </div>
        </div>
      </CuiBottomSheet.Body>
    </CuiBottomSheet>
  )
}
