export const SIDES_QUICKFILTERS = [
  {
    id: '1-all',
    image: 'https://static.cookunity.com/cross/front-lib/icons/menuby-all.svg',
    name: 'All',
    label: 'All',
    pattern: ''
  },
  {
    id: '2-veggies-and-salads',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/sides-veggies-salads.svg',
    name: 'Veggies and Salads',
    label: 'Veggies and Salads',
    pattern: 'Veggies & Salads'
  },
  {
    id: '3-grains-and-starch',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/sides-grains-starch.svg',
    name: 'Grains and Starch',
    label: 'Grains and Starch',
    pattern: 'Grains & Starches'
  },
  {
    id: '4-pasta-and-bread',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/sides-pasta-bread.svg',
    name: 'Pasta and Bread',
    label: 'Pasta and Bread',
    pattern: 'Pasta & Bread'
  },
  {
    id: '5-finger-food',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/sides-finger-food.svg',
    name: 'Finger Food',
    label: 'Finger Food',
    pattern: 'Finger Foods'
  },
  {
    id: '6-side-soups',
    image: 'https://static.cookunity.com/cross/front-lib/icons/sides-soups.svg',
    name: 'Side Soups',
    label: 'Side Soups',
    pattern: 'Side Soups'
  },
  {
    id: '7-side-protein',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/sides-protein.svg',
    name: 'Protein',
    label: 'Protein',
    pattern: 'Protein'
  }
]

export const BREAKFAST_QUICKFILTERS = [
  {
    id: '1-all',
    image: 'https://static.cookunity.com/cross/front-lib/icons/menuby-all.svg',
    name: 'All',
    label: 'All',
    pattern: ''
  },
  {
    id: '2-eggs',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-eggs.svg',
    name: 'Eggs',
    label: 'Eggs',
    pattern: 'Eggs'
  },
  {
    id: '3-pancakes-waffles',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-pancakes-waffles.svg',
    name: 'Pancakes & Waffles',
    label: 'Pancakes & Waffles',
    pattern: 'Pancakes & Waffles'
  },
  {
    id: '4-yogurt-granola-oatmeal',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-yogurt-granola.svg',
    name: 'Yogurt, Granola & Oatmeal',
    label: 'Yogurt, Granola & Oatmeal',
    pattern: 'Yogurt'
  },
  {
    id: '5-bread-pastries',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bread-pastries.svg',
    name: 'Bread & Pastries',
    label: 'Bread & Pastries',
    pattern: 'Bread & Pastries'
  },
  {
    id: '6-smoothies-juices',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-juices.svg',
    name: 'Smoothies & Juices',
    label: 'Smoothies & Juices',
    pattern: 'Smoothies & Juices'
  },
  {
    id: '7-bars',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/breakfast-bar.svg',
    name: 'Bars',
    label: 'Bars',
    pattern: 'Bars'
  }
]

export const SAUCES_QUICKFILTERS = [
  {
    id: '1-all',
    image: 'https://static.cookunity.com/cross/front-lib/icons/menuby-all.svg',
    name: 'All',
    label: 'All',
    pattern: ''
  },
  {
    id: '40-sauces',
    image: 'https://static.cookunity.com/cross/front-lib/icons/qf_Sauces.svg',
    name: 'Sauces',
    label: 'Sauces',
    pattern: 'Sauces'
  },
  {
    id: '41-dips',
    image: 'https://static.cookunity.com/cross/front-lib/icons/qf_Dips.svg',
    name: 'Dips',
    label: 'Dips',
    pattern: 'Dips'
  },
  {
    id: '42-condiments-dressing',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/qf_Condiments_Dressings.svg',
    name: 'Dressings',
    label: 'Dressings',
    pattern: 'Condiments & Dressings'
  },
  {
    id: '42-spices-seasoning',
    image:
      'https://static.cookunity.com/cross/front-lib/icons/qf_Spices_Seasonings.svg',
    name: 'Spices',
    label: 'Spices',
    pattern: 'Spices & Seasonings'
  }
]

export const SIDES_CATEGORY_ID = 3632
export const SAUCES_CATEGORY_ID = 5808
export const DRINKS_CATEGORY_ID = 33
export const TREATS_CATEGORY_ID = 34
export const MEALS_CATEGORY_ID = 0
export const MEALS_CATEGORY_LABEL = 'Meals'
export const BREAKFAST_CATEGORY_LABEL = 'Breakfast'
export const SIDES_CATEGORY_LABEL = 'Sides'
export const BREAKFAST_CATEGORY_ID = 3110
export const DEFAULT_OPTION_ID = '1-all'
export const MOBILE_SCROLL_POSITION = 530
export const DESKTOP_SCROLL_POSITION = 515
export const DEFAULT_OPTION_LABEL = 'All'

export const CATEGORY_PROMOTIONS_MAP = {
  [SIDES_CATEGORY_ID]: 'Sides',
  [SAUCES_CATEGORY_ID]: 'Sauces',
  [DRINKS_CATEGORY_ID]: 'Drinks',
  [TREATS_CATEGORY_ID]: 'Treats',
  [MEALS_CATEGORY_ID]: 'Meals',
  [BREAKFAST_CATEGORY_ID]: 'Breakfast'
}

export const DEFAULT_OPTION = {
  id: '1-all'
}

export const FILTER_TYPES = {
  STICKY: 'Sticky',
  QUICK: 'Quick',
  ADVANCED: 'Advanced',
  SEACRH: 'Search',
  PERSONALIZED: 'Personalized'
}

export const ID_QUICK_FILTER_ALREADY_REQUESTED = '1-youvetried'
export const ID_QUICK_FILTER_FAVORITES = '2-fav'
export const ID_QUICK_FILTER_PREMIUM = '3-premium'

export const FILTERS_BY_CATEGORY = {
  [SIDES_CATEGORY_ID]: SIDES_QUICKFILTERS,
  [BREAKFAST_CATEGORY_ID]: BREAKFAST_QUICKFILTERS,
  [SAUCES_CATEGORY_ID]: SAUCES_QUICKFILTERS
}
