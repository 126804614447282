import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { dayByDeliveryDateSelector } from 'src/components/DeliveryCard/selectors'
import { formatFixedPrice } from 'src/utils/utils'

export function useDeflectionWithPromoCopies({
  payload,
  type,
  credits,
  promotions
}) {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [detail, setDetail] = useState('')
  const [cancelText, setCancelText] = useState('')
  const [confirmText, setConfirmText] = useState('')
  const day = useSelector(state =>
    dayByDeliveryDateSelector(state, payload.displayDate)
  )

  useLayoutEffect(() => {
    const [promotion] = promotions

    const promotionsKey = promotion ? '_promotions' : ''
    const creditsKey = credits !== '0' ? '_credits' : ''
    const key = `${type}${promotionsKey}${creditsKey}`

    const copies = getCopies({ key })

    setTitle(copies.title)
    setDescription(copies.description)
    setDetail(copies.detail)
    setCancelText(copies.cancel)
    setConfirmText(copies.confirm)

    // eslint-disable-next-line
  }, [type])

  function getCopies({ key }) {
    const [promotion] = promotions
    const formattedCredits = formatFixedPrice(credits)

    switch (key) {
      case 'cancel_credits':
        return {
          cancel: 'Use your credits',
          confirm: 'Cancel subscription',
          description: t(
            'skip_deflection_with_promo.cancel_with_credits_description',
            {
              credits: formattedCredits
            }
          ),
          detail: '',
          title: t('skip_deflection_with_promo.cancel_with_credits_title')
        }
      case 'cancel_promotions':
        return {
          cancel: 'Use your discount',
          confirm: 'Cancel subscription',
          description: t(
            'skip_deflection_with_promo.cancel_with_promo_description',
            {
              offer: promotion.offer
            }
          ),
          detail: '',
          title: t('skip_deflection_with_promo.dont_miss_out_title', {
            offer: promotion.offer
          })
        }
      case 'cancel_promotions_credits':
        return {
          cancel: 'Use your credits',
          confirm: 'Cancel subscription',
          description: t(
            'skip_deflection_with_promo.cancel_with_credits_and_discounts_description',
            {
              credits: formattedCredits
            }
          ),
          detail: '',
          title: t(
            'skip_deflection_with_promo.cancel_with_credits_and_discounts_title'
          )
        }
      case 'pause_promotions_credits':
      case 'pause_promotions':
        return {
          cancel: 'Use your discount',
          confirm: 'Pause Plan',
          description: t('skip_deflection_with_promo.pause_description', {
            offer: promotion.offer
          }),
          detail: '',
          title: t('skip_deflection_with_promo.dont_lose_out_title', {
            offer: promotion.offer
          })
        }
      case 'skip_promotions_credits':
      case 'skip_promotions':
        const deliveryDate = moment(day.displayDate).format('MMM. D')
        const cutoffDate = moment(day.cutoff.time).format('dddd, MMM. D')
        return {
          cancel: 'Use your discount',
          confirm: 'Skip order',
          description: t('skip_deflection_with_promo.skip_description', {
            offer: promotion.offer
          }),
          detail: t('skip_deflection_with_promo.skip_detail', {
            deliveryDate,
            cutoffDate
          }),
          title: t('skip_deflection_with_promo.dont_miss_out_title', {
            offer: promotion.offer
          })
        }
      default:
        return {
          cancel: '',
          confirm: '',
          description: '',
          detail: '',
          title: ''
        }
    }
  }

  return {
    title,
    description,
    detail,
    cancelText,
    confirmText
  }
}
