import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'
import { useMobileDevice } from 'src/hooks/useMobileDevice'
import { ReactComponent as Info } from 'src/assets/svg/info-empty-circled.svg'
import './FormItemHeader.scss'

type FormItemHeaderProps = {
  title?: string
  subtitle?: string
  info?: string
  className?: string
}

const FormItemHeader = ({
  title,
  subtitle,
  info,
  className
}: FormItemHeaderProps) => {
  const isMobile = useMobileDevice()
  return (
    <div className={classNames('preferences-item__header', className)}>
      <div className="preferences-item__header-title">
        {title}
        {!!info && (
          <>
            <Info data-tooltip-id={`form-header-${title}`} />
            <Tooltip
              id={`form-header-${title}`}
              className="preferences-item__header-tooltip-container"
              opacity={1}
              closeEvents={{ click: true, mouseleave: true, blur: true }}
              place={isMobile ? 'bottom' : 'top'}
              globalCloseEvents={{ clickOutsideAnchor: true }}
              openOnClick={isMobile}>
              <div className="preferences-item__header-tooltip">{info}</div>
            </Tooltip>
          </>
        )}
      </div>
      {subtitle && (
        <p
          className="preferences-item__header-subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </div>
  )
}

export { FormItemHeader }
