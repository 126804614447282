import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CuiButton } from 'front-lib'

import Modal from '../../../../components/NewModal'
import Form, { Row, Field, Col, ButtonRow, ErrorMessage } from '../Form'
import './AddPartner.scss'

const nameIsValid = value => value.trim().match(/^[a-zA-Z ]{2,20}$/)

const AddPartner = props => {
  const { isOpen, onRequestClose, createProfile } = props

  return (
    <Modal mediumSize isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="cookunity__add_partner_modal">
        <h2 className="title">What’s your Partner’s name?</h2>

        <Formik
          isInitialValid={false}
          initialValues={{
            firstname: ''
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string().test(
              'is-valid',
              'Use a valid name',
              value => value && nameIsValid(value)
            )
          })}
          onSubmit={(values, { setSubmitting }) => {
            createProfile(values.firstname.trim())
            onRequestClose()
            setSubmitting(false)
          }}>
          {({ isValid, isSubmitting }) => {
            return (
              <Form>
                <Row>
                  <Col>
                    <Field
                      type="text"
                      name="firstname"
                      placeholder="Enter their name"
                    />
                    <ErrorMessage name="firstname" component="div" />
                  </Col>
                </Row>
                <ButtonRow>
                  <CuiButton
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !isValid}>
                    Confirm
                  </CuiButton>
                  <CuiButton fill="clear" size="large" onClick={onRequestClose}>
                    Cancel
                  </CuiButton>
                </ButtonRow>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default AddPartner
