import actionCreator from '../../utils/actionCreator'
import { PFX } from '../actions'

export const getThreads = actionCreator(PFX, 'get_threads')
export const getCurrentThread = actionCreator(PFX, 'get_current_thread')
export const changeStatusThread = actionCreator(PFX, 'change_status_thread')

export const createMessage = actionCreator(PFX, 'create_message')
export const createThread = actionCreator(PFX, 'create_thread')

export const getNotifications = actionCreator(PFX, 'get_notifications')
export const changeStatusNotification = actionCreator(
  PFX,
  'change_status_notification'
)
