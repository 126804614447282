import classNames from 'classnames'
import { HOUSEHOLD_ICON_BY_ID } from 'src/pages/preferences/utils'
import { HOUSEHOLD_OPTION_IDS } from 'src/types/preferences'
import './HouseholdOption.scss'

type HouseholdOptionProps = {
  id: HOUSEHOLD_OPTION_IDS
  label: string
  isSelected: boolean
  onClick: Function
}

const HouseholdOption = ({
  id,
  label,
  isSelected,
  onClick
}: HouseholdOptionProps) => {
  const Icon = HOUSEHOLD_ICON_BY_ID[id]

  return (
    <div
      className={classNames('household-option', { selected: isSelected })}
      onClick={() => onClick()}>
      <Icon />
      {label}
    </div>
  )
}

export default HouseholdOption
