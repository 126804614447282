import { gql, useMutation } from '@apollo/client'

export const CHANGE_DEFAULT_PLAN_MUTATION = gql`
  mutation changeDefaultPlan($planChange: ChangePlanInput!) {
    changePlan(planChange: $planChange) {
      __typename
      ... on PlanChangeInfo {
        dateInEffect
      }
      ... on PlanChangeError {
        error
      }
    }
  }
`
// Old change plan endpoint - migrate to useChangeUserPlan
export function useChangeDefaultPlan({
  onError,
  onSuccess
}: {
  onError?: () => void
  onSuccess?: () => void
} = {}) {
  const [mutation, rest] = useMutation(CHANGE_DEFAULT_PLAN_MUTATION, {
    onError,
    onCompleted: onSuccess,
    notifyOnNetworkStatusChange: true
  })

  function changeDefaultPlan({
    planChange
  }: {
    planChange: {
      plan: number
      deliveryDays: { days: string[]; start: string; end: string }
    }
  }) {
    mutation({ variables: { planChange } })
  }

  return {
    changeDefaultPlan,
    ...rest
  }
}
