import { gql, useMutation } from '@apollo/client'

const STOP_MEMBERSHIP = gql`
  mutation stopMembership($membershipId: Int!) {
    stopMembership(membershipId: $membershipId) {
      success
      error
    }
  }
`

export function useStopMembership({ onCompleted, onError }) {
  const [mutate, rest] = useMutation(STOP_MEMBERSHIP, {
    refetchQueries: ['UserMembershipActivation'],
    onCompleted,
    onError
  })

  return {
    stopMembership: variables => mutate({ variables }),
    ...rest
  }
}
