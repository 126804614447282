import { useEffect } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { useSelector } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'

import { EconomicPlanForeverModal } from 'src/componentsV2/EconomicPlanForeverModal/EconomicPlanForeverModal'
import { EconomicPlanModal } from '../EconomicPlanModal/EconomicPlanModal'
import { ConfirmationPlanChangeModalOverlay } from '../MyFlexModePlan/components/confirmationPlanChange/ConfirmationPlanChangeModalOverlay'
import { economicPlanModalAtom } from 'src/atoms/modals/economicPlanModal.atom'
import { confirmationPlanChangeModalAtom } from 'src/atoms/modals/confirmationPlanChangeModal.atom'
import { getUserSelector } from 'src/modules/MyAccount/selectors'
import { isEconomicPlanSelector } from 'src/redux-api-bridge/selectors'
import { CLIENTS_TO_SHOW_VALUE_PLAN_MOBILE_MODAL_IN_MY_PLAN_PAGE } from '../../clientsToShowValuePlanMobileModalInMyPlanPage'
import { FF_ENABLE_MOBILE_VALUE_PLAN_MODAL } from 'src/constants/feature-flags'
import { mobileValuePlanModalAtom } from 'src/atoms/modals/mobileValuePlanModal.atom'

export function ModalsContainer() {
  const user = useSelector(getUserSelector)
  const isEconomicPlan = useSelector(isEconomicPlanSelector)
  const enableMobileValuePlanModal = useFlag(FF_ENABLE_MOBILE_VALUE_PLAN_MODAL)

  const economicPlanModal = useAtomValue(economicPlanModalAtom)
  const confirmationPlanChangeModal = useAtomValue(
    confirmationPlanChangeModalAtom
  )
  const [mobileValuePlanModal, setMobileValuePlanModal] = useAtom(
    mobileValuePlanModalAtom
  )

  useEffect(() => {
    const showModal =
      !!user &&
      enableMobileValuePlanModal &&
      isEconomicPlan &&
      CLIENTS_TO_SHOW_VALUE_PLAN_MOBILE_MODAL_IN_MY_PLAN_PAGE.has(user?.email)

    setMobileValuePlanModal({ isOpen: showModal })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, enableMobileValuePlanModal, isEconomicPlan])

  return (
    <>
      {economicPlanModal.isOpen && <EconomicPlanModal />}
      {mobileValuePlanModal.isOpen && (
        <EconomicPlanForeverModal isMobileView={true} />
      )}
      {confirmationPlanChangeModal.isOpen && (
        <ConfirmationPlanChangeModalOverlay />
      )}
    </>
  )
}
