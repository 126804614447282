import {
  ALLERGENS_IDS,
  MACRONUTRIENT_FITLERS,
  PREFERENCES_IDS
} from 'src/componentsV2/Filters/CuiFiltersPanel/constants'

export const getSelectedProfilePreference = (state, filters, useAllergens) => {
  const currentProfile = state.selectedProfilePreference
  const { restrictions = [], diets = [], tastes = [] } = currentProfile || {}

  const dietsMatched = diets.every(diet =>
    filters?.diets?.some(profileDiet => profileDiet.id === diet.id)
  )

  const proteinsMatched = tastes.every(protein =>
    filters?.proteins?.some(
      profileProtein => profileProtein.name === protein.name
    )
  )

  const spicyPreference = state.filterTypes?.preferences.find(
    ({ name }) => name === 'Spicy'
  )

  const notIngredientsAvoidsAndNotSpicyPreferences = restrictions
    .filter(r => r.id[0] !== 'i')
    .filter(r => Number(r.id.slice(1)) !== spicyPreference.id)

  const preferencesMatched = notIngredientsAvoidsAndNotSpicyPreferences.every(
    restriction => {
      const [restrictionType, ...id] = restriction.id
      const restrictionId = Number(id.join(''))

      if (!useAllergens && restrictionType === 'a') return true

      const preferenceId =
        restrictionType === 'a'
          ? getPreferenceIdByAllergenId(
              restrictionId,
              notIngredientsAvoidsAndNotSpicyPreferences
            )
          : restrictionId

      if (!preferenceId) return true

      return filters?.preferences?.some(
        profilePreference => profilePreference.id === preferenceId
      )
    }
  )
  return {
    selectedProfilePreference:
      dietsMatched && proteinsMatched && preferencesMatched
        ? currentProfile
        : null
  }
}

export const getProfilePreferences = (
  preferences,
  restrictions,
  useAllergens = false
) => {
  const filteredPreferences = preferences.filter(({ name }) => name !== 'Spicy')

  const mappedPreferences =
    restrictions?.reduce((prev, current) => {
      const [restrictionType, ...id] = current.id
      const restrictionId = Number(id.join(''))

      if (restrictionType === 'i') return prev

      if (restrictionType === 'a') {
        if (!useAllergens) return prev

        const preferenceId = getPreferenceIdByAllergenId(
          restrictionId,
          restrictions
        )
        const preference = filteredPreferences.find(
          ({ id }) => id === preferenceId
        )
        return preference ? [...prev, preference] : prev
      }

      const preference = filteredPreferences.find(
        ({ id }) => id === restrictionId
      )
      return preference ? [...prev, preference] : prev
    }, []) ?? []

  const removedDuplicatedPreferences = new Map(
    mappedPreferences.map(m => [m.id, m])
  ).values()
  return [...removedDuplicatedPreferences]
}

const getPreferenceIdByAllergenId = (allergenId, restrictions) => {
  if (allergenId === ALLERGENS_IDS.DAIRY_FREE) return PREFERENCES_IDS.DAIRY_FREE

  if (allergenId === ALLERGENS_IDS.SOY_FREE) return PREFERENCES_IDS.SOY_FREE
  if (
    allergenId === ALLERGENS_IDS.TREENUT_FREE ||
    allergenId === ALLERGENS_IDS.PEANUT_FREE
  ) {
    const hasPeanutsAndTreenuts = [
      ALLERGENS_IDS.TREENUT_FREE,
      ALLERGENS_IDS.PEANUT_FREE
    ].every(allergenId =>
      restrictions?.some(({ id }) => {
        const restrictionId = Number(id.slice(1))
        return restrictionId === allergenId
      })
    )
    return hasPeanutsAndTreenuts ? PREFERENCES_IDS.NUT_FREE : undefined
  }
}

export const createMacronutrientsInitialState = macronutrients => ({
  [MACRONUTRIENT_FITLERS.CALORIES]: {
    name: MACRONUTRIENT_FITLERS.CALORIES,
    preferenceId: PREFERENCES_IDS.LOW_CALORIES,
    min: 0,
    max: macronutrients.calories,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'cal'
  },
  [MACRONUTRIENT_FITLERS.CARBS]: {
    name: MACRONUTRIENT_FITLERS.CARBS,
    preferenceId: PREFERENCES_IDS.LOW_CARBS,
    min: 0,
    max: macronutrients.carbs,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'gr'
  },
  [MACRONUTRIENT_FITLERS.PROTEIN]: {
    name: MACRONUTRIENT_FITLERS.PROTEIN,
    preferenceId: PREFERENCES_IDS.HIGH_PROTEIN,
    min: 0,
    max: macronutrients.protein,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'gr'
  },
  [MACRONUTRIENT_FITLERS.FAT]: {
    name: MACRONUTRIENT_FITLERS.FAT,
    preferenceId: PREFERENCES_IDS.LOW_FAT,
    min: 0,
    max: macronutrients.fat,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'gr'
  },
  [MACRONUTRIENT_FITLERS.SODIUM]: {
    name: MACRONUTRIENT_FITLERS.SODIUM,
    preferenceId: PREFERENCES_IDS.LOW_SODIUM,
    min: 0,
    max: macronutrients.sodium,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'mg'
  },
  [MACRONUTRIENT_FITLERS.SUGAR]: {
    name: MACRONUTRIENT_FITLERS.SUGAR,
    preferenceId: PREFERENCES_IDS.LOW_SUGAR,
    min: 0,
    max: macronutrients.sugar,
    currentMinRange: null,
    currentMaxRange: null,
    applied: false,
    unitName: 'gr'
  }
})

export const clearMacronutrientsFilters = prevMacronutrients => {
  const macronutrients = {
    [MACRONUTRIENT_FITLERS.CALORIES]: prevMacronutrients.calories.max,
    [MACRONUTRIENT_FITLERS.CARBS]: prevMacronutrients.carbs.max,
    [MACRONUTRIENT_FITLERS.PROTEIN]: prevMacronutrients.protein.max,
    [MACRONUTRIENT_FITLERS.FAT]: prevMacronutrients.fat.max,
    [MACRONUTRIENT_FITLERS.SODIUM]: prevMacronutrients.sodium.max,
    [MACRONUTRIENT_FITLERS.SUGAR]: prevMacronutrients.sugar.max
  }

  return createMacronutrientsInitialState(macronutrients)
}
