import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { getImpersonator, getTokenSilently } from 'src/auth0'
import { setContext } from '@apollo/client/link/context'
import { getPlatform } from 'src/utils/utils'
import { RetryLink } from '@apollo/client/link/retry'

const API_URL = process.env.REACT_APP_SUBSCRIPTION_API_URL
const GRAPHQL_URI = `${API_URL}/graphql/user`

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: 'include'
})

const headersLink = setContext(async (_, { headers }) => {
  const authHeaders = await getAuthHeaders()
  return {
    headers: {
      ...headers,
      ...authHeaders,
      'cu-platform': getPlatform()
    }
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: 500,
    jitter: true
  },
  attempts: {
    max: 2,
    retryIf: error => !!error
  }
})

async function getAuthHeaders() {
  const impersonator = getImpersonator()
  if (impersonator && impersonator.cookieValue) {
    return
  }
  if (impersonator && impersonator.email) {
    return {
      'x-cook-impersonation-secret': impersonator.secret,
      'x-cook-impersonation-email': impersonator.email
    }
  }

  const token = await getTokenSilently()
  return {
    Authorization: token || ''
  }
}

export const apolloSubscriptionPrivateClient = new ApolloClient({
  link: retryLink.concat(headersLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})
