import createReducer from '../../utils/createReducer'
import { helperStatus, messagesThreadSorted } from '../helpers'

import * as actions from './actions'

const initialState = {
  inFlight: false,
  inFlightNotifications: false,
  succeeded: false,
  error: false,
  currentThread: {},
  threads: [],
  notifications: []
}

export default createReducer(initialState, {
  [actions.createMessage.start.type]: state => ({
    ...state,
    ...helperStatus({ inFlight: true, succeeded: false, error: false })
  }),
  [actions.createMessage.succeed.type]: (state, action) => ({
    ...state,
    ...helperStatus({ inFlight: false, succeeded: true, error: false }),
    currentThread: {
      ...state.currentThread,
      messages: [action.payload, ...state.currentThread.messages]
    }
  }),
  [actions.createMessage.failed.type]: (state, action) => ({
    ...state,
    ...helperStatus({
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    })
  }),
  [actions.createThread.succeed.type]: (state, action) => ({
    ...state,
    ...helperStatus({ inFlight: false, succeeded: true, error: false }),
    currentThread: action.payload
  }),
  [actions.createThread.failed.type]: (state, action) => ({
    ...state,
    error: action.payload.err
  }),
  [actions.getCurrentThread.start.type]: state => ({
    ...state,
    ...helperStatus({ inFlight: true, succeeded: false, error: false })
  }),
  [actions.getCurrentThread.succeed.type]: (state, action) => ({
    ...state,
    ...helperStatus({ inFlight: false, succeeded: true, error: false }),
    currentThread: {
      ...action.payload,
      messages: action.payload && messagesThreadSorted(action.payload.messages)
    }
  }),
  [actions.getCurrentThread.failed.type]: (state, action) => ({
    ...state,
    ...helperStatus({
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    })
  }),
  [actions.getThreads.start.type]: state => ({
    ...state,
    ...helperStatus({ inFlight: true, succeeded: false, error: false })
  }),
  [actions.getThreads.succeed.type]: (state, action) => ({
    ...state,
    ...helperStatus({ inFlight: false, succeeded: true, error: false }),
    threads: [...action.payload, ...state.threads]
  }),
  [actions.getThreads.failed.type]: (state, action) => ({
    ...state,
    ...helperStatus({
      inFlight: false,
      succeeded: false,
      error: action.payload.err
    })
  }),
  [actions.getNotifications.start.type]: state => ({
    ...state,
    inFlightNotifications: true
  }),
  [actions.getNotifications.succeed.type]: (state, action) => ({
    ...state,
    inFlightNotifications: false,
    notifications: action.payload
  }),
  [actions.getNotifications.failed.type]: state => ({
    ...state,
    inFlightNotifications: false
  }),
  [actions.changeStatusThread.start.type]: (state, action) => {
    const index = state.threads.findIndex(elem => elem.id === action.payload)
    return {
      ...state,
      threads: [
        ...state.threads.slice(0, index),
        {
          ...state.threads[index],
          inFlight: true
        },
        ...state.threads.slice(index + 1)
      ]
    }
  },
  [actions.changeStatusThread.succeed.type]: (state, action) => {
    const index = state.threads.findIndex(elem => elem.id === action.payload.id)
    return {
      ...state,
      threads: [
        ...state.threads.slice(0, index),
        {
          ...state.threads[index],
          ...action.payload,
          inFlight: false
        },
        ...state.threads.slice(index + 1)
      ]
    }
  },
  [actions.changeStatusThread.failed.type]: (state, action) => {
    const index = state.threads.findIndex(elem => elem.id === action.payload.id)
    return {
      ...state,
      threads: [
        ...state.threads.slice(0, index),
        {
          ...state.threads[index],
          inFlight: false
        },
        ...state.threads.slice(index + 1)
      ]
    }
  },
  [actions.changeStatusNotification.start.type]: (state, action) => {
    const index = state.notifications.findIndex(
      elem => elem.id === action.payload
    )
    return {
      ...state,
      notifications: [
        ...state.notifications.slice(0, index),
        {
          ...state.notifications[index],
          inFlight: true
        },
        ...state.notifications.slice(index + 1)
      ]
    }
  },
  [actions.changeStatusNotification.failed.type]: (state, action) => {
    const index = state.threads.findIndex(elem => elem.id === action.payload.id)
    return {
      ...state,
      threads: [
        ...state.threads.slice(0, index),
        {
          ...state.threads[index],
          inFlight: false
        },
        ...state.threads.slice(index + 1)
      ]
    }
  },
  [actions.changeStatusNotification.succeed.type]: (state, action) => {
    const index = state.notifications.findIndex(
      elem => elem.id === action.payload.id
    )
    return {
      ...state,
      notifications: [
        ...state.notifications.slice(0, index),
        {
          ...state.notifications[index],
          status: action.payload.status,
          inFlight: false
        },
        ...state.notifications.slice(index + 1)
      ]
    }
  }
})
