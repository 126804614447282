import { gql } from '@apollo/client'
import { apolloSubscriptionPublicClient } from './apolloSubscriptionPublicClient'
import { apolloSubscriptionPrivateClient } from './apolloSubscriptionPrivateClient'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query: (query, variables = {}) =>
    apolloSubscriptionPrivateClient.query({
      query: gql(query),
      variables
    }),
  publicQuery: (query, variables = {}) =>
    apolloSubscriptionPublicClient.query({
      query: gql(query),
      variables
    }),
  publicMutation: (mutation, variables = {}) =>
    apolloSubscriptionPublicClient.mutate({
      mutation: gql(mutation),
      variables
    }),
  mutation: (mutation, variables = {}) =>
    apolloSubscriptionPrivateClient.mutate({
      mutation: gql(mutation),
      variables
    })
}
