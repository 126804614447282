export interface OptionItem {
  label: string
  id: string | number
}

export type AutopilotQuestionOption = {
  id: string
  label: string
}

export enum AutopilotUserAttributes {
  HOUSEHOLD = 'household',
  STANDARD_MEALS = 'standardMeals',
  FLAVOR_DISCOVERY = 'flavorDiscovery',
  PREMIUM_MEALS = 'premiumMeals'
}

export enum StandardMealsQuestionResponses {
  RECOMMENDED_MEALS = 'recommended',
  PREVIOUS_ORDERS = 'previous-orders',
  MIX = 'mixed'
}

export enum AutopilotQuestionType {
  HOUSEHOLD = 'household',
  PRODUCTS_BASED = 'products-based'
}

export type AutopilotTabQuestion = {
  type: AutopilotQuestionType
  title?: string
  subtitle?: string
  info?: string
  question?: string
  clarification?: string
  allowMultipleSelection: boolean
  showOptInOptions: boolean
  options: AutopilotQuestionOption[]
  userAttribute: AutopilotUserAttributes
}

enum ComponentLayout {
  LIST = 'list',
  GRID = 'grid'
}

export type AutopilotTabSection = {
  title: string
  subtitle: string
  info: string
  layout: ComponentLayout
  items: AutopilotTabQuestion[]
}

export enum YES_NO_IDS {
  NO = 'no',
  YES = 'yes'
}

export type YesNoOptionType = {
  id: YES_NO_IDS
  label: string
}

export type UserProfile = {
  id: string
  firstname: string
}

export enum HOUSEHOLD_OPTION_IDS {
  JUST_ME = 'just-me',
  ME_AND_SOMEONE_ELSE = 'me-and-someone-else',
  ME_AND_MORE = 'me-and-more'
}
