export const reactivateSubscription = `
mutation reactivateSubscription($input: PublicSubscriptionEventInput!) {
    publicCreateSubscriptionEvent(input: $input) {
        ... on CreateSubscriptionEventErrorType {
            error
        }
        ... on CreateSubscriptionEventSuccesType {
            id
            userId
            event
            date
            isReactivation
            resurrectionCount
            lastResurrectionDate
            email
        }
    }
}
`

export const consumeCoupon = `
mutation consumeCoupon($coupon: String!, $isResurrected: Boolean!) {
    consumeCoupon(coupon: $coupon, isResurrected: $isResurrected) {
      __typename
      ... on GenericResponse {
        result
      }
      ... on GenericError {
        error
      }
    }
  }
`

export const sampleMenu = `
  query sampleMenu {
    sampleMenu {
            entity_id
            premium_fee
            name
            short_description
            calories
            image
            image_path
            price
            sku
            category_id
            chef_id
            chef_firstname
            chef_lastname
            logopic
            bannerpic
            stock
            inStock
            meat_type
            stars
            reviews
            is_celebrity_chef
            sidedish {
              id
            }
            specifications_detail {
              label
            }
      }
  }
`

export const checkCoupon = `
query coupon ($coupon: String!){
  coupon(coupon: $coupon) {
    promotion {
      id
      title
      discount {
        rate
        duration
      }
      credit {
        amount
      }
    }
  }
}
`

export const assignPrepaidOffer = `
mutation AssignPrepaidOffer($prepaidOfferId: String!) {
  assignPrepaidOffer(prepaidOfferId: $prepaidOfferId) {
    error
    status
  }
}
`

export const generateCartFromResurrectionCampaign = `
mutation generateCartFromResurrectionCampaign($campaignSlug: String!, $chefId: Int, $chefBrandId: Int, $highlightedMealsIds: [Int], $chefBrandIds: [Int], $noPopulateCart: Boolean) {
  generateCartFromResurrectionCampaign(campaignSlug: $campaignSlug, chefId: $chefId, chefBrandId: $chefBrandId, highlightedMealsIds: $highlightedMealsIds, chefBrandIds: $chefBrandIds, noPopulateCart: $noPopulateCart) {
    deliveryDate
    chefId
    chefIds
  }
}
`
