import tracking from 'src/shared/tracking'
import { RoutePaths } from 'src/routes/routePaths'

export enum ActionType {
  TRACK = 'TRACK',
  NAVIGATION = 'NAVIGATION'
}

interface BaseAction {
  type: ActionType
}

export type Action = NavigationAction | TrackAction

export enum TrackingType {
  EVENT = 'event'
}

export enum TrackingTag {}

export type TrackingExtras = Record<string, string | boolean | number>

export interface TrackAction extends BaseAction {
  type: ActionType.TRACK
  data: {
    subType: TrackingType
    tag: TrackingTag
    extras: TrackingExtras
  }
}

export interface NavigationAction extends BaseAction {
  type: ActionType.NAVIGATION
  data: {
    path: RoutePaths
  }
}

export type Context = {
  history: any
}

const executeActionByType: Record<ActionType, (Action, Context) => void> = {
  [ActionType.TRACK]: (action: TrackAction) => {
    if (action.data.subType === TrackingType.EVENT) {
      tracking.track(action.data.tag, action.data.extras)
    }
  },
  [ActionType.NAVIGATION]: (action: NavigationAction, { history }) => {
    history.push(action.data.path)
  }
}

export const executeActions = (actions: Action[], context: Context) => {
  actions.forEach(action => {
    executeActionByType[action.type](action, context)
  })
}
