import React from 'react'
import classnames from 'classnames'

import Dots from '../../assets/icons/dots.png'

export const Item = props => {
  const { children, onClick, disabled, danger, testId, ...rest } = props
  return (
    <div
      data-test-id={testId}
      className={classnames('item', { disabled, danger })}
      onClick={onClick}
      {...rest}>
      {children}
    </div>
  )
}

class Dropdown extends React.Component {
  state = {
    displayMenu: false
  }

  showDropdownMenu = event => {
    event.stopPropagation()
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu)
    })
  }

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu)
    })
  }

  render() {
    const { displayMenu } = this.state
    const { children, disabled, hide, testId } = this.props
    return (
      !hide && (
        <div
          data-test-id={testId}
          className={classnames('cookunity__setting_dropdown', { disabled })}>
          <div
            className="button"
            onClick={e => {
              if (!disabled) {
                this.showDropdownMenu(e)
              }
            }}>
            <img src={Dots} alt="dropdown icon" />
          </div>

          {displayMenu && <div className="list">{children}</div>}
        </div>
      )
    )
  }
}

export default Dropdown
