import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  getAccountData,
  isEarlyUserSelector
} from 'src/redux-api-bridge/account/selectors'
import { useTracking } from './useTracking'

export function useIdentifyAccountData() {
  const isEarlyUser = useSelector(isEarlyUserSelector)
  const account = useSelector(getAccountData)
  const { identify } = useTracking()

  useEffect(() => {
    if (isEarlyUser !== null) {
      identify(account.magento_id, { is_early_user: isEarlyUser })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isEarlyUser])
}
