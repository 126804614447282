import { useEffect } from 'react'
import tracking from 'src/shared/tracking'

export const useConfirmationPlanChangeTracking = (
  setShowConfirmationPlanChangeModal: (
    showConfirmationPlanChangeModal: boolean
  ) => void
) => {
  useEffect(() => {
    tracking.track('Overlay Viewed', {
      overlay_name: 'Flex mode - Back to weekly plan confirmation',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '2/2'
    })
  }, [])

  const handleClose = (): void => {
    tracking.track('Overlay Closed', {
      overlay_name: 'Flex mode - Back to weekly plan confirmation',
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'button',
      context: 'subscription',
      page_name: 'My Plan',
      overlay_trigger: 'manual',
      overlay_step: '2/2'
    })

    setShowConfirmationPlanChangeModal(false)
  }

  return { handleClose }
}
