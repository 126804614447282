import React from 'react'
import moment from 'moment'
import { formatFixedPriceWithNegatives } from 'src/utils/utils'
import './CreditTable.scss'

interface CreditTableProps {
  credit: {
    amount: number
    source: string
    expirationDate: string
  }
}

export function CreditTable({ credit }: CreditTableProps) {
  return (
    <div className="credit-table">
      <div className="credit-table__row">
        <div className="credit-table__cell">
          <span className="credit-table__label">Amount: </span>
          {formatFixedPriceWithNegatives(credit.amount)}
        </div>
        <div className="credit-table__cell">
          <span className="credit-table__label">Source: </span>
          {credit.source || 'Pending Credit'}
        </div>
        <div className="credit-table__cell">
          <span className="credit-table__label">Expiration date: </span>
          {moment(credit.expirationDate).format('MM/DD/YYYY')}
        </div>
      </div>
    </div>
  )
}
