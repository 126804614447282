import { handleActions } from 'redux-actions'

import * as menuActions from './actions'
import { MENU_VIEW_MODE } from 'src/constants/menu'
import { mealsAreEquals } from 'src/utils/utils'
import { INPUT_ORIGIN } from 'src/modules/MyPlan/components/SearchRevamp/SearchInput/useSearchInput'

const initialState = {
  alreadyRequestedMeals: null,
  menu: null,
  date: null,
  inFlight: false,
  prefetchInFlight: false,
  succeeded: false,
  error: null,
  isCrossSellingModalOpen: false,
  initialScrollTo: null,
  viewMode: MENU_VIEW_MODE.CONDENSED,
  crossSelling: {
    inFlight: false,
    error: false,
    succeeded: false,
    items: null,
    category: null,
    date: null,
    title: '',
    subTitle: ''
  },
  fullMenuMealInitialPosition: {
    headerId: null,
    mealPosition: null
  },
  crossSellingModalSeen: false,
  searchView: {
    show: false,
    menuViewOutTransitioning: false,
    searchedValue: '',
    suggestions: null,
    shouldShowSuggestions: true
  },
  freePremiumMeals: {
    isActive: false,
    availableMeals: [],
    amountToChoose: 0,
    addedMeals: []
  }
}

export default handleActions(
  {
    [menuActions.getUserMenuStart]: (state, { payload: { fromPrefetch } }) => ({
      ...state,
      menu: null,
      inFlight: true,
      succeeded: false,
      error: null,
      prefetchInFlight: fromPrefetch
    }),
    [menuActions.getUserMenuFail]: (state, action) => ({
      ...state,
      inFlight: false,
      prefetchInFlight: false,
      succeeded: false,
      error: action.payload,
      menu: null,
      date: null
    }),
    [menuActions.getUserMenuSuccess]: (state, { payload: { menu, date } }) => ({
      ...state,
      inFlight: false,
      prefetchInFlight: false,
      succeeded: true,
      error: null,
      menu,
      date,
      freePremiumMeals: {
        ...state.freePremiumMeals,
        ...menu.freePremiumMeals
      }
    }),
    [menuActions.getUserAlreadyRequestedMealsStart]: state => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: true,
        succeeded: false,
        error: null,
        meals: [],
        date: null
      }
    }),
    [menuActions.getUserAlreadyRequestedMealsSuccess]: (
      state,
      { payload: { meals, date } }
    ) => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: false,
        succeeded: true,
        error: null,
        meals,
        date
      }
    }),
    [menuActions.getUserAlreadyRequestedMealsFail]: (state, action) => ({
      ...state,
      alreadyRequestedMeals: {
        inFlight: false,
        succeeded: false,
        error: action.payload,
        meals: [],
        date: null
      }
    }),
    [menuActions.resetUserMenu]: () => initialState,
    [menuActions.setIsCrossSellingModalOpen]: (state, action) => ({
      ...state,
      isCrossSellingModalOpen: action.payload
    }),
    [menuActions.setInitialScrollTo]: (state, action) => ({
      ...state,
      initialScrollTo: action.payload
    }),
    [menuActions.setMenuViewMode]: (state, action) => ({
      ...state,
      viewMode: action.payload
    }),
    [menuActions.getCrossSellingItemsStart]: state => ({
      ...state,
      crossSelling: {
        inFlight: true,
        succeeded: false,
        error: null,
        items: null,
        category: null,
        title: '',
        subTitle: ''
      }
    }),
    [menuActions.getCrossSellingItemsFail]: (state, action) => ({
      ...state,
      crossSelling: {
        inFlight: false,
        succeeded: false,
        error: action.payload,
        items: null,
        category: null,
        title: '',
        subTitle: ''
      }
    }),
    [menuActions.getCrossSellingItemsSuccess]: (
      state,
      { payload: { items, date, category, title, subTitle } }
    ) => ({
      ...state,
      crossSelling: {
        inFlight: false,
        succeeded: true,
        error: null,
        items,
        category,
        date,
        title,
        subTitle
      }
    }),
    [menuActions.setCrossSellingModalSeen]: state => ({
      ...state,
      crossSellingModalSeen: true
    }),
    [menuActions.setFullMenuMealInitialPosition]: (
      state,
      { payload: { headerId, mealPosition } }
    ) => ({
      ...state,
      fullMenuMealInitialPosition: {
        headerId,
        mealPosition
      }
    }),
    [menuActions.setShowMenuSearchView]: (state, action) => ({
      ...state,
      searchView: {
        ...state.searchView,
        show: action.payload.show,
        searchedValue: '',
        suggestions: null,
        shouldShowSuggestions: false,
        origin: ''
      }
    }),
    [menuActions.setStartMenuTransitionToSearchView]: state => ({
      ...state,
      searchView: {
        ...state.searchView,
        show: true,
        searchedValue: '',
        suggestions: null,
        shouldShowSuggestions: false,
        menuViewOutTransitioning: true,
        origin: ''
      }
    }),
    [menuActions.setEndMenuTransitionToSearchView]: state => ({
      ...state,
      searchView: {
        ...state.searchView,
        menuViewOutTransitioning: false
      }
    }),
    [menuActions.setSearchViewValue]: (state, action) => ({
      ...state,
      searchView: {
        ...state.searchView,
        searchedValue: action.payload.searchedValue,
        suggestions: action.payload.suggestions,
        shouldShowSuggestions: action.payload.shouldShowSuggestions,
        origin: action.payload.origin
      }
    }),
    [menuActions.suggestionPillClicked]: (state, action) => ({
      ...state,
      searchView: {
        ...state.searchView,
        searchedValue: action.payload.searchedValue,
        shouldShowSuggestions: false,
        origin: INPUT_ORIGIN.PILL
      }
    }),
    [menuActions.recentSearchClicked]: (state, action) => ({
      ...state,
      searchView: {
        ...state.searchView,
        searchedValue: action.payload.searchedValue,
        shouldShowSuggestions: false,
        origin: INPUT_ORIGIN.LAST_SEARCHES
      }
    }),
    [menuActions.freePremiumMealsAdd]: (state, action) => {
      const mealToAdd = action.payload.meal
      return {
        ...state,
        freePremiumMeals: {
          ...state.freePremiumMeals,
          addedMeals: addFreePremiumMealHelper(
            state.freePremiumMeals.addedMeals,
            mealToAdd
          )
        }
      }
    },
    [menuActions.freePremiumMealsRemove]: (state, action) => {
      const mealToRemove = action.payload.meal
      return {
        ...state,
        freePremiumMeals: {
          ...state.freePremiumMeals,
          addedMeals: removeFreePremiumMealHelper(
            state.freePremiumMeals.addedMeals,
            mealToRemove
          )
        }
      }
    },
    [menuActions.clearFreePremiumMeals]: state => {
      return {
        ...state,
        freePremiumMeals: {
          ...state.freePremiumMeals,
          addedMeals: []
        }
      }
    }
  },
  initialState
)

function addFreePremiumMealHelper(addedMeals, mealToAdd) {
  const existingMealIndex = addedMeals.findIndex(meal =>
    mealsAreEquals(meal, mealToAdd)
  )

  if (existingMealIndex !== -1) {
    const updatedMeals = [...addedMeals]
    updatedMeals[existingMealIndex] = {
      ...mealToAdd,
      quantity: updatedMeals[existingMealIndex].quantity + 1
    }
    return updatedMeals
  } else {
    return [...addedMeals, { ...mealToAdd, quantity: 1 }]
  }
}

function removeFreePremiumMealHelper(addedMeals, mealToRemove) {
  const existingMealIndex = addedMeals.findIndex(meal =>
    mealsAreEquals(meal, mealToRemove)
  )

  if (existingMealIndex !== -1) {
    const updatedMeals = [...addedMeals]
    updatedMeals[existingMealIndex] = {
      ...updatedMeals[existingMealIndex],
      quantity: updatedMeals[existingMealIndex].quantity - 1
    }
    return updatedMeals.filter(meal => meal.quantity > 0)
  } else {
    return addedMeals
  }
}
