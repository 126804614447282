import React from 'react'
import classnames from 'classnames'

const Hint = ({ strong, inline, ...rest }) => (
  <span
    className={classnames('cookunity__form_hint', { strong, inline })}
    {...rest}
  />
)

export default Hint
