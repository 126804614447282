/* eslint-disable jsx-a11y/alt-text */
import { useEffect } from 'react'
import { CuiBottomSheet, CuiModal, CuiButton } from 'front-lib'
import { useMobileDevice } from 'src/hooks/useMobileDevice'

import EconomicPlanForeverImg from 'src/assets/img/economic-plan-forever.jpg'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import tracking from 'src/shared/tracking'
import './EconomicPlanForeverModal.scss'
import { useSelector, useDispatch } from 'react-redux'
import { getUserId } from '../../redux-api-bridge/account/selectors'
import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'
import { changePlanToEconomicPlanForever } from 'src/redux-api-bridge/economicPlanForever/effects'
import { formatNumber } from '../../utils/utils'
import { useMarkModalAsViewed } from 'src/api/Modal/markModalAsViewed'
import { ModalType } from 'src/types/nextModal'

const LOCALSTORAGE_KEY = 'cu-economic-plan-forever-modal'

const EconomicPlanForeverModal = ({ isMobileView = false }) => {
  const dispatch = useDispatch()
  const isMobile = useMobileDevice()
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)
  const { viewModal } = useMarkModalAsViewed()

  const [isViewed, setAsViewed] = useLocalStorage(LOCALSTORAGE_KEY, false)
  const showModal = !isViewed

  const userId = useSelector(getUserId)

  const overlayName = `Economic Plan Forever${isMobileView ? ' Mobile' : ''}`

  useEffect(() => {
    if (showModal) {
      tracking.track('Overlay Viewed', {
        overlay_name: overlayName,
        overlay_class: 'modal',
        overlay_source: 'custom',
        overlay_type: 'message',
        page_name: 'Orders Page',
        overlay_trigger: 'automatic',
        overlay_options: ['Activate Value Plan', 'Remind me later']
      })
    }
  }, [showModal, overlayName])

  const onClose = () => {
    viewModal({
      type: ModalType.EconomicPlanForeverModal
    })
    setAsViewed(true)
  }

  const buttonClickHandler = () => {
    tracking.track('Overlay Clicked', {
      overlay_name: overlayName,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'Orders Page',
      overlay_trigger: 'automatic',
      overlay_options: ['Activate Value Plan', 'Remind me later'],
      overlay_action: 'Activate Value Plan'
    })

    dispatch(changePlanToEconomicPlanForever(userId))

    onClose()
  }

  const onCloseHandler = () => {
    tracking.track('Overlay Closed', {
      overlay_name: overlayName,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'Orders page',
      overlay_trigger: 'automatic',
      overlay_options: ['Activate Value Plan', 'Remind me later']
    })
    onClose()
  }

  const onCancelHandler = () => {
    tracking.track('Overlay Clicked', {
      overlay_name: overlayName,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'message',
      page_name: 'Orders page',
      overlay_trigger: 'automatic',
      overlay_options: ['Activate Value Plan', 'Remind me later'],
      overlay_action: 'Remind me later'
    })
    onClose()
  }

  const getPlanPrice = () => {
    const pricePerMeal = formatNumber(
      subscriptionPlan.price / subscriptionPlan.mealsPerDelivery
    )
    return pricePerMeal
  }

  const modalContent = (
    <div className="economic-plan-forever-menu-modal__content">
      <div className="economic-plan-forever-menu-modal__img-container">
        <img src={EconomicPlanForeverImg} />
      </div>
      <div className="economic-plan-forever-menu-modal__text-container">
        <div className="economic-plan-forever-menu-modal__title">
          Want to keep saving on meals? You got it. 
        </div>
        <div className="economic-plan-forever-menu-modal__subtitle">
          We’re delighted to offer you the option to enjoy a special rate on
          meals for as long as you’d like. Activate the Value Plan now to keep
          savoring our chefs’ tasty creations for just{' '}
          <b>{`$${getPlanPrice()}`} per meal*!</b> 
        </div>
        <div className="economic-plan-forever-menu-modal__end">
          {' '}
          * The discounted price per meal for the Value Plan may be subject to
          change based on changes in the standard plan pricing. Add-ons and
          extras are available for an additional cost.
        </div>
        <div className="economic-plan-forever-action-buttons">
          <CuiButton fill="outline" size="large" onClick={onCancelHandler}>
            Remind me later
          </CuiButton>
          <CuiButton size="large" onClick={buttonClickHandler}>
            Activate Value Plan
          </CuiButton>
        </div>
      </div>
    </div>
  )

  if (!showModal) return null

  if (isMobile) {
    return (
      <CuiBottomSheet
        className="economic-plan-forever-menu-modal__mobile"
        isOpen
        onRequestClose={onCloseHandler}>
        <CuiBottomSheet.Body>{modalContent}</CuiBottomSheet.Body>
      </CuiBottomSheet>
    )
  }
  return (
    <CuiModal
      isOpen
      withCloseIcon={false}
      onRequestClose={onCloseHandler}
      className="economic-plan-forever-menu-modal__desktop">
      {modalContent}
    </CuiModal>
  )
}

export { EconomicPlanForeverModal }
