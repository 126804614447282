import React, { useState, useEffect } from 'react'
import { string, func } from 'prop-types'

import NoMealImg from '../../assets/img/no-image.png'
import './styles.scss'

const MealImage = ({
  mealImage,
  onMealClick,
  classNameImage,
  classNameNoImgContainer,
  withoutText
}) => {
  const [errorImage, setErrorImage] = useState(false)

  useEffect(() => {
    setErrorImage(mealImage && mealImage.includes('/no-image'))
  }, [mealImage])

  const handleOnError = () => {
    setErrorImage(true)
  }

  const image = errorImage ? NoMealImg : mealImage
  return errorImage ? (
    <div className={classNameNoImgContainer}>
      <img
        src={image}
        className={classNameImage}
        alt="meal"
        onClick={onMealClick}
        onError={handleOnError}
      />
      {errorImage && !withoutText && (
        <p className="txt-noimage heading-3">Image coming soon</p>
      )}
    </div>
  ) : (
    <img
      src={image}
      className={classNameImage}
      alt="meal"
      onClick={onMealClick}
      onError={handleOnError}
    />
  )
}

MealImage.defaultProps = {
  classNameNoImgContainer: 'no-img-container',
  withoutText: false
}

MealImage.propTypes = {
  mealImage: string,
  onMealClick: func,
  classNameImage: string,
  classNameNoImgContainer: string
}

export default MealImage
