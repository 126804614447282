export const COPY_STATUS = {
  PARTIAL_ORDER: 'partial-order',
  EMPTY_ORDER: 'empty-order',
  ORDER_FULLFILLED_BUT_NOT_REQUESTED: 'fullfilled-but-not-requested',
  ORDER_REQUESTED: 'order-requested',
  ORDER_CONFIRMED: 'order-confirmed',
  ORDER_REQUEST_PENDING: 'order-request-pending',
  // Cheapper than "in delivery" state because we can rely on known dates rather than tracking endpoint.
  ORDER_IN_SHIPPING_DAY: 'order-in-shipping-day',
  HOLIDAY: 'holiday',
  ORDER_SKIPPED: 'order-skipped',
  NO_MENU: 'no-menu'
}

export const ORDER_STATUS = {
  // Confirmed by cutoff, can't be edited.
  CONFIRMED: 'confirmed',
  // Skipped by user.
  SKIPPED: 'skipped',
  // Requested by user, can be edited.
  REQUESTED: 'requested',
  // Still being created by magento.
  PENDING: 'pending',
  // Skipped by pausing the plan in account page.
  PAUSED: 'paused'
}

export const FLEX_MODE_MEALS_BY_PLAN = 4
