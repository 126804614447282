import { createAction } from 'redux-actions'

export const changePlanToEconomicPlanForeverStart = createAction(
  'CHANGE_PLAN_TO_ECONOMIC_PLAN_FOREVER_START'
)
export const changePlanToEconomicPlanForeverSuccess = createAction(
  'CHANGE_PLAN_TO_ECONOMIC_PLAN_FOREVER_SUCEEDED'
)
export const changePlanToEconomicPlanForeverFail = createAction(
  'CHANGE_PLAN_TO_ECONOMIC_PLAN_FOREVER_FAILED'
)
