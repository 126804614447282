import { gql, useQuery } from '@apollo/client'

export const GET_USER_DELIVERY_QUERY = gql`
  query getUserDeliveries($shippingDates: [String!]) {
    getUserDeliveries(shippingDates: $shippingDates) {
      shippingDate
      plan {
        id
        price
        deliveries
        mealsPerDelivery
      }
    }
  }
`

export function useGetUserDeliveries({ shippingDates }) {
  const { data, ...rest } = useQuery(GET_USER_DELIVERY_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { shippingDates },
    fetchPolicy: 'cache-first',
    skip: !shippingDates.length
  })

  const userDeliveries = data?.getUserDeliveries || []

  return {
    userDeliveries,
    ...rest
  }
}
