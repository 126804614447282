enum RoutePaths {
  ORDERS = '/',
  HISTORY = '/account/past-orders',
  MEAL_PREFERENCES = '/personalize',
  PLAN = '/account/plan',
  CREDITS_AND_PROMOTIONS = '/promotions',
  BILLING_AND_RECEIPTS = '/account/payments',
  CHANGE_PLAN = '/account/plan/change',
  FAVORITES = '/favorites',
  REFER_A_FRIEND = '/referral',
  ACCOUNT = '/account/settings',
  NOTIFICATIONS = '/notifications',
  NUTRITION = '/nutrition',
  HOW_IT_WORKS = '/how-it-works',
  PRODUCTS = '/products',
  MENU = '/meals',
  DOWNLOAD_APP = '/download-our-app',
  CONTENTTOCOMMERCE = '/c2c',
  REVIEW_CHALLENGE = '/review-challenge',
  MONTHLY_PLAN = '/monthly-plan',
  MEALS_LIST = '/productList'
}

export { RoutePaths }
