import { ADDONS_CATEGORIES } from 'src/modules/MyPlan/utils'

export const getMealsCount = (items, key = '') => {
  return (
    items
      ?.filter(item => {
        const meal = key ? item[key] : item
        return (
          ADDONS_CATEGORIES.indexOf(meal.categoryId || meal.category_id) === -1
        )
      })
      .reduce((qty, meal) => qty + meal.qty, 0) || 0
  )
}
