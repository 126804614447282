import { gql, useMutation } from '@apollo/client'
import { GET_USER_DELIVERY_QUERY } from 'src/api/DeliveryDay/useGetUserDeliveries'
import { useSelector } from 'react-redux'
import { getUpcomingDeliveries } from 'src/modules/MyDeliveries/selectors'

export const CHANGE_DELIVERY_PLAN_MUTATION = gql`
  mutation changeDeliveryPlan($planId: Int!, $shippingDate: String!) {
    changeDeliveryPlan(planId: $planId, shippingDate: $shippingDate)
  }
`

export function useChangeDeliveryPlan({
  onError,
  onSuccess
}: {
  onError?: () => void
  onSuccess?: () => void
}) {
  const deliveryDays = useSelector(getUpcomingDeliveries)
  const [mutation, { loading, ...rest }] = useMutation(
    CHANGE_DELIVERY_PLAN_MUTATION,
    {
      onError,
      onCompleted: onSuccess,
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_DELIVERY_QUERY,
          variables: { shippingDates: deliveryDays.map(d => d.date) }
        }
      ]
    }
  )

  function changeDeliveryPlan({ planId, shippingDate }) {
    mutation({ variables: { planId, shippingDate } })
  }

  return {
    changeDeliveryPlan,
    loading,
    ...rest
  }
}
