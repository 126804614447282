import moment from 'moment'

export const getTimeLeft = cutoff => {
  let timeLeftHours = null
  const timeLeftDays =
    cutoff &&
    moment(cutoff.time)
      .utcOffset(+cutoff.utcOffset)
      .diff(moment(), 'days')

  if (timeLeftDays === 0) {
    timeLeftHours =
      cutoff &&
      moment(cutoff.time)
        .utcOffset(+cutoff.utcOffset)
        .diff(moment(), 'hours')
    if (timeLeftHours >= 12 && timeLeftHours <= 24) {
      return '1 day'
    } else if (timeLeftHours >= 1 && timeLeftHours <= 12) {
      return `${timeLeftHours} hour${timeLeftHours === 1 ? '' : 's'}`
    } else {
      return 'less than one hour'
    }
  } else {
    return `${timeLeftDays} day${timeLeftDays === 1 ? '' : 's'}`
  }
}
