import * as R from 'ramda'

export const selectScheduleMode = state =>
  R.path(['schedule', 'mode'], state) || null

export const selectScheduleSelectedDate = state =>
  R.path(['schedule', 'selectedDate'], state) || false

export const selectScheduleTimeslot = state =>
  R.path(['schedule', 'timeslot'], state) || false

export const selectScheduleCutOff = state =>
  R.path(['schedule', 'cutOff'], state) || false

export const selectScheduleNewTimeslot = state =>
  R.path(['schedule', 'newTimeslot'], state) || false

export const selectSchedulePanelIsOpen = state =>
  R.path(['schedule', 'isOpen'], state) || false
