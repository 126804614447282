import menuService from '../menuService'

export const getClientSurvey = () =>
  menuService.query(
    `query surveyClient {
      surveyClient {
        clientId
        email
        hasPendingSurvey
        customSurveys {
          name
          email
          clientId
          hasPendingSurvey
        }
      }
    }`,
    {},
    true
  )

export const saveSurvey = surveyInfo =>
  menuService.mutation(
    `mutation surveySave($survey: SurveyInput!) {
      surveySave(survey: $survey) {
        ... on SurveyResponse {
          message
        }
      }
    }`,
    { survey: surveyInfo },
    true
  )
