import React, { FunctionComponent } from 'react'
import { ComponentProps } from 'src/types/component'
import { ChefLink } from 'src/pages/orders/components/RatingTab/components/Link/components/ChefLink/ChefLink'
import './Link.scss'

export enum LinkType {
  CHEF = 'chef_link'
}

export type LinkProps = ComponentProps & {
  subType: LinkType
  text: string
  image: {
    name: string
  }
}

const componentBySubType: Record<
  LinkType,
  FunctionComponent<ComponentProps>
> = {
  [LinkType.CHEF]: ChefLink
}

export const Link: React.FC<LinkProps> = ({ subType, text, image }) => {
  const ComponentToRender = componentBySubType[subType]

  return <ComponentToRender text={text} image={image} />
}
