import { useMemo } from 'react'
import './CircularProgress.scss'

const CircularProgress = ({
  percentage,
  diameterInPx,
  borderWidthInPx,
  className
}) => {
  const {
    progressStrokesDasharray,
    progressStrokeDashoffset,
    radiusInPx,
    circleRadiusInPx,
    readablePercentage,
    strokeColor
  } = useMemo(() => {
    const out = {
      radiusInPx: diameterInPx / 2,
      circleRadiusInPx: diameterInPx / 2 - borderWidthInPx / 2,
      progressStrokesDasharray: 0
    }
    if (percentage) {
      const circleCircumference = 2 * Math.PI * out.circleRadiusInPx
      out.progressStrokesDasharray = circleCircumference
      out.progressStrokeDashoffset = circleCircumference * (1 - percentage)
      out.readablePercentage = (percentage * 100).toFixed(0)
      out.strokeColor =
        percentage < 0.51
          ? 'url(#progressGradient1)'
          : percentage < 0.99
          ? 'url(#progressGradient2)'
          : 'url(#progressGradient3)'
    }

    return out
  }, [percentage, diameterInPx, borderWidthInPx])

  return (
    <div className="circular-progress">
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={diameterInPx}
        height={diameterInPx}
        viewBox={`0 0 ${diameterInPx}px ${diameterInPx}px`}>
        <defs>
          <linearGradient
            id="progressGradient1"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0%" stopColor="#FFDA37" />
            <stop offset="66%" stopColor="#FFC535" />
            <stop offset="100%" stopColor="#FF9B26" />
          </linearGradient>
          <linearGradient
            id="progressGradient2"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0%" stopColor="#61CE5E" />
            <stop offset="66%" stopColor="#FFC535" />
            <stop offset="100%" stopColor="#FFDA37" />
          </linearGradient>
          <linearGradient
            id="progressGradient3"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0%" stopColor="#7BD779" />
            <stop offset="100%" stopColor="#1B9819" />
          </linearGradient>
        </defs>

        <circle
          cx={radiusInPx}
          cy={radiusInPx}
          r={circleRadiusInPx}
          fill="white"
          stroke="white"
          strokeWidth={borderWidthInPx}
          transform={`rotate(-90, ${radiusInPx}, ${radiusInPx})`}
        />

        <circle
          cx={radiusInPx}
          cy={radiusInPx}
          r={circleRadiusInPx}
          fill="white"
          stroke={strokeColor}
          strokeWidth={borderWidthInPx}
          strokeDasharray={progressStrokesDasharray}
          strokeDashoffset={progressStrokeDashoffset}
          transform={`rotate(-90, ${radiusInPx}, ${radiusInPx})`}
        />

        <text
          x="50%"
          y="45%"
          dominantBaseline="central"
          textAnchor="middle"
          fill="#000"
          fontWeight="700"
          fontFamily="TT Norms Pro"
          fontSize="14px">
          {readablePercentage}%
        </text>
        <text
          x="50%"
          y="65%"
          fontFamily="TT Norms Pro"
          dominantBaseline="central"
          textAnchor="middle"
          fill="#000"
          fontSize="8px">
          complete
        </text>
      </svg>
    </div>
  )
}

export { CircularProgress }
