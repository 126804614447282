import React from 'react'
import styled, { css } from 'styled-components'

import CookUnityLogo from '../../assets/svg/logo-cookunity.svg'

export const View = styled.div`
  box-sizing: border-box;
  width: 100 vw;
  height: 100 vh;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://i2.wp.com/hospitality21.com/wp-content/uploads/2015/04/o-CHEFS-KITCHEN-facebook.jpg?fit=2000%2C1000&ssl=1);
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  box-sizing: border-box;
  margin: auto 0%;

  background: #ffffff;
`
export const Logo = styled.img.attrs({
  src: CookUnityLogo
})`
  width: 100%;
  margin: 10% 0px 0px 0px;
`
export const Subtitle = styled.h5`
  margin: 5px;
`
export const ErrorSubtitle = styled(Subtitle)`
  color: red;
`
export const Label = styled.h4`
  margin: 5px;
`

export const InputContainer = styled.div`
  width: 100%;
  margin: 7px 0px;
`

export const ErrorLabel = styled.div`
  text-align: left;
  color: red;
`

export const Input = styled.input`
  height: 40px;
  width: 100%;
  margin: 7px 0px;

  background: none;
  border: 0;
  border-bottom: 1px solid #999;
  border-radius: 2px;

  font-size: 100%;
  text-align: left;
  resize: both;
`

export const LoginInput = ({ withError, ...rest }) => {
  return (
    <InputContainer>
      <Input {...rest} />
      <ErrorLabel>{withError ? withError : ''}</ErrorLabel>
    </InputContainer>
  )
}

export const BaseLoginCss = css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;

  width: 100%;
  height: 50px;
  margin: 10px 0;

  border: 0px none;
  border-radius: 2px;

  color: white;
  text-transform: uppercase;
  background-color: ${props => props.backgroundColor};
`

export const BaseLoginBtn = styled.button`
  ${BaseLoginCss};
`

export const ForgotPasswordLink = styled.a`
  cursor: pointer;
  color: #999;
  font-size: 0.8em;
  margin: 30px;
`
export const LoginBtn = styled.button`
  width: 100%;
  border-radius: 6px;
  padding: 0.8em;
  text-transform: none;
  font-size: 1.4em;
  background: black;
  margin: 20px 0px;
  border: none;
  color: white;
  cursor: pointer;
`
