import { gql, useMutation } from '@apollo/client'

export const RETURN_TO_LAST_REGULAR_PLAN_MUTATION = gql`
  mutation returnToLastRegularPlan {
    returnToLastRegularPlan {
      success
    }
  }
`

export function useReturnToLastRegularPlanMutation() {
  const [returnToLastRegularPlan, rest] = useMutation(
    RETURN_TO_LAST_REGULAR_PLAN_MUTATION
  )

  return {
    returnToLastRegularPlan,
    rest
  }
}
