import React from 'react'
import classNames from 'classnames'
import { ComponentProps } from 'src/types/component'
import './Text.scss'

export enum TextStyle {
  H1 = 'Mobile/h1',
  H3 = 'Mobile/h3',
  H5 = 'Mobile/h5',
  MEDIUM_REGULAR = 'Common/Body M/Regular',
  MEDIUM_BOLD = 'Common/Body M/Bold',
  SMALL_BOLD = 'Common/Body S/Bold',
  XS_BOLD = 'Common/Body XS/Bold',
  XXS_BOLD = 'Common/Body XXS/Bold'
}

export enum TextColor {
  PRIMARY = '#000000',
  SECONDARY = '#FFFFFF',
  FEEDBACK_ERROR = '#EE5744',
  BLACK_400 = '#4d4d4f'
}

export enum TextTag {
  H1 = 'h1',
  H3 = 'h3',
  H5 = 'h5',
  SPAN = 'span',
  P = 'p'
}

export const classNameByStyle: Record<TextStyle, string> = {
  [TextStyle.H1]: 'h1',
  [TextStyle.H3]: 'h3',
  [TextStyle.H5]: 'h5',
  [TextStyle.MEDIUM_REGULAR]: 'medium-regular',
  [TextStyle.MEDIUM_BOLD]: 'medium-bold',
  [TextStyle.SMALL_BOLD]: 'small-bold',
  [TextStyle.XS_BOLD]: 'xs-bold',
  [TextStyle.XXS_BOLD]: 'xxs-bold'
}

const textTagByStyle: Record<TextStyle, TextTag> = {
  [TextStyle.H1]: TextTag.H1,
  [TextStyle.H3]: TextTag.H3,
  [TextStyle.H5]: TextTag.H5,
  [TextStyle.MEDIUM_REGULAR]: TextTag.P,
  [TextStyle.MEDIUM_BOLD]: TextTag.P,
  [TextStyle.SMALL_BOLD]: TextTag.P,
  [TextStyle.XS_BOLD]: TextTag.P,
  [TextStyle.XXS_BOLD]: TextTag.P
}

interface TextProps extends ComponentProps {
  value: string
  style: TextStyle
  tag?: React.ElementType<
    any,
    TextTag.H1 | TextTag.H3 | TextTag.H5 | TextTag.SPAN | TextTag.P
  >
  color?: TextColor
  className?: string
}

export const Text: React.FC<TextProps> = ({
  value,
  style,
  tag,
  color,
  className = ''
}) => {
  let Tag = textTagByStyle[style] || tag

  return (
    <Tag
      dangerouslySetInnerHTML={{ __html: value }}
      className={classNames('text', classNameByStyle[style], className)}
      style={{ color }}
    />
  )
}
