import React from 'react'
import { Link } from 'react-router-dom'

import { useEmbedded } from '../../../../embedded'
import { RoutePaths } from 'src/routes/routePaths'
import BackIcon from '../../../../assets/icons/back.png'

import './View.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  testId?: string
  hideBack?: boolean
}
export function View({
  title = '',
  hideBack = false,
  testId,
  children
}: Props) {
  const { isAppEmbedded } = useEmbedded()

  return (
    <div className="cookunity__setting_view" data-test-id={testId}>
      {!isAppEmbedded && !hideBack && (
        <div className="setting_view_header">
          <Link className="back" to={RoutePaths.PLAN} replace>
            <img src={BackIcon} alt="back icon" />
          </Link>
        </div>
      )}
      {title && <h1 className="title">{title}</h1>}
      {children}
    </div>
  )
}
