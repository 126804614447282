import React from 'react'
import classnames from 'classnames'
import {
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import './TagsList.scss'

type TagsListProps = {
  tags: { id: string; label: string }[]
  selectedTags: string[]
  onClick: (id: string) => void
  className?: string
}

export const TagsList: React.FC<TagsListProps> = ({
  tags,
  selectedTags,
  onClick,
  className = ''
}) => {
  return (
    <div className={classnames('tags-list', className)}>
      {tags.map(({ id, label }) => (
        <div
          className={classnames('tags-list__tag', {
            selected: selectedTags.includes(id)
          })}
          onClick={() => onClick(id)}>
          <Text
            className="tags-list__tag-label"
            value={label}
            style={TextStyle.XXS_BOLD}
          />
        </div>
      ))}
    </div>
  )
}
