import axios from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
const URL = process.env.REACT_APP_API_URL
const TOKEN = process.env.REACT_APP_USER_TOKEN_TEST

const configCredentials = {
  headers: {
    Authorization: TOKEN,
    'Content-type': 'application/json'
  },
  withCredentials: true
}

const config = {
  headers: {
    Authorization: TOKEN,
    'Content-type': 'application/json'
  }
}
const clientWithCredentials = (path, verb, data = null, baseUrl = null) =>
  axios(
    Object.assign({}, configCredentials, {
      url: baseUrl ? baseUrl + path : URL + path,
      method: verb,
      data
    })
  )

// eslint-disable-next-line import/no-anonymous-default-export
export default (path, verb, data = null, baseUrl = null) =>
  axios(
    Object.assign({}, config, {
      url: baseUrl ? baseUrl + path : URL + path,
      method: verb,
      data
    })
  )

export { clientWithCredentials }
