import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { getImpersonator, getTokenSilently } from 'src/auth0'
import { setContext } from '@apollo/client/link/context'
import { getPlatform } from 'src/utils/utils'
import { RetryLink } from '@apollo/client/link/retry'
import { gql } from '@apollo/client'
import { apolloSubscriptionPrivateClient } from './apolloSubscriptionPrivateClient'

const MENU_SERVICE_URL = process.env.REACT_APP_MENU_SERVICE_URL

const menuServiceHttpLink = createHttpLink({
  uri: MENU_SERVICE_URL,
  credentials: 'include'
})

const authLink = setContext(async (_, { headers }) => {
  const impersonator = getImpersonator()
  if (impersonator) {
    return {
      headers: {
        ...headers,
        'x-cook-impersonation-secret': impersonator.secret,
        'x-cook-impersonation-email': impersonator.email
      }
    }
  }

  const token = await getTokenSilently()
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : ''
    }
  }
})

const customHeaders = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'cu-platform': getPlatform()
    }
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 500,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error
  }
})

const menuServiceClient = new ApolloClient({
  link: retryLink
    .concat(authLink)
    .concat(customHeaders)
    .concat(menuServiceHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

const publicMenuServiceClient = new ApolloClient({
  link: retryLink.concat(customHeaders).concat(menuServiceHttpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query: (query, variables = {}, useMenuService = false) => {
    return (useMenuService
      ? menuServiceClient
      : apolloSubscriptionPrivateClient
    ).query({
      query: gql(query),
      variables
    })
  },
  publicQuery: (query, variables = {}) =>
    publicMenuServiceClient.query({
      query: gql(query),
      variables
    }),
  mutation: (mutation, variables = {}, useMenuService = false) =>
    (useMenuService
      ? menuServiceClient
      : apolloSubscriptionPrivateClient
    ).mutate({
      mutation: gql(mutation),
      variables
    })
}
