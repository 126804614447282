import { useEffect } from 'react'
import { CuiButton } from 'front-lib'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MembershipOverlayName } from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'

import './UnityPassPaused.scss'

interface Props {
  onClose: () => void
}

export function UnityPassPaused({ onClose }: Props) {
  const { trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.benefits
  })

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="unity-pass-banner-by-chef">
        <img src={UnityPassImage} alt="Unity Pass" />
      </div>
      <div className="cancel-container">
        <div className="title-container">
          <span className="title">Your Unity Pass is now paused</span>
        </div>
        <div className="description-container">
          <span>
            Your Unity Pass has been paused for one month and will automatically
            reactivate on Nov. 30, when your normal monthly fee will be charged.
            <br />
            All your exclusive perks will be ready and waiting for you when you
            return!
          </span>
        </div>
        <div className="button-container">
          <CuiButton className="secondary-button" onClick={() => onClose()}>
            Got it
          </CuiButton>
        </div>
      </div>
    </>
  )
}
