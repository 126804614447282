import moment from 'moment'

import Analytics from '../utils/analytics'
import { getDevice, getDeviceOS } from '../utils/utils'
import store from '../store'
import {
  getUserStore,
  getUserId,
  getUserMagentoId
} from '../redux-api-bridge/account/selectors'
import { getCurrentDate } from '../modules/MyPlan/selectors'
import { cookUnityUuid } from 'src/utils/browserCookies'
import { selectedDeliverySelector } from 'src/redux/slices/selectedDelivery/selectors'

const logger = {
  error: console.error.bind(console, 'Tracking Error:')
}

const genericStaticProps = {
  device: getDevice(),
  device_os: getDeviceOS(),
  context: 'subscription'
}

const getGenericProps = () => genericStaticProps

const getGenericTrackProps = () => {
  const state = store.getState()
  const userStore = getUserStore(state)
  const subscriber_id = getUserId(state)
  const user_id = getUserMagentoId(state)
  const selectedDelivery = selectedDeliverySelector(state)
  const currDate = getCurrentDate(state) ? getCurrentDate(state).date : ''
  const delivery_date =
    (currDate &&
      `${moment.utc(currDate).format('YYYY-MM-DD')}T21:00:00.000Z`) ||
    selectedDelivery?.displayDate
  let anonymous_id
  try {
    anonymous_id = window.analytics?.user()?.anonymousId()
  } catch (e) {
    anonymous_id = cookUnityUuid
  }

  return {
    ...getGenericProps(),
    subscriber_id,
    user_id,
    menu_store: userStore && userStore.name,
    delivery_date,
    anonymous_id
  }
}

const getUtmParams = () => {
  let params = {}

  const search = new URLSearchParams(window.location.search)
  const utms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']

  utms.forEach(key => {
    const value = search.get(key)
    if (value) {
      params[key] = value
    }
  })

  return params
}

const getGenericPageProps = () => {
  const state = store.getState()
  const userStore = getUserStore(state)

  return {
    ...getGenericProps(),
    ...{
      title: window.document.title,
      url: window.location.href,
      path: window.location.pathname,
      search: window.location.search,
      referrer: window.document.referrer,
      store_id: userStore && userStore.id,
      menu_store: userStore && userStore.name,
      ...getUtmParams()
    }
  }
}

export const track = (eventName, properties = {}, context = {}) => {
  try {
    let props = {
      ...getGenericTrackProps(),
      ...properties
    }
    Analytics.track(eventName, props, context)
  } catch (err) {
    logger.error(err)
  }
}

export const page = (eventName, properties = {}) => {
  try {
    let props = {
      ...getGenericPageProps(),
      ...properties
    }
    Analytics.page(eventName, props)
  } catch (err) {
    logger.error(err)
  }
}

export const identify = (userId, properties = {}) => {
  try {
    let traits = {
      ...getGenericProps(),
      ...properties
    }

    Analytics.identify(userId, traits)
  } catch (err) {
    logger.error(err)
  }
}

export const trackExperiment = (id, name, variation, screenName) => {
  try {
    let props = {
      ...getGenericProps(),
      experiment_id: id,
      experiment_name: name,
      variation_name: variation,
      screen_name: screenName
    }

    track('Experiment Viewed', props)
  } catch (err) {
    logger.error(err)
  }
}

export const trackCollectionClicks = (eventName, properties = {}) => {
  try {
    const state = store.getState()
    let props = {
      user_id: getUserMagentoId(state),
      subscriber_id: getUserId(state),
      device: getDevice(),
      device_os: getDeviceOS(),
      ...properties
    }
    Analytics.track(eventName, props)
  } catch (err) {
    logger.error(err)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  track,
  page,
  identify,
  trackExperiment,
  trackCollectionClicks
}
