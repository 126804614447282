/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as selectors from '../modules/Promotions/selectors.js'

export const usePromotions = (
  { includeCreditPromotions = false } = { includeCreditPromotions: false }
) => {
  const storePromotions = useSelector(state => selectors.getPromotions(state))
  const [promotions, setPromotions] = useState([])
  const [availablePromotions, setAvailablePromotions] = useState([])
  const [expiredPromotions, setExpiredPromotions] = useState([])

  useEffect(() => {
    getAvailablePromos()
    getExpiredPromos()
  }, [storePromotions])

  useEffect(() => {
    getPromotions()
  }, [availablePromotions, expiredPromotions])

  const getPromotions = () => {
    let orderedPromos = []
    if (storePromotions && storePromotions.length > 0) {
      orderedPromos = [...availablePromotions, ...expiredPromotions]
    }
    setPromotions(orderedPromos)
  }

  const getExpiredPromos = () => {
    let expiredPromos = []
    if (storePromotions && storePromotions.length > 0) {
      expiredPromos = storePromotions
        .filter(({ promotion: { discount, credit }, createdAt }) => {
          if (includeCreditPromotions && credit !== null) {
            return true
          }

          if (credit !== null && discount === null) {
            return true
          }

          const start = moment(createdAt)
          const end = moment(start).add(discount.duration, 'days')
          const isExpired = moment().isAfter(end)
          return isExpired
        })
        .sort(
          (p1, p2) =>
            moment(getExpirationDate(p2)) - moment(getExpirationDate(p1))
        )
      setExpiredPromotions(expiredPromos)
    }
  }

  const getAvailablePromos = () => {
    let availablePromos = []
    if (storePromotions && storePromotions.length > 0) {
      availablePromos = storePromotions
        .filter(({ promotion: { discount, credit }, createdAt }) => {
          if (includeCreditPromotions && credit !== null) {
            return true
          }

          if ((credit !== null && discount === null) || !discount) {
            return false
          }

          const start = moment(createdAt)
          const end = moment(start).add(discount.duration, 'days')
          const isExpired = moment().isAfter(end)
          return !isExpired
        })
        .sort(
          (p1, p2) =>
            moment(getExpirationDate(p1)) - moment(getExpirationDate(p2))
        )
      setAvailablePromotions(availablePromos)
    }
  }

  const getExpirationDate = ({
    promotion: { discount, credit },
    createdAt
  }) => {
    let endDate
    const promo = discount ? discount : credit
    if (promo) {
      const start = moment(createdAt)
      endDate = moment(start)
        .add(promo.duration, 'days')
        .format('yyyy/MM/DD')
    }
    return endDate
  }

  return {
    storePromotions,
    promotions,
    availablePromotions,
    getAvailablePromos,
    getExpirationDate,
    getPromotions
  }
}
