export const mealData = `
  mealData {
    id
    name
    sku
    category
    logopic
    bannerpic
    complocality
    compdesi
    urlPath
    price
    image {
      image
      full_path
    }
    meal_story
    chef_firstname
    chef_lastname
    chef_id
    calories
    short_description
    meat_type
  }
`

export const invoiceFields = `
  id
  customerId
  date
  customerName
  deliveryAddress
  subtotal
  deliveryFee
  taxes
  tip
  discount
  chargedCredit
  total
  ccNumber
  planId
  createdAt
  updatedAt
  charges {
    id
    chargeId
    status
    stripeId
    refund {
      amount
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
  __typename
  orders {
    id
    delivery_date
    display_date
    time_start
    time_end
    items {
      product {
        id
        name
        sku
        short_description
        image_path
        image
        calories
        category_id
        assortment
        category
        sidedish
        chef_firstname
        chef_lastname
        logopic
        bannerpic
        tags
        meat_type
        chef_id
        stars
        user_rating
        premium_special
        review {
          id
          order
          product
          rating
          review
          reasons
          created_at
        }
      }
      price {
        price
        originalPrice
        priceIncludingTax
        basePriceIncludingTax
      }
      qty
    }
  }
  orderCreations {
    id
    magentoOrderId
    deliveryDate
    discountOrders {
      id
      promotion {
        id
        title
      }
      discountAmount
      createdAt
    }
    promotionOrders {
      promotion {
        id
        title
      }
      createdAt
    }
    requote {
      quote {
        detail {
          id
          qty
          price
          charged
        }
        total
      }
    }
  }
`
export const mealDataComplete = `
mealData {
  id
  name
  sku
  category
  logopic
  bannerpic
  complocality
  compdesi
  urlPath
  price
  image {
    image
    full_path
  }
  meal_story
  chef_firstname
  chef_lastname
  chef_id
  calories
  short_description
  meat_type
  specificationsDetails {
    id
    label
  }
  ingredients {
    id
    name
    description
  }
  nutritionInfo {
    servingSize
    calories
    fat
    saturatedFat
    carbs
    fiber
    protein
    sodium
    sugar
  }
  user_rating
  stars
  cookingSteps {
    oven_steps_without_sealing
    microwave_steps_without_sealing
    oven_steps
    microwave_steps
  }
}
`

export const fragments = {
  userData: `
      fragment UserData on User {
        id
        store_id
        referral_code
        createdAt
        name
        email
        magento_id
        status
        status_event {
          event
        }
        ring {
          id
          name
          is_local
          shipping_service
          shipping_days
        }
        isEligible {
          isEligible
          message
          type
        }
        plan_id
        plan_variety
        plan_comments
        email_notifications
        email_notification_weekly_menu
        email_notification_daily_menu
        email_notification_pending_review
        email_notification_promotions
        email_notification_new_meals
        email_notification_fav_meals
        sms_notifications
        stripe_id
        tip
        ring_id
        delivery_options
        user_type_id
        user_subtype_id

        deliveryDays {
          id
          day
          time_start
          time_end
        }
        currentCredit
        promotionActivations {
          id
          promotionId
          createdAt
          promotion {
            id
            title
            promotionType
            credit {
              id
              amount
              createdAt
              updatedAt
              promotionId
            }
            discount {
              id
              rate
              type
              duration
              promotionId
              createdAt
              updatedAt
            }
            compoundPromotions {
              id
              orderCount
              rate
              type
              promotionId
            }
          }
        }
      }
    `,
  profile: `
      fragment Profile on UserProfile {
          id
          firstname
          lastname
          diets {
            id
            name
            description
            image
          }
          restrictions {
            id
          }
          tastes {
            id
            name
          }
          goals {
            id
            name
          }
          gender
          ageRange
          height
          weight
          mealAvoids {
            mealId
            profileId
            ${mealData}
          }
          mealWishes {
            mealId
            profileId
            ${mealDataComplete}
          }
      }
    `,
  threadFields: `
    fragment threadFields on Thread {
      id
      type
      status
      customerId
      createdAt
      messages {
        id
        date
        status
        content
        author
      }
      recipient {
        id
        type
        name
        email
        image
      }
    }
  `,
  notificationFields: `
    fragment notificationFields on Notification {
      id
      type
      status
      customerId
      createdAt
    }
  `,
  orderType: `
    fragment orderType on Order {
      id
      order_creation_id
      delivery_date
      display_date
      address {
        id
        isActive
        city
        country
        region
        regionId
        postcode
        telephone
        street
      }
      time_start
      time_end
      comment
      items {
        product {
          id
          name
        }
        price {
          price
        }
        qty
      }
      tip
      grandTotal
      orderStatus {
        state
      }
    }
  `
}

export default fragments
