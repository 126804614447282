import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getReferralCode = state =>
  pathOr(null, ['api', 'subscription', 'info', 'referral_code'], state)

export const getReferralLink = createSelector(
  [getReferralCode],
  referralCode => {
    if (referralCode) {
      return `${process.env.REACT_APP_SITE_BASE_URL}/landing-referral?referral_code=${referralCode}`
    }
    return null
  }
)

export const getNewReferralLink = createSelector(
  [getReferralCode],
  referralCode => {
    if (referralCode) {
      return `${process.env.REACT_APP_SITE_BASE_URL}/referral?utm_campaign=${referralCode}`
    }
    return null
  }
)

export const getUserName = state =>
  pathOr(null, ['api', 'subscription', 'info', 'name'], state)

export const getUserFirstName = createSelector([getUserName], userName => {
  if (userName) {
    return userName.split(' ')[0].trim()
  }
  return ''
})

export const getUserReferralsHistory = state =>
  pathOr(null, ['api', 'userReferrals'], state)

export const getUser = state =>
  pathOr(null, ['api', 'subscription', 'info'], state)
