import { Membership, SpecialOfferMembership } from 'src/types/membership'
import { useReplaceHtmlWildCard } from '../useReplaceHtmlWildCard'

/**
 * Types
 */

interface Props {
  specialOffer: SpecialOfferMembership
  membership: Membership
}

/**
 * SpecialOfferBody component
 */

export const SpecialOfferBody = ({ specialOffer, membership }: Props) => {
  const { replaceHtmlWildCard } = useReplaceHtmlWildCard(membership)

  return (
    <>
      <div className="title-container">
        <span className="title">
          {specialOffer?.title && (
            <div
              dangerouslySetInnerHTML={{
                __html: replaceHtmlWildCard(specialOffer.title)
              }}></div>
          )}
        </span>
      </div>
      <div className="description-container">
        {specialOffer?.description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: replaceHtmlWildCard(specialOffer.description)
            }}></div>
        )}
      </div>
    </>
  )
}
