import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { getPreferencesUserSelector } from 'src/redux-api-bridge/preferences/selectors'
import { ReactComponent as AddPlusCircle } from 'src/assets/svg/add-plus-circle.svg'
import './ProfileTabs.scss'

const MAX_PROFILES = 2

const ProfileTabs = ({ selectedProfileId, setProfile, addProfile }) => {
  const preferencesUser = useSelector(getPreferencesUserSelector)

  return preferencesUser ? (
    <div className="preferences-tabs">
      {preferencesUser?.profiles?.map(profile => (
        <div
          key={profile.id}
          role="button"
          onClick={() => setProfile(profile?.id)}
          className={classnames('preferences-tabs__item', {
            selected: selectedProfileId === profile?.id
          })}>
          {`${profile?.firstname}’s Profile`}
        </div>
      ))}
      {preferencesUser?.profiles?.length < MAX_PROFILES && (
        <div
          className="preferences-tabs__item"
          onClick={addProfile}
          role="button">
          <AddPlusCircle /> Add Profile
        </div>
      )}
    </div>
  ) : null
}

export { ProfileTabs }
