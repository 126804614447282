import { Discount, DiscountType } from 'src/types/promotion'

export const getDiscountLabel = (discount: Discount): string => {
  let label = ''

  if (discount.type === DiscountType.Percent) {
    label = `${discount.rate * 100}%`
  }

  if (discount.type === DiscountType.AbsoluteAmount) {
    label = `$${discount.rate}`
  }

  return label
}

export const hasMealsWithSkuDiscount = meals =>
  meals?.some(meal => meal.filter_by.includes('discount_by_sku'))
