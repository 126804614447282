import { FC } from 'react'

export interface ChallengeStep {
  stepNumber: number
  type: string
  status: string
  isLoading: boolean
}

export interface ChallengeStepCopy {
  Component: FC<any> | null
  CTA: string
  DESCRIPTION: string | null
  ICON: string | null
  REDIRECT: string
  TITLE: string
}

export interface ChallengeStepWithCopy
  extends ChallengeStep,
    ChallengeStepCopy {
  VALUES?: {
    deliveryDate: string
    deliveryWindow: string
    meals: number
  }
}
export interface ChallengeData {
  id: number
  finishedAt: string | null
  expireAt: string | null
  steps: ChallengeStep[]
}

export interface Challenge {
  challengeId: number
  enabled: boolean
  variant: string
  id: number
  expireAt: string | null
}

export interface State {
  isOpen: boolean
  active: Challenge[]
  data: Map<string, ChallengeData> | null
  inFlight: boolean
  error: boolean
  succeded: boolean
  claim: {
    id: number | null
    inFlight: boolean
    error: boolean
    succeded: boolean
  }
}

export enum ChallengeState {
  Completed = 'completed',
  Pending = 'pending',
  Finished = 'finished'
}
