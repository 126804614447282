import React from 'react'
import classnames from 'classnames'

const Label = ({ strong, inline, ...rest }) => (
  <span
    className={classnames('cookunity__form_label', { strong, inline })}
    {...rest}
  />
)

export default Label
