import gqlClient from '../gqlClient'

/**
 * Economic Plan Forever
 */
export const changePlanToEconomicPlanForever = ({ userId }) =>
  gqlClient.mutation(
    `
    mutation changePlanToEconomicPlanForever ($userId: Int!) {
      changePlanToEconomicPlanForever (userId: $userId) {
        __typename
      }
    }
  `,
    { userId }
  )
