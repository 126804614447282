import { buildImgUrl } from 'src/utils/utils'
import moment from 'moment'
import tracking from '../../shared/tracking'

const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
const CHEF_IMG_URL_SOURCE =
  process.env.REACT_APP_CHEF_IMG_URL_SOURCE + '/media/avatar-small/thumbnail/'

export const getMeals = items => {
  return items.map(item => {
    return {
      ...item,
      id: +item.product.id,
      inventoryId: item.product?.inventoryId,
      mealName: `${item.product.name ? item.product.name : ''}`.trim(),
      mealDescription: `${
        item.product.short_description ? item.product.short_description : ''
      }`.trim(),
      name: `${item.product.name ? item.product.name : ''} ${
        item.product.short_description ? item.product.short_description : ''
      }`.trim(),
      image:
        (item.product.image || '').substr(-12) === 'no_selection'
          ? ''
          : item.product.image,
      chef_firstname: item.product.chef_firstname
        ? item.product.chef_firstname.trim()
        : '',
      chef_lastname: item.product.chef_lastname
        ? item.product.chef_lastname.trim()
        : '',
      chefId: item.product.chef_id,
      is_celebrity_chef: item.product.is_celebrity_chef === '1',
      chefImage: item.product.chef_bannerpic
        ? item.product.chef_bannerpic
        : CHEF_IMG_URL_SOURCE + item.product.bannerpic,
      quantity: item.qty,
      meatType: item.product.meat_type,
      calories: +item.product.calories,
      tags: item.product.calories
        ? [{ label: `${item.product.calories} cal` }]
        : [],
      premium_special: item.product ? item.product.premium_special : null
    }
  })
}

export const getMealsFromCart = (items = []) => {
  return items.map(item => {
    return {
      ...item,
      id: +item.id || +item.entity_id,
      chefId: item.chef_id,
      mealName: `${item.name ? item.name : ''}`.trim(),
      mealDescription: `${
        item.short_description ? item.short_description : ''
      }`.trim(),
      image: buildImgUrl(IMG_URL_SOURCE, item.image_path, item.image),
      chefImage:
        CHEF_IMG_URL_SOURCE +
        (item.bannerpic
          ? item.bannerpic
              .replace('/media/avatar/', '')
              .replace('/media/avatar-small/thumbnail/', '')
          : ''),
      chef_firstname: item.chef_firstname ? item.chef_firstname.trim() : '',
      chef_lastname: item.chef_lastname ? item.chef_lastname.trim() : '',
      is_celebrity_chef: item.is_celebrity_chef === 'true',
      calories: +item.calories,
      quantity: item.qty,
      meatType: item.meat_type,
      tags: item.calories ? [{ label: `${item.calories} cal` }] : []
    }
  })
}

export const getRecommendations = items => {
  return items.map(item => ({
    ...item,
    id: +item.id,
    mealName: `${item.name ? item.name : ''}`.trim(),
    mealDescription: `${
      item.short_description ? item.short_description : ''
    }`.trim(),
    name: `${item.name ? item.name : ''} ${
      item.short_description ? item.short_description : ''
    }`.trim(),
    image: item.image.full_path,
    chefName: `${item.chef_firstname ? item.chef_firstname : ''} ${
      item.chef_lastname ? item.chef_lastname : ''
    }`.trim(),
    chefId: item.chef_id,
    is_celebrity_chef: item.is_celebrity_chef === 'true',
    quantity: item.qty,
    meatType: item.meat_type,
    tags: item.calories ? [{ label: `${item.calories} cal` }] : [],
    chefImage: CHEF_IMG_URL_SOURCE + item.bannerpic,
    product: {
      mealName: item.name,
      short_description: item.short_description,
      chef_firstname: item.chef_firstname,
      chef_lastname: item.chef_lastname
    },
    chef_img: CHEF_IMG_URL_SOURCE + item.bannerpic,
    premium_special: item.premium_special
  }))
}

export const getAddress = address => {
  if (address) {
    const { city, region, street } = address
    return `${street}, ${city}, ${region}`
  }
  return ''
}

export const trackEventReschedule = (
  selectedDate,
  selectedTimeslot,
  newDate
) => {
  tracking.track('Reschedule', {
    order_id: selectedDate.order ? selectedDate.order.id : '-',
    old_delivery_date: selectedDate.date,
    old_delivery_time: `${moment(selectedTimeslot.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot.end, 'h:mm A').format('h:mm A')}`,
    new_delivery_date: newDate.date,
    new_delivery_time: `${moment(selectedTimeslot.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot.end, 'h:mm A').format('h:mm A')}`
  })
}

export const emptyDay = ({ order, recommendation, date }, carts) =>
  !recommendation && !order && !getCartByDate(carts, date)

export const merchandiseDay = ({ order, recommendation }) =>
  !recommendation && !order

export const getCartByDate = (carts, date) => {
  if (carts && carts[date] && carts[date].items && carts[date].items.length) {
    return carts[date].items
  }
  return null
}

const isConfirmed = upcomingDay => !upcomingDay.canEdit

const isSkipped = upcomingDay => upcomingDay.skip

const isPaused = upcomingDay => upcomingDay.isPaused

const isPending = upcomingDay => upcomingDay.order?.id === '0'

const isRequested = upcomingDay => !!upcomingDay.order

const isUndecided = upcomingDay =>
  !upcomingDay.order ||
  !upcomingDay.order.items ||
  !upcomingDay.order.items.length
const isUnknown = () => true

const strategyMap = {
  confirmed: isConfirmed,
  skipped: isSkipped,
  paused: isPaused,
  pending: isPending,
  requested: isRequested,
  undecided: isUndecided,
  unknown: isUnknown
}

const strategyOrder = [
  'confirmed',
  'skipped',
  'paused',
  'pending',
  'requested',
  'undecided',
  'unknown'
]

const calculateStatus = upcomingDay => {
  for (const strategy of strategyOrder) {
    if (strategyMap[strategy](upcomingDay)) {
      return strategy
    }
  }
}

export const getDeliveryDates = upcomingDeliveries => {
  const deliveryDates = []

  for (const ud of upcomingDeliveries) {
    if (ud.menuAvailable && ud.canEdit) {
      deliveryDates.push({
        delivery_date: ud.displayDate,
        order_status: calculateStatus(ud)
      })
    }
  }

  return deliveryDates
}
