import React from 'react'
import classNames from 'classnames'

import './RadioButtonList.scss'

type RadioButtonListOption = {
  id: string | number
  label: string
}

type RadioButtonListProps = {
  options: RadioButtonListOption[]
  checkedOptionId?: string | number
  onChange: Function
  name: string
  direction: 'row' | 'column'
  allowEmptyResponse: boolean
}

const RadioButtonList = ({
  options,
  checkedOptionId,
  onChange,
  name,
  direction,
  allowEmptyResponse
}: RadioButtonListProps) => (
  <div className={classNames('radio-button-list', name, direction)}>
    {options.map((option, index) => {
      const isChecked = checkedOptionId === option.id
      const onChangeFn = () => {
        if (!allowEmptyResponse && isChecked) {
          return
        }

        return onChange(allowEmptyResponse && isChecked ? null : option, name)
      }

      return (
        <div
          key={`${index}-${option.id}`}
          className="radio-button-list__option"
          onClick={onChangeFn}>
          <input
            type="radio"
            key={`${name}-${option.id}`}
            name={`${name}-${option.id}`}
            id={`${name}-${option.id}`}
            value={option.label}
            checked={isChecked}
            disabled={false}
          />
          <label htmlFor={`${name}-${option.id}`}>{option.label}</label>
        </div>
      )
    })}
  </div>
)

export default RadioButtonList
