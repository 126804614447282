import { CuiModal } from 'front-lib'

export function ChangePlanSuccessModal({
  className,
  isOpen,
  onClose,
  children
}) {
  return (
    <CuiModal
      isOpen={isOpen}
      className={className}
      onRequestClose={onClose}
      mediumSize>
      {children}
    </CuiModal>
  )
}
