import { handleActions, createAction } from 'redux-actions'

export const selectDelivery: (index: number) => any = createAction(
  'SELECT_DELIVERY'
)

export interface State {
  index: number
}

export const selectedDeliveryReducer = handleActions(
  {
    [selectDelivery as any]: (
      state: State,
      action: { payload: number }
    ): State => {
      return {
        index: action.payload
      }
    }
  },
  {
    index: 0
  } as State
)
