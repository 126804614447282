import { State } from '../../types/challenge'

export const isChallengePanelOpenSelector = (state: {
  challenge: State
}): boolean => {
  return state.challenge.isOpen
}

export const getChallenge = (state: { challenge: State }): State => {
  return state.challenge
}
