import React from 'react'
import classnames from 'classnames'
import { ResponsiveModalOrBottomSheet } from 'src/components/ResponsiveModalOrBottomSheet/ResponsiveModalOrBottomSheet'
import { Component, ComponentType } from 'src/types/component'
import {
  Button,
  ButtonType,
  RatingDetail,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import { ButtonStyle } from 'src/pages/orders/components/RatingTab/components/Button/Button'
import './BottomSheet.scss'

interface BottomSheetProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  isLoading?: boolean
  title?: string
  mealProps: {
    id: string
    title: string
    image: {
      name: string
    }
    chef: any
  }
  className?: string
  childrenAsProps: Component[]
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
  title,
  mealProps,
  className = '',
  childrenAsProps
}) => {
  const ratingDetail = childrenAsProps.find(
    child => child?.type === ComponentType.RATING_DETAIL
  )

  const primaryButton = childrenAsProps.find(
    child =>
      child?.type === ComponentType.BUTTON &&
      child?.props.subType === ButtonType.PRIMARY
  )

  const secondaryButton = childrenAsProps.find(
    child =>
      child?.type === ComponentType.BUTTON &&
      child?.props.subType === ButtonType.SECONDARY
  )

  return (
    <ResponsiveModalOrBottomSheet
      isOpen={!!ratingDetail && isOpen}
      onClose={onClose}
      title={title}
      className={classnames('bottom-sheet', className)}>
      <RatingDetail {...ratingDetail?.props} mealProps={mealProps} />
      <div className="bottom-sheet__button-section">
        <Button
          {...secondaryButton?.props}
          style={ButtonStyle.PRIMARY}
          textStyle={TextStyle.SMALL_BOLD}
          onClick={onClose}
        />
        <Button
          {...primaryButton?.props}
          style={ButtonStyle.SECONDARY}
          textStyle={TextStyle.SMALL_BOLD}
          onClick={onSubmit}
          showLoaderIndicator={isLoading}
        />
      </div>
    </ResponsiveModalOrBottomSheet>
  )
}
