import { gql, useMutation } from '@apollo/client'

export const CREATE_SKIP_PERIOD_MUTATION = gql`
  mutation createSkipPeriod(
    $weeks: Int!
    $origin: OperationOrigin
    $reason: String
  ) {
    createSkipPeriod(weeks: $weeks, origin: $origin, reason: $reason) {
      __typename
      ... on GenericError {
        error
      }
      ... on Skips {
        skips {
          date
          userId
          id
        }
      }
    }
  }
`

export function useCreateSkipPeriod({
  onCompleted = () => {},
  onError = () => {}
}: {
  onCompleted?: () => void
  onError?: () => void
}) {
  const [createSkipPeriod, rest] = useMutation(CREATE_SKIP_PERIOD_MUTATION, {
    onCompleted,
    onError,
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['lastSkips']
  })

  return {
    createSkipPeriod: (variables: {
      weeks: string
      origin: string
      reason: string
    }) => createSkipPeriod({ variables }),
    ...rest
  }
}
