import React, { Fragment } from 'react'
import classnames from 'classnames'
import { CuiButton } from 'front-lib'
import { CuiLink } from 'front-lib'

const Row = props => {
  const {
    title,
    label,
    subtitle,
    isLoading,
    withDropdown,
    dropdown,
    icon,
    testId
  } = props
  return (
    <div
      className={classnames('section_row', { loading: isLoading })}
      data-test-id={`${testId}-row`}>
      {!isLoading && icon && <div>{icon}</div>}
      <div className="row_info">
        {(title || label) && (
          <div className="title_container">
            {title && <p className="title">{title}</p>}
            {label && <span className="label">{label}</span>}
          </div>
        )}
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>
      {!isLoading && withDropdown && dropdown}
    </div>
  )
}

const SettingSection = props => {
  const {
    className,
    style,
    title,
    label,
    subtitle,
    rows,
    withAction,
    actionLabel = 'Edit',
    withActionButton,
    buttonLabel = 'Change',
    buttonClassName = '',
    disabled,
    onActionClick,
    actionIsActive,
    actionComponent,
    isLoading,
    withActionDropdown,
    actionDropdown,
    icon,
    children,
    testId = null
  } = props

  return (
    <div
      data-test-id={testId}
      className={classnames('cookunity__setting_section_container', className, {
        loading: isLoading
      })}
      style={style}>
      {!actionIsActive ? (
        <Fragment>
          <div
            className="cookunity__setting_section"
            data-test-id={`${testId}-section`}>
            <div className="rows_container">
              {rows && rows.length > 0 ? (
                rows.map((row, i) => (
                  <Row
                    testId={row.testId}
                    isLoading={isLoading}
                    key={i}
                    icon={icon}
                    title={row.title}
                    label={row.label}
                    subtitle={row.subtitle}
                    withDropdown={withActionDropdown}
                    dropdown={actionDropdown}
                  />
                ))
              ) : (
                <Row
                  testId={testId}
                  isLoading={isLoading}
                  icon={icon}
                  title={title}
                  label={label}
                  subtitle={subtitle}
                  withDropdown={withActionDropdown}
                  dropdown={actionDropdown}
                />
              )}
            </div>
            {children && (
              <div
                className={classnames('setting_children', {
                  loading: isLoading
                })}>
                {!isLoading && children}
              </div>
            )}
            {!isLoading &&
              (withActionDropdown ? null : withAction ? (
                <>
                  <div className="action_link">
                    <CuiLink
                      color="primary"
                      fill="clear"
                      icon={null}
                      showUnderline
                      size="medium"
                      type="button"
                      onClick={() => {
                        if (!disabled) {
                          onActionClick()
                        }
                      }}
                      disabled={disabled}>
                      {actionLabel}
                    </CuiLink>
                  </div>
                </>
              ) : null)}
          </div>
          {!isLoading && withActionButton && (
            <div className="cookunity__action_button_container">
              <CuiButton
                size="large"
                disabled={disabled}
                className={buttonClassName}
                onClick={() => {
                  if (!disabled) {
                    onActionClick()
                  }
                }}>
                {buttonLabel}
              </CuiButton>
            </div>
          )}
        </Fragment>
      ) : (
        actionComponent
      )}
    </div>
  )
}

export default SettingSection
