import classNames from 'classnames'
import Card from 'src/pages/preferences/components/Card/Card'
import { FormItemHeader } from 'src/pages/preferences/components/FormItemHeader/FormItemHeader'
import { TypedAutopilotQuestionProps } from 'src/pages/preferences/utils'
import HouseholdOption from 'src/pages/preferences/components/AutopilotTab/components/AutopilotQuestion/components/HouseholdOption/HouseholdOption'
import {
  AutopilotUserAttributes,
  HOUSEHOLD_OPTION_IDS
} from 'src/types/preferences'
import './HouseholdQuestion.scss'

const HouseholdQuestion = ({
  title,
  subtitle,
  info,
  options,
  autopilotFormData,
  onUpdate,
  isDisabled
}: TypedAutopilotQuestionProps) => (
  <Card>
    {title && <FormItemHeader title={title} subtitle={subtitle} info={info} />}
    <div className={classNames('household-question', { disabled: isDisabled })}>
      {options.map((option, index) => {
        const isSelected = autopilotFormData.household === option.id

        return (
          <HouseholdOption
            key={`${index}-${option.id}`}
            id={option.id as HOUSEHOLD_OPTION_IDS}
            label={option.label}
            isSelected={isSelected}
            onClick={() =>
              onUpdate(
                AutopilotUserAttributes.HOUSEHOLD,
                isSelected ? null : option.id
              )
            }
          />
        )
      })}
    </div>
  </Card>
)

export default HouseholdQuestion
