import './ConfirmationPlanChangeModal.scss'

import { CuiButton, CuiModal } from 'front-lib'
import { useTranslation, Trans } from 'react-i18next'

export function ConfirmationPlanChangeModalDesktop({ isOpen, handleClose }) {
  const { t } = useTranslation()

  return (
    <CuiModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="flex-mode__confirmation-modal__modal-content"
      iconClassName="flex-mode__confirmation-modal__close-icon">
      <div className="flex-mode__confirmation-modal__container">
        <div className="flex-mode__confirmation-modal__title-container">
          <h1 className="flex-mode__confirmation-modal__title">
            {t(
              'pages.my_plan.flex_mode.modal_confirmation.you_ve_switched_back.desktop'
            )}
          </h1>
        </div>
        <div className="flex-mode__confirmation-modal__text-container">
          <p className="flex-mode__confirmation-modal__text">
            <Trans t={t}>
              {t(
                'pages.my_plan.flex_mode.modal_confirmation.your_plan_is_currently_4_meals.desktop'
              )}
            </Trans>
          </p>
          <div className="flex-mode__confirmation-modal__buttons-container">
            <CuiButton
              fill="solid"
              color="primary"
              size="large"
              onClick={handleClose}>
              {t('pages.my_plan.flex_mode.modal_confirmation.got_it')}
            </CuiButton>
          </div>
        </div>
      </div>
    </CuiModal>
  )
}
