import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDaysWithMenuAvailableSelector,
  selectSchedulePanelIsOpenSelector
} from './selectors'
import { toggleSchedulePanel } from 'src/redux-api-bridge/schedule/effects'

export function useHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const isSchedulePanelOpen = useSelector(selectSchedulePanelIsOpenSelector)
  const daysWithMenuAvailable = useSelector(getDaysWithMenuAvailableSelector)
  const extraDeliveryDisabled = !daysWithMenuAvailable.length

  function handleToggleSchedulePanel() {
    dispatch(toggleSchedulePanel('extraDelivery', !isSchedulePanelOpen))
  }

  function handleCloseMenu(event) {
    event.stopPropagation()
    setIsMenuOpen(false)
  }

  function toggleUserMenu(event) {
    event.stopPropagation()
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)
  }

  return {
    toggleUserMenu,
    handleToggleSchedulePanel,
    extraDeliveryDisabled,
    isMenuOpen,
    handleCloseMenu
  }
}
