import moment from 'moment'
import classnames from 'classnames'

import './Calendar.scss'
import { Tooltip } from 'react-tooltip'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { useTranslation } from 'react-i18next'
import { CalendarBoxType } from 'src/types/calendarBoxType'

interface CalendarBoxProps {
  type:
    | 'regular'
    | 'confirmed'
    | 'confirmedAfter'
    | 'resume'
    | 'skipped'
    | 'today'
    | 'paused'
  date: string
}

const CalendarBox = ({
  date: initialDate,
  type = 'regular'
}: CalendarBoxProps) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  const date = moment(initialDate)

  const calendarBoxContent = (
    <div
      data-tooltip-id={`calendar-box-tooltip-${initialDate}`}
      className={classnames('calendar-box-content', type)}>
      <div>{date.format('ddd')}</div>
      <div className="date">{date.format('D')}</div>
      <div>{date.format('MMM')}</div>
      {type === CalendarBoxType.skipped && <div className="cross-line"></div>}
    </div>
  )

  return (
    <>
      {calendarBoxContent}
      {type !== 'regular' && type !== 'paused' && (
        <Tooltip
          id={`calendar-box-tooltip-${initialDate}`}
          opacity={1}
          openOnClick={isMobile}
          className="calendar-box-tooltip">
          {t(`feedbackAfterPause.day_info.${type}`)}
        </Tooltip>
      )}
    </>
  )
}

export default CalendarBox
