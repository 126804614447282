import { OAuthError } from '@auth0/auth0-react'

import { isBoolean } from '../utils/utils'

const CODE_RE = /[?&]code=[^&]+/
const ERROR_RE = /[?&]error=[^&]+/

export const hasAuthParams = (searchParams = window.location.search) =>
  CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)

const normalizeErrorFn = fallbackMessage => error => {
  if ('error' in error) {
    return new OAuthError(error.error, error.error_description)
  }
  if (error instanceof Error) {
    return error
  }
  return new Error(fallbackMessage)
}

export const loginError = normalizeErrorFn('Login failed')

export const tokenError = normalizeErrorFn('Get access token failed')

export const wrappedGetToken = getTokenFn => async opts => {
  try {
    return await getTokenFn(opts)
  } catch (error) {
    throw tokenError(error)
  }
}

export const toAuth0ClientOptions = opts => {
  const { clientId, redirectUri, maxAge, ...validOpts } = opts
  return {
    ...validOpts,
    client_id: clientId,
    redirect_uri: redirectUri,
    max_age: maxAge
  }
}

export const toAuth0LoginRedirectOptions = opts => {
  if (!opts) {
    return
  }
  const { redirectUri, ...validOpts } = opts
  return {
    ...validOpts,
    redirect_uri: redirectUri
  }
}

export const defaultOnRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    (appState && appState.returnTo) || window.location.pathname
  )
}

export const adaptUserData = (user = {}) => {
  const namespace = 'https://cookunity.com'
  const namespace_email = user && user[`${namespace}/email`]
  return {
    ...user,
    email: user.email || namespace_email || null,
    given_name: user.given_name || null,
    family_name: user.family_name || null,
    isSocial: isBoolean(user[`${namespace}/isSocial`])
      ? user[`${namespace}/isSocial`]
      : null,
    isSubscribed: isBoolean(user[`${namespace}/isSubscribed`])
      ? user[`${namespace}/isSubscribed`]
      : null,
    provider: user[`${namespace}/provider`] || null,
    magento_id: user[`${namespace}/magento_id`] || null,
    subscription_id: user[`${namespace}/subscription_id`] || null
  }
}
