import { CuiLoader } from 'front-lib'

import './Loader.scss'

const Loader = () => {
  return (
    <div className="loader-container">
      <CuiLoader />
    </div>
  )
}

export default Loader
