import './ComparePlansModal.scss'

import { CuiButton } from 'front-lib'
import { FC } from 'react'
import { noop } from 'src/utils/utils'
import { useTranslation } from 'react-i18next'

const FLEX_MODE_PLAN_PRICE = '$15.59'

export const FlexModePlan: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex-mode__compare-modal__plan-container flex-mode__compare-modal__plan-container--secondary">
      <div className="flex-mode__compare-modal__plan-description-container">
        <h1 className="flex-mode__compare-modal__plan-title">
          {t('pages.my_plan.flex_mode.modal_compare.flex_mode')}
        </h1>
        <h3 className="flex-mode__compare-modal__plan-subtitle">
          {t('pages.my_plan.flex_mode.modal_compare.no_commitment')}
        </h3>
        <p className="flex-mode__compare-modal__plan-description">
          {t('pages.my_plan.flex_mode.modal_compare.order_the_meals_you_want')}
        </p>
      </div>
      <div className="flex-mode__compare-modal__plan-price-container">
        <span className="flex-mode__compare-modal__plan-price-prefix">
          {t('pages.my_plan.flex_mode.modal_compare.starting_at')}
        </span>
        <span className="flex-mode__compare-modal__plan-price">
          {FLEX_MODE_PLAN_PRICE}
          <span className="flex-mode__compare-modal__plan-price-suffix">
            / {t('pages.my_plan.flex_mode.modal_compare.meal')}
          </span>
        </span>
      </div>
      <div className="flex-mode__compare-modal__plan-button-container">
        <CuiButton fill="solid" disabled onClick={noop}>
          {t('pages.my_plan.flex_mode.modal_compare.turned_on')}
        </CuiButton>
      </div>
      <ul className="flex-mode__compare-modal__plan-feature-container">
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.4_meals_minimum')}
        </li>
        <li className="flex-mode__compare-modal__plan-feature">
          {t('pages.my_plan.flex_mode.modal_compare.no_automatic_orders')}
        </li>
      </ul>
    </div>
  )
}
