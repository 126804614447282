import Slider from 'react-slick'
import { useMobileDevice } from '../../hooks/useMobileDevice'
import './MealCardSlider.scss'

const settings = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export const MealCardSlider = ({
  children,
  sliderSettings = settings,
  sliderRef = null
}) => {
  const isMobile = useMobileDevice()
  if (isMobile) {
    return (
      <div className="meal-card-slider__mobile" ref={sliderRef}>
        {children}
      </div>
    )
  }
  return (
    <div className="meal-card-slider__desktop">
      <Slider arrows={true} {...sliderSettings} ref={sliderRef}>
        {children}
      </Slider>
    </div>
  )
}
