import createReducer from '../../utils/createReducer'
import { getCartFromMenuCategories } from './utils'

import * as actions from './actions'
import * as menuActions from '../menu/actions'
import * as orderActions from '../order/actions'

const initialState = {
  carts: null,
  unifiedCarts: null,
  gifts: null,
  isLoading: false,
  isError: false,
  isOpen: false,
  useUnifiedCart: false,
  isCrudOperationInFlight: false
}

export default createReducer(initialState, {
  [actions.fetchUnifiedCartForDate]: (state, { payload: { date } }) => ({
    ...state,
    unifiedCarts: {
      ...state.unifiedCarts,
      [date]: null
    },
    isLoading: true,
    isError: false,
    useUnifiedCart: true,
    isCrudOperationInFlight: true
  }),
  [actions.fetchUnifiedCartForDateSucceed]: (
    state,
    { payload: { date, unifiedCart } }
  ) => ({
    ...state,
    isLoading: false,
    unifiedCarts: {
      ...state.unifiedCarts,
      [date]: unifiedCart
    },
    isError: false,
    isCrudOperationInFlight: false
  }),
  [actions.fetchUnifiedCartForDateFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true,
    isCrudOperationInFlight: false
  }),
  [actions.addProductToUnifiedCart]: state => ({
    ...state,
    isLoading: true,
    isError: false,
    isCrudOperationInFlight: true
  }),
  [actions.addProductToUnifiedCartSucceed]: (
    state,
    { payload: { date, unifiedCart } }
  ) => ({
    ...state,
    isLoading: false,
    unifiedCarts: {
      ...state.unifiedCarts,
      [date]: unifiedCart
    },
    isError: false,
    isCrudOperationInFlight: false
  }),
  [actions.addProductToUnifiedCartFailed]: (
    state,
    { payload: { date, unifiedCart } }
  ) => ({
    ...state,
    isLoading: false,
    unifiedCarts: {
      ...state.unifiedCarts,
      [date]: unifiedCart
    },
    isError: true,
    isCrudOperationInFlight: false
  }),
  [actions.removeProductFromUnifiedCart]: state => ({
    ...state,
    isLoading: true,
    isError: false,
    isCrudOperationInFlight: true
  }),
  [actions.removeProductFromUnifiedCartSucceed]: (
    state,
    { payload: { date, unifiedCart } }
  ) => ({
    ...state,
    isLoading: false,
    unifiedCarts: {
      ...state.unifiedCarts,
      [date]: unifiedCart
    },
    isError: false,
    isCrudOperationInFlight: false
  }),
  [actions.removeProductFromUnifiedCartFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true,
    isCrudOperationInFlight: false
  }),
  [actions.fetchCartForDate]: (state, { payload: { date } }) => ({
    ...state,
    carts: {
      ...state.carts,
      [date]: null
    },
    gifts: {
      ...state.gifts,
      [date]: null
    },
    isLoading: true,
    isError: false
  }),
  [actions.fetchCartForDateSucceed]: (
    state,
    { payload: { date, cart, gifts } }
  ) => ({
    ...state,
    isLoading: false,
    gifts: {
      ...state.gifts,
      [date]: gifts
    },
    carts: {
      ...state.carts,
      [date]: cart
    },
    isError: false
  }),
  [actions.fetchCartForDateFailed]: state => ({
    ...state,
    isLoading: false,
    isError: true
  }),
  [menuActions.getUserMenuStart]: (state, { payload: { date } }) => ({
    ...state,
    carts: {
      ...state.carts,
      [date]: state.carts && state.carts[date] ? state.carts[date] : []
    }
  }),
  [menuActions.getUserMenuSuccess]: (state, { payload: { menu, date } }) => {
    const currentCart = state.carts[date]

    return {
      ...state,
      carts: {
        ...state.carts,
        [date]:
          currentCart && currentCart.length > 0
            ? getCartFromMenuCategories(menu.categories, currentCart)
            : []
      }
    }
  },
  [orderActions.discardOrderChanges]: (state, { payload: { date } }) => {
    return {
      ...state,
      carts: {
        ...state.carts,
        [date]: []
      }
    }
  },
  [actions.setCartOpen]: (state, { payload: { isOpen } }) => ({
    ...state,
    isOpen
  }),
  [actions.addProductToCartSucceed]: (
    state,
    { payload: { date, product } }
  ) => {
    const cart = state.carts[date]
    const productInCartIndex = cart.findIndex(
      p => p.inventoryId === product.inventoryId
    )
    if (productInCartIndex >= 0) {
      return {
        ...state,
        carts: {
          ...state.carts,
          [date]: state.carts[date].map(p =>
            p.inventoryId === product.inventoryId
              ? {
                  ...p,
                  qty: product?.quantity,
                  quantity: product?.quantity
                }
              : p
          )
        }
      }
    }
    return {
      ...state,
      carts: {
        ...state.carts,
        [date]: [
          ...state.carts[date],
          {
            ...product,
            entityId: product?.entityId || product?.entity_id,
            title: product?.title || product?.name,
            qty: product?.quantity
          }
        ]
      }
    }
  },
  [actions.removeProductFromCartSucceed]: (
    state,
    { payload: { date, product, inventoryId } }
  ) => {
    if (product) {
      return {
        ...state,
        carts: {
          ...state.carts,
          [date]: state.carts[date].map(p =>
            p.inventoryId === product.inventoryId
              ? {
                  ...p,
                  qty: product?.quantity || product?.qty,
                  quantity: product?.quantity || product?.qty
                }
              : p
          )
        }
      }
    }
    return {
      ...state,
      carts: {
        ...state.carts,
        [date]: state.carts[date].filter(p => p.inventoryId !== inventoryId)
      }
    }
  },
  [actions.clearCartSucceed]: (state, { payload: { date } }) => {
    return {
      ...state,
      carts: {
        ...state.carts,
        [date]: []
      }
    }
  }
})
