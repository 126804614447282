import { CuiIcon } from 'front-lib'
import { toast } from 'react-toastify'

const defaultOptions = {
  hideProgressBar: true,
  pauseOnHover: true,
  closeButton: <CuiIcon name="close" className="close-icon" />,
  autoClose: 2000
}

export const toaster = {
  /**
   * @param {string | ReactNode} message
   * @param {*} options
   * @returns toast object
   */
  default: (message, options = {}) => {
    return toast(message, {
      className: 'default',
      ...defaultOptions,
      ...options
    })
  },
  /**
   * @param {string | ReactNode} message
   * @param {*} options
   * @returns toast object
   */
  success: (message, options = {}) => {
    return toast(message, {
      className: 'success',
      icon: <CuiIcon name="allDone" />,
      ...defaultOptions,
      ...options
    })
  },
  /**
   * @param {string | ReactNode} message
   * @param {*} options
   * @returns toast object
   */
  error: (message, options = {}) => {
    return toast(message, {
      className: 'error',
      icon: <CuiIcon name="warning" />,
      ...defaultOptions,
      ...options
    })
  },
  /**
   * @param {string | ReactNode} message
   * @param {*} options
   * @returns toast object
   */
  failure: (message, options = {}) => {
    return toast(message, {
      className: 'failure',
      icon: <CuiIcon name="close" />,
      ...defaultOptions,
      ...options
    })
  }
}
