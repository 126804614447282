import { FC, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { CuiButton } from 'front-lib'
import './SwitchPlanTourModal.scss'
import { TOUR_HIGHLIGHT_TARGET_SELECTOR_SWITCH_PLAN } from './constants'
import { useSwitchPlanTourModalTracking } from './SwitchPlanTourModalTracking'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from 'src/hooks/useOnClickOutside'

interface Props {
  handleCloseSwitchPlanTourModal: () => void
}

export const SwitchPlanTourModal: FC<Props> = ({
  handleCloseSwitchPlanTourModal
}) => {
  const { t } = useTranslation()
  const clickOutsideRef = useRef(null)
  // TODO: there is a re rendering going on here that should not be happening
  // Check where it is happening and fix it so that we can delete 'isFirstRender' logic
  const isFirstRender = useRef(true)
  useOnClickOutside(clickOutsideRef, () => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    handleClose()
  })

  useEffect(() => {
    const targets = document.getElementsByClassName(
      TOUR_HIGHLIGHT_TARGET_SELECTOR_SWITCH_PLAN
    )
    if (!targets.length) {
      console.warn(
        'Modal shown but no highlight target found, lets try with requestFrame or MutationObserver'
      )
      return
    }
    const target = targets[0]
    target.classList.add('switch-plan__tour-highlight')
    return () => {
      target.classList.remove('switch-plan__tour-highlight')
    }
  }, [])

  const {
    handleCloseTracking,
    handleContinueTracking
  } = useSwitchPlanTourModalTracking()

  function handleClose() {
    handleCloseTracking()
    handleCloseSwitchPlanTourModal()
  }

  function handleContinue() {
    handleContinueTracking()
    handleCloseSwitchPlanTourModal()
  }

  return (
    <ReactModal
      isOpen
      className="switch-plan-tour-step-modal__content"
      overlayClassName="switch-plan-tour-step-modal__overlay"
      portalClassName="switch-plan-tour-step-modal"
      onRequestClose={handleClose}>
      <div className="content-wrapper">
        <div className="content" ref={clickOutsideRef}>
          <div className="title">
            {t(
              'pages.my_plan.flex_mode.modal_switch_plan_tour.ready_to_switch'
            )}
          </div>
          <div className="description">
            {t(
              'pages.my_plan.flex_mode.modal_switch_plan_tour.go_back_to_weekly'
            )}
          </div>
          <div className="cta-container">
            <CuiButton onClick={handleContinue}>
              {' '}
              {t('pages.my_plan.flex_mode.modal_switch_plan_tour.got_it')}
            </CuiButton>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
