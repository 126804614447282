import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'

const API_URL = process.env.REACT_APP_SUBSCRIPTION_API_URL
const GRAPHQL_URI = `${API_URL}/graphql/public`

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  credentials: 'include'
})

const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: 500,
    jitter: true
  },
  attempts: {
    max: 2,
    retryIf: error => !!error
  }
})

export const apolloSubscriptionPublicClient = new ApolloClient({
  link: retryLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})
