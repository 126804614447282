import './SwitchConfirmChangePlanModal.scss'

import { CuiBottomSheet, CuiButton, CuiIcon } from 'front-lib'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwitchConfirmChangePlanModalTracking } from './useSwitchConfirmChangePlanModalTracking'

interface Props {
  showSwitchConfirmChangePlanModal: boolean
  setShowSwitchConfirmChangePlanModal: (
    showSwitchConfirmChangePlanModal: boolean
  ) => void
  onSwitchBackPlan: () => void
}

export const SwitchConfirmChangePlanModalMobile: FC<Props> = ({
  showSwitchConfirmChangePlanModal,
  setShowSwitchConfirmChangePlanModal,
  onSwitchBackPlan
}) => {
  const { t } = useTranslation()
  const {
    handleClose,
    handleConfirm
  } = useSwitchConfirmChangePlanModalTracking(
    setShowSwitchConfirmChangePlanModal,
    onSwitchBackPlan
  )

  return (
    <CuiBottomSheet isOpen={showSwitchConfirmChangePlanModal}>
      <CuiBottomSheet.Body>
        <button
          className={
            'flex-mode__switch-modal__close-icon flex-mode__switch-modal__close-icon-bottom-sheet'
          }
          onClick={handleClose}>
          <CuiIcon name="close" />
        </button>
        <div className="flex-mode__switch-modal__container">
          <div className="flex-mode__switch-modal__title-container">
            <h1 className="flex-mode__switch-modal__title">
              {t(
                `pages.my_plan.flex_mode.modal_switch.are_you_sure_you_want_to_switch`
              )}
            </h1>
          </div>
          <div className="flex-mode__switch-modal__text-container">
            <p className="flex-mode__switch-modal__text">
              {t('pages.my_plan.flex_mode.modal_switch.by_switching.mobile')}
            </p>
          </div>
          <div className="flex-mode__switch-modal__buttons-container">
            <CuiButton fill="solid" color="primary" onClick={handleConfirm}>
              {t('pages.my_plan.flex_mode.modal_switch.yes_lets_do_it')}
            </CuiButton>
            <CuiButton fill="outline" onClick={handleClose}>
              {t('pages.my_plan.flex_mode.modal_switch.cancel')}
            </CuiButton>
          </div>
        </div>
      </CuiBottomSheet.Body>
    </CuiBottomSheet>
  )
}
