import actionCreator from '../utils/actionCreator'

export const PFX = 'USER'

export const initApp = actionCreator(PFX, 'init_app')

export const setValuesOnboarding = actionCreator(PFX, 'set_values_onboarding')

export const getUserData = actionCreator(PFX, 'get_user_data')

export const getUserPastOrders = actionCreator(PFX, 'get_user_past_orders')
export const setMealReviewForOrder = actionCreator(
  PFX,
  'set_meal_review_for_order'
)

export const editUserReview = actionCreator(PFX, 'update_user_review')

export const getUserSettings = actionCreator(PFX, 'get_user_settings')

export const getSubscriptionData = actionCreator(PFX, 'get_subscription_data')
export const getTimeSlots = actionCreator(PFX, 'get_time_slots')
export const updateSubscriptionDays = actionCreator(
  PFX,
  'update_subcription_days'
)
export const setDeliveryInfoToCheck = actionCreator(
  PFX,
  'set_delivery_info_to_check'
)

export const getAddresses = actionCreator(PFX, 'get_addresses')
export const updateAddress = actionCreator(PFX, 'update_address')
export const deleteAddress = actionCreator(PFX, 'delete_address')
export const setDefaultAddress = actionCreator(PFX, 'set_default_address')
export const getAvailableDeliveryDays = actionCreator(
  PFX,
  'get_available_delivery_days'
)

export const updateUserInfo = actionCreator(PFX, 'update_user_info')
export const changeTip = actionCreator(PFX, 'update_tip')

export const getInvoices = actionCreator(PFX, 'get_invoices')

export const updateOrder = actionCreator(PFX, 'update_order')
export const rescheduleOrder = actionCreator(PFX, 'reschedule_order')
export const reschedulingOrder = actionCreator(PFX, 'rescheduling_order')
export const getPreferencesData = actionCreator(PFX, 'get_preferences_data')
export const unskipOrder = actionCreator(PFX, 'unskip_order')
export const changeSubscriptionPreferences = actionCreator(
  PFX,
  'change_subscription_preferences'
)
export const prepopulatePartnerProfile = actionCreator(
  PFX,
  'prepopulate_partner_profile'
)
export const cancelPartnerCreation = actionCreator(
  PFX,
  'cancel_partner_creation'
)
export const getProfilesData = actionCreator(PFX, 'get_profiles_data')
export const removeProfile = actionCreator(PFX, 'remove_profile')

export const getUpcomingDays = actionCreator(PFX, 'get_upcoming_days')
export const chefDropline = actionCreator(PFX, 'chef_dropline')
export const getChefDetail = actionCreator(PFX, 'get_chef_detail')
export const getMenuByFilters = actionCreator(PFX, 'get_menu_by_filters')

export const recoverPassword = actionCreator(PFX, 'recover_password')

export const getPublicData = actionCreator(PFX, 'get_public_data')

export const unsubscribe = actionCreator(PFX, 'unsubscribe')
export const getUnsubscribeReasons = actionCreator(
  PFX,
  'get_unsubscribe_reasons'
)

export const get25Off = actionCreator(PFX, 'get_25_off')

export const clearNotification = actionCreator(PFX, 'clear_notification')

export const changeDateAndTimeslot = actionCreator(
  PFX,
  'change_date_and_timeslot'
)

export const toggleOrderConfirmed = actionCreator(
  PFX,
  'toggle_refer_friend_modal'
)

export const setSetting = actionCreator(PFX, 'set_setting')
export const toggleAddonsTooltip = actionCreator(PFX, 'toggle_addons_tooltip')
export const getZipcodesByDeliveryZone = actionCreator(
  PFX,
  'get_zipcodes_by_delivery_zone'
)

export const createSubscriptionEvent = actionCreator(
  PFX,
  'create_subscription_event'
)

export const sendNutritionMessage = actionCreator(PFX, 'send_nutrition_message')
export const getTrackingInfo = actionCreator(PFX, 'get_tracking_info')

export const addMealWish = actionCreator(PFX, 'add_meal_wish')
export const removeMealWish = actionCreator(PFX, 'remove_meal_wish')
export const addMealAvoid = actionCreator(PFX, 'add_meal_avoid')
export const removeMealAvoid = actionCreator(PFX, 'remove_meal_avoid')
export const getAvailableMeals = actionCreator(PFX, 'get_available_meals')

export const addMealWishRequest = actionCreator(PFX, 'add_meal_wish_request')

export const sendSatisfactionResponse = actionCreator(
  PFX,
  'send_satisfaction_response'
)

export const updateOrderComment = actionCreator(PFX, 'update_order_comment')

export const getAllOrders = actionCreator(PFX, 'get_all_orders')

export const getUserReferrals = actionCreator(PFX, 'get_user_referrals')

export const getStores = actionCreator(PFX, 'get_stores')

export const getUserCurrentCredit = actionCreator(
  PFX,
  'get_user_current_credit'
)

export const getUserSurvey = actionCreator(PFX, 'get_user_survey')

export const submitSurvey = actionCreator(PFX, 'submit_survey')

export const getUserPromotions = actionCreator(PFX, 'get_user_promotions')

export const getIdleDeliveryDays = actionCreator(PFX, 'get_idle_delivery_days')

export const applyAutoPilotPromotion = actionCreator(
  PFX,
  'apply_autopilot_promotion'
)
