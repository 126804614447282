import { SaveChangesModal } from './SaveChangesModal'
import { useHistory } from 'react-router'
import { useEffect, useRef, useState } from 'react'

export const RouterPrompt = ({
  unsavedChanges,
  onSave,
  onClose,
  onProceed
}) => {
  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  const unblockRef = useRef()

  function handleShowModal() {
    setShowPrompt(true)
  }

  useEffect(() => {
    unblockRef.current = history.block(location => {
      if (unsavedChanges) {
        setCurrentPath(location.pathname)
        handleShowModal()
        return false
      }
      return true
    })
    return () => {
      unblockRef.current && unblockRef.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges])

  const handleProceed = () => {
    if (unblockRef) {
      unblockRef.current()
    }
    setShowPrompt(false)
    onProceed()
    history.push(currentPath)
  }

  const handleClose = () => {
    setShowPrompt(false)
    onClose()
  }

  const handleSave = () => {
    if (unblockRef) {
      unblockRef.current()
    }
    setShowPrompt(false)
    onSave()
    history.push(currentPath)
  }

  return (
    <SaveChangesModal
      isOpen={showPrompt}
      onSave={() => handleSave()}
      onClose={() => handleClose()}
      onProceed={() => handleProceed()}
    />
  )
}
