import { CuiImage } from 'front-lib'

import { ReactComponent as Ribbon } from 'src/assets/svg/award.svg'
import UnityPassLogo from 'src/assets/img/unitypass.png'

export function MembershipMenuItem() {
  return (
    <>
      <Ribbon />
      <CuiImage className="unity_pass_logo" src={UnityPassLogo} />
    </>
  )
}
