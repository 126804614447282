import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CuiFabButton, CuiLogo, CuiSegment } from 'front-lib'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getInitialsSelector } from '../../selectors'
import { RoutePaths } from '../../../../routes/routePaths'
import useChallenge from 'src/hooks/useChallenge'
import { CHALLENGE_IDS } from 'src/constants/challenge'
import ChallengeMenu from 'src/components/ChallengeMenu/ChallengeMenu'

const HeaderDesktop = ({
  toggleUserMenu,
  extraDeliveryDisabled,
  handleToggleSchedulePanel
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { showChallenge } = useChallenge(CHALLENGE_IDS.FIVE_MILESTONES)

  const initials = useSelector(getInitialsSelector)

  const isOrdersPage = location.pathname === RoutePaths.ORDERS

  return (
    <div className="cookunity__header_container">
      <header className={'cui-header dark'}>
        <NavLink to={RoutePaths.ORDERS} className="cui-header__nav-link">
          <CuiLogo />
        </NavLink>
        <div>
          <CuiSegment color="light">
            {isOrdersPage && (
              <button
                onClick={handleToggleSchedulePanel}
                disabled={extraDeliveryDisabled}
                className="cui-segment-button cui-header__extra-delivery">
                {t('header.add_extra_delivery.desktop')}
              </button>
            )}
            {showChallenge && <ChallengeMenu />}
            <div className="cui-segment-button cui-segment-icon-button">
              <CuiFabButton onClick={toggleUserMenu}>{initials}</CuiFabButton>
            </div>
          </CuiSegment>
        </div>
      </header>
    </div>
  )
}

export default HeaderDesktop
