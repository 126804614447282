import { handleActions } from 'redux-actions'

import { helperStatus } from '../helpers'

import * as actions from './actions'
import { formatDeliveryTimeOptions } from './utils'

const initialState = {
  inFlight: false,
  succeeded: false,
  error: false,
  action: {
    id: null,
    type: '',
    isRequired: false,
    ordersConfirmed: [],
    ordersToEdit: [],
    ordersToCancel: [],
    deliveryDaysOptions: [],
    deliveryTimeOptions: [],
    deliveryNewRing: {},
    address: [],
    isLocalRing: false
  },
  newAddress: null
}

export default handleActions(
  {
    [actions.checkChangeAddressStart]: state => {
      return {
        ...state,
        action: initialState.action,
        newAddress: null,
        ...helperStatus({ inFlight: true, succeeded: false, error: false })
      }
    },
    [actions.checkChangeAddressSuccess]: (
      state,
      { payload: { action, newAddress } }
    ) => {
      return {
        ...state,
        action: {
          ...action,
          deliveryTimeOptions: formatDeliveryTimeOptions(
            action.deliveryTimeOptions
          )
        },
        newAddress: newAddress.address,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.checkChangeAddressFail]: (state, action) => {
      return {
        ...state,
        action: initialState.action,
        newAddress: null,
        ...helperStatus({
          inFlight: false,
          succeeded: false,
          error: action.payload.err
        })
      }
    }
  },
  initialState
)
