import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import tracking from '../../../shared/tracking'
import { getRouteName } from 'src/utils/getRouteName'

const withTracking = Component => {
  return props => {
    const location = useLocation()

    useEffect(() => {
      const routeName = getRouteName(location.pathname)
      if (routeName) {
        tracking.page(routeName)
      }
    }, [location.pathname])

    return <Component {...props} />
  }
}

export default withTracking
