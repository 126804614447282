import React from 'react'
import { Form } from 'formik'
import './index.scss'
import './index.rebranding.scss'

const CustomForm = props => (
  <Form {...props} className="cookunity__my_account_form" />
)

export default CustomForm
