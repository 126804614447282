import { useEffect, useMemo } from 'react'
import { CuiButton } from 'front-lib'
import { useTrackMembershipModal } from '../../../useTrackMembershipModal'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import {
  Step,
  MembershipData,
  MembershipOverlayName,
  MembershipOverlayActions,
  SpecialOfferMembership
} from 'src/types/membership'
import UnityPassImage from 'src/assets/img/unitypass.png'
import './UnityPassIntroduction.scss'
import { useLifeSpan } from 'src/api/User/useLifeSpan'
import { SpecialOfferBody } from './SpecialOfferBody'
import { ResurrectedBody } from './ResurrectedBody'
import { DefaultBody } from './DefaultBody'
import { useReplaceHtmlWildCard } from '../useReplaceHtmlWildCard'

/**
 * Types
 */
interface Props {
  onContinue: (step: Step) => void
  membershipData: MembershipData
  bannerText: string
  specialOffer?: SpecialOfferMembership
}

/**
 * Helpers
 */

const getBodyVariant = (lifespan, specialOffer, membership) => {
  if (specialOffer) {
    return (
      <SpecialOfferBody specialOffer={specialOffer} membership={membership} />
    )
  }

  if (lifespan?.wasResurrected) {
    return <ResurrectedBody membership={membership} />
  }

  return <DefaultBody membership={membership} />
}

/**
 * UnityPassIntroduction component
 */

export function UnityPassIntroduction({
  onContinue,
  bannerText,
  membershipData,
  specialOffer
}: Props) {
  const { trackModalViewed, trackOptionClicked } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.introduction
  })
  const { membership } = membershipData
  const { lifespan } = useLifeSpan()
  const { replaceHtmlWildCard } = useReplaceHtmlWildCard(membership)

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleContinue() {
    trackOptionClicked({ overlayAction: MembershipOverlayActions.discover })
    onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
  }

  const ctaButtonText = useMemo(() => {
    if (specialOffer?.primaryCta) {
      return replaceHtmlWildCard(specialOffer.primaryCta)
    }
    return 'Discover Unity Pass benefits'
  }, [specialOffer?.primaryCta, replaceHtmlWildCard])

  if (!membership) {
    return null
  }

  return (
    <>
      <div className="unity-pass-banner-with-image">
        <img src={UnityPassImage} alt="Unity Pass" />
        <div className="unity-pass-by-chef">{bannerText}</div>
      </div>

      <div className="unity-pass-image-container">
        {membership?.discount?.imageUrl && (
          <img src={membership.discount.imageUrl} alt="placeholder" />
        )}
      </div>

      <div className="introduction-container">
        {getBodyVariant(lifespan, specialOffer, membership)}
        <div className="action-buttons">
          <CuiButton onClick={handleContinue}>{ctaButtonText}</CuiButton>
        </div>
      </div>
    </>
  )
}
