import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { RoutePaths } from 'src/routes/routePaths'
import routes from 'src/routes'
import { useAuth0 } from '@auth0/auth0-react'
import { RedirectToLogin } from 'src/components/Routes/RedirectToLogin'
import Loader from 'src/common/Loader'

export function Routes() {
  const { isAuthenticated } = useAuth0()

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map(route => {
          const key =
            typeof route.path === 'string' ? route.path : route.path.join?.('')
          return (
            <Route
              key={key}
              path={route.path}
              name={route.name}
              routes={route.routes}
              exact={route.exact}
              render={props => {
                if (route.private && !isAuthenticated) {
                  return <RedirectToLogin />
                }
                return <route.component {...props} />
              }}
            />
          )
        })}
        <Route exact component={() => <Redirect to={RoutePaths.ORDERS} />} />
      </Switch>
    </Suspense>
  )
}
