import moment from 'moment'

const analyticsOrderStateMap = {
  skipped: 'skipped',
  paused: 'paused',
  confirmed: 'confirmed',
  confirmedPending: 'confirmed-pending',
  toBeCompleted: 'to-be-completed',
  partialCart: 'partial-cart'
}

export function getOrderStatus({
  mealsCount,
  isConfirmed,
  isSkipped,
  isPaused,
  hasOrderOrRecommendation
}) {
  // When paused it's also skipped so check paused first.
  if (isPaused) {
    return analyticsOrderStateMap.paused
  }
  if (isSkipped) {
    return analyticsOrderStateMap.skipped
  }
  if (isConfirmed) {
    return analyticsOrderStateMap.confirmed
  }
  if (hasOrderOrRecommendation) {
    return analyticsOrderStateMap.confirmedPending
  }
  if (mealsCount === 0) {
    return analyticsOrderStateMap.toBeCompleted
  }
  return analyticsOrderStateMap.partialCart
}

export function getBrowseEventCtaCopyFromOrderStatus(orderStatus) {
  switch (orderStatus) {
    case analyticsOrderStateMap.confirmedPending:
      return 'Edit Order'
    case analyticsOrderStateMap.partialCart:
      return 'Complete Order'
    case analyticsOrderStateMap.toBeCompleted:
    case analyticsOrderStateMap.skipped:
    case analyticsOrderStateMap.paused:
      return 'Browse Menu'
    case analyticsOrderStateMap.confirmed:
      return 'Track Order'
    default:
      return ''
  }
}

/**
 * As part of sc-36903 ticket, we need to re-use the logic to get the copy for the
 * browse menu event so we can use it in the UI and in the tracking event,
 * both should change at the same time.
 *
 * "hasOrderOrRecommendation" means confirmed by the user.
 *
 * @param { {
 *   mealsCount,
 *   isConfirmed,
 *   isSkipped,
 *   isPaused,
 *   hasOrderOrRecommendation
 * } } props
 */
export function getBrowseEventCtaCopy(orderStatusProps) {
  const orderStatus = getOrderStatus(orderStatusProps)
  return getBrowseEventCtaCopyFromOrderStatus(orderStatus)
}

export function formatDate(date) {
  return moment(date)
    .format('ddd, MMM. DD ')
    .replace('May.', 'May')
}
