import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import ReactGA from 'react-ga'

import history from './shared/history'
import { env, ENVS } from './constants/env'

const enableTools = env === ENVS.prod || env === ENVS.qa

if (enableTools) {
  datadogLogs.init({
    clientToken: 'pub60a3538f9e52a9b769f387a1d60282db',
    site: 'datadoghq.com',
    service: 'subscription-front',
    sessionSampleRate: 100,
    env,
    version: process.env.REACT_APP_VERSION
  })
  datadogRum.init({
    applicationId: 'd09b2136-e4b9-43a8-a49c-b8a5e4d3bfff',
    clientToken: 'pub60a3538f9e52a9b769f387a1d60282db',
    site: 'datadoghq.com',
    service: 'subscription-front',
    env,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackResources: true,
    trackLongTasks: true
  })
}

ReactGA.initialize('UA-46494018-1')
history.listen(location => {
  ReactGA.pageview(location.pathname + location.hash)
})

window.getAppVersion = () => {
  console.info(process.env.REACT_APP_VERSION)
}
