import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchViewValue } from 'src/redux-api-bridge/menu/actions'
import { findSuggestionsByUserSearch } from '../utils'
import { getSearchByValues } from 'src/redux-api-bridge/menu/selectors'
import { trackSearchRevampFilterApplied } from 'src/utils/tracking'
import { SearchInputState } from './SearchInput'

export const INPUT_ORIGIN = {
  USER_SEARCH: 'Typing',
  CLOSE: 'Close icon',
  PILL: 'Search Suggested Pill',
  LAST_SEARCHES: 'Last Searches'
}

export const useSearchInput = (inputChange: SearchInputState) => {
  const dispatch = useDispatch()
  const [debounceValue, setDebounceValue] = useState(inputChange.value)
  const searchByParams = useSelector(getSearchByValues)

  useEffect(() => {
    let handler
    if (inputChange.value?.length > 0 || !!inputChange.origin) {
      handler = setTimeout(() => {
        setDebounceValue(inputChange.value)
        dispatch(
          setSearchViewValue({
            searchedValue: inputChange.value,
            suggestions: findSuggestionsByUserSearch(
              inputChange.value,
              searchByParams
            ),
            shouldShowSuggestions: inputChange.shouldShowSuggestions,
            origin: inputChange.origin
          })
        )
        trackSearchRevampFilterApplied(inputChange.origin, inputChange.value)
      }, inputChange.delay)
    }

    return () => {
      handler && clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputChange])
  return { debounceValue }
}
