import React, { Fragment } from 'react'
import classnames from 'classnames'

import PlusWhite from '../../assets/icons/plus-white.png'
import GiftCard from '../../assets/svg/giftcard.svg'

const AddSetting = props => {
  const {
    className,
    style,
    label,
    disabled,
    onClick,
    actionIsActive,
    actionComponent,
    isLoading,
    redeem,
    payment,
    testId
  } = props

  return (
    <div
      data-test-id={testId}
      className={classnames('cookunity__add_setting', className, {
        loading: isLoading,
        redeem
      })}
      style={style}>
      {!actionIsActive ? (
        <Fragment>
          <img
            src={redeem ? GiftCard : PlusWhite}
            alt="plus icon"
            className={classnames('icon', { disabled, payment, redeem })}
          />

          <span
            className={classnames('label', { disabled })}
            onClick={() => {
              if (!disabled) {
                onClick()
              }
            }}>
            {label}
          </span>
        </Fragment>
      ) : (
        actionComponent
      )}
    </div>
  )
}

export default AddSetting
