import DOMPurify from 'dompurify'

/**
 * Helpers
 */

const emojiMapper = {
  ':wave:': '👋',
  ':chef:': '👨‍🍳',
  ':wink:': '😉',
  ':meal:': '🍽️',
  ':smile:': '😊',
  ':heart:': '❤️',
  ':moneybag:': '💰',
  ':sparkles:': '✨',
  ':tada:': '🎉',
  ':clinking_glasses:': '🥂',
  ':fork_and_knife:': '🍴',
  ':fire:': '🔥',
  ':rocket:': '🚀',
  ':star:': '⭐',
  ':christmas_tree:': '🎄'
}

export const stringToEmoji = (text: string) => {
  return text.replace(/:[a-z_]+:/g, match => {
    return emojiMapper[match] || match
  })
}

export const sanitizeHtml = (html: string) => {
  const sanitizedHtml = DOMPurify.sanitize(stringToEmoji(html))
  return sanitizedHtml
}
