import moment from 'moment'

export const calculateCurrentDate = (upcomingDays, now = moment()) => {
  const day = upcomingDays.find(day => day.available && day.scheduled)

  if (!day) {
    var nextMonday = now
      .clone()
      .add(1, 'week')
      .startOf('week')
      .day(1)

    return nextMonday.format('YYYY-MM-DD')
  }

  return day.date
}

export const formatUnsubscribeReasonsData = ({ subscriptionEventReasons }) => {
  const unsubscribeReasons = []

  subscriptionEventReasons.forEach(reason => {
    if (reason.type === 'title') {
      unsubscribeReasons.push({
        ...reason,
        children: []
      })
    } else {
      const lastParentIndex = unsubscribeReasons.length - 1
      unsubscribeReasons[lastParentIndex].children.push({
        ...reason
      })
    }
  })

  return { unsubscribeReasons }
}

export const filterByDateInvoices = invoices => {
  if (invoices && invoices.length > 0) {
    const month = moment(invoices[0].date).format('MM')
    const year = moment(invoices[0].date).format('YYYY')
    return invoices.reduce((accumulator, invoice) => {
      const monthIndex = moment(invoice.date).format('MM')
      const yearIndex = moment(invoice.date).format('YYYY')
      if (monthIndex === month && yearIndex === year) {
        accumulator.push(invoice)
      }
      return accumulator
    }, [])
  }
  return invoices
}

export const helperStatus = ({ inFlight, succeeded, error }) => {
  return { inFlight, succeeded, error }
}

export const messagesThreadSorted = messages =>
  messages &&
  messages.sort((a, b) => {
    const dateA = moment(a.date)
    const dateB = moment(b.date)
    if (dateA.isAfter(dateB)) return -1
    return 1
  })

export const formatDeliveryDays = deliveryDays => {
  return deliveryDays.map(number => ({
    day: number,
    label: moment()
      .day(number)
      .format('dddd'),
    value: moment()
      .day(number)
      .format('ddd')
      .toUpperCase()
  }))
}
