import { FunctionComponent } from 'react'
import {
  AutopilotQuestionOption,
  AutopilotQuestionType,
  AutopilotTabQuestion,
  HOUSEHOLD_OPTION_IDS,
  YES_NO_IDS,
  YesNoOptionType
} from 'src/types/preferences'
import HouseholdQuestion from 'src/pages/preferences/components/AutopilotTab/components/AutopilotQuestion/components/HouseholdQuestion/HouseholdQuestion'
import ProductsBasedQuestion from 'src/pages/preferences/components/AutopilotTab/components/AutopilotQuestion/components/ProductsBasedQuestion/ProductsBasedQuestion'
import { AutopilotFormData } from 'src/pages/preferences/PreferencesPage'
import { ReactComponent as HouseholdJustMe } from 'src/assets/svg/autopilot/household-just-me.svg'
import { ReactComponent as HouseholdMeAndSomeoneElse } from 'src/assets/svg/autopilot/household-me-and-someone-else.svg'
import { ReactComponent as HouseholdMeAndMore } from 'src/assets/svg/autopilot/household-me-and-more.svg'
import { DropdownSelectorItem } from 'src/pages/preferences/components/DropdownSelector/DropdownSelector'
import {
  SECTIONS,
  SECTIONS_PRIORITIES
} from 'src/pages/preferences/components/ProgressBar/constants'

export const SAVE_MODAL_ACTIONS = {
  SAVE: 'save',
  PROCEED: 'proceed',
  CLOSE: 'close'
}

export type TypedAutopilotQuestionProps = Pick<
  AutopilotTabQuestion,
  | 'title'
  | 'subtitle'
  | 'info'
  | 'question'
  | 'clarification'
  | 'allowMultipleSelection'
  | 'showOptInOptions'
  | 'options'
  | 'userAttribute'
> & {
  autopilotFormData: AutopilotFormData
  onUpdate: Function
  onQuestionClicked: Function
  isDisabled: boolean
}

export const AutopilotQuestionComponentByType: Record<
  AutopilotQuestionType,
  FunctionComponent<TypedAutopilotQuestionProps>
> = {
  [AutopilotQuestionType.HOUSEHOLD]: HouseholdQuestion,
  [AutopilotQuestionType.PRODUCTS_BASED]: ProductsBasedQuestion
}

export const YES_NO_OPTIONS: YesNoOptionType[] = [
  { id: YES_NO_IDS.NO, label: 'No' },
  { id: YES_NO_IDS.YES, label: 'Yes' }
]

export const mapSelectedOptions = (
  options: DropdownSelectorItem[],
  selected: string | string[] | DropdownSelectorItem[] | null
): DropdownSelectorItem[] => {
  if (typeof selected === 'string') {
    const fullSelectedOption = options.find(option => option.id === selected)

    return [fullSelectedOption as AutopilotQuestionOption]
  }

  if (Array.isArray(selected) && selected.length > 0) {
    if (typeof selected[0] === 'string') {
      return options.filter(option =>
        (selected as string[]).includes(option.id)
      )
    }

    return selected as DropdownSelectorItem[]
  }

  return []
}

export const HOUSEHOLD_ICON_BY_ID: Record<
  HOUSEHOLD_OPTION_IDS,
  FunctionComponent
> = {
  [HOUSEHOLD_OPTION_IDS.JUST_ME]: HouseholdJustMe,
  [HOUSEHOLD_OPTION_IDS.ME_AND_SOMEONE_ELSE]: HouseholdMeAndSomeoneElse,
  [HOUSEHOLD_OPTION_IDS.ME_AND_MORE]: HouseholdMeAndMore
}

export const updateBasedOnNoPreferenceOption = (
  options: DropdownSelectorItem[]
): DropdownSelectorItem[] => {
  const noPreferenceIndex = options.findIndex(
    ({ id }) => id === 'any' || id === 'mixed' || id === 'no-preference'
  )

  if (noPreferenceIndex === -1) {
    return options
  }

  return noPreferenceIndex === 0 ? [options[1]] : [options[options.length - 1]]
}

export const getProgressData = profiles => {
  let percentage = 0,
    firstIncompleteRule

  SECTIONS_PRIORITIES.forEach(rule => {
    if (!rule.profileIndex || rule.profileIndex < profiles.length) {
      if (checkRule(rule, profiles)) {
        percentage +=
          profiles.length === 1
            ? rule.percentage.single
            : rule.percentage.double
      } else if (!firstIncompleteRule) {
        firstIncompleteRule = rule
      }
    }
  })

  return { rule: firstIncompleteRule, percentage }
}

export const checkRule = (rule, profiles) => {
  if (rule.profileIndex) {
    if (
      !profiles?.[rule.profileIndex] ||
      rule.profileIndex >= profiles.length
    ) {
      return true
    }
  }

  return isSectionCompleted(rule.section, profiles[rule.profileIndex])
}

export const isSectionCompleted = (sectionName, profile) => {
  switch (sectionName) {
    case SECTIONS.ALLERGENS:
      return profile.allergies?.length > 0 || profile.noSelection.allergies
    case SECTIONS.DIET:
      return profile.diets?.length > 0
    case SECTIONS.LIFESTYLE:
      return profile.lifestyle?.length > 0 || profile.noSelection.lifestyle
    case SECTIONS.PROTEINS:
      return profile.proteins?.length > 0 || profile.noSelection.proteins
    case SECTIONS.INGREDIENTS:
      return profile.ingredients?.length > 0 || profile.noSelection.ingredients
    case SECTIONS.CUISINES:
      return profile.cuisines?.length > 0 || profile.noSelection.cuisines
    default:
      return false
  }
}
