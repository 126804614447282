import React from 'react'
import { useSelector } from 'react-redux'
import { Component, ComponentProps, ComponentType } from 'src/types/component'
import { BottomSheet } from 'src/pages/orders/components/RatingTab/components'
import Card from 'src/pages/preferences/components/Card/Card'
import { MealRatingCardDetail } from 'src/pages/orders/components/RatingTab/components/MealRatingCardDetail/MealRatingCardDetail'
import {
  getCurrentProductId,
  isLoadingRatingProduct
} from 'src/redux-api-bridge/ratings/selectors'
import { useRatingProduct } from 'src/hooks/useRatingProduct'
import './MealRatingCard.scss'

interface MealRatingCardProps extends ComponentProps {
  id: string
  title: string
  image: {
    name: string
  }
  childrenAsProps?: Component[]
}

export const MealRatingCard: React.FC<MealRatingCardProps> = ({
  id,
  title,
  image,
  children,
  childrenAsProps = []
}) => {
  const currentProductId: number | string = useSelector(getCurrentProductId)
  const isLoading: boolean = useSelector(isLoadingRatingProduct)
  const bottomSheet = childrenAsProps.find(
    child => child?.type === ComponentType.BOTTOM_SHEET
  )

  const {
    openRatingProductModal,
    closeRatingProductModal,
    submitRatingProductModal
  } = useRatingProduct(id, title)

  return (
    <>
      {!!bottomSheet && (
        <BottomSheet
          isOpen={currentProductId === id}
          onClose={closeRatingProductModal}
          onSubmit={submitRatingProductModal}
          isLoading={isLoading}
          {...bottomSheet.props}
          mealProps={{
            id,
            title,
            image,
            chef: children
          }}
          className="rating-detail-bottom-sheet"
          childrenAsProps={bottomSheet.children}
        />
      )}
      <Card className="meal-rating-card">
        <MealRatingCardDetail
          id={id}
          title={title}
          image={image}
          onRate={openRatingProductModal}>
          {children}
        </MealRatingCardDetail>
      </Card>
    </>
  )
}
