import { FC } from 'react'
import { Link } from 'react-router-dom'
import { CuiButton } from 'front-lib'

interface Props {
  to: string
  text: string
  onClick: (text: string) => void
}

const ButtonLink: FC<Props> = ({ to, text, onClick = () => {}, ...props }) => {
  return (
    <Link to={to} onClick={() => onClick(text)}>
      <CuiButton {...props}>{text}</CuiButton>
    </Link>
  )
}

export default ButtonLink
