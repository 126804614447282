import { useEffect, useState } from 'react'
import { CuiButton } from 'front-lib'
import { CompletenessCircle } from './components/CompletenessCircle/CompletenessCircle'
import { capitalize } from 'src/utils/utils'
import { COMPLETED_SUBTITLE, getTitle, isCompleted } from './constants'
import tracking from 'src/shared/tracking'
import { UserProfile } from 'src/types/preferences'
import { getProgressData } from 'src/pages/preferences/utils'
import './ProgressBar.scss'

type ProgressBarProps = {
  profiles: UserProfile[]
  focusSection: Function
}

const ProgressBar = ({ profiles, focusSection }: ProgressBarProps) => {
  const [percentage, setPercentage] = useState(0)
  const [incompleteRule, setIncompleteRule] = useState<any>()

  useEffect(() => {
    const { rule, percentage } = getProgressData(profiles)
    setPercentage(percentage)
    setIncompleteRule(rule)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles])

  const getCtaText = () => {
    const name = profiles?.[incompleteRule?.profileIndex]?.firstname
    return incompleteRule?.cta.replace('{name}', capitalize(name || ''))
  }

  const onCtaClick = () => {
    tracking.track('Overlay Clicked', {
      overlay_name: 'Eater Profile - progress bar',
      overlay_class: 'custom',
      overlay_source: 'custom',
      overlay_type: 'custom',
      page_name: 'Preferences',
      overlay_trigger: 'automatic',
      overlay_action: 'Complete Profile'
    })
    focusSection(
      profiles[incompleteRule.profileIndex]?.id,
      incompleteRule.section
    )
  }

  return (
    <div className="progress-bar__container">
      <div className="progress-bar__content">
        <CompletenessCircle percentage={percentage} />
        <div>
          <div className="progress-bar__title">{getTitle(percentage)}</div>
          <div className="progress-bar__subtitle">
            {isCompleted(percentage)
              ? COMPLETED_SUBTITLE
              : incompleteRule?.subtitle}
          </div>
        </div>
      </div>
      {!!incompleteRule && (
        <div className="progress-bar__cta">
          <CuiButton fill="outline" color="dark" onClick={onCtaClick}>
            {getCtaText()}
          </CuiButton>
        </div>
      )}
    </div>
  )
}

export { ProgressBar }
