import moment from 'moment'
import { getTotalMealsOrder } from './utils'
import {
  OrderItemToTrackType,
  OrderToTrackParamsType
} from '../types/tracking-types'

function orderItemsToTrackMapper(items: OrderItemToTrackType[]) {
  return items.map((item: OrderItemToTrackType) => {
    // TODO item.nutritionalFacts is object when creating and (empty) array when editing
    // undefined when adding bundles
    const { __typename, ...nutritional_info } = item.nutritionalFacts || {}
    const specificationsDetails = item.specificationsDetails || []
    return {
      category: item.category,
      chef_id: item.chef?.id,
      chef_name: item.chefName,
      id: item.entity_id,
      image_url: item.imageUrl,
      name: item.title,
      nutritional_info,
      is_premium: item.is_premium,
      price: item.price,
      quantity: item.qty,
      rating: item.stars,
      sku: item.sku,
      specification: JSON.stringify(specificationsDetails.map(s => s.label))
    }
  })
}

export function orderToTrackMapper(params: OrderToTrackParamsType) {
  const {
    pageName,
    currentOrder,
    currentDate,
    selectedTimeslot,
    orderStatus,
    orderDetail
  } = params

  const cutOffDay = currentDate?.cutoff?.time
    ? moment(currentDate.cutoff.time).format('YYYY-MM-DD')
    : null

  return {
    page_name: pageName,
    delivery_date: currentDate?.displayDate,
    order_status: orderStatus,
    shipping_date: currentDate?.date,
    delivery_address: currentOrder?.delivery_address,
    delivery_time: `${moment(selectedTimeslot?.start, 'h:mm A').format(
      'h:mm A'
    )} ${moment(selectedTimeslot?.end, 'h:mm A').format('h:mm A')}`,
    expected_delivery_date: currentOrder?.delivery_date,
    is_edited_order: currentOrder?.edited,
    order_addons_count: currentOrder?.order_addons_count,
    order_currency: currentOrder?.order_currency,
    order_cutoff_date: cutOffDay,
    order_discount: orderDetail?.totalPromoDiscount,
    order_id: Number(currentOrder?.id),
    order_product_count: getTotalMealsOrder(currentOrder),
    order_subtotal: orderDetail?.subTotalOrder,
    order_taxes: orderDetail?.totalTaxes,
    order_total: orderDetail?.totalOrder,
    products: orderItemsToTrackMapper(currentOrder?.items || []),
    promotion_coupon: currentOrder?.coupon,
    shipping_days: Number(currentOrder?.shipping_days)
  }
}
