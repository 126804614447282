import * as client from '../../api/Resurrection'
import * as actions from './actions'

export const getResurrectionCampaign = ({ campaignSlug }) => dispatch => {
  dispatch(actions.getResurrectionCampaignStart())
  return client
    .getResurrectionCampaign({ campaignSlug })
    .then(({ data }) => {
      dispatch(
        actions.getResurrectionCampaignSuccess(data.getResurrectionCampaign)
      )
    })
    .catch(err => {
      dispatch(
        actions.getResurrectionCampaignFail({
          err
        })
      )
    })
}

export const getFeaturedMeals = ({ attrs, source }) => dispatch => {
  dispatch(actions.getFeaturedMealsStart())
  return client
    .getFeaturedMeals(attrs, source)
    .then(({ data }) => {
      dispatch(actions.getFeaturedMealsSuccess(data.featuredMeals))
    })
    .catch(err => {
      dispatch(
        actions.getFeaturedMealsFail({
          err
        })
      )
    })
}
