import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { mapMealsForMembershipRefetch } from 'src/modules/MyPlan/components/OrderSummaryV2/utils'
import { ReactComponent as Alert } from '../../../../assets/svg/alert-circle.svg'
import { getOrderWithStock } from 'src/redux-api-bridge/order/selectors'
import { useStartMembership } from 'src/api/Membership/useStartMembership'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { fetchOrderDetail } from 'src/redux-api-bridge/order/effects'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { membershipModalAtom } from 'src/atoms/modals/membershipModal.atom'
import {
  MembershipData,
  MembershipOverlayActions,
  MembershipOverlayName,
  Step
} from 'src/types/membership'

import './MembershipCheckout.scss'
import { useRejoinMembership } from 'src/api/Membership/useRejoinMembership'

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL

interface Props {
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipCheckout({
  membershipData,
  onContinue,
  refetch
}: Props) {
  const [errorMessage, setErrorMessage] = useState('')
  const { isRejoin } = useAtomValue(membershipModalAtom)
  const { startMembership, loading: startLoading } = useStartMembership({
    onCompleted,
    onError
  })
  const { rejoinMembership, loading: rejoinLoading } = useRejoinMembership({
    onCompleted,
    onError
  })
  const location = useLocation()
  const dispatch = useDispatch()
  const orderWithStock = useSelector(getOrderWithStock)
  const origin = isOrderSummaryPath()
    ? 'Order checkout upselling banner'
    : 'User menu'
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal({
    overlayName: MembershipOverlayName.checkout,
    origin
  })

  function isOrderSummaryPath() {
    const currentPath = location.pathname
    return currentPath.includes('/meals')
  }

  useEffect(() => {
    trackModalViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onPurchaseMembership() {
    if (membershipData?.membership.id) {
      setErrorMessage('')
      if (isRejoin) {
        rejoinMembership({ membershipId: membershipData.membership.id })
      } else {
        startMembership({ membershipId: membershipData.membership.id })
      }
      trackOptionClicked({ overlayAction: MembershipOverlayActions.confirm })
    }
  }

  function onCompleted(response) {
    if (
      response.startMembership?.success ||
      response.rejoinMembership?.success
    ) {
      if (isOrderSummaryPath()) {
        const meals = mapMealsForMembershipRefetch(orderWithStock)
        dispatch(fetchOrderDetail(meals))
      }
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.confirmation)
    } else {
      onError(response)
    }
  }

  function onError(response) {
    const error =
      response?.startMembership?.error || response?.rejoinMembership?.error
    setErrorMessage(
      error || 'Sorry, we encountered an error. Please try again.'
    )
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="checkout-container">
        {(startLoading || rejoinLoading) && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <span className="title">Please review your Unity Pass purchase</span>
        <div className="summary-container">
          <span className="title">Order Summary</span>
          <div className="detail">
            <span>Unity Pass</span>
            <span>${membershipData?.membership.cost}</span>
          </div>
          <div className="summary-description">
            <span className="first">
              Benefits: <strong>free delivery</strong>, exclusive discounts,
              three premium meals per order at no extra fee.
            </span>
            <span className="second">
              This is a recurring monthly charge — cancel anytime.
            </span>
          </div>
          <hr className="dotted-line"></hr>
          <div className="summary-footer">
            <span>ORDER TOTAL</span>
            <span>${membershipData?.membership.cost}</span>
          </div>
        </div>
        {!errorMessage && (
          <div className="terms">
            <span>
              By tapping the button, I agree to the{' '}
              <a target="_blank" href={`${BASE_URL}/terms`} rel="noreferrer">
                Terms and Conditions
              </a>{' '}
              and a recurring monthly charge of $
              {membershipData?.membership.cost} plus tax until canceled. To
              avoid charges, cancel in your account before renewal.
            </span>
          </div>
        )}
        {errorMessage && (
          <div className="error">
            <div className="image">
              <Alert className="alert-error" />
            </div>
            <span>{errorMessage}</span>
          </div>
        )}
        <div className="checkout-buttons">
          <CuiButton onClick={onPurchaseMembership}>Confirm purchase</CuiButton>
        </div>
      </div>
    </>
  )
}
