import React from 'react'
import styled from 'styled-components'

import LoaderAnimation from '../../../../common/Loader'

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`
const Loader = () => (
  <Container>
    <LoaderAnimation />
  </Container>
)

export default Loader
