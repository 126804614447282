import React from 'react'
import Select, { components } from 'react-select'

import OpenIndicator from '../../assets/icons/dropdown_arrow_down.png'
import CloseIndicator from '../../assets/icons/dropdown_arrow_up.png'
import './css/Select.scss'

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen }
  } = props
  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <img alt="close-dropdown" src={CloseIndicator} />
      ) : (
        <img alt="open-dropdown" src={OpenIndicator} />
      )}
    </components.DropdownIndicator>
  )
}

const CustomSelect = props => {
  return (
    <Select
      className="dietary-dropdown-container"
      classNamePrefix="dietary-dropdown"
      components={{ DropdownIndicator }}
      {...props}
    />
  )
}

export default CustomSelect
