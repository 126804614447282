import React from 'react'
import classNames from 'classnames'
import './Card.scss'

type CardProps = {
  className?: string
  children: React.ReactNode
}

const Card: React.FC<CardProps> = ({ className = '', children }) => {
  return <div className={classNames('card', className)}>{children}</div>
}

export default Card
