import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { useCreateSkip } from 'src/api/order/useCreateSkip'
import mobileAndTabletcheck from 'src/utils/mobileAndTabletcheck'
import { track } from 'src/shared/tracking'
import { getTotalMealsOrder } from 'src/utils/utils'
import { fetchMyPlan } from 'src/redux-api-bridge'
import { dayByDeliveryDateSelector } from 'src/components/DeliveryCard/selectors'
import { RoutePaths } from 'src/routes/routePaths'
import { orderSkippedSuccessModalAtom } from 'src/atoms/modals/orderSkippedSuccessModal.atom'

export function useSkipOrder({
  date,
  onCompleted
}: {
  date: string
  onCompleted?: () => void
}) {
  const [dates, setDates] = useState({ date: '', displayDate: '' })
  const day = useSelector(state => dayByDeliveryDateSelector(state, date))
  const history = useHistory()
  const setOderSkippedSuccessModal = useSetAtom(orderSkippedSuccessModalAtom)
  const dispatch = useDispatch()
  const { createSkip } = useCreateSkip({
    onCompleted: onCompleted || onSkippedSuccess
  })

  useEffect(() => {
    if (day) {
      setDates({ date: day.date, displayDate: day.displayDate })
    }
  }, [day])

  function skipOrder() {
    const origin = getSkipOrigin()
    const order = day.order
    const { date, displayDate } = dates
    createSkip({
      skip: { date, deliveryDate: displayDate },
      origin
    })
    trackEventSkipDay({ date, order })
  }

  function onSkippedSuccess() {
    dispatch(fetchMyPlan)
    history.push(`${RoutePaths.ORDERS}`)
    setOderSkippedSuccessModal({ isOpen: true, date: dates.displayDate })
  }

  function trackEventSkipDay({ date, order }) {
    const deliveryTime = order
      ? `${moment(order.time_start, 'h:mm A').format('h:mm A')} ${moment(
          order.time_end,
          'h:mm A'
        ).format('h:mm A')}`
      : '-'

    const numberOfMeals = getTotalMealsOrder(order)

    track('Order Skipped', {
      order_skip_date: new Date(),
      delivery_date: date,
      delivery_time: deliveryTime,
      number_of_meals: numberOfMeals
    })
  }

  function getSkipOrigin() {
    const isMobile = mobileAndTabletcheck()
    return isMobile ? 'customer_web_mobile' : 'customer_web_desktop'
  }

  return {
    skipOrder
  }
}
