import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHALLENGE_IDS } from 'src/constants/challenge'
import { ChallengeData } from 'src/types/challenge'
import { getUpcomingDeliveries } from 'src/modules/MyDeliveries/selectors'
import { getSubscriptionPlanSelector } from 'src/modules/MyAccount/selectors'
import { getDateLabel } from 'src/utils/date'
import { formatTime } from 'src/utils/utils'
import { getChallengeStepCopies } from 'src/utils/challenge'
import {
  getAddMealStatus,
  getRemoveMealWishStatus
} from 'src/components/AddWishes/selectors'
import { getUserReferralsHistory } from 'src/modules/Referral/selectors'
import { fetchChallengeWithSteps } from 'src/redux-api-bridge/challenge/effects'
import {
  cleanAddMealWishStatus,
  cleanRemoveMealWishStatus
} from 'src/redux-api-bridge'
import { getProfilesStatus } from 'src/modules/MyPlan/selectors'
import { cleanProfileStatus } from 'src/redux-api-bridge'

const useChallengeSteps = (data: ChallengeData) => {
  const dispatch = useDispatch()
  const [loadingStep, setLoadingStep] = useState('')
  const upcomingDays = useSelector(getUpcomingDeliveries)
  const subscriptionPlan = useSelector(getSubscriptionPlanSelector)

  const addMealWishStatus = useSelector(getAddMealStatus)
  const removeMealWishStatus = useSelector(getRemoveMealWishStatus)
  const referrals = useSelector(getUserReferralsHistory)
  const profilesStatus = useSelector(getProfilesStatus)

  useEffect(() => {
    setLoadingStep('')
  }, [data?.steps])

  useEffect(() => {
    if (removeMealWishStatus.inFlight || addMealWishStatus.inFlight) {
      setLoadingStep('has_favorite_meal')
    }

    if (removeMealWishStatus.succeeded) {
      fetchStepsData()
      dispatch(cleanRemoveMealWishStatus())
    }
    if (addMealWishStatus.succeeded) {
      fetchStepsData()
      dispatch(cleanAddMealWishStatus())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeMealWishStatus, addMealWishStatus])

  useEffect(() => {
    if (referrals.inFlight) {
      setLoadingStep('has_referral')
    }
    if (referrals.succeed) {
      fetchStepsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrals])

  useEffect(() => {
    if (profilesStatus.inFlight) {
      setLoadingStep('has_eater_profile_setted')
    }

    if (profilesStatus.succeeded) {
      fetchStepsData()
      dispatch(cleanProfileStatus())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesStatus])

  const firstDeliveryInfo = useMemo(() => {
    const firstDelivery = upcomingDays.find(delivery => delivery.isFirstOrder)

    const orderExists = firstDelivery && firstDelivery.order
    const orderDelivered = new Date(firstDelivery?.displayDate) <= new Date()

    if (!orderExists || orderDelivered) {
      return null
    }

    return {
      deliveryDate: getDateLabel(new Date(firstDelivery.order.delivery_date), {
        month: 'long'
      }),
      deliveryWindow: `${formatTime(
        firstDelivery.order.time_start
      )} - ${formatTime(firstDelivery.order.time_end)}`,
      meals: subscriptionPlan?.mealsPerDelivery * subscriptionPlan?.deliveries
    }
  }, [upcomingDays, subscriptionPlan])

  const formatFiveMilestonesSteps = data => {
    const nextAvailableOrder = upcomingDays.find(
      order =>
        order.available && order.canEdit && !order.skip && !order.isPaused
    )

    const nextOrder = upcomingDays.find(
      order =>
        order.available &&
        order.canEdit &&
        order.menuAvailable &&
        !order.skip &&
        !order.isPaused &&
        !order.order
    )

    return data.steps.map(step => {
      const copies = getChallengeStepCopies(step, {
        firstDelivery: firstDeliveryInfo,
        date: nextAvailableOrder?.displayDate || '',
        nextOrderDate: nextOrder?.displayDate || ''
      })

      return {
        ...step,
        ...copies,
        isLoading: loadingStep === step.type,
        VALUES: {
          deliveryDate: firstDeliveryInfo?.deliveryDate,
          deliveryWindow: firstDeliveryInfo?.deliveryWindow,
          meals: firstDeliveryInfo?.meals
        }
      }
    })
  }

  const stepsData = useMemo(() => {
    if (data?.id === CHALLENGE_IDS.FIVE_MILESTONES) {
      return formatFiveMilestonesSteps(data)
    }
    if (data?.id === CHALLENGE_IDS.THREE_ORDERS) {
      return data.steps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, upcomingDays, loadingStep])

  const fetchStepsData = () => {
    if (data && data.id) {
      dispatch(fetchChallengeWithSteps(data.id))
    }
  }

  return {
    stepsData
  }
}

export default useChallengeSteps
