import React from 'react'
import { ComponentProps } from 'src/types/component'
import {
  Text,
  TextStyle
} from 'src/pages/orders/components/RatingTab/components'
import { LinkProps } from 'src/pages/orders/components/RatingTab/components/Link/Link'
import { SafeImage } from 'src/componentsV2/SafeImage/SafeImage'
import './ChefLink.scss'

const chefNoImage =
  'https://play-lh.googleusercontent.com/N33Lq9SyPb_gs3x--sCVG8ccFr2ocSOO-uIZUrVddoLfXmCnJW_NZmHCrND9gFf5JQw'

export type ChefLinkProps = ComponentProps & Omit<LinkProps, 'subType'>

export const ChefLink: React.FC<ChefLinkProps> = ({ text, image }) => {
  return (
    <div className="chef-link">
      <SafeImage
        imageSrc={image.name}
        fallbackSrc={chefNoImage}
        alt={text}
        className="chef-link__img"
      />
      {!!text && (
        <Text
          value={text}
          style={TextStyle.XS_BOLD}
          className="chef-link__text"
        />
      )}
    </div>
  )
}
