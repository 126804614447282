import React, { FC, MouseEvent, useEffect } from 'react'
import './FullScreenError.scss'
import { CuiLink } from 'front-lib'
import { useTranslation } from 'react-i18next'
import Analytics from '../../utils/analytics'
import { RoutePaths } from '../../routes/routePaths'

export const FullScreenError: FC = () => {
  const { t } = useTranslation()

  useEffect(() => {
    Analytics.track('Error Screen')
  }, [])

  const onGoToMyDeliveries = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    await Analytics.track('GoToMyDeliveries Clicked - Error')
    window.location.href = RoutePaths.ORDERS
  }

  return (
    <div className="container-cui-404">
      <h1 className="title">{t('fullScreenError.title')}</h1>
      <CuiLink
        size="large"
        onClick={onGoToMyDeliveries}
        className="btn lead-hero"
        type="button">
        <p className="rebrand bold">{t('fullScreenError.button')}</p>
      </CuiLink>
    </div>
  )
}
