import createReducer from '../../utils/createReducer'

import * as actions from './actions'

const initialState = {
  cards: null,
  userCardsStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  createCardStatus: {
    inFlight: false,
    succeeded: false,
    error: false
  },
  removeCardStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  defaultCardStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  },
  redeemGiftCardStatus: {
    inFlight: false,
    succeeded: false,
    error: null
  }
}
export default createReducer(initialState, {
  [actions.getPaymentMethods.start.type]: state => ({
    ...state,
    userCardsStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.getPaymentMethods.succeed.type]: (state, action) => ({
    ...state,
    cards: action.payload.cards || [],
    userCardsStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.getPaymentMethods.failed.type]: state => ({
    ...state,
    cards: [],
    userCardsStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.setDefaultPayment.succeed.type]: (state, action) => ({
    ...state,
    subscription: {
      ...state.subscription,
      info: (action.payload || {}).subscription
    },
    defaultCardStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.setDefaultPayment.failed.type]: state => ({
    ...state,
    defaultCardStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.setDefaultPayment.start.type]: state => ({
    ...state,
    defaultCardStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.createCard.start.type]: state => ({
    ...state,
    createCardStatus: {
      inFlight: true,
      succeeded: false,
      error: false
    }
  }),
  [actions.createCard.succeed.type]: (state, action) => ({
    ...state,
    createCardStatus: {
      inFlight: false,
      succeeded: true,
      error: false,
      payload: action.payload
    }
  }),
  [actions.createCard.failed.type]: state => ({
    ...state,
    createCardStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  }),
  [actions.removePaymentMethod.start.type]: state => ({
    ...state,
    removeCardStatus: {
      inFlight: true,
      succeeded: false,
      error: null
    }
  }),
  [actions.removePaymentMethod.succeed.type]: state => ({
    ...state,
    removeCardStatus: {
      inFlight: false,
      succeeded: true,
      error: null
    }
  }),
  [actions.removePaymentMethod.failed.type]: (state, action) => ({
    ...state,
    removeCardStatus: {
      inFlight: false,
      succeeded: false,
      error: action.payload
    }
  }),
  [actions.redeemGiftCard.start.type]: state => ({
    ...state,
    redeemGiftCardStatus: {
      inFlight: true,
      succeeded: false,
      error: false
    }
  }),
  [actions.redeemGiftCard.succeed.type]: (state, action) => ({
    ...state,
    redeemGiftCardStatus: {
      inFlight: false,
      succeeded: true,
      error: false,
      payload: action.payload
    }
  }),
  [actions.redeemGiftCard.failed.type]: state => ({
    ...state,
    redeemGiftCardStatus: {
      inFlight: false,
      succeeded: false,
      error: true
    }
  })
})
