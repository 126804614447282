import { handleActions } from 'redux-actions'

import { helperStatus } from '../helpers'

import * as actions from './actions'

const initialState = {
  inFlight: false,
  succeeded: false,
  error: false,
  incentive: null,
  date: null,
  weeksSinceFirstDelivery: -1
}

export default handleActions(
  {
    [actions.getHappyPathInfoStart]: state => {
      return {
        ...state,
        incentive: null,
        date: null,
        ...helperStatus({ inFlight: true, succeeded: false, error: false })
      }
    },
    [actions.getHappyPathInfoSuccess]: (state, { payload }) => {
      return {
        ...state,
        incentive: payload.incentive,
        date: payload.date,
        weeksSinceFirstDelivery: payload.weeksSinceFirstDelivery,
        ...helperStatus({ inFlight: false, succeeded: true, error: false })
      }
    },
    [actions.getHappyPathInfoFail]: (state, { payload }) => {
      return {
        ...state,
        incentive: null,
        date: null,
        ...helperStatus({
          inFlight: false,
          succeeded: false,
          error: payload.err
        })
      }
    }
  },
  initialState
)
