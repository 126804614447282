import { createSelector } from 'reselect'
import { pathOr } from 'ramda'

export const getUserSelector = state =>
  pathOr(null, ['api', 'user', 'info'], state)

export const getSubscriptionInfo = state =>
  pathOr(null, ['api', 'subscription', 'info'], state)

export const getSubscriptionId = state =>
  pathOr(null, ['api', 'subscription', 'info', 'id'], state)

export const getSubscriptionPlanSelector = state =>
  pathOr(null, ['api', 'subscription', 'userPlan'], state)

const getAddresses = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses'], state)

export const getExpireDateSelector = state =>
  pathOr(null, ['api', 'subscription', 'info', 'expireDate'], state)

const getDefaultAddressZipcode = createSelector([getAddresses], addresses => {
  if (addresses && addresses.length) {
    return addresses[0].postcode
  }
  return ''
})

export const myAccountIsLoadingSelector = createSelector(
  [getDefaultAddressZipcode, getSubscriptionInfo],
  (zipcode, subscription) => !(zipcode && subscription)
)

export const userInfoIsLoadingSelector = createSelector(
  [getUserSelector, getSubscriptionPlanSelector],
  (user, plan) => !(user && plan)
)

export const getReferralCode = state =>
  pathOr(null, ['api', 'subscription', 'info', 'referral_code'], state)

export const getDeliveryAddress = state =>
  pathOr(null, ['api', 'subscription', 'info', 'addresses', 0], state)

export const getDeliveryDay = state =>
  pathOr(null, ['api', 'subscription', 'info', 'deliveryDays', 0, 'day'], state)

export const getPlanSize = state =>
  pathOr(null, ['api', 'subscription', 'userPlan', 'mealsPerDelivery'], state)
